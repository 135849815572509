import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children, ...propierties }) => {
  const user = localStorage.getItem("token");

  if (user) {
    return <Route {...propierties}>{children}</Route>;
  } else {
    return <Redirect to="/login" />;
  }
};

export default PrivateRoute;
