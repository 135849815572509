// import gifLogo1 from '../../assets/img/Logo-1.gif'
import gifLogo1 from "../../assets/img/Logo-1.gif";
import { toTop } from "../../utils/animationFunctions";

export default function GifLoader() {
  toTop();
  return (
    <div className="container-gif">
      <div className="d-flex justify-content-center align-items-center h-100">
        <img src={gifLogo1} className="logoGif" alt="Logo Gif" />
      </div>
    </div>
  );
}
