import React, { useState, useEffect, useRef, useContext } from "react";
import { services } from "../services/api";
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from 'react-select';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slide from '@mui/material/Slide';
import GifLoader from "../components/ui/gifLoader";
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom'
import capturaContext from "../context/captura_context/capturaContext";
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import Rating from '@mui/material/Rating';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const FormularioDinamico = () => {


  const colorUI = "#6f1d46";
  // const colorUI = "#8d949e";


  const { envio_conceptos, TPDiverso } = useContext(capturaContext);
  const { categoria, concepto, folio } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [checked, setchecked] = useState(false);
  const containerRef = useRef(null);

  let history = useHistory();

  const [checkvalue, setCheckvalue] = useState({});


  const { CANTIDAD, IMPORTE } = envio_conceptos[0]


  const [Calificacion, setCalificacion] = useState({
    calificacionValor: 0,
    openModalCali: false
  })

  if (TPDiverso) {
    localStorage.setItem("cantidad", CANTIDAD)
    localStorage.setItem("TPDiverso", TPDiverso)
    localStorage.setItem("IMPORTE", IMPORTE)
  }


  const styleCalificacion = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: "30px",
    boxShadow: 24,
    p: 4,

    marginBottom: "4%"
  };

  const [state, setState] = useState({
    data: {},
    id: "",
    email: "",
    razonSocial: "",
    rfc: "",
    erroremail: false,
    erroremailMensaje: "",
    errorRazonSocial: false,
    errorRazonSocialMensaje: "",
    errorRfc: false,
    errorRfcMensaje: "",
    errorImporte: "",
    errorImporteMensaje: "",
    loading: true,
    descripcion: "",
    pagoLinea: 0,
    cantidad: CANTIDAD === 0 ? localStorage.getItem("cantidad") : CANTIDAD,
    tp_diverso: TPDiverso ?? localStorage.getItem("TPDiverso"),
    importe: '',
    documentos: true,
    folio: '',
    linkPago: '',
    tipoImporte: '',

  });




  const setValues = (id, value) => {


    setState({
      ...state, data: {
        ...state.data, campos: state.data.campos.map((item) => {

          if (item.id === id) {

            item.value = value

            return item

          } else {
            return item
          }
        }
        )
      }
    })

  }
  const handleSacarValor = (id) => {


    const response = state.data.campos.filter((item) => {

      if (item.id === id) {

        return item.value

      }
    }
    )

    return response[0];

  }

  const setChecksValue = (id, value) => {
    setState({
      ...state, data: {
        ...state.data, campos: state.data.campos.map((item) => {

          if (item.id === id) {

            if (checkvalue[value]) {
              delete checkvalue[value];
            } else {
              checkvalue[value] = value;
            }



            item.value = Object.keys(checkvalue)

            return item

          } else {
            return item
          }


        }
        )
      }
    })
  }

  const setValuesFiles = async (id, value) => {


    setState({
      ...state,
      data: {
        ...state.data, campos: state.data.campos.map((item) => {

          if (item.id === id) {

            const typeFile = value.target.files[0].name.split('.');

            const reader = new FileReader();
            reader.onloadend = async () => {

              const base64String = await reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

              item.value = base64String
              item.extencion = typeFile[typeFile.length - 1]
            };
            reader.readAsDataURL(value.target.files[0]);

            return item

          } else {
            return item
          }

        })

      },
      documentos: false
    })

    // console.log(state.data)
  }

  const setDataToDB = async () => {


    let recaptcha_token = "";

    await ReactRecaptcha3.getToken().then(token => {
      // console.log(token)

      recaptcha_token = token
      // send token with form data
      // dataToSend.token = token
      // fetch(url, { method: 'POST', body: JSON.stringify(dataToSend) })
    }, error => {
      // handle error here
      console.log(error)
    })

    let data = {
      formulario: [
        {
          nombre: state.data.nombre,
          campos: state.data.campos
        }
      ],
      id: state.id,
      email: state.email,
      // cantidad: parseFloat(state.CANTIDAD),
      cantidad: state.CANTIDAD,
      importe: state.importe,
      folio: state.folio,
      recaptcha_token: recaptcha_token
    }

    if (state.pagoLinea === 1) {

      data.rfc = state.rfc;
      data.razon_social = state.razonSocial;
      // data.tp_diverso = state.tp_diverso;
      data.tp_diverso = state.tp_diverso;

    }

    let response;
    if (folio) {
      response = await services('PUT', `solicitudes/peticion/modificar`, data)
    } else {
      response = await services('POST', `peticion`, data)
    }


    if (response.data.errores) {

      setState({
        ...state, erroremail: state.email === "", erroremailMensaje: state.email === "", errorRazonSocial: state.razonSocial === "", errorRazonSocialMensaje: state.razonSocial === "", loading: false, errorRfc: response.data.errores.rfc ? true : false, errorRfcMensaje: response.data.errores.rfc ? response.data.errores.rfc[0] : "", data: {
          ...state.data, campos: state.data.campos.map((item) => {

            if (Object.keys(response.data.errores).includes(item.id)) {


              let errormensaje = "";

              response.data.errores[item.id].map((error) => {
                errormensaje = `${errormensaje} ${error} ,`;
              })

              item["error"] = true;
              item["mensajeError"] = errormensaje;



              return item;

            }
            else {

              item["error"] = false;
              item["mensajeError"] = "";


              return item;

            }
          })


        }
      })



    } else if (response.data.error) {
      swal({
        title: "Solicitud",
        text: `${response.data.error}`,
        icon: "error",
        buttons: "Aceptar",
        confirmButton: true,
      }).then((Calificar) => {

        history.push('/home')

      });
    } else {
      // console.log("entra");
      // console.log(folio);

      if (folio) {
        // console.log("entra here");
        swal({
          title: "Solicitud Actualizada",
          text: `en próximos días te llegará una notificación a tu correo verificando que tu solicitud ha sido atendida para que puedas continuar con tu trámite \n folio:${folio}`,
          icon: "success",
          buttons: "Aceptar",
          confirmButton: true,
        }).then((Calificar) => {
          if (Calificar) {
            api(`solicitudes/detalle/peticion/${folio}`);

            swal({
              title: "Gracias por usar el servicio ¿Te gustaria calificarlo?",
              icon: "success",
              buttons: ["No", "Si"],
              confirmButton: true,
            })
              .then((Calificar) => {
                if (Calificar) {
                  setCalificacion({ ...Calificacion, openModalCali: true })
                }
              });

          }
        });

      } else {
        setState({
          ...state, loading: false, data: {
            ...state.data, campos: state.data.campos.map((item) => {

              item.error = false;
              item.mensajeError = ""

              return item

            })
          }
        });

        swal({
          title: "Solicitud Generada",
          text: `en próximos días te llegará una notificación a tu correo verificando que tu solicitud ha sido atendida para que puedas continuar con tu trámite \n folio:${response.data[0].folio}`,
          icon: "success",
          buttons: "Salir al menu",

          confirmButton: true,
        }).then((Calificar) => {
          if (Calificar) {
            history.push('/home')
          } else {
            history.push('/home')
          }
        });
      }
    }
  }

  const nextSteap = async () => {
    // setState({ ...state, loading: true });

    setState({ ...state, loading: true })

    if (state.email === "") {

      setState({ ...state, erroremail: true, erroremailMensaje: "El correo es Requerido", loading: false })

    }
    else if (state.importe === "") {

      setState({ ...state, errorImporte: true, errorImporteMensaje: "El Importe es Requerido", loading: false })

    }
    else {

      if (state.pagoLinea === 1) {
        if (state.razonSocial === "") {
          setState({
            ...state,
            errorImporte: false, errorImporteMensaje: "",
            erroremail: false, erroremailMensaje: "",
            errorRazonSocial: state.razonSocial === "", errorRazonSocialMensaje: state.razonSocial === "" ? "la Razon Social es Requerida" : "",
            errorRfc: state.rfc === "", errorRfcMensaje: state.rfc === "" ? "El RFC es Requerido" : "", loading: false
          })
        } else if (state.rfc === "") {
          setState({
            ...state,
            erroremail: false, erroremailMensaje: "",
            errorRazonSocial: state.razonSocial === "", errorRazonSocialMensaje: state.razonSocial === "" ? "la Razon Social es Requerida" : "",
            errorRfc: state.rfc === "", errorRfcMensaje: state.rfc === "" ? "El RFC es Requerido" : "", loading: false
          })
        } else {
          setDataToDB();
        }
      } else {
        setDataToDB();
      }

    }

  }

  const setComponent = (component) => {


    switch (component.type) {

      case "text":
      case "password":
      case "number":
      case "email":
      case "url":
      case "tel":

        return <>
          <InputLabel className="mx-2  FormularioDinamicoRadios">{component.label}</InputLabel>
          <TextField id={component.id}

            required={component.validaciones == "required" ? true : false}
            // label={component.label}
            name={component.name}
            type={component.type}
            fullWidth
            error={component.error ? true : false}
            defaultValue={component.value}
            helperText={component.mensajeError ? component.mensajeError : ""}
            onChange={(e) => { setValues(component.id, e.target.value) }}
            disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false}
          />


        </>


      case "textarea":

        return <>
          <InputLabel className="mx-2  FormularioDinamicoRadios">{component.label}</InputLabel>
          <TextField id={component.id}

            required={component.validaciones == "required" ? true : false}
            // label={component.label}
            name={component.name}
            type={component.type}
            fullWidth
            multiline
            maxRows={4}
            error={component.error ? true : false}
            helperText={component.mensajeError ? component.mensajeError : ""}
            defaultValue={component.value}
            onChange={(e) => { setValues(component.id, e.target.value) }}
            disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false}
          />

        </>


      case "file":

        return <>
          {folio ?
            state.documentos ?

              <CardActionArea onClick={console.log}>

                {component.extencion !== "pdf" && component.extencion !== "doc" && component.extencion !== "xmls" && component.extencion !== "docx" ?
                  <CardMedia
                    component="img"
                    height="150"
                    src={component.value}
                    alt={`Documento ${component.label}`}
                  />

                  :
                  component.extencion !== "pdf" ?

                    <a href={component.value} rel="noreferrer" target='_blank' style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', cursor: 'pointer', padding: '5px 7px', marginBottom: '10px', marginLeft: "25%", marginTop: "6%" }}><CloudDownloadIcon /> Descargar Documento</a>

                    :

                    <>
                      <CardMedia
                        component="iframe"
                        height="150"
                        src={component.value}
                        alt={`Documento ${component.label}`}
                      />
                      <a href={component.value} rel="noreferrer" target='_blank' style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', cursor: 'pointer', padding: '5px 7px', marginBottom: '10px' }}>Abrir PDF</a>
                    </>

                }

              </CardActionArea>
              :
              null
            :
            <>
              <InputLabel className="mx-2  FormularioDinamicoRadios">{component.label}</InputLabel>
              <TextField id={component.id}

                fullWidth
                required={component.validaciones == "required" ? true : false}

                name={component.name}
                type={component.type}
                error={component.error ? true : false}
                disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false}

                defaultValue={folio ? '' : component.value}
                helperText={component.mensajeError ? component.mensajeError : ""}
                onChange={(e) => { setValuesFiles(component.id, e) }}
              />
            </>

          }

          {state.estatusID === "5" ?
            <>
              <InputLabel className="mx-2  FormularioDinamicoRadios">{component.label}</InputLabel>
              <TextField id={component.id}

                fullWidth
                required={component.validaciones == "required" ? true : false}

                name={component.name}
                type={component.type}
                error={component.error ? true : false}
                disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false}

                defaultValue={folio ? '' : component.value}
                helperText={component.mensajeError ? component.mensajeError : ""}
                onChange={(e) => { setValuesFiles(component.id, e) }}
              />
            </>
            :
            null
          }


        </>

      case "date":
      case "datetime-local":
        return <>
          <InputLabel className="mx-2  FormularioDinamicoRadios">{component.label}</InputLabel>
          <TextField id={component.id}
            disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false}
            fullWidth
            required={component.validaciones == "required" ? true : false}
            name={component.name}
            type={component.type}
            defaultValue={component.value}
            placeholder='DD/MM/YYYY'
            onChange={(e) => { setValues(component.id, e.target.value) }}
            error={component.error ? true : false}
            helperText={component.mensajeError ? component.mensajeError : ""}
          />

        </>

      case "select":

        return <>
          <InputLabel className="mx-2 FormularioDinamicoRadios">{component.label}</InputLabel>

          <Select
            disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false}

            required={component.validaciones == "required" ? true : false}
            name={component.name}
            value={handleSacarValor(component.id)}
            id={component.id}
            onChange={(e) => {
              setValues(component.id, e.value)
            }}
            options={component.options}
            placeholder="Seleccione una opción"
            getOptionValue={options => options.id}
            getOptionLabel={options => options.value}
            error={component.error ? true : false}
            helperText={component.mensajeError ? component.mensajeError : ""}
          />
          {
            component.error ?
              <div className="errorFormDinamicComponent">
                <spam>{component.mensajeError}</spam>
              </div> : null
          }




        </>

      case "switch":

        return <>
          <FormGroup>
            <FormControlLabel name={component.name}
              error={component.error ? true : false}
              helperText={component.mensajeError ? component.mensajeError : ""}
              disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false} sx={{ marginLeft: "25%" }} id={component.id} required={component.validaciones == "required" ? true : false} onChange={(e, value) => { setValues(component.id, value) }} control={<Switch />} label={component.label} />
          </FormGroup>
          {
            component.error ?
              <div className="errorFormDinamicComponent">
                <spam>{component.mensajeError}</spam>
              </div> : null
          }
        </>



      case "checkbox":
        return <>
          <FormLabel className="FormularioDinamicoRadios" component="legend">{component.label}</FormLabel>


          <FormGroup row sx={{ marginLeft: "25%" }} name={component.name} onChange={(e) => { setChecksValue(component.id, e.target.value) }}>

            {component.options.map((item) => (
              <>

                <FormControlLabel disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false}
                  id={item.id}
                  error={component.error ? true : false}
                  helperText={component.mensajeError ? component.mensajeError : ""}
                  value={item.value} checked={component.value !== null ? component.value.includes(item.value) : false} required={component.validaciones == "required" ? true : false} control={<Checkbox />} label={item.value} />

              </>

            ))
            }


          </FormGroup>
          {
            component.error ?
              <div className="errorFormDinamicComponent">
                <spam>{component.mensajeError}</spam>
              </div> : null
          }

        </>

      case "radio":
        return <>

          <FormControl className="FormularioDinamicoFormControl"
            disabled={state.estatusID !== "5" && state.estatusID !== "" ? true : false}
            error={component.error ? true : false}
            helperText={component.mensajeError ? component.mensajeError : ""}
          >
            <FormLabel className="FormularioDinamicoRadios" id="radio-buttons-group">{component.label}

            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              row
              name="radio-buttons-group"
              onChange={(e) => { setValues(component.id, e.target.value) }}
            >
              {component.options.map((item) => (
                <FormControlLabel value={item.value} checked={component.value === item.value} control={<Radio />} label={item.value} />
              ))
              }
            </RadioGroup>
          </FormControl>
          {
            component.error ?
              <div className="errorFormDinamicComponent">
                <spam>{component.mensajeError}</spam>
              </div> : null
          }


        </>
      
    }

  }



  const api = async (url) => {

    let response = await services('GET', url, null)

    // if (!response.data?.pagoLinea) {
    //   console.log(response.data?.pagoLinea)
    //  // history.replace("/")
    // } else 
    if (response.error) {
      // console.log("Error",response.error)
      history.replace("/")
    } else {
      // console.log(response.data.importe)
      setState({
        ...state,
        pagoLinea: response.data.pagoLinea,
        data: response.data.formulario[0],
        id: response.data.id,
        loading: false,
        descripcion: response.data.descripcion,
        // email: "",
        folio: folio ?? null,
        rfc: folio ? response.data.rfc : '',
        razonSocial: folio ? response.data.razon_social : '',
        email: folio ? response.data.email : '',
        tp_diverso: folio ? response.data.tp_diverso : TPDiverso ?? localStorage.getItem("TPDiverso"),
        CANTIDAD: folio ? response.data.CANTIDAD : CANTIDAD === 0 ? localStorage.getItem("cantidad") : CANTIDAD,
        importe: response.data.importe, // : IMPORTE === 0 ? localStorage.getItem("IMPORTE"): IMPORTE,
        observaciones: folio ? response.data.observaciones : '',
        estatusID: folio ? response.data.estatus_id : '',
        estatusSolicitud: folio ? response.data.estatus : '',
        linkPago: folio ? response.data.linkPago : '',
        tipoImporte: response.data.tipoImporte,
      })
    }
  }

  const DescargaDocumento = async () => {


    setState({ ...state, loading: true })


    let response = await services('POST', `solicitudes/peticion/descargar`, { folio: state.folio })

    if (response.status === 200) {

      setState({ ...state, loading: false })

      // console.log(response.data);
      const url = window.URL.createObjectURL(new Blob([response.data]));



      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Resultado_Tramite');
      document.body.appendChild(link);
      link.click();

      if (folio) {

        api(`solicitudes/detalle/peticion/${folio}`);
      } else {

        api(`formularios/formulario/${categoria}/${concepto}`);
      }
      //console.log(response);
    } else if (response.status === 402) {

      setState({ ...state, loading: false })

      swal({
        title: "Error en la Solicitud",
        text: `${response.data.error}`,
        icon: "success",
        buttons: "Aceptar",
        confirmButton: true,
      }).then((Calificar) => {
        if (Calificar) {

        }
      });

    }


  }

  useEffect(() => {

    let isMounted = true

    if (isMounted) {

      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(status => {
        // status: success/error
        // success - script is loaded and greaptcha is ready
        // error - script is not loaded
        // console.log(status)
      })
      if (folio) {
        // console.log(folio, "entro")
        api(`solicitudes/detalle/peticion/${folio}`);
      } else {
        // console.log("entro a crear ")
        api(`formularios/formulario/${categoria}/${concepto}`);
      }

    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])


  return (
    <>
      {state.loading &&
        <GifLoader />
      }
      <div className="w-75 mx-auto" >
        <div style={{ overflow: "hidden" }} ref={containerRef} className="h-75 d-flex justify-content-center card m-3 text-center" >
          {!checked &&
            <Slide direction="right" in={!checked} container={containerRef.current}>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <h3 className='content_title content_titleForm_dinamic'>{state.data.nombre}</h3>
                <p className="formDinamicoDescripc" >{state.descripcion}</p>
                {state.tipoImporte === "FIJO" ?
                  <p className="formDinamicoDescripc" >${state.importe}</p>
                  : null
                }



                {folio ?
                  <div className="d-flex">
                    <div>Estatus de la Solicitud :</div>
                    <div className="text-bold ms-3" style={
                      {
                        color: state.estatusID === "6" ? "#e19720" : state.estatusID === "5" ? "#ff0303" : state.estatusID === "4" || state.estatusID === "2" ? "#346f03" : "#13036f"
                      }
                    }>{state.estatusSolicitud}</div>
                  </div>
                  :
                  null
                }
                {folio ?
                  <div className="d-flex">
                    <div>Cantidad :</div>
                    <div className="text-bold ms-3">{state.CANTIDAD}</div>
                  </div>
                  :
                  null
                }
                {folio ?
                  <div className="d-flex">
                    <div>Importe :</div>
                    <div className="text-bold ms-3">$ {state.importe}</div>
                  </div>
                  :
                  null
                }

                {folio ?

                  state.estatusID === "5" ?
                    <div className="text-center text-danger">
                      <div>Solicitud Rechazada por :</div>
                      <div className="text-bold ms-3" >{state.observaciones}</div>
                    </div>
                    :
                    null


                  :
                  null
                }
                {folio ?
                  <div className="d-flex">
                    <div>Folio :</div>
                    <div className="text-bold ms-3">{state.folio}</div>
                  </div>
                  :
                  null
                }

                {
                  state.estatusID === "6" ?
                    <a href={state.linkPago} target='_blank' rel="noreferrer" style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', cursor: 'pointer', padding: '5px 7px', marginBottom: '10px', marginTop: "1%" }}><PaymentsIcon /> Pagar en linea</a>
                    // <div className='cta cta--guinda mt-3' onClick={() => {history.push('/')}}> Pagar</div>

                    :
                    state.estatusID === "9" ?
                      <div className='cta cta--guinda mt-3' onClick={() => { DescargaDocumento() }}> Descarga tu Documento del Tramite</div>

                      :
                      null
                }
                {state.data.campos !== undefined ?
                  <Box sx={{ width: '100%', marginTop: "5%", marginBottom: "5%" }}>
                    <Grid direction="row" container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid className="formDinamicoaire" item xs={1} direction="row">
                        <InputLabel className="mx-2  FormularioDinamicoRadios">Correo Electronico</InputLabel>
                        <TextField id="email-form"
                          required
                          // label={"Correo Electronico"}
                          value={state.email}
                          name={"correo"}
                          type="email"
                          fullWidth
                          error={state.erroremail}
                          helperText={state.erroremailMensaje}
                          onChange={(e) => { setState({ ...state, email: e.target.value }) }}
                          disabled={folio ? true : false}
                        />
                      </Grid>
                      {state.pagoLinea === 1 ?
                        <>
                          <Grid className="formDinamicoaire" item xs={1} direction="row">
                            <InputLabel className="mx-2  FormularioDinamicoRadios">Razon Social</InputLabel>

                            <TextField id="razon_social-form"
                              required
                              // label={"Correo Electronico"}
                              value={state.razonSocial}
                              name={"razon_social"}
                              type="text"
                              disabled={folio ? true : false}
                              // disabled={state.estatusID !== "5" &&  state.estatusID !== "" ? true : false}
                              fullWidth
                              error={state.errorRazonSocial}
                              helperText={state.errorRazonSocialMensaje}
                              onChange={(e) => { setState({ ...state, razonSocial: e.target.value }) }} data
                            //disabled={folio ? true : false}
                            />

                          </Grid>

                          <Grid className="formDinamicoaire" item xs={1} direction="row">
                            <InputLabel className="mx-2  FormularioDinamicoRadios">RFC</InputLabel>

                            <TextField id="rfc-form"
                              required
                              // label={"Correo Electronico"}
                              value={state.rfc}
                              name={"rfc"}
                              type="text"
                              fullWidth
                              disabled={folio ? true : false}
                              // disabled={state.estatusID !== "5" &&  state.estatusID !== "" ? true : false}
                              error={state.errorRfc}
                              helperText={state.errorRfcMensaje}
                              onChange={(e) => { setState({ ...state, rfc: e.target.value }) }}
                            //disabled={folio ? true : false}
                            />

                          </Grid>

                        </>

                        : null}

                      {
                        state.tipoImporte === "MANUAL" ?

                          <Grid className="formDinamicoaire" item xs={1} direction="row">
                            <InputLabel className="mx-2  FormularioDinamicoRadios">Importe</InputLabel>

                            <TextField id="rfc-form"
                              required
                              // label={"Correo Electronico"}
                              value={state.importe}
                              name={"Importe"}
                              type="text"
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}

                              disabled={folio ? true : false}
                              // disabled={state.estatusID !== "5" &&  state.estatusID !== "" ? true : false}
                              error={state.errorImporte}
                              helperText={state.errorImporteMensaje}
                              onChange={(e) => { setState({ ...state, importe: e.target.value }) }}
                            //disabled={folio ? true : false}
                            />

                          </Grid>

                          : null
                      }
                      {state.data.campos.map(item => (

                        <Grid className="formDinamicoaire" item xs={1} >

                          {setComponent(item)}
                        </Grid>


                      ))}
                    </Grid>
                  </Box>
                  :
                  <></>
                }

                {
                  state.estatusID === "" ?
                    <div className='cta cta--guinda mt-2' onClick={nextSteap}> Continuar </div>
                    :
                    null
                }
                {
                  state.estatusID !== "6" && state.estatusID !== "" ?

                    <div className='cta cta--guinda mt-2' onClick={() => { state.estatusID === "5" ? nextSteap() : history.push('/') }}> {state.estatusID === "5" ? "Guardar Cambios" : "Regresar"}</div>
                    :
                    null
                }
              </div>

            </Slide>
          }
        </div>
      </div>





      <Modal
        open={Calificacion.openModalCali}
        onClose={() => setCalificacion({ ...Calificacion, openModalCali: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleCalificacion}>
          <Box
            sx={{
              '& > legend': { mt: 2 },
              marginBottom: "3%"
            }}
          >
            <Typography component="legend">¿Cómo calificarías tu experiencia?</Typography>
            <Rating
              size="large"
              sx={{
                marginLeft: "22%",
                marginTop: "4%",
              }}
              name="simple-controlled"
              value={Calificacion.calificacionValor}
              onChange={(event, newValue) => {
                setCalificacion({ ...Calificacion, calificacionValor: newValue });
              }}
            />

          </Box>
          <Grid container justifyContent="flex-end" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

            <div className='cta cta--guinda mt-2' onClick={() => setCalificacion({ ...Calificacion, openModalCali: false })}>Enviar</div>

          </Grid>
        </Box>
      </Modal>



    </>)
}





export default FormularioDinamico;