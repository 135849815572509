import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  FormControl,
  Modal,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import IconUI from "../components/ui/icon_ui";
import {
  AiOutlineUserAdd,
  AiOutlineEdit,
  AiOutlineCheck,
} from "react-icons/ai";
import { FcCancel } from "react-icons/fc";
import MUIDataTable from "mui-datatables";
import { services } from "../services/api";
import { NotificacionContext } from "../context/notificacion_context";
import CapturaContext from "../context/captura_context/capturaContext";
import KeyIcon from "@mui/icons-material/Key";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";
import { maxLengthLimitInput } from "../utils/inputsRules";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import GifLoader from "../components/ui/gifLoader";

const options = {
  selectableRows: false,
  tableBodyHeight: "auto",
  textLabels: {
    body: {
      noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
      toolTip: "Clasificar",
      columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
    },
    pagination: {
      next: "Siguiente",
      previous: "Anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    filter: {
      all: "Todo",
      title: "Filtros",
      reset: "Restaurar",
    },
    viewColumns: {
      title: "Ver columnas",
      titleAria: "Mostrar/Ocultar columnas en la tabla",
    },
    selectedRows: {
      text: "columna(s) selecciona(as)",
      delete: "Eliminar",
      deleteAria: "Eliminar filas seleccionadas",
    },
  },
  responsive: "standard",
  downloadOptions: {
    filename: "tablaUsuarios.csv",
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: false,
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#7c858c !important",
      },
    },
  },
};

const CrudUsers = (props) => {
  const colorUI = "#6f1d46";
  const { deletDatosTabla } = useContext(CapturaContext);

  const { showNotification } = useContext(NotificacionContext);

  const [modalShow, setModalShow] = useState(false);
  const [userSelected, setUserSelected] = useState("");
  const [credentialsPinPad, setCredentialsPinPad] = useState({
    user_id: "",
    convenio: "",
    password: "",
    usuario: "",
    urlPINPAD: "",
    Ambiente: "",
    operationType: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const rolUser = localStorage.getItem("rol");

  // const [state, setState] = useState({
  //   loading: false,
  // });

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: colorUI,
    "&:hover": {
      backgroundColor: colorUI,
    },
  }));

  const closeModal = (boolean) => {
    setModalShow(boolean);
    setCredentialsPinPad({
      ...credentialsPinPad,
      user_id: "",
      usuario: "",
      password: "",
      convenio: "",
    });
  };

  const habilitarUser = async (body) => {
    let eliminarUsuario = await services(
      "DELETE",
      `usuarios/eliminar/${body.curp}`
    );
    if (eliminarUsuario.status === 200) {
      showNotification(
        "success",
        "Acción Exitosa!",
        "La operación se realizó correctamente",
        null,
        4
      );
      deletDatosTabla(eliminarUsuario.data);
    } else {
      showNotification(
        "error",
        "Acción No Exitosa!",
        "La operación no se realizó correctamente",
        null,
        4
      );
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isObjectEmpty = (obj) => {
    for (let key in obj) {
      if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
        return true;
      }
    }
    return false;
  };

  // Metodo asignar credenciales PINPAD
  const assingCredentials = async () => {
    swal({
      title: "Atención",
      text: "¿Las credenciales son correctas?",
      icon: "info",
      buttons: ["Cancelar", "Aceptar"],
      confirmButton: true,
    }).then(async (result) => {
      if (result) {
        const asignCredentials = await services(
          "PUT",
          `usuarios/pinpad`,
          credentialsPinPad
        );

        if (asignCredentials.status === 200) {
          swal({
            title: "¡Éxito!",
            text: "Asignación de credenciales correcta",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            closeModal();
          });
          document.querySelector(
            ".swal-button--confirm"
          ).style.backgroundColor = colorUI;
        } else {
          swal({
            title: "Error",
            text:
              asignCredentials.data.error !== null
                ? asignCredentials.data.error
                : "No se han podido asignar las credenciales",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            closeModal();
          });
          document.querySelector(
            ".swal-button--confirm"
          ).style.backgroundColor = colorUI;
          return;
        }
      } else {
        return;
      }
    });
    document.querySelector(".swal-button--confirm").style.backgroundColor =
      colorUI;
  };

  const columns = [
    {
      name: "curp",
      label: "CURP",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let curp = props.dataUsers[dataIndex].curp;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {curp}
            </div>
          );
        },
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = props.dataUsers[dataIndex].nombre;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "primerApellido",
      label: "Apellido Paterno",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let primerApellido = props.dataUsers[dataIndex].primerApellido;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {primerApellido}
            </div>
          );
        },
      },
    },
    {
      name: "segundoApellido",
      label: "Apellido Materno",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let segundoApellido = props.dataUsers[dataIndex].segundoApellido;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {segundoApellido}
            </div>
          );
        },
      },
    },
    {
      name: "rol",
      label: "Rol",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let rol = props.dataUsers[dataIndex].rol;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {rol}
            </div>
          );
        },
      },
    },
    {
      name: "area",
      label: "Área",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          const area = props.dataUsers[dataIndex].area;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {area}
            </div>
          );
        },
      },
    },
    {
      name: "fechaBaja",
      label: "Fecha Baja",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let val = props.dataUsers[dataIndex].fechaBaja;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro  d-flex align-items-center justify-content-center"
                  : " d-flex align-items-center justify-content-center"
              }
            >
              {val === null ? "N/A" : val}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            {props.dataUsers[dataIndex].fechaBaja === null ? (
              <div
                style={{ height: "60px" }}
                className={
                  i % 2 === 0
                    ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                    : "w-100 d-flex justify-content-center align-items-center "
                }
              >
                {props.dataUsers[dataIndex].rol === "Ventanilla" &&
                props.dataUsers[dataIndex].pinpad === 1 &&
                rolUser === "Administrador" ? (
                  <div
                    style={{ height: 30, width: 30, zIndex: 0 }}
                    className="cta cta--primary cta--icon icon_btn me-1"
                    onClick={() => {
                      let userData = props.dataUsers[dataIndex].curp;
                      let userId = props.dataUsers[dataIndex].id;

                      setTimeout(() => {
                        setCredentialsPinPad((prevState) => ({
                          ...prevState,
                          user_id: userId,
                        }));
                      }, 2000);

                      setUserSelected(userData);

                      closeModal(true);
                    }}
                  >
                    <IconUI size={"20px"} color={"white"}>
                      <KeyIcon />
                    </IconUI>
                    <div className="icon_btn_help">
                      Asignar credenciales de PinPad
                    </div>
                  </div>
                ) : null}

                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    let bodyUpdate = {
                      curp: props.dataUsers[dataIndex].curp,
                      email: props.dataUsers[dataIndex].email,
                      fechaBaja: props.dataUsers[dataIndex].fechaBaja,
                      id: props.dataUsers[dataIndex].id,
                      id_municipio: props.dataUsers[dataIndex].id_municipio,
                      id_oficina: props.dataUsers[dataIndex].id_oficina,
                      nombre: props.dataUsers[dataIndex].nombre,
                      primerApellido: props.dataUsers[dataIndex].primerApellido,
                      rol: props.dataUsers[dataIndex].rol,
                      segundoApellido:
                        props.dataUsers[dataIndex].segundoApellido,
                      area: props.dataUsers[dataIndex].area,
                      id_dependencia: props.dataUsers[dataIndex].id_dependencia,
                      nombre_dependencia:
                        props.dataUsers[dataIndex].nombre_dependencia,
                    };

                    props.showPopUpUpdateOnView(bodyUpdate);
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <AiOutlineEdit />
                  </IconUI>
                  <div className="icon_btn_help">Editar</div>
                </div>
                <div
                  style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }}
                  className="cta cta--warning cta--icon icon_btn"
                  onClick={() => {
                    showNotification(
                      "option",
                      "¿Estás seguro de realizar esta acción?",
                      "Al dar click en Aceptar se inhabilitará este usuario",
                      () => {
                        let body = {
                          curp: props.dataUsers[dataIndex].curp,
                        };
                        habilitarUser(body);
                      },
                      null
                    );
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <FcCancel />
                  </IconUI>
                  <div className="icon_btn_help">Bloquear</div>
                </div>
              </div>
            ) : (
              <div
                className={
                  i % 2 === 0
                    ? "w-100 d-flex justify-content-center p-3 bg-rosaClaro"
                    : "w-100 d-flex justify-content-center p-3"
                }
              >
                <div
                  style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }}
                  className="cta cta--success cta--icon icon_btn"
                  onClick={() => {
                    showNotification(
                      "option",
                      "¿Estás seguro de realizar esta acción?",
                      "Al dar click en Aceptar se habilitará este usuario",
                      () => {
                        let body = {
                          curp: props.dataUsers[dataIndex].curp,
                        };
                        habilitarUser(body);
                      },
                      null
                    );
                  }}
                >
                  <IconUI size={"15px"} color={"white"}>
                    <AiOutlineCheck />
                  </IconUI>
                  <div className="icon_btn_help">Desbloquear</div>
                </div>
              </div>
            )}
          </>
        ),
      },
    },
  ];

  return (
    <>
      {/* {state.loading ? <GifLoader /> : null} */}
      <div className="container">
        <Grid sx={{ marginTop: 3 }} container spacing={2}>
          <Grid align="center" item xs={12} sm={6} md={6}></Grid>
          <Grid align="right" item xs={12} sm={6} md={6}>
            <div
              style={{ width: 200 }}
              className="cta cta--guinda"
              onClick={() => {
                props.showPopUpOnView();
              }}
            >
              <div className="me-2">
                <IconUI size={"20px"} color={"white"}>
                  <AiOutlineUserAdd />
                </IconUI>
              </div>{" "}
              Nuevo Usuario
            </div>
          </Grid>
        </Grid>
      </div>

      <Box sx={{ backgroundColor: "red" }}>
        <Grid className="my-5">
          <Grid item xs={8}>
            <MUIDataTable
              style={{ Overflow: "hidden" }}
              title={"Usuarios del Sistema"}
              data={props.dataUsers ?? []}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Modal asignar credenciales PINPAD*/}
      <Modal
        open={modalShow}
        onClose={() => closeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <h2 className="mb-4 text-center">
            Asignar credenciales de <strong>PinPad</strong> para el usuario{" "}
            <strong className="d-none d-md-block">{userSelected}</strong>
          </h2>
          <h3 className="d-md-none">
            <strong>{userSelected}</strong>
          </h3>

          <Grid align="right" item xs={12} sm={6} md={3}>
            <FormControl sx={{ marginTop: 3, width: "100%" }}>
              <InputLabel htmlFor="my-input">Convenio</InputLabel>
              <Input
                fullWidth
                value={credentialsPinPad.convenio}
                onChange={(e) => {
                  setCredentialsPinPad({
                    ...credentialsPinPad,
                    convenio: e.target.value,
                  });
                }}
                onKeyPress={(event) => {
                  maxLengthLimitInput(event, 30, "convenio");
                }}
                id="convenio"
                name="convenio"
                aria-describedby="my-helper-text"
                required
              />
            </FormControl>
          </Grid>
          <Grid align="right" item xs={12} sm={6} md={3}>
            <FormControl sx={{ marginTop: 3, width: "100%" }}>
              <InputLabel htmlFor="my-input">Usuario</InputLabel>
              <Input
                fullWidth
                value={credentialsPinPad.usuario}
                onChange={(e) => {
                  setCredentialsPinPad({
                    ...credentialsPinPad,
                    usuario: e.target.value,
                  });
                }}
                id="usuario"
                name="usuario"
                aria-describedby="my-helper-text"
                required
              />
            </FormControl>
          </Grid>
          <Grid align="right" item xs={12} sm={6} md={3}>
            <FormControl sx={{ marginTop: 3, width: "100%" }}>
              <InputLabel htmlFor="passwordUser">Contraseña</InputLabel>
              <Input
                fullWidth
                type={showPassword ? "text" : "password"}
                value={credentialsPinPad.password}
                onChange={(e) => {
                  setCredentialsPinPad({
                    ...credentialsPinPad,
                    password: e.target.value,
                  });
                }}
                onKeyPress={(event) => {
                  maxLengthLimitInput(event, 30, "passwordUser");
                }}
                id="passwordUser"
                name="passwordUser"
                aria-describedby="my-helper-text"
                required
                endAdornment={
                  <InputAdornment className="me-2" position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid align="right" item xs={12} sm={6} md={3}>
            <FormControl sx={{ marginTop: 3, width: "100%" }}>
              <InputLabel htmlFor="my-input">URL de la Pinpad</InputLabel>
              <Input
                fullWidth
                value={credentialsPinPad.urlPINPAD}
                onChange={(e) => {
                  setCredentialsPinPad({
                    ...credentialsPinPad,
                    urlPINPAD: e.target.value,
                  });
                }}
                // onKeyPress={(event) => {
                //   maxLengthLimitInput(event, 30, "usuario");
                // }}
                id="usuario"
                name="usuario"
                aria-describedby="my-helper-text"
                required
              />
            </FormControl>
          </Grid>
          <Grid align="right" item xs={12} sm={6} md={3}>
            <FormControl sx={{ marginTop: 3, width: "100%" }}>
              <InputLabel htmlFor="my-input">Ambiente</InputLabel>
              <Input
                fullWidth
                value={credentialsPinPad.Ambiente}
                onChange={(e) => {
                  setCredentialsPinPad({
                    ...credentialsPinPad,
                    Ambiente: e.target.value,
                  });
                }}
                id="usuario"
                name="usuario"
                aria-describedby="my-helper-text"
                required
              />
            </FormControl>
          </Grid>
          <Grid align="right" item xs={12} sm={6} md={3}>
            <FormControl sx={{ marginTop: 3, marginBottom: 8, width: "100%" }}>
              <InputLabel htmlFor="my-input">Tipo de operación</InputLabel>
              <Input
                fullWidth
                value={credentialsPinPad.operationType}
                onChange={(e) => {
                  setCredentialsPinPad({
                    ...credentialsPinPad,
                    operationType: e.target.value,
                  });
                }}
                onKeyPress={(event) => {
                  maxLengthLimitInput(event, 30, "usuario");
                }}
                id="usuario"
                name="usuario"
                aria-describedby="my-helper-text"
                required
              />
            </FormControl>
          </Grid>

          <div className="d-flex flex-column-reverse flex-md-row gap-3 gap-md-0 justify-content-between">
            <ColorButton
              className="bg-danger"
              variant="outlined"
              onClick={() => closeModal(false)}
            >
              Cerrar
            </ColorButton>
            <ColorButton
              variant="outlined"
              onClick={() => {
                if (isObjectEmpty(credentialsPinPad)) {
                  swal({
                    title: "Campos requeridos",
                    text: "Por favor, llena todos los campos requeridos",
                    icon: "info",
                    button: "Aceptar",
                  }).then(() => {});
                  document.querySelector(
                    ".swal-button--confirm"
                  ).style.backgroundColor = colorUI;
                  return;
                }
                assingCredentials();
              }}
            >
              Asignar
              <IconUI size={"20px"} color={"white"}>
                <KeyIcon className="ms-1" />
              </IconUI>
            </ColorButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CrudUsers;
