import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { TableFooter } from "@material-ui/core";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Card,
  Box,
  Radio,
  TextareaAutosize,
  Typography,
  Container,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { services } from "../services/api";
import { NotificacionContext } from "../context/notificacion_context";
import { useContext, useState, useEffect } from "react";
import CapturaContext from "../context/captura_context/capturaContext";
import { onlyLetter, maxLengthLimitInput } from "../utils/inputsRules";
import { toTop } from "../utils/animationFunctions";
import { numberFormatMexico } from "../utils/formats";
import GifLoader from "../components/ui/gifLoader";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import InputAdornment from "@mui/material/InputAdornment";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import IconUI from "../components/ui/icon_ui";
import { GrFormSubtract } from "react-icons/gr";
import swal from "sweetalert";

// Es para hacer post al enpoint

const colorUI = "#6f1d46";
// const colorUI = "#8d949e";

export const ResumenViewPublic = () => {
  const capturactx = useContext(CapturaContext);
  const {
    envioPagoLinea,
    envio_conceptos,
    envioConceptos,
    TPDiverso,
    idCategoria,
  } = capturactx;
  const { showNotification } = useContext(NotificacionContext);

  const history = useHistory();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (envio_conceptos.length === 0) {
        history.replace("/");
      }

      dataToEndponitCaptura(
        "CONSULTA",
        "CONSULTA",
        null,
        envio_conceptos,
        TPDiverso,
        1
      );
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  //Estilado de la tabla
  // const StyledTableRow = styled(TableRow)(() => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: '#FFE6EC',
  //   },
  //   // hide last border
  //   '&:last-child td, &:last-child th': {
  //     border: 0,
  //   },
  // }));

  const [state, setState] = useState({
    showFisica: true,
    rfcf: false,
    name: false,
    ap: false,
    am: false,
    rfcm: false,
    rz: false,
    error500: false,
    infocurp: "Ingresa el RFC.",
    consultacurp: "",
    loading: false,
  });

  const [envioConceptosData, setEnvioConceptosData] = useState({
    dataToEndponitCapturaFromApi: [],
  });

  const [total, setTotal] = useState(0);

  const getTotalConceptos = (data) => {
    //Suma total
    let total = 0;

    data.map((row) => {
      if (row.MONEDA === "UMA") {
        total +=
          parseFloat(row.IMPORTE) *
          parseFloat(row.CANTIDAD) *
          parseFloat(row.UMA);
        return true;
      } else {
        if (row.IMPORTE_NETO_CONDICION.includes("-")) {
          total -= parseFloat(row.IMPORTE_NETO_CONDICION);
        } else {
          total += parseFloat(row.IMPORTE_NETO_CONDICION);
        }
        return true;
      }
    });
    total = numberFormatMexico(total);
    setTotal(total);
  };

  const generaLineaCaptura = async (data, TPProcesamiento) => {
    setState({ ...state, loading: true });
    const response = await services(
      "POST",
      `generaLineaCaptura/${idCategoria}`,
      data
    );
    if (TPProcesamiento === 1) {
      setEnvioConceptosData({
        ...envioConceptosData,
        dataToEndponitCapturaFromApi: response.data.TB_CALC_DETALLE,
      });
      setState({ ...state, loading: false });
      getTotalConceptos(response.data.TB_CALC_DETALLE);
    }
    if (TPProcesamiento === 2) {
      try {
        localStorage.setItem("token", response.data.ES_OPAG.TOKEN);
        envioPagoLinea(response.data.ES_OPAG);
        setState({ ...state, nextStep: false });
      } catch (error) {
        // showNotification("warning", "Error de Servidor", "Por favor intente de nuevo mas tarde", null, 4)
        setState({ ...state, error500: true });
      }
    }
  };
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "a") {
      setState({ ...state, showFisica: true });
    } else {
      setState({ ...state, showFisica: false });
    }
  };

  const dataToEndponitCaptura = (
    rfc,
    nombre_razon,
    obs,
    envio_conceptos,
    TPDiverso,
    TPProcesamiento
  ) => {
    //Construyendo data para endpoint
    const dataEndponitCaptura = {
      MT_ServGralLC_PI_Sender: {
        ES_GEN_DATA: {
          TP_PROCESAMIENTO: TPProcesamiento, //EN 2 ME GENERA LA LINEA DE CAPTURA, EN 1 CONSULTO LOS DATOS DE LA TURA SIN QUE ME LA GENERE
          TP_DATOMAESTRO: "E",
          TP_DIVERSO: TPDiverso ? TPDiverso : "",
          COD_BP: null,
          COD_CTA_CTO: null,
          COD_OBJ_CTO: null,
          RFC: rfc, //PARA CONSULTA NULL
          NOMBRE_RAZON: nombre_razon, //PARA CONSULTA EL NOMBRE ES CONSULTA
          DOMICILIO: null,
          OBSERVACIONES: obs,
          ID_LEGADO: null,
          REFERENCIA1: null,
          REFERENCIA2: null,
          REFERENCIA3: null,
          REFERENCIA4: null,
          REFERENCIA5: null,
        },
        TB_CONCEPTOS: [],
      },
    };
    //Haciendo map del nuestra data y setteando valores para el endpoint de captura
    envio_conceptos.map((row) =>
      dataEndponitCaptura.MT_ServGralLC_PI_Sender.TB_CONCEPTOS.push({
        TP_INGRESO: row.TP_INGRESO, // si se llena con TP_INGRESO del primer objeto
        OP_PRINCIPAL: null,
        OP_PARCIAL: null,
        CANTIDAD: row.CANTIDAD, // Si es importe Manual cantidad siempre es 1 o Fijo cantidad es N
        BASE: null,
        IMPORTE: row.IMPORTE, // Si es Manual se llena con IMPORTE si no es NULL
        PERIODO: null,
        FEC_VENCIMIENTO: null,
        FEC_BASE_VENCIMIENTO: null,
        IMPORTE_AUX1: null,
        IMPORTE_AUX2: null,
        IMPORTE_AUX3: null,
        MARCA_AUX1: null,
        MARCA_AUX2: null,
        MARCA_AUX3: null,
        CANT_AUX1: null,
        CANT_AUX2: null,
        CANT_AUX3: null,
      })
    );

    if (TPProcesamiento)
      generaLineaCaptura(dataEndponitCaptura, TPProcesamiento).then(() => {
        if (TPProcesamiento === 2) {
          if (localStorage.getItem("token")) {
            history.push("/capturaP");
          }
        }
      });
  };

  const handleChangebuscarCurp = async (event) => {
    let curp = event.target.value;

    if (curp.length === 18) {
      setState({
        ...state,
        rfcf: true,
        infocurp: "CURP Valida",
        consultacurp: "succes",
      });
    } else if (curp.length !== 18) {
      setState({
        ...state,
        rfcf: false,
        infocurp: "Ingresa tu RFC.",
        consultacurp: "",
      });
    }
  };

  return (
    <>
      {state.loading ? (
        <>
          <div className="vh-100">
            <GifLoader></GifLoader>
          </div>
        </>
      ) : (
        <>
          {state.error500 ? (
            <Container>
              <img
                alt="ErrorImg"
                style={{ marginLeft: "10%" }}
                src="mensajeError.png"
              ></img>
            </Container>
          ) : (
            <>
              <div className="container my-3">
                <div className="border-bottom--guinda text-big mb-2 text-bold">
                  Resumen de Pago
                </div>
              </div>
              <Box sx={{ flexGrow: 1, marginTop: 3 }}>
                <Grid className="gridContainer">
                  <Grid item xs={8}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableRow>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: "bold" }}>
                              Concepto
                            </Typography>
                          </TableCell>
                          <TableCell align="center" style={{ width: 170 }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Costo
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: "bold" }}>
                              Cantidad
                            </Typography>
                          </TableCell>
                          <TableCell align="center" style={{ width: 170 }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Total
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableBody>
                          {envioConceptosData.dataToEndponitCapturaFromApi.map(
                            (row, index) => (
                              <TableRow
                                key={row.TXT_CONCEPTO}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={
                                  index % 2 === 0
                                    ? { backgroundColor: "" }
                                    : { backgroundColor: "#FFE6EC" }
                                }
                              >
                                <TableCell align="left">
                                  <div align="left" className="d-flex">
                                    <div align="left" className="mt-2">
                                      {parseFloat(row.CANTIDAD) === 0 ? (
                                        <>
                                          <div className="ms-5">
                                            <IconUI
                                              style={{ marginLeft: "50" }}
                                              size={"14px"}
                                              //  color={'#6A0F49'}
                                              color={"#8d949e"}
                                            >
                                              <GrFormSubtract />{" "}
                                              {row.TXT_CONCEPTO}
                                            </IconUI>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="ms-3">
                                            {row.TXT_CONCEPTO}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="d-flex align-items-start ms-2">
                                      {parseFloat(row.CANTIDAD) === 0 ? (
                                        <span
                                          style={{ fontSize: "8px" }}
                                          className="badge bg-guinda"
                                        >
                                          accesorio
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  {row.MONEDA_CONDICION === "%" ? (
                                    <>
                                      {parseFloat(row.IMPORTE_CONDICION) === 0
                                        ? numberFormatMexico(
                                            row.IMPORTE_NETO_CONDICION
                                          )
                                        : numberFormatMexico(
                                            row.IMPORTE_CONDICION
                                          )}{" "}
                                      %
                                    </>
                                  ) : (
                                    <>
                                      ${" "}
                                      {row.MONEDA === "UMA"
                                        ? ` ${Math.trunc(
                                            row.IMPORTE_CONDICION
                                          )} UMA ($ ${(
                                            row.IMPORTE_CONDICION * row.UMA
                                          ).toFixed(2)} MXN)`
                                        : parseFloat(row.IMPORTE_CONDICION) ===
                                          0
                                        ? numberFormatMexico(
                                            row.IMPORTE_NETO_CONDICION
                                          )
                                        : numberFormatMexico(
                                            row.IMPORTE_CONDICION
                                          )}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {row.MONEDA_CONDICION === "%" ? (
                                    1
                                  ) : (
                                    <>
                                      {parseFloat(row.CANTIDAD) === 0
                                        ? parseFloat(row.BASE_CONDICION)
                                        : parseFloat(row.CANTIDAD)}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>
                                  ${" "}
                                  {row.MONEDA === "UMA"
                                    ? numberFormatMexico(
                                        parseFloat(row.IMPORTE_CONDICION) *
                                          parseFloat(row.CANTIDAD) *
                                          parseFloat(row.UMA)
                                      )
                                    : numberFormatMexico(
                                        row.IMPORTE_NETO_CONDICION
                                      )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left">
                              <b>Total:</b>
                            </TableCell>
                            <TableCell align="center">
                              <b>$ {total}</b>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  marginTop: 3,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={4}></Grid>
                  <Grid align="center" item xs={12} sm={12} md={4}>
                    <div>
                      <RadioGroup
                        className="d-flex"
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                          <div>
                            <FormControlLabel
                              checked={selectedValue === "a"}
                              onChange={handleChange}
                              value="a"
                              name="radio-buttons"
                              control={<Radio />}
                              label="Persona Física"
                              sx={{
                                "&.Mui-checked": {
                                  color: colorUI,
                                },
                              }}
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              checked={selectedValue === "b"}
                              onChange={handleChange}
                              value="b"
                              name="radio-buttons"
                              control={<Radio />}
                              label="Persona Moral"
                              sx={{
                                "&.Mui-checked": {
                                  color: colorUI,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </RadioGroup>
                      {/* Persona Física
                      <Radio
                        checked={selectedValue === "a"}
                        onChange={handleChange}
                        value="a"
                        name="radio-buttons"
                        sx={{
                          "&.Mui-checked": {
                            color: colorUI,
                          },
                        }}
                        inputProps={{ "aria-label": "A" }}
                      />
                      Persona Moral
                      <Radio
                        checked={selectedValue === "b"}
                        onChange={handleChange}
                        value="b"
                        name="radio-buttons"
                        sx={{
                          "&.Mui-checked": {
                            color: colorUI,
                          },
                        }}
                        inputProps={{ "aria-label": "B" }}
                      /> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}></Grid>
                </Grid>
              </Box>
              {state.showFisica ? (
                //Formulario de persona física
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: 3,
                    marginBottom: 5,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Grid container>
                    <Grid className="gridContainer" align="center" item xs={12}>
                      <Card
                        style={{
                          paddingTop: 30,
                          paddingLeft: 30,
                          paddingRight: 30,
                          paddingBottom: 30,
                        }}
                        variant="outlined"
                      >
                        <form
                          encType="multipart/form-data"
                          method="post"
                          name="formFisica"
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}></Grid>
                            <Grid align="center" item xs={12} sm={6} md={6}>
                              <FormControl
                                sx={{
                                  marginTop: 3,
                                  width: 400,
                                  maxWidth: "100%",
                                }}
                              >
                                <InputLabel
                                  htmlFor="my-input"
                                  sx={{
                                    "& .MuiInputLabel-root": { color: colorUI },
                                  }}
                                >
                                  RFC / CURP *
                                </InputLabel>
                                <Input
                                  fullWidth
                                  error={state.rfcf}
                                  onChange={(event) => {
                                    handleChangebuscarCurp(event);
                                  }}
                                  onKeyPress={(event) => {
                                    maxLengthLimitInput(event, 17, "curp");
                                  }}
                                  inputProps={{
                                    style: {
                                      textTransform: "uppercase",
                                      color: colorUI,
                                    },
                                  }}
                                  id="rfc_fisica"
                                  name="rfc_fisica"
                                  aria-describedby="my-helper-text"
                                  required
                                  endAdornment={
                                    <InputAdornment position="end">
                                      {state.consultacurp === "succes" ? (
                                        <OfflinePinIcon color="success" />
                                      ) : (
                                        <></>
                                      )}
                                      {state.consultacurp === "error" ? (
                                        <ReportGmailerrorredIcon color="error" />
                                      ) : (
                                        <></>
                                      )}
                                    </InputAdornment>
                                  }
                                />
                                <FormHelperText id="my-helper-text">
                                  {state.infocurp}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}></Grid>
                          </Grid>
                          <Grid sx={{ marginTop: 1 }} container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl
                                sx={{
                                  marginTop: 3,
                                  width: 400,
                                  maxWidth: "100%",
                                }}
                              >
                                <InputLabel htmlFor="my-input">
                                  Nombre (s) *
                                </InputLabel>
                                <Input
                                  error={state.name}
                                  onChange={(event) => {
                                    setState({ ...state, name: false });
                                  }}
                                  onKeyPress={(event) => {
                                    onlyLetter(event, 29);
                                  }}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  id="nombre"
                                  name="nombre"
                                  aria-describedby="my-helper-text"
                                />
                                <FormHelperText id="my-helper-text">
                                  Ingresa tu nombre.
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl
                                sx={{
                                  marginTop: 3,
                                  width: 400,
                                  maxWidth: "100%",
                                }}
                              >
                                <InputLabel htmlFor="my-input">
                                  Apellido Paterno *
                                </InputLabel>
                                <Input
                                  error={state.ap}
                                  onChange={(event) => {
                                    setState({ ...state, ap: false });
                                  }}
                                  onKeyPress={(event) => {
                                    onlyLetter(event, 19);
                                  }}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  id="paterno"
                                  name="paterno"
                                  aria-describedby="my-helper-text"
                                />
                                <FormHelperText id="my-helper-text">
                                  Ingresa tu apellido paterno.
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl
                                sx={{
                                  marginTop: 3,
                                  width: 400,
                                  maxWidth: "100%",
                                }}
                              >
                                <InputLabel htmlFor="my-input">
                                  Apellido Materno *
                                </InputLabel>
                                <Input
                                  error={state.am}
                                  onChange={(event) => {
                                    setState({ ...state, am: false });
                                  }}
                                  onKeyPress={(event) => {
                                    onlyLetter(event, 19);
                                  }}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  id="materno"
                                  name="materno"
                                  aria-describedby="my-helper-text"
                                />
                                <FormHelperText id="my-helper-text">
                                  Ingresa tu apellido materno.
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </form>
                        <Grid
                          sx={{ marginTop: 3, marginBottom: 5 }}
                          container
                          spacing={2}
                        >
                          <Grid style={{ width: "100%" }} item xs={12}>
                            <InputLabel
                              sx={{ marginLeft: 3, marginBottom: 1 }}
                              align="left"
                            >
                              Observaciones
                            </InputLabel>
                            <FormControl style={{ width: "100%" }}>
                              <TextareaAutosize
                                placeholder=""
                                id="obs_fisica"
                                name="obs_fisica"
                                onKeyPress={(event) => {
                                  maxLengthLimitInput(event, 249);
                                }}
                                style={{
                                  width: "100%",
                                  height: 100,
                                  paddingTop: 15,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  paddingBottom: 15,
                                  textTransform: "uppercase",
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{ marginTop: 3, marginBottom: 5 }}
                          container
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            {state.nextStep ? (
                              <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                                <GifLoader></GifLoader>
                              </div>
                            ) : (
                              <>
                                <div
                                  style={{ marginRight: 20 }}
                                  className="d-flex my-3"
                                >
                                  <div className="col"></div>
                                  <div
                                    className="cta cta--rosa mx-3"
                                    onClick={() => {
                                      envioConceptos([]);
                                      history.replace("/");
                                    }}
                                  >
                                    Cancelar
                                  </div>
                                  <div
                                    className="cta cta--guinda"
                                    onClick={() => {
                                      //Obteniendo valores del form
                                      let rfc_fisica =
                                        document.getElementById(
                                          "rfc_fisica"
                                        ).value;
                                      let nombre =
                                        document.getElementById("nombre").value;
                                      let paterno =
                                        document.getElementById(
                                          "paterno"
                                        ).value;
                                      let materno =
                                        document.getElementById(
                                          "materno"
                                        ).value;
                                      let obs_fisica =
                                        document.getElementById(
                                          "obs_fisica"
                                        ).value;

                                      let stateFisica = {
                                        rfcf: false,
                                        name: false,
                                        ap: false,
                                        am: false,
                                      };
                                      //Validando campos requeridos
                                      if (
                                        rfc_fisica === "" ||
                                        nombre === "" ||
                                        paterno === "" ||
                                        materno === ""
                                      ) {
                                        if (rfc_fisica === "") {
                                          stateFisica.rfcf = true;
                                        }
                                        if (nombre === "") {
                                          stateFisica.name = true;
                                        }
                                        if (paterno === "") {
                                          stateFisica.ap = true;
                                        }
                                        if (materno === "") {
                                          stateFisica.am = true;
                                        }
                                        //Cambiando estado
                                        setState({
                                          ...state,
                                          rfcf: stateFisica.rfcf,
                                          name: stateFisica.name,
                                          ap: stateFisica.ap,
                                          am: stateFisica.am,
                                        });

                                        showNotification(
                                          "warning",
                                          "Campos requeridos",
                                          "Por favor, llena todos los campos requeridos",
                                          null,
                                          4
                                        );
                                      } else if (
                                        rfc_fisica.length !== 13 &&
                                        rfc_fisica.length !== 18
                                      ) {
                                        // Validando que el RFC / CURP tenga 13 o 18 caracteres
                                        stateFisica.rfcf = true;
                                        setState({
                                          ...state,
                                          rfcf: stateFisica.rfcf,
                                        });
                                        showNotification(
                                          "warning",
                                          "RFC / CURP incorrecto",
                                          "El campo debe de tener 13 o 18 caracteres.",
                                          null,
                                          4
                                        );
                                      } else if (
                                        nombre.length < 2 ||
                                        paterno.length < 2 ||
                                        materno.length < 2
                                      ) {
                                        // Validando que por lo menos tengan 2 caracteres
                                        if (nombre.length < 2) {
                                          stateFisica.name = true;
                                        }
                                        if (paterno.length < 2) {
                                          stateFisica.ap = true;
                                        }
                                        if (materno.length < 2) {
                                          stateFisica.am = true;
                                        }
                                        //Cambiando estado
                                        setState({
                                          ...state,
                                          name: stateFisica.name,
                                          ap: stateFisica.ap,
                                          am: stateFisica.am,
                                        });
                                        showNotification(
                                          "warning",
                                          "Valores incorrectos",
                                          "El campo debe de tener al menos 2 caracteres.",
                                          null,
                                          4
                                        );
                                      } else {
                                        swal({
                                          title:
                                            "¿Estás seguro de que tu información es correcta?",
                                          icon: "warning",
                                          buttons: ["No", "Si"],
                                          confirmButton: true,
                                        }).then((willDelete) => {
                                          if (willDelete) {
                                            let nombre_completo =
                                              nombre +
                                              " " +
                                              paterno +
                                              " " +
                                              materno;
                                            showNotification(
                                              "success",
                                              "Operación exitosa",
                                              "La línea de captura se generó correctamente!",
                                              null,
                                              3
                                            );
                                            //Función para endpoint de captura
                                            setState({
                                              ...state,
                                              nextStep: true,
                                            });
                                            dataToEndponitCaptura(
                                              rfc_fisica,
                                              nombre_completo,
                                              obs_fisica,
                                              envio_conceptos,
                                              TPDiverso,
                                              2
                                            );
                                          }
                                        });
                                      }
                                    }}
                                  >
                                    Continuar
                                  </div>
                                </div>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                //Fin formulario persona física
                //Formulario de persona moral
                <Box sx={{ flexGrow: 1, marginTop: 3, marginBottom: 5 }}>
                  <Grid container>
                    <Grid className="gridContainer" align="center" item xs={12}>
                      <Card
                        style={{
                          paddingTop: 30,
                          paddingLeft: 30,
                          paddingRight: 30,
                          paddingBottom: 30,
                        }}
                        variant="outlined"
                      >
                        <Grid sx={{ marginTop: 3 }} container spacing={2}>
                          <Grid align="center" item xs={12} sm={6} md={6}>
                            <FormControl
                              sx={{
                                marginTop: 3,
                                width: 400,
                                maxWidth: "100%",
                              }}
                            >
                              <InputLabel htmlFor="my-input">RFC *</InputLabel>
                              <Input
                                error={state.rfcm}
                                onChange={(event) => {
                                  setState({ ...state, rfcm: false });
                                }}
                                onKeyPress={(event) => {
                                  maxLengthLimitInput(event, 11, "rfc");
                                }}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                id="rfc_moral"
                                name="rfc_moral"
                                aria-describedby="my-helper-text"
                              />
                              <FormHelperText id="my-helper-text">
                                Ingresa tu RFC.
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid align="center" item xs={12} sm={6} md={6}>
                            <FormControl
                              sx={{
                                marginTop: 3,
                                width: 400,
                                maxWidth: "100%",
                              }}
                            >
                              <InputLabel htmlFor="my-input">
                                Razón Social *
                              </InputLabel>
                              <Input
                                error={state.rz}
                                onChange={(event) => {
                                  setState({ ...state, rz: false });
                                }}
                                onKeyPress={(event) => {
                                  maxLengthLimitInput(
                                    event,
                                    50,
                                    "razon_social"
                                  );
                                }}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                id="razon_social"
                                name="razon_social"
                                aria-describedby="my-helper-text"
                              />
                              <FormHelperText id="my-helper-text">
                                Ingresa tu razón social.
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{ marginTop: 3, marginBottom: 5 }}
                          container
                          spacing={2}
                        >
                          <Grid style={{ width: "100%" }} item xs={12}>
                            <InputLabel
                              sx={{ marginLeft: 3, marginBottom: 1 }}
                              align="left"
                            >
                              Observaciones
                            </InputLabel>
                            <FormControl style={{ width: "100%" }}>
                              <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder=""
                                id="obs_moral"
                                name="obs_moral"
                                onKeyPress={(event) => {
                                  maxLengthLimitInput(event, 249);
                                }}
                                style={{
                                  width: "100%",
                                  height: 100,
                                  paddingTop: 15,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  paddingBottom: 15,
                                  textTransform: "uppercase",
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{ marginTop: 3, marginBottom: 5 }}
                          container
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            <div
                              style={{ marginRight: 20 }}
                              className="d-flex my-3"
                            >
                              <div className="col"></div>
                              <div
                                className="cta cta--rosa mx-3"
                                onClick={() => {
                                  toTop();

                                  history.replace("/");
                                }}
                              >
                                Cancelar
                              </div>
                              <div
                                className="cta cta--guinda"
                                onClick={() => {
                                  //Obteniendo valores del form
                                  let rfc_moral =
                                    document.getElementById("rfc_moral").value;
                                  let razon_social =
                                    document.getElementById(
                                      "razon_social"
                                    ).value;
                                  let obs_moral =
                                    document.getElementById("obs_moral").value;

                                  let stateMoral = { rfcm: false, rz: false };

                                  //Validando campos requeridos
                                  if (rfc_moral === "" || razon_social === "") {
                                    if (rfc_moral === "") {
                                      stateMoral.rfcm = true;
                                    }
                                    if (razon_social === "") {
                                      stateMoral.rz = true;
                                    }

                                    setState({
                                      ...state,
                                      rfcm: stateMoral.rfcm,
                                      rz: stateMoral.rz,
                                    });
                                    showNotification(
                                      "warning",
                                      "Campos requeridos",
                                      "Por favor, llena todos los campos requeridos",
                                      null,
                                      4
                                    );
                                  } else if (rfc_moral.length !== 12) {
                                    // Validando que el RFC / CURP tenga 13 o 18 caracteres
                                    stateMoral.rfcm = true;
                                    setState({
                                      ...state,
                                      rfcm: stateMoral.rfcm,
                                    });
                                    showNotification(
                                      "warning",
                                      "RFC incorrecto",
                                      "El campo debe de tener 12 caracteres.",
                                      null,
                                      4
                                    );
                                  } else if (razon_social.length < 8) {
                                    // Validando que por lo menos tengan más de 8 caracteres
                                    stateMoral.rz = true;
                                    //Cambiando estado
                                    setState({ ...state, rz: stateMoral.rz });
                                    showNotification(
                                      "warning",
                                      "Valores incorrectos",
                                      "El campo debe de tener al menos 8 caracteres.",
                                      null,
                                      4
                                    );
                                  } else {
                                    swal({
                                      title:
                                        "¿Estás seguro de que tu información es correcta?",
                                      icon: "warning",
                                      buttons: ["No", "Si"],
                                      confirmButton: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        showNotification(
                                          "success",
                                          "Operación exitosa",
                                          "La línea de captura se generó correctamente!",
                                          null,
                                          3
                                        );
                                        //Función para endpoint de captura
                                        dataToEndponitCaptura(
                                          rfc_moral,
                                          razon_social,
                                          obs_moral,
                                          envio_conceptos,
                                          TPDiverso,
                                          2
                                        );
                                      }
                                    });
                                  }
                                }}
                              >
                                Continuar
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
                //Fin formulario persona moral
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
