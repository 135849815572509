import React, { useState, useEffect, useContext } from "react";
import { NotificacionContext } from "../context/notificacion_context";
import MUIDataTable from "mui-datatables";
import IconUI from "../components/ui/icon_ui";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineCheck } from "react-icons/ai";
import { AiFillPlusCircle, AiFillEdit } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { components } from "react-select";
import { services } from "../services/api";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import TextField from "@mui/material/TextField";

import GifLoader from "../components/ui/gifLoader";

import { GrClose } from "react-icons/gr";

const colorUI = "#6f1d46";
// const colorUI = "#8d949e";

const styleModalItem = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: 500,
  overflow: "hidden",
  overflowY: "scroll",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "150px",
  maxHeight: "150px",
}));

const ItemInicio = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "15px",
  minHeight: "250px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const ItemOrdenProceso = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
  width: "100%",
  borderRadius: "30px",
  lineHeight: "60px",
}));

const ConfiguraTramiteGrauititoPlantillas = () => {
  const { showNotification } = useContext(NotificacionContext);

  const [templates, setTemplate] = useState({
    nombre: "",
    descripcion: "",
    formulario_id: null,
    active: 1,
    SelectedForm: null,
  });

  const [state, setState] = useState({
    loading: true,
    dataForms: [],
    modal: false,
    plantillasTramites: [],
  });

  const dataPeticion = async () => {
    try {
      let ListForm = await services(
        "GET",
        `gratuitos/formulariosGratuitos/list`,
        null
      );
      let plantillasTramites = await services(
        "GET",
        `gratuitos/plantillaTramite/index`,
        null
      );

      const reversedForms = Array.isArray(ListForm.data.data)
        ? ListForm.data.data.reverse()
        : [];
      const reversedPlantillas = Array.isArray(plantillasTramites.data.data)
        ? plantillasTramites.data.data.reverse()
        : [];

      setState({
        ...state,
        loading: false,
        dataForms: reversedForms,
        plantillasTramites: reversedPlantillas,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setState({
        ...state,
        loading: false,
        dataForms: [],
        plantillasTramites: [],
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dataPeticion();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const editarTramite = () => {
    swal({
      title: "¿Estás Seguro de Editar el Trámite?",
      text: `Se actualizarán los datos del trámite`,
      buttons: {
        cancel: "Cancelar",
        editar: "Editar",
      },
    }).then(async (result) => {
      if (result == "editar") {
        let data = new FormData();
        data.append("plantilla_id", state.plantilla_id);
        data.append("nombre", state.nombre);
        data.append("descripcion", state.descripcion);
        data.append("formulario_id", templates.formulario_id);

        let editarPlantilla = await services(
          "POST",
          `gratuitos/plantillaTramite/crear_editar`,
          data
        );

        if (editarPlantilla.status === 200) {
          // let tramites = await services('GET', `gratuitos/plantillaTramite/index`, null);
          let plantillasTramites = await services(
            "GET",
            `gratuitos/plantillaTramite/index`,
            null
          );
          if (plantillasTramites.status === 200) {
            state.plantillasTramites = plantillasTramites.data.data;
            setState({
              ...state,
            });
            swal("Ok", "Trámite Actualizado Correctamente", "success");
            handleClose();
          }
        } else {
          swal("Error", "No fue posible editar el trámite", "error");
        }
      }
    });
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };

  const options = {
    selectableRows: false,
    tableBodyHeight: "auto",
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "tablaUsuarios.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
  };

  const columnsPlantillas = [
    {
      name: "id",
      label: "ID",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let id = state.plantillasTramites[dataIndex].id;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {id}
            </div>
          );
        },
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.plantillasTramites[dataIndex].nombre;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "dependencia",
      label: "Dependencia",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre =
            state.plantillasTramites[dataIndex]?.formularios?.dependencia
              ?.nombre;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "descripcion",
      label: "Descripción",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.plantillasTramites[dataIndex].descripcion;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            {state.plantillasTramites[dataIndex].active == 1 ? (
              <div
                style={{ height: "60px" }}
                className={
                  i % 2 === 0
                    ? "w-100 d-flex gap-2 align-items-center justify-content-center bg-rosaClaro "
                    : "w-100 d-flex justify-content-center align-items-center gap-2"
                }
              >
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    const formIdBuscado =
                      state.plantillasTramites[dataIndex].form_id;

                    // Buscar el formulario en dataForms por el form_id
                    const formularioEncontrado = state.dataForms.find(
                      (form) => form.id === formIdBuscado
                    );

                    setState({
                      ...state,
                      modal: true,
                      itemSelected: dataIndex,
                      nombre: state.plantillasTramites[dataIndex].nombre,
                      descripcion:
                        state.plantillasTramites[dataIndex].descripcion,
                      plantilla_id: state.plantillasTramites[dataIndex].id,
                      formulario_id:
                        state.plantillasTramites[dataIndex].form_id,
                    });

                    setTemplate({
                      ...templates,
                      formulario_id:
                        state.plantillasTramites[dataIndex].form_id,
                      SelectedForm: formularioEncontrado,
                    });
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <AiOutlineEdit />
                  </IconUI>
                  <div className="icon_btn_help">Editar</div>
                </div>
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--danger cta--icon icon_btn"
                  onClick={() => {
                    swal({
                      title: "¿Estás seguro de eliminar este trámite?",
                      buttons: {
                        cancel: "Cancelar",
                        eliminar: "Eliminar",
                      },
                    }).then(async (result) => {
                      if (result == "eliminar") {
                        let data = new FormData();
                        data.append(
                          "plantilla_id",
                          state.plantillasTramites[dataIndex].id
                        );
                        data.append(
                          "nombre",
                          state.plantillasTramites[dataIndex].nombre
                        );
                        data.append(
                          "descripcion",
                          state.plantillasTramites[dataIndex].descripcion
                        );
                        data.append(
                          "formulario_id",
                          state.plantillasTramites[dataIndex].form_id
                        );
                        data.append("active", 0);
                        // let response = await services('FREEAPIPOST', `plantillaTramite/crear_editar`, data)
                        let response = await services(
                          "POST",
                          `gratuitos/plantillaTramite/crear_editar`,
                          data
                        );
                        let plantillasTramites = await services(
                          "GET",
                          `gratuitos/plantillaTramite/index`,
                          null
                        );

                        if (response.status === 200) {
                          swal(response.data.message);
                          setState({
                            ...state,
                            plantillasTramites: plantillasTramites.data.data,
                          });
                        }
                      } else {
                        swal("Se canceló la eliminación de la plantilla");
                      }
                    });
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <AiOutlineDelete />
                  </IconUI>
                  <div className="icon_btn_help">Eliminar</div>
                </div>
              </div>
            ) : (
              <div
                className={
                  i % 2 === 0
                    ? "w-100 d-flex justify-content-center p-3 bg-rosaClaro"
                    : "w-100 d-flex justify-content-center p-3"
                }
              >
                <div
                  style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }}
                  className="cta cta--success cta--icon icon_btn"
                  onClick={() => {
                    showNotification(
                      "option",
                      "¿Estás seguro de realizar esta acción?",
                      "Al dar click en Aceptar se habilitará este trámite",
                      async () => {
                        let data = new FormData();
                        data.append(
                          "plantilla_id",
                          state.plantillasTramites[dataIndex].id
                        );
                        data.append(
                          "nombre",
                          state.plantillasTramites[dataIndex].nombre
                        );
                        data.append(
                          "descripcion",
                          state.plantillasTramites[dataIndex].descripcion
                        );
                        data.append(
                          "formulario_id",
                          state.plantillasTramites[dataIndex].form_id
                        );
                        data.append("active", 1);
                        // let response = await services('FREEAPIPOST', `plantillaTramite/crear_editar`, data)
                        let response = await services(
                          "POST",
                          `gratuitos/plantillaTramite/crear_editar`,
                          data
                        );
                        let plantillasTramites = await services(
                          "GET",
                          `gratuitos/plantillaTramite/index`,
                          null
                        );

                        if (response.status === 200) {
                          swal(response.data.message);
                          setState({
                            ...state,
                            plantillasTramites: plantillasTramites.data.data,
                          });
                        }
                      },
                      null
                    );
                  }}
                >
                  <IconUI size={"15px"} color={"white"}>
                    <AiOutlineCheck />
                  </IconUI>
                  <div className="icon_btn_help">Desbloquear</div>
                </div>
              </div>
            )}
          </>
        ),
      },
    },
  ];

  const handleSetFormToProcedure = (item) => {
    setTemplate({ ...templates, formulario_id: item.id, SelectedForm: item });
  };

  const handleSetProcedure = async () => {
    if (templates.nombre !== "") {
      if (templates.descripcion !== "") {
        if (templates.formulario_id !== null) {
          let crearPlantilla = await services(
            "POST",
            `gratuitos/plantillaTramite/crear_editar`,
            templates
          );
          let plantillasTramites = await services(
            "GET",
            `gratuitos/plantillaTramite/index`,
            null
          );

          if (crearPlantilla.status === 200) {
            swal("OK", "Plantilla creada con éxito", "success");
            setTemplate({
              nombre: "",
              descripcion: "",
              formulario_id: null,
              active: 1,
              SelectedForm: null,
            });
            setState({
              ...state,
              nombre: "",
              descripcion: "",
              formulario_id: null,
              active: 1,
              plantillasTramites: plantillasTramites.data.data,
            });
          } else if (crearPlantilla.status === 500) {
            swal("¡Atención!", crearPlantilla.data.error, "warning");
          } else {
            // no va entrar revisar chrisDelFuturo
            swal(
              "¡Atención!",
              "Falló al crear una plantilla, comuniquese con el administrador.",
              "warning"
            );
          }
        } else {
          swal(
            "¡Atención!",
            "Debes seleccionar un formulario a la plantilla",
            "warning"
          );
        }
      } else {
        swal(
          "¡Atención!",
          "Debes Agregar una descripción a la plantilla",
          "warning"
        );
      }
    } else {
      swal("¡Atención!", "Debes Agregar un nombre a la plantilla", "warning");
    }
  };
  const handleClose = () => {
    setState({ ...state, modal: false, nombre: "" });
    setTemplate({
      nombre: "",
      descripcion: "",
      formulario_id: null,
      active: 1,
      SelectedForm: null,
    });
  };
  return (
    <div className="container" style={{ height: "1200px" }}>
      {state.loading ? (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
          <GifLoader></GifLoader>
        </div>
      ) : (
        <></>
      )}

      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Crear Trámite
        </div>
        <div>
          <Grid item xs={2} sm={4} md={12} key={0}>
            <TextField
              value={templates.nombre}
              onChange={(e) => {
                setTemplate({ ...templates, nombre: e.target.value });
              }}
              id="nombrePlantila"
              label="Nombre"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={2} sm={4} md={12} key={0}>
            <TextField
              value={templates.descripcion}
              onChange={(e) => {
                setTemplate({ ...templates, descripcion: e.target.value });
              }}
              id="descripcionPlantilla"
              label="Descripción"
              variant="standard"
              fullWidth
            />
          </Grid>
          <div className="mt-4">
            <InputLabel>Selecciona un formulario</InputLabel>
            <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
              <Select
                options={state.dataForms}
                placeholder="Seleccione un formulario"
                getOptionValue={(options) => options.id}
                getOptionLabel={(options) =>
                  options.nombre + " - " + options?.dependencia?.nombre ?? "N/A"
                }
                onChange={handleSetFormToProcedure}
                components={{ NoOptionsMessage }}
                value={templates.SelectedForm}
              />
            </FormControl>
          </div>
          <div className="cta cta--guinda" onClick={handleSetProcedure}>
            Añadir trámite
          </div>
        </div>

        <Box sx={{ flexGrow: 1 }}>
          <Grid className="container mt-5 mb-5">
            <Grid item xs={8}>
              <MUIDataTable
                style={{ Overflow: "hidden" }}
                title={"Listado de Trámites"}
                data={state.plantillasTramites}
                columns={columnsPlantillas}
                options={options}
              />
            </Grid>
          </Grid>
        </Box>
      </div>

      <Modal
        open={state.modal}
        onClose={handleClose}
        aria-labelledby="modal-add-new-item"
        aria-describedby="modal-add-item"
      >
        <Box sx={style}>
          <div className="d-flex mb-3">
            <div className="col"></div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleClose();
              }}
            >
              <IconUI size={15}>
                <GrClose></GrClose>
              </IconUI>
            </div>
          </div>
          <div className="container my-3">
            <div className="border-bottom--guinda text-big mb-2 text-bold">
              {" "}
              {`Editar Trámite`}
            </div>
          </div>
          <Box sx={{ flexGrow: 1, marginTop: "3%" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={12} key={0}>
                <TextField
                  value={state.nombre}
                  onChange={(e) => {
                    setState({ ...state, nombre: e.target.value });
                  }}
                  id="nombre"
                  label="Nombre"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sm={4} md={12} key={0}>
                <TextField
                  value={state.descripcion}
                  onChange={(e) => {
                    setState({ ...state, descripcion: e.target.value });
                  }}
                  id="descripcion"
                  label="Descripción"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sm={4} md={12} key={0}>
                <InputLabel className="mx-2">
                  Selecciona un formulario
                </InputLabel>
                <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                  <Select
                    options={state.dataForms}
                    placeholder="Seleccione un formulario"
                    getOptionValue={(options) => options.id}
                    getOptionLabel={(options) =>
                      options.nombre + " - " + options?.dependencia?.nombre ??
                      "N/A"
                    }
                    onChange={handleSetFormToProcedure}
                    components={{ NoOptionsMessage }}
                    value={templates.SelectedForm}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <div className="container">
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
              <Grid
                align="center"
                item
                xs={12}
                sm={6}
                md={12}
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{ width: 200 }}
                  className="cta cta--guinda"
                  onClick={() => {
                    editarTramite();
                  }}
                >
                  <div className="me-2">
                    <IconUI size={"20px"} color={"white"}>
                      {state.type === "add" ? (
                        <AiFillPlusCircle />
                      ) : (
                        <AiFillEdit />
                      )}{" "}
                    </IconUI>
                  </div>{" "}
                  Editar
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>

      {/* <Modal
                open={open}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Selecciona el tipo de campo que quieres para tu formulario...
                    </Typography>
                    <Box sx={{ flexGrow: 1, marginTop: "5%" }}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {ComponentesList.map((item, index) => {
                                // console.log(item, "listado de tipos de inputs")
                                return (
                                    <Grid item xs={2} sm={4} md={4} key={index}>
                                        <Item onClick={() => { ItemSeleccionado(item.tipo, item.type, item.idValidacion) }}>{item.tipo} {item.icon} </Item>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    <ColorButton sx={{ marginTop: "3%", marginLeft: "85%" }} onClick={handleClose} variant="outlined"> Terminar </ColorButton>
                </Box>
            </Modal> */}

      {/* <Modal
                open={openModalItem.edit}
                onClose={handleCloseModalItemEdit}
            >
                <Box sx={styleModalItem}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edita tu Componente {openModalItem.opcionEdit}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                        <TextField
                            sx={{ marginBottom: "2%" }}
                            fullWidth
                            id="Nombre_Campo"
                            label="Nombre del Campo"
                            defaultValue=""
                            variant="outlined"
                            value={ItemState.name}
                            onChange={(e) => setItemState({ ...ItemState, label: e.target.value, name: e.target.value })}
                        />

                        {["text", "password", "number", "email", "url", "tel", "file", "date", "datetime-local"].includes(ItemState.type) ?
                            <div className="mt-2">
                                <InputLabel className="mx-2">Categoria del campo</InputLabel>
                                <FormControl sx={{ m: 1, width: '100%', p: 1 }}>
                                    <Select options={state.categoriasInputs}
                                        placeholder="Seleccione una Categoria"
                                        getOptionValue={options => options.id}
                                        getOptionLabel={options => options.tipo}
                                        onChange={handlesetCategoriaCampo}
                                        components={{ NoOptionsMessage }}
                                    />
                                </FormControl>
                            </div>
                            :
                            <></>
                        }
                        {ItemState.type === "select" || openModalItem.opcion === "check" || openModalItem.opcion === "radio" ?
                            <>

                                <InputLabel id="demo-simple-select-helper-label">Opcion 1:</InputLabel>
                                <Input
                                    fullWidth
                                    helperText='Ingresa la opcion'
                                    id='inputOpcion1'
                                    type='text'
                                    value={state.Opcionuno ? state.Opcionuno : ''}
                                    onChange={handleOpcioneComponent}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="add folio"
                                                onClick={() => { agregar() }}
                                                sx={{ color: colorUI, backgroundColor: '#ffc3d0' }}
                                            >
                                                <AddToPhotosIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                                {opciones.length > 0 ? opciones.map((item, i) => {
                                    return (
                                        <Box
                                            key={i}
                                            sx={{
                                                // width: 370,
                                                maxWidth: '100%',
                                                // display: 'flex',
                                                // alignItems: 'flex-end'
                                            }}
                                        >
                                            <InputLabel sx={{ marginTop: "3%" }} id={`labelFolio-${i + 1}`}>Opcion {i + 2}:</InputLabel>
                                            <Input
                                                // sx={{ width: 260 }}
                                                fullWidth
                                                id={i.toString()}
                                                type={item.type}
                                                value={item.value ? item.value : ''}
                                                onChange={handleChangeDinamicInput}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="delete input"
                                                            onClick={(e) => handleDeleteInput(e, item)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </Box>
                                    );
                                }) : <></>}

                            </>
                            :
                            <></>
                        }
                        <div className="mt-2" >
                            <InputLabel sx={{ marginTop: "10%" }} className="mx-2">Validaciones para el campo</InputLabel>
                            <FormControl sx={{ m: 1, width: '100%', p: 1 }}>
                                <Select
                                    // closeMenuOnSelect={false}
                                    isMulti
                                    options={state.validaciones}
                                    placeholder="Seleccione una Categoria"
                                    getOptionValue={options => options.validacion}
                                    getOptionLabel={options => `${options.descripcion}`}
                                    onChange={options => validacionesInputSet(options)}
                                    components={{ NoOptionsMessage }}

                                />
                            </FormControl>
                        </div>

                        {
                            cantidadValidar.min ?
                                <div className="mt-2" >
                                    <TextField
                                        sx={{ marginBottom: "2%" }}
                                        fullWidth
                                        id="min"
                                        label="Ingrese el minimo de caracteres permitidos"
                                        defaultValue=""
                                        variant="outlined"
                                        // value={ItemState.name}
                                        onChange={(e) => setCcantidad({ ...cantidadValidar, valuemin: e.target.value })}
                                    />
                                </div>

                                :
                                <></>
                        }
                        {
                            cantidadValidar.size ?

                                <div className="mt-2" >
                                    <TextField
                                        sx={{ marginBottom: "2%" }}
                                        fullWidth
                                        id="size"
                                        label="Ingrese el maximo de letras permitidas"
                                        defaultValue=""
                                        variant="outlined"
                                        // value={ItemState.name}
                                        onChange={(e) => setCcantidad({ ...cantidadValidar, valuesize: e.target.value })}
                                    />
                                </div>
                                :
                                <></>
                        }
                    </Typography>
                    <ColorButton sx={{ marginTop: "3%", marginLeft: "75%" }} onClick={() => handleUpdateItem(openModalItem.type)} variant="outlined"> Guardar </ColorButton>
                </Box>
            </Modal> */}

      {/* configura tu componente */}
      {/* 
            <Modal
                open={openModalItem.open}
                onClose={handleCloseModalItem}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModalItem}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Configura tu Componente {openModalItem.opcion}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                        <TextField
                            sx={{ marginBottom: "2%" }}
                            fullWidth
                            id="Nombre_Campo"
                            label="Nombre del Campo"
                            defaultValue=""
                            variant="outlined"
                            value={ItemState.name}
                            onChange={(e) => setItemState({ ...ItemState, label: e.target.value, name: e.target.value })}
                        />

                        {openModalItem.opcion === "INPUT" ?
                            <div className="mt-2">
                                <InputLabel className="mx-2">Categoria del campo</InputLabel>
                                <FormControl sx={{ m: 1, width: '100%', p: 1 }}>
                                    <Select options={state.categoriasInputs}
                                        placeholder="Seleccione una Categoria"
                                        getOptionValue={options => options.id}
                                        getOptionLabel={options => options.tipo}
                                        onChange={handlesetCategoriaCampo}
                                        components={{ NoOptionsMessage }}
                                    />
                                </FormControl>
                            </div>
                            :
                            <></>
                        }
                        {openModalItem.opcion === "SELECIÓN" || openModalItem.opcion === "CHECKS" || openModalItem.opcion === "BOTON OPCION" ?
                            <>

                                <InputLabel id="demo-simple-select-helper-label">Opcion 1:</InputLabel>
                                <Input
                                    fullWidth
                                    helperText='Ingresa la opcion'
                                    id='inputOpcion1'
                                    type='text'
                                    // value={folioCaptura.folio ? folioCaptura.folio : ''}
                                    onChange={handleOpcioneComponent}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="add folio"
                                                onClick={() => { agregar() }}
                                                sx={{ color: colorUI, backgroundColor: '#ffc3d0' }}
                                            >
                                                <AddToPhotosIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                                {opciones.length > 0 ? opciones.map((item, i) => {
                                    return (
                                        <Box
                                            key={i}
                                            sx={{
                                                // width: 370,
                                                maxWidth: '100%',
                                                // display: 'flex',
                                                // alignItems: 'flex-end'
                                            }}
                                        >
                                            <InputLabel sx={{ marginTop: "3%" }} id={`labelFolio-${i + 1}`}>Opcion {i + 2}:</InputLabel>
                                            <Input
                                                // sx={{ width: 260 }}
                                                fullWidth
                                                id={i.toString()}
                                                type={item.type}
                                                value={item.value ? item.value : ''}
                                                onChange={handleChangeDinamicInput}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="delete input"
                                                            onClick={(e) => handleDeleteInput(e, item)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </Box>
                                    );
                                }) : <></>}

                            </>
                            :
                            <></>
                        }
                        <div className="mt-2" >
                            <InputLabel sx={{ marginTop: "10%" }} className="mx-2">Validaciones para el campo</InputLabel>
                            <FormControl sx={{ m: 1, width: '100%', p: 1 }}>
                                <Select
                                    // closeMenuOnSelect={false}
                                    isMulti
                                    options={state.validaciones}
                                    placeholder="Seleccione una Categoria"
                                    getOptionValue={options => options.validacion}
                                    getOptionLabel={options => `${options.descripcion}`}
                                    onChange={options => validacionesInputSet(options)}
                                    components={{ NoOptionsMessage }}

                                />
                            </FormControl>
                        </div>

                        {
                            cantidadValidar.min ?
                                <div className="mt-2" >
                                    <TextField
                                        sx={{ marginBottom: "2%" }}
                                        fullWidth
                                        id="min"
                                        label="Ingrese el minimo requerido"
                                        defaultValue=""
                                        variant="outlined"
                                        // value={ItemState.name}
                                        onChange={(e) => setCcantidad({ ...cantidadValidar, valuemin: e.target.value })}
                                    />
                                </div>

                                :
                                <></>
                        }
                        {
                            cantidadValidar.size ?

                                <div className="mt-2" >
                                    <TextField
                                        sx={{ marginBottom: "2%" }}
                                        fullWidth
                                        id="size"
                                        label="Ingrese el maximo de letras permitidas"
                                        defaultValue=""
                                        variant="outlined"
                                        // value={ItemState.name}
                                        onChange={(e) => setCcantidad({ ...cantidadValidar, valuesize: e.target.value })}
                                    />
                                </div>
                                :
                                <></>
                        }
                    </Typography>
                    <ColorButton sx={{ marginTop: "3%", marginLeft: "75%" }} onClick={() => handleAddItem(openModalItem.type)} variant="outlined"> Guardar </ColorButton>
                </Box>
            </Modal> */}
    </div>
  );
};

export default ConfiguraTramiteGrauititoPlantillas;
