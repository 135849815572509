import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { services } from "../services/api";
import { useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import GifLoader from "../components/ui/gifLoader";
import loaderGift from "../assets/loader.gif";
import Modal from "@mui/material/Modal";
import ReactRecaptcha3 from "react-google-recaptcha3";

// Componente Luis Adrian
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const Valoracion = () => {
  const { token } = useParams();
  const history = useHistory();
  const [modalView, setModalView] = React.useState(false);
  const [stateAssessment, setStateAssessment] = React.useState({
    numero: 0,
    token: token,
    comentario: "",
  });

  const textareaRef = React.useRef(null);
  const modalRef = React.useRef(null);

  const [dataProcedure, setDataProcedure] = React.useState({
    image: "",
    subtitulo: "",
    titulo: "",
    url: "",
    folio: "",
    id: 0,
    valoracionPrev: 0,
    comentarios: [],
    nextPage: null,
    prevPage: null,
    disabledNext: true,
    disabledPrev: true,
  });

  const [loader, setLoader] = React.useState(false);
  const [loadingBtns, setLoadingBtns] = React.useState(false);

  const onChangeComment = (e) => {
    setStateAssessment({
      ...stateAssessment,
      comentario: e.target.value,
    });
  };

  const onChangeStars = (e) => {
    setStateAssessment({
      ...stateAssessment,
      numero: e.target.value,
    });
  };

  const viewModal = () => {
    setModalView(!modalView);
  };

  const validation = () => {
    if (stateAssessment.numero === 0) {
      swal({
        title: "¡Atención!",
        text: "Califica por lo menos con 1 estrella.",
        icon: "error",
      });
    } else {
      sendComments();
    }
  };

  // FUNCION PARA OBTENER COMENTARIOS POR TRAMITE
  const getComments = async (cursor) => {
    setLoadingBtns(true);
    let recaptcha_token = "";

    await ReactRecaptcha3.getToken().then(
      (token) => {
        // console.log(token);
        recaptcha_token = token;
        // send token with form data
        // dataToSend.token = token
        // fetch(url, { method: 'POST', body: JSON.stringify(dataToSend) })
      },
      (error) => {
        console.log(error);
      }
    );

    const dataComments = {
      recaptcha_token: recaptcha_token,
      cursor: cursor,
    };

    const { data, status } = await services(
      "POST",
      `ventanillaTramites/valoracion/comentarios/${dataProcedure.id}`,
      dataComments
    );

    if (status === 422) {
      swal({
        title: "¡Atención!",
        text: `${
          data !== undefined
            ? data
            : "El trámite ya ha sido valorado anteriormente."
        }`,
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        setModalView(false);
      });
    }

    if (status === 404) {
      swal({
        title: "¡Atención!",
        text: data,
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        history.push("/");
      });
    }

    if (status === 200) {
      setLoadingBtns(false);
      setDataProcedure({
        ...dataProcedure,
        comentarios: data.data,
        nextPage: data.next_cursor,
        prevPage: data.prev_cursor,
        disabledNext: data.next_page_url,
        disabledPrev: data.prev_page_url,
      });
    }
  };

  const nextPageComments = async () => {
    getComments(dataProcedure.nextPage);
  };

  const prevPageComments = async () => {
    getComments(dataProcedure.prevPage);
  };

  // FUNCION PARA ENVIAR LA DATA DE LA VALORACION
  const sendComments = async () => {
    const sendData = stateAssessment;
    const { data, status } = await services(
      "POST",
      "ventanillaTramites/valoracion/valorar",
      sendData
    );

    setLoader(true);

    if (status === 422) {
      setLoader(false);
      swal({
        title: "¡Error!",
        text: `${
          data !== undefined
            ? data
            : "El trámite ya ha sido valorado anteriormente."
        }`,
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        history.push(`${dataProcedure.url}`);
      });
    }

    if (status === 404) {
      setLoader(false);
      swal({
        title: "¡Error!",
        text: `${
          data !== undefined ? data : "No se ha podido procesar la valoración"
        }`,
        icon: "error",
        button: "Aceptar",
      }).then(() => {});
    }

    if (status === 200) {
      setLoader(false);
      swal({
        title: "¡Éxito!",
        text: "Valoración enviada correctamente, gracias por sus comentarios",
        icon: "success",
        button: "Aceptar",
      }).then(() => {
        // history.push(`${dataProcedure.url}`);
        history.push("/");
      });
      setStateAssessment({
        ...stateAssessment,
        comentario: "",
      });
    }
  };

  // FUNCION PARA TRAER TOKEN
  // const getToken = async () => {
  //   const dataSend = {
  //     tramite_id: 63,
  //     folio: "dsfds",
  //   };

  //   const { data, status } = await services(
  //     "POST",
  //     `ventanillaTramites/valoracion/link`,
  //     dataSend
  //   );

  //   console.log(data);
  // };

  // FUNCION PARA EL GET DE LA PETICION
  const getProcedure = async () => {
    const { data, status } = await services(
      "GET",
      `ventanillaTramites/tramite/${token}`
    );

    if (status === 422) {
      swal({
        title: "¡Atención!",
        text: `${
          data !== undefined
            ? data
            : "El trámite ya ha sido valorado anteriormente."
        }`,
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        history.push("/");
      });
    }

    if (status === 404) {
      swal({
        title: "¡Atención!",
        text: "Este trámite no existe",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        history.push("/");
      });
    }

    // 200 valoracion enviada correctamente
    // navigate a la url de la api
    if (status === 200) {
      setDataProcedure({
        ...dataProcedure,
        image: data.image,
        subtitulo: data.subtitulo,
        titulo: data.titulo,
        valoracionPrev: data.valoracion,
        url: data.url,
        folio: data.f,
        id: data.id,
      });
    }
  };

  // FORMATEO DE FECHA DE CREACION
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString("es-ES", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} a las ${formattedTime}`;
  };

  // Cargado de api
  React.useEffect(() => {
    getProcedure();

    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
      (status) => {
        // console.log(status);
      }
    );
    // getToken();
  }, []);

  React.useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to calculate new height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scroll height
    }
  }, [stateAssessment.comentario]);

  return (
    <>
      {loader ? <GifLoader /> : null}
      <div>
        <div className="valoracion-container mb-5 mt-4 position-relative">
          <div className="d-flex justify-content-center d-md-block">
            <button
              className="bg-color text-white p-2 btn-commments mb-4 mb-md-0 me-lg-3"
              onClick={() => {
                viewModal();
                getComments(null);
              }}
            >
              Ver comentarios
            </button>
          </div>

          <div className="mb-5">
            <h4 className="text-bold h3 text-center color-text">
              Encuesta de satisfacción
            </h4>
            <p className="text-center mb-0 h5 mt-2 text-gray">
              Tu opinión es muy importante para nosotros
            </p>
          </div>

          <div className="d-flex flex-column flex-md-row w-100 w-md-75 mx-auto justify-content-center rounded-card gap-4 bg-white p-3 shadow height-responsive">
            {dataProcedure.image !== "" ? (
              <img
                className="img-fluid img-data mx-auto "
                src={dataProcedure.image}
                width={200}
                height={200}
                alt="Imagen decorativa"
              />
            ) : null}
            {dataProcedure.subtitulo !== "" ? (
              <div className="d-flex flex-column pt-2 w-100 justify-content-between">
                <div className="text-center text-md-start pb-3 pb-md-0">
                  <h4 className="color-text fw-bold h4-5">
                    {dataProcedure.titulo}
                  </h4>
                  <p className="m-0">{dataProcedure.subtitulo}</p>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-center gap-2 justify-content-end">
                  <p className="mb-0 color-text fw-bold">Valoración:</p>
                  <Rating
                    name="half-rating-read"
                    size="large"
                    defaultValue={dataProcedure.valoracionPrev}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center w-100 p-4">
                <img
                  className="img-fluid loader-gift"
                  src={loaderGift}
                  alt="Cargando.."
                />
                <p className="fw-bold mb-0">Cargando..</p>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center d-md-block">
            <button
              className="bg-color text-white p-3 btn-commments-responsive mt-4 mb-md-0 me-md-3"
              onClick={() => {
                viewModal();
                getComments();
              }}
            >
              Ver comentarios
            </button>
          </div>

          {/* Estrellitas de valoracion parte inferior derecha*/}
          <div className="bg-white w-100 w-md-75 mx-auto mt-5 p-3 shadow rounded-card">
            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
              <textarea
                ref={textareaRef}
                maxLength={255}
                className="comentarios mt-2 w-md-100"
                name="commentsValo"
                id="commentsValo"
                value={stateAssessment.comentario}
                onChange={onChangeComment}
                placeholder="Escríbenos tus comentarios"
              ></textarea>

              <div className="d-flex justify-content-center">
                <Box
                  sx={{
                    "& > legend": { mt: 4 },
                  }}
                  className="text-center"
                >
                  <Typography
                    className="fs-4 color-text fw-bold mt-3"
                    component="legend"
                  >
                    ¿Cómo calificarías tu experiencia?
                  </Typography>
                  <div className="d-flex justify-content-center">
                    <Rating
                      className="custom-rating my-2"
                      size="large"
                      name="size-large"
                      value={stateAssessment.numero}
                      onChange={onChangeStars}
                    />
                  </div>
                </Box>
              </div>

              <div className="d-flex flex-column flex-md-row w-100 justify-content-between align-items-center px-2">
                {dataProcedure.folio !== "" ? (
                  <p className="m-0 fs-6 color-text pb-3 pb-md-0 text-bold">
                    <span className="fw-bold">Folio de Trámite:</span>{" "}
                    {dataProcedure.folio}
                  </p>
                ) : (
                  <p className="m-0 fs-5 color-text">Cargando folio...</p>
                )}

                <input
                  onClick={validation}
                  className="p-2 fs-5 btn-submit"
                  style={{ backgroundColor: "#229450", color: "#fff" }}
                  type="submit"
                  value="Enviar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL PARA CARGAR COMENTARIOS POR TRAMITE */}
      {modalView ? (
        <Modal
          open={modalView}
          onClose={viewModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal} ref={modalRef}>
            <div
              className="w-100 top-0 bg-danger position-sticky"
              style={{ zIndex: "999" }}
            >
              <button
                onClick={viewModal}
                className="border-0 rounded-circle bg-color position-absolute"
                style={{ right: "0" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-x p-1"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#fff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </button>
            </div>

            <h2 className="d-flex align-items-center gap-2 color-text fw-bold h4 text-center text-md-start mb-2 ">
              COMENTARIOS
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-message d-none d-md-block"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#6f1d46"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 9h8" />
                <path d="M8 13h6" />
                <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
              </svg>
            </h2>

            <div className="py-4">
              <ul className="p-0">
                {dataProcedure.comentarios.length === 0 ? (
                  <li>No hay comentarios en este trámite…</li>
                ) : loadingBtns ? (
                  <li>Cargando comentarios…</li>
                ) : (
                  dataProcedure.comentarios?.map((comment, index) => (
                    <div className="d-flex flex-column flex-md-row gap-2 mb-4">
                      <span className="d-md-none">Comentario {index + 1}</span>
                      <div className="w-auto d-flex justify-content-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-user-circle d-none d-md-block"
                          width="45"
                          height="45"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#6f1d46"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                          <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                          <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                        </svg>
                      </div>
                      <div className="w-100">
                        <li
                          className="fs-5 bg-gray rounded-3 p-3 d-flex gap-2 align-items-center position-relative"
                          key={index}
                        >
                          {comment.comentario}
                          <span className="position-absolute end-0 bottom-0 fs-6 me-3 mb-1 d-none d-md-block fw-bold">
                            Creado el: {formatDate(comment.created_at)}
                          </span>
                        </li>
                      </div>
                    </div>
                  ))
                )}
              </ul>
            </div>
            <div className="d-flex flex-column gap-3 gap-md-0 flex-md-row w-100 justify-content-between">
              <button
                disabled={
                  loadingBtns || dataProcedure.disabledPrev === null
                    ? true
                    : false
                }
                onClick={() => {
                  if (modalRef.current) {
                    modalRef.current.scrollTo({ top: 0 });
                  }
                  prevPageComments();
                }}
                className={`${
                  dataProcedure.disabledPrev === null ? "opacity-25" : null
                } m-0 color-text p-2 rounded-card border-0 border-md-0 d-flex align-items-center justify-content-center`}
              >
                {loadingBtns ? (
                  <div className="spinner-border color-text" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-caret-left"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#6f1d46"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 6l-6 6l6 6v-12" />
                    </svg>
                    <span className="color-text fs-5">Atrás</span>
                  </>
                )}
              </button>
              <button
                disabled={
                  loadingBtns || dataProcedure.disabledNext === null
                    ? true
                    : false
                }
                onClick={() => {
                  if (modalRef.current) {
                    modalRef.current.scrollTo({ top: 0 });
                  }
                  nextPageComments();
                }}
                className={`${
                  dataProcedure.disabledNext === null ? "opacity-25" : null
                } m-0 border-0 p-2 rounded-card d-flex align-items-center justify-content-center`}
              >
                {loadingBtns ? (
                  <div className="spinner-border color-text" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                ) : (
                  <>
                    <span className="color-text fs-5">Siguiente</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-caret-right"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#6f1d46"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 18l6 -6l-6 -6v12" />
                    </svg>
                  </>
                )}
              </button>
            </div>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default Valoracion;
