import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import consultaFolioEmisor from "../assets/img/Consulta_folio_emisor.jpg";

const InfoActas = () => {
  const history = useHistory();
  const backButton = () => {
    history.push("/validaciondeacta");
  };

  return (
    <Grid
      container
      spacing={2}
      columns={6}
      justifyContent="center"
      alignContent="center"
      sx={{ minHeight: "70vh" }}
    >
      <Grid item xs={15} justifyContent="start" className="mx-4">
        <Typography
          variant="outlined"
          sx={{
            width: "120px",
            cursor: "pointer",
            margin: ".2rem",
            color: "#6f1d46",
          }}
          onClick={() => backButton()}
        >
          <ArrowBackIcon sx={{ marginRight: "0.5rem" }} />
          Regresar
        </Typography>
      </Grid>
      <Grid
        item
        xs={16}
        md={6}
        lg={4}
        className="sombrasprincipal card d-flex flex-column m-4 h-100 justify-content-center"
      >
        <h2>Consulta el Folio Emisor de tus documentos</h2>
        <h5>¿Cómo puedo consultar el folio emisor de mis documentos?</h5>
        <img
          src={consultaFolioEmisor}
          alt="Consulta de Folio emisor"
          className="d-flex px-5 pb-2"
        />
      </Grid>
    </Grid>
  );
};

export default InfoActas;
