import React from 'react'

export default function DotLoader(props) {
    return (
        <div className={`dottedloader dottedloader--${props.size}`}>
            <div className="dottedloader_dot" style={{backgroundColor:props.color}}></div>
            <div className="dottedloader_dot" style={{backgroundColor:props.color}}></div>
            <div className="dottedloader_dot" style={{backgroundColor:props.color}}></div>
        </div>
    )
}
DotLoader.defaultProps = {
    color:'#1946bb',
    size:'re'
}
