import { FormControl, Input, InputLabel } from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { NotificacionContext } from "../context/notificacion_context";
import { services } from "../services/api";
import GifLoader from "../components/ui/gifLoader";

const colorUI = "#6f1d46";
// const colorUI="#8d949e";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const CambioContraseñaView = () => {
  let { search } = useLocation();
  // const { code } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("code");

  const { showNotification } = useContext(NotificacionContext);
  const [state, setState] = useState({
    passwordOne: "",
    passwordTwo: "",
    showPassword: false,
    loading: false,
  });

  const handleChangePassword = ({ target }) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
  };

  // useEffect(async () => {

  //   let body = {
  //     code: myParam
  //   }
  //   let checkCode = await services("POST", "validarCode", body)

  //   if (!checkCode.data?.peticion) {
  //     window.location = "/"
  //   }

  // }, [])

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const changePass = async () => {
    setState({ ...state, loading: true });
    const { passwordOne, passwordTwo } = state;
    if (passwordOne !== passwordTwo) {
      showNotification(
        "error",
        "Contraseñas no coinciden",
        "Favor de verificar las Contraseñas",
        null,
        4
      );
    } else {
      if (passwordOne.length > 5 && passwordTwo.length > 5) {
        let body = {
          // "code": code.get("code"),
          code: myParam,
          password: passwordOne,
          password_confirmation: passwordTwo,
        };

        let data = await services("POST", "password-change", body);

        if (
          data.status == 500 &&
          data.data.error == "El campo code seleccionado no es válido."
        ) {
          showNotification(
            "error",
            "El token expiro",
            "Solicite de nuevo el cambio de contraseña",
            null,
            4
          );
        } else {
          showNotification(
            "success",
            data.data.mensaje,
            "En unos segundos se redireccionara al Login",
            null,
            4
          );
          setTimeout(() => {
            window.location = "/login";
          }, 3500);
        }
      } else {
        showNotification(
          "error",
          "Contraseñas deben ser mayor a 5 caracteres.",
          "Favor de verificar las Contraseñas",
          null,
          4
        );
      }
    }
    setState({ ...state, loading: false });
  };

  return (
    <div className="container my-5 vh-100">
      {state.loading ? <GifLoader></GifLoader> : null}
      <div className="row m-3 p-3">
        <div className="card col-md-8 m-auto text-center">
          <p
            style={{ fontWeight: "bolder", textAlign: "center" }}
            className="text-guinda"
          >
            Cambio de Contraseña
          </p>
          <div>Tú contraseña debe tener al menos 6 caracteres.</div>
          <FormControl className="w-75 m-auto" variant="standard">
            <InputLabel htmlFor="password">Nueva Contraseña</InputLabel>
            <Input
              id="password"
              name="passwordOne"
              value={state.passwordOne}
              type={state.showPassword ? "text" : "password"}
              onChange={handleChangePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl className="w-75 m-auto mt-3" variant="standard">
            <InputLabel htmlFor="password">Confirmar Contraseña</InputLabel>
            <Input
              id="password"
              name="passwordTwo"
              value={state.passwordTwo}
              type={state.showPassword ? "text" : "password"}
              onChange={handleChangePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <ColorButton
            className=" m-auto mt-4"
            variant="outlined"
            onClick={() => {
              changePass();
            }}
          >
            Cambiar contraseña{" "}
          </ColorButton>
        </div>
      </div>
    </div>
  );
};

export default CambioContraseñaView;
