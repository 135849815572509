import { useEffect, useState } from "react";
import { services } from "../services/api";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CertificadoHome from "../assets/img/EscudoGris.png";
import GifLoader from "../components/ui/gifLoader";
import { useHistory } from "react-router-dom";

const TramiteGratuitoList = () => {
  const [formulario, setFormulario] = useState({
    data: [],
    loading: false,
  });
  const history = useHistory();

  const api = async () => {
    const response = await services(
      "GET",
      "gratuitos/tramites/seleccionarPlantilla",
      null
    );
    const ordenamiento = response.data.data.sort(function(a, b){return b.id - a.id})
    if (response.status === 200) {
      setFormulario({
        ...formulario,
        data: ordenamiento,
        loading: false,
      });
    }
  };

  const handleGoToCategory = (item) => {
    history.replace(`/TramitesGratuitos/Formulario/${item}`);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setFormulario({ ...formulario, loading: true });
      api();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div className="container p-3" style={{ height: "auto" }}>
        {formulario.loading ? (
          <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <GifLoader></GifLoader>
          </div>
        ) : (
          <></>
        )}
        <div className="container my-3">
          <div className="border-bottom--guinda text-big mb-2 text-bold">
            Mis Trámites
          </div>
        </div>
        <div className="d-lg-flex h-100">
          <Box sx={{ flexGrow: 1, marginTop: "2%" }}>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={16}>
                <Grid container spacing={2} columns={{ xs: 5, sm: 10, md: 15 }}>
                  {formulario.data.length === 0 ? (
                    <div className="text-center tex-big my-3 text-bold text-guinda w-100">
                      No hay Registros configurados
                    </div>
                  ) : (
                    <>
                      {formulario.data.map((item) => (
                        <Grid item xs={5}>
                          <Card
                            sx={{
                              maxWidth: 450,
                              marginTop: "3%",
                              minWidth: 250,
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            }}
                          >
                            <CardActionArea
                              onClick={() => handleGoToCategory(item.id)}
                            >
                              <CardContent sx={{ minHeight: 170 }}>
                                <Card className="nuevodiseño">
                                  <CardActionArea>
                                    <CardContent
                                      sx={{
                                        minHeight: 170,
                                        textAlign: "center",
                                      }}
                                    >
                                      <CardMedia
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <img
                                          width={"50%"}
                                          alt="Imagen Decorativa"
                                          src={CertificadoHome}
                                        />
                                      </CardMedia>
                                      <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                      >
                                        
                                        {item.nombre} <br /> <small> {item?.formularios?.dependencia?.nombre} </small>
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default TramiteGratuitoList;
