import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PaymentIcon from "@mui/icons-material/Payment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { services } from "../services/api";
import GifLoader from "../components/ui/gifLoader";

const colorUI = "#6f1d46";
// const colorUI="#8d949e";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const ColorButtonSecond = styled(Button)(({ theme }) => ({
  color: colorUI,
  backgroundColor: "#ffc3d0",
  border: "none",
  "&:hover": {
    transform: "scale(1.005)",
    boxShadow: "3px 3px 6px 0px rgba(0, 0, 0, 0.2)",
  },
}));

const ValidarPago = () => {
  const history = useHistory();
  const [state, setState] = useState({
    data: {},
    isPayment: {},
    ready: false,
    error: false,
  });

  const { lcaptura } = useParams();

  const pdfFake = "";

  const validar = async (lcaptura) => {
    let lineadepago = await services(
      "POST",
      `consultaLineaDiversos?${lcaptura}`,
      null
    );

    if (lineadepago.status === 200) {
      setState({
        ...state,
        data: lineadepago.data.ES_OPAG
          ? lineadepago.data.ES_OPAG
          : lineadepago.data.ES_ORDEN_PAGO,
        ready: true,
        isPayment: lineadepago.data.Pago,
      });
    }
    if (lineadepago.status === 500) {
      alert("500");
      setState({ ...state, ready: false });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!lcaptura) {
        history.replace("/");
        return;
      }
      validar(lcaptura);
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // const regresar = () => {
  //     history.replace("/login")
  // }

  const formDate = (date) => {
    return `${date.substring(0, 4)}/${date.substring(4, 6)}/${date.substring(
      6,
      8
    )}`;
  };
  const {
    CONCEPTOPLINEA,
    FECHAPLINEA,
    FORMATO,
    IMPORTE,
    LINEA_CAPTURA,
    TOKEN,
    URLRETORNO,
  } = state.data;
  const { Estatus, DOC_PAGO, FEC_PAGO, mensage } = state.isPayment;

  return (
    <div className=" h-100">
      {!state.ready ? (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <GifLoader></GifLoader>
          </div>
        </>
      ) : (
        <Container maxWidth="md">
          <Box sx={{ height: "auto", paddingTop: "3%" }}>
            <Box>
              <Grid
                container
                spacing={1}
                sx={{ justifyContent: "right", alignItems: "right" }}
              >
                {/* <Grid item xs="auto" >
                                    <ColorButton variant="outlined" onClick={() => { regresar() }} endIcon={<KeyboardReturnIcon />} >Regresar </ColorButton>
                                </Grid> */}
                <Grid item xs="auto">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={FORMATO ? FORMATO + "/2" : pdfFake}
                  >
                    <ColorButtonSecond
                      variant="outlined"
                      endIcon={<CloudDownloadIcon />}
                    >
                      {Estatus === "01"
                        ? "Descargar mi Recibo de pago"
                        : "Descargar mi formato de pago"}
                    </ColorButtonSecond>
                  </a>
                </Grid>
                <Grid item xs="auto">
                  <form action={process.env.REACT_APP_URLPAGO} method="post">
                    <input
                      type="hidden"
                      name="concepto"
                      value={CONCEPTOPLINEA}
                    />
                    <input
                      type="hidden"
                      name="lcaptura"
                      value={LINEA_CAPTURA}
                    />
                    <input type="hidden" name="monto" value={IMPORTE} />

                    <input
                      type="hidden"
                      name="fecha_vencimiento"
                      value={FECHAPLINEA}
                    />
                    <input type="hidden" name="tkn" value={TOKEN} />
                    {/* <input type="hidden" name="urlDiversos" value={process.env.REACT_APP_URLREDIRECT}/> */}
                    <input
                      type="hidden"
                      name="urlDiversos"
                      value={URLRETORNO}
                    />
                    <input type="hidden" name="urlRetorno" value={URLRETORNO} />
                    <input type="hidden" name="email" value="" />

                    {/* 01 = pagado 02 = no se a pagado  */}
                    {Estatus === "02" ? (
                      <ColorButton
                        variant="outlined"
                        type="submit"
                        endIcon={<PaymentIcon />}
                      >
                        {" "}
                        Pagar en Línea
                      </ColorButton>
                    ) : (
                      <></>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {Estatus === "01" || Estatus === "05" ? (
            <div className="container my-3">
              <div className="bg-green text-small text-green p-2">
                <h5>{mensage}</h5>
                No.Recibo: {DOC_PAGO} <br />
                Fecha de pago: {FEC_PAGO ? formDate(FEC_PAGO) : ""} <br />
              </div>
            </div>
          ) : (
            <div className="container my-3">
              <div className="bg-red text-small p-2">
                <h5>{mensage}</h5>
                {Estatus === "02" ? (
                  <>
                    Descarga tu formato de pago o realiza el pago en línea
                    <br />
                  </>
                ) : null}
                Línea de captura: {LINEA_CAPTURA} <br />
              </div>
            </div>
          )}
          <div className="pt-2 border-bottom--guinda text-big mb-2 text-bold">
            {Estatus === "01" ? "Recibo de pago" : "Formato de pago"}
          </div>
          {!state.error ? (
            <div className="d-flex justify-content-center aling-items-center py-3 vh-100">
              <object
                data={FORMATO}
                type="application/pdf"
                height="100%"
                width="100%"
                aria-label="formato"
              ></object>
              {/* <PDFViewer
                document={{
                  url: { FORMATO },
                }}
                scale={1.2}
                loader={<GifLoader />}
                hideNavbar={true}
                alert={(err) => (
                  <Container>
                    <img style={{ width: "90%" }} src="mensajeError.png"></img>{" "}
                    {setState({ ...state, error: true })}
                  </Container>
                )}
              /> */}
            </div>
          ) : (
            <div className="vh-100 d-flex justify-content-center aling-items-center pt-3 pb-3">
              <object
                data={FORMATO}
                type="application/pdf"
                height="100%"
                width="100%"
                aria-label="formato"
              ></object>
              {/* <a href={FORMATO} target="_blank" rel="noopener noreferrer"><div className='cta cta--guinda'>VER FORMATO DE PAGO</div></a> */}
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

export default ValidarPago;
