import React from 'react';
import LineaCaptura from '../components/linea_captura';



const Cajero = () => {

    const user = JSON.parse(localStorage.getItem('usuario'));

    const rol = user.rol ? user.rol : 'Ventanilla';
    const id = user.curp ? user.curp : '0';
    const username = user.nombre ? user.nombre : 'Cajero Feliz';
    const municipio = user.id_municipio ? user.id_municipio : 'Morelia';
    const oficina = user.id_oficina ? user.id_oficina : 'COCOTRA';


    return (
        <div className='cajeroview vh-100'>
            <LineaCaptura
                rol={rol}
                id={id}
                user={username}
                municipio={municipio}
                oficina={oficina}
            />
        </div>
    )
}



export default Cajero;