import { TextField } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { NotificacionContext } from "../context/notificacion_context";
import { services } from "../services/api";
import GifLoader from "../components/ui/gifLoader";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { useHistory } from "react-router-dom";

const colorUI = "#6f1d46";
// const colorUI="#8d949e";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));
const RecuperarContraseñaView = () => {
  const [email, setEmail] = useState("");
  const [state, setState] = useState({
    loading: false,
  });
  const { showNotification } = useContext(NotificacionContext);
  const history = useHistory();

  const sendEmail = async () => {
    setState({ loading: true });

    let recaptcha_token = "";

    await ReactRecaptcha3.getToken().then(
      (token) => {
        recaptcha_token = token;
      },
      (error) => {
        console.log(error);
      }
    );

    const body = {
      email: email,
      recaptcha_token: recaptcha_token,
    };

    let servicePassword = await services("POST", "password-forget", body);

    if (servicePassword.status === 500) {
      showNotification(
        "error",
        "Error con el Email",
        servicePassword?.data?.error,
        null,
        4
      );
    } else {
      showNotification(
        "success",
        "Email Enviado",
        "Se envio correctamente ",
        null,
        4
      );
      history.push("/");
    }

    setState({ loading: false });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
        (status) => {}
      );
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="container my-5 vh-100">
      {state.loading ? <GifLoader></GifLoader> : null}
      <div className=" row  m-3 p-3">
        <div className="card col-md-6 m-auto text-center">
          <p
            style={{ fontWeight: "bolder", textAlign: "center" }}
            className="text-guinda"
          >
            ¿Olvidaste tu contraseña?
          </p>
          <p>
            Por favor, rellene el correo electrónico que utilizó para
            registrarse. Se le enviará un correo electrónico con instrucciones
            sobre cómo restablecer su contraseña.
          </p>
          <TextField
            className="w-75 m-auto my-2"
            label="Email"
            name="email"
            size="small"
            variant="standard"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <ColorButton
            className="m-auto mt-4"
            variant="outlined"
            onClick={() => {
              sendEmail();
            }}
          >
            Enviar{" "}
          </ColorButton>
        </div>
      </div>
    </div>
  );
};

export default RecuperarContraseñaView;
