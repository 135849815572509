import Select, { components } from "react-select";
// import { TextField } from '@mui/material';

import { FormControl, InputLabel } from "@mui/material";

const SelectBusqueda = ({
  opciones,
  funcion,
  selectOpcion,
  descripcionMultiple = null,
  descripcion = null,
  id = null,
  titulo = " Institución Educativa",
}) => {
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <>
      <InputLabel id="demo-simple-select-helper-label" className="mx-2 ">
        {titulo}
      </InputLabel>
      <FormControl
        sx={{
          width: "100%",
          borderBottom: "1px solid",
          borderColor: "#4b101f",
        }}
      >
        <Select
          value={selectOpcion}
          className="SelectBuscarCEdulas mb-2"
          options={opciones}
          placeholder={`Seleccione ${titulo}`}
          getOptionValue={(options) => (id ? options[id] : options.id)}
          getOptionLabel={(options) =>
            descripcionMultiple
              ? `${options[descripcionMultiple[0]]} --  ${
                  options[descripcionMultiple[1]]
                }`
              : descripcion
              ? options[descripcion]
              : options.descripcion
          }
          onChange={(options) => funcion(options)}
          components={{ NoOptionsMessage }}
        />
      </FormControl>
    </>
  );
};

export default SelectBusqueda;
