import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { NotificacionContextProvider } from "./context/notificacion_context";
import NotificacionUi from "./components/ui/notificacion_ui";
import HomeView from "./views/home_View";
import WelcomeView from "./views/welcome_View";
import { SubCatalogo } from "./views/subCatalogo_View";
import { Resumen } from "./views/resumen_View";
import { HeaderComponent } from "./components/header_component";
import { Footer } from "./components/footer_component";
import Captura from "./views/captura";
import PrivateRoute from "./components/auth/privateRoute";
import PrivateRouteAdmin from "./components/auth/privateRouteAdmin";
import CapturaState from "../src/context/captura_context/capturaState";
import { NoMatchView } from "./views/noMatcht_View";
import ValidarPago from "./views/validar_View";
import Administrador from "./views/admin";
import Dependencias from "./views/dependencias";
import Areas from "./views/Areas";
import Cajero from "./views/cajero";
import Error500 from "./views/error_View";
import Bitacora from "./components/bitacora";
import RecuperarContraseñaView from "./views/recuperarContraseñaView";
import CambioContraseñaView from "./views/cambioContraseñaView";
import HomeViewPublic from "./views/homeViewPublic";
import { SubCatalogoViewPublic } from "./views/subCatalogoViewPublic";
import { ResumenViewPublic } from "./views/resumenViewPublic";
import CapturaViewPublic from "./views/capturaViewPublic";
import { SecretariaEducacionView } from "./views/secretariaEducacionView";
import CertificadoVehicularView from "./views/CertificadoVehicularView";
import ValidarDocumentos from "./views/VerificadorDocumentos/ValidarDocumentos";
import ValidarLineaDeCaptura from "./views/VerificadorDocumentos/VerificadorLineaCaptura";
import SolicitudCertificadoView from "./views/SolicitudCertificadoView";

import { ConfiguraTramite } from "./views/configuraTrámite";
import ConfiguraTramiteGratuito from "./views/ConfiguraTramiteGratuito";
import FormularioDinamico from "./views/formularioDinamico";

import ImpuestoEstatalView from "./views/ImpuestoEstatalView";
import ConsesionView from "./views/ConsesionView";

import RegistroCedulas from "./views/registroCedulas";
import RegistroCedulasProvisionales from "./views/RegistroCedulasProvisionales";
//Soni
import RegistroCedulasEstado from "./views/registroCedulasEstado";

// import FormularioTramiteGratuito from "./views/FormularioTramiteGratuito";
import TramiteGratuitoList from "./views/TramiteGratuitoList";
import ConfiguraTramiteGrauititoPlantillas from "./views/ConfiguraTramiteGrauititoPlantillas";
import Turnadotramite from "./views/turnadotramite";

import FormularioGratuito from "./views/FormularioGratuito";

import ListadeTramites from "./views/ListadeTramites";

import TablaBeta from "./views/tablapruebas";
import ReportesView from "./views/reports/ReportesView";

// Cedulas
import SolicitudCedulas from "./views/CedulasSEE/SolicitudCedula";
import ImcedTramites from "./views/imcedTramites";

import Valoracion from "./views/Valoracion";
// import Mantenimiento from "./components/Mantenimiento";
// import { Login } from "./views/Pinpad/Login";
import { VerificadorPago } from "./views/Pinpad/verificadorPago";
import { CancelacionPago } from "./views/Pinpad/CancelacionPago";
import InfoActas from "./views/InfoActas";
import ValidacionActas from "./views/ValidacionActas";
import { FooterVisibilityProvider } from "./context/footer_context/footerVisibilityContext";

export default function App() {
  return (
    <CapturaState>
      <Router>
        <NotificacionContextProvider>
          <FooterVisibilityProvider>
            <main
              id="mainContent"
              className="space-header-main background-fondo-home background-gray"
            >
              <div className="d-flex flex-column">
                {/* DESCOMENTAR ESTE COMPONENTE PARA EL MANTENIMIENTO */}
                {/* <Mantenimiento /> */}
                <HeaderComponent />
                <NotificacionUi />
                <div>
                  <Switch>
                    <Route exact path="/">
                      <HomeViewPublic />
                    </Route>
                    <Route exact path="/imced/tramites">
                      <ImcedTramites />
                    </Route>
                    <Route exact path="/subcategoriaP/:id">
                      <SubCatalogoViewPublic />
                    </Route>
                    <Route exact path="/resumenP">
                      <ResumenViewPublic />
                    </Route>
                    <Route exact path="/verificar-Certificado/:folio">
                      <ValidarDocumentos />
                    </Route>
                    <Route exact path="/verificar-linea-de-captura/:folio">
                      <ValidarLineaDeCaptura />
                    </Route>
                    <Route exact path="/capturaP">
                      <CapturaViewPublic />
                    </Route>
                    <Route exact path="/validarpago/:lcaptura">
                      <ValidarPago />
                    </Route>
                    <Route exact path="/educacion/duplicados">
                      <SecretariaEducacionView />
                    </Route>
                    <Route exact path="/educacion/solicitudCertificado">
                      <SolicitudCertificadoView />
                    </Route>
                    <Route exact path="/valoracion/tramite/:token">
                      <Valoracion />
                    </Route>
                    <Route exact path="/educacion/titulos">
                      <RegistroCedulasEstado />
                    </Route>
                    <Route exact path="/educacion/titulos/:folio">
                      <RegistroCedulasEstado />
                    </Route>
                    <Route exact path="/educacion/cedulas">
                      <RegistroCedulas />
                    </Route>
                    <Route exact path="/educacion/solicitudCedulas">
                      <SolicitudCedulas />
                    </Route>
                    <Route exact path="/educacion/solicitudCedulas/:folio">
                      <SolicitudCedulas />
                    </Route>
                    <Route exact path="/educacion/provisional">
                      <RegistroCedulasProvisionales />
                    </Route>
                    <Route exact path="/educacion/provisional/:folio">
                      <RegistroCedulasProvisionales />
                    </Route>
                    <Route exact path="/educacion/cedulas/:folio">
                      <RegistroCedulas />
                    </Route>
                    <Route exact path="/Constancia-de-Interés-Particular">
                      <CertificadoVehicularView />
                    </Route>
                    <Route exact path="/Solicitud-Impuesto-Estatal">
                      <ImpuestoEstatalView />
                    </Route>
                    <Route exact path="/Solicitud-Concesion">
                      <ConsesionView />
                    </Route>
                    <Route exact path="/login">
                      <WelcomeView />
                    </Route>
                    <Route exact path="/recuperarContraseña">
                      <RecuperarContraseñaView />
                    </Route>
                    <Route exact path="/cambioContraseña">
                      <CambioContraseñaView />
                    </Route>
                    {/* <Route exact path="/home">
                    <HomeView />
                  </Route> */}
                    <PrivateRoute exact path="/subcategoria/:id">
                      <SubCatalogo />
                    </PrivateRoute>
                    <PrivateRoute exact path="/resumen">
                      <Resumen />
                    </PrivateRoute>
                    <PrivateRoute exact path="/captura">
                      <Captura />
                    </PrivateRoute>
                    <Route exact path="/tramite/:categoria/:concepto/">
                      <FormularioDinamico />
                    </Route>
                    <PrivateRoute exact path="/TramitesGratuitos">
                      <TramiteGratuitoList />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/TramitesGratuitos/Formulario/:id"
                    >
                      <FormularioGratuito />
                    </PrivateRoute>
                    <PrivateRoute exact path="/Tramites">
                      <ListadeTramites />
                    </PrivateRoute>
                    <PrivateRoute exact path="/test">
                      <TablaBeta />
                    </PrivateRoute>
                    <Route exact path="/modificar/tramite/:concepto/:folio">
                      <FormularioDinamico />
                    </Route>
                    <PrivateRouteAdmin exact path="/admin">
                      <Administrador />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin exact path="/reportes">
                      <ReportesView />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin exact path="/configuradorTramite">
                      <ConfiguraTramite />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin exact path="/Turnado-Tramite">
                      <Turnadotramite />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin
                      exact
                      path="/configuradorTramiteGratuito"
                    >
                      <ConfiguraTramiteGratuito />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin
                      exact
                      path="/configuradorTramiteGratuito_plantillas"
                    >
                      <ConfiguraTramiteGrauititoPlantillas />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin exact path="/dependencias">
                      <Dependencias />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin exact path="/areas">
                      <Areas />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin exact path="/areas/:filtro">
                      <Areas />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin exact path="/ventanilla">
                      <Cajero />
                    </PrivateRouteAdmin>
                    <PrivateRoute exact path="/validarpago/:lcaptura">
                      <ValidarPago />
                    </PrivateRoute>
                    <PrivateRoute exact path="/verificarPago/:lcaptura">
                      <VerificadorPago />
                    </PrivateRoute>
                    <Route exact path="/serverError">
                      <Error500 />
                    </Route>
                    <Route exact path="/bitacora">
                      <Bitacora />
                    </Route>
                    <PrivateRouteAdmin exact path="/home">
                      <HomeView />
                    </PrivateRouteAdmin>
                    <PrivateRouteAdmin exact path="/cancelacion-pago">
                      <CancelacionPago />
                    </PrivateRouteAdmin>
                    <Route exact path="/validaciondeacta">
                      <ValidacionActas />
                    </Route>
                    <Route exact path="/masInformacion-Actas">
                      <InfoActas />
                    </Route>
                    <Route path="*">
                      <NoMatchView />
                    </Route>
                  </Switch>
                </div>
                {/* <div className="col"></div> */}
                <Footer />
              </div>
            </main>
          </FooterVisibilityProvider>
        </NotificacionContextProvider>
      </Router>
    </CapturaState>
  );
}
