import React, { useState, useEffect, useRef, useContext } from "react";
import { services } from "../services/api";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import Select, { components } from "react-select";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Slide from "@mui/material/Slide";
import GifLoader from "../components/ui/gifLoader";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import capturaContext from "../context/captura_context/capturaContext";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, FormHelperText } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const FormularioGratuito = () => {
  const colorUI = "#6f1d46";
  // const colorUI = "#8d949e";

  const { envio_conceptos, TPDiverso } = useContext(capturaContext);

  const [checked, setchecked] = useState(false);
  const containerRef = useRef(null);

  const folio = false;
  const { id } = useParams();

  let history = useHistory();

  const [checkvalue, setCheckvalue] = useState({});

  const { CANTIDAD, IMPORTE } = envio_conceptos[0];

  if (TPDiverso) {
    localStorage.setItem("cantidad", CANTIDAD);
    localStorage.setItem("TPDiverso", TPDiverso);
    localStorage.setItem("IMPORTE", IMPORTE);
  }

  const [state, setState] = useState({
    nombre: "",
    data: undefined,
    id: "",
    email: "",
    razonSocial: "",
    rfc: "",
    erroremail: false,
    erroremailMensaje: "",
    errorRazonSocial: false,
    errorRazonSocialMensaje: "",
    errorRfc: false,
    errorRfcMensaje: "",
    loading: true,
    descripcion: "",
    pagoLinea: 0,
    cantidad: CANTIDAD === 0 ? localStorage.getItem("cantidad") : CANTIDAD,
    tp_diverso: TPDiverso ?? localStorage.getItem("TPDiverso"),
    importe: IMPORTE === 0 ? localStorage.getItem("IMPORTE") : IMPORTE,
    documentos: true,
    folio: "",
    linkPago: "",
  });

  const [datos, setDatos] = useState({
    dataAreas: [],
    dataDependencias: [],
    selectedDep: [],
    selectedArea: [],
  });
  const [dependencia, setDependencia] = useState();

  const [area, setArea] = useState();

  const [areas, setAreas] = useState();

  const setValues = (id, selectedId) => {
    setState((prevState) => ({
      ...prevState,
      data: prevState.data.map((item) =>
        item.id === id ? { ...item, valor: selectedId } : item
      ),
    }));
  };
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };

  const handleChangeDependencia = (item) => {
    setDependencia(item.id);
    const areasEncontrada = areas.filter(
      (area) => area.id_dependencia === item.id
    );
    setDatos({
      ...datos,
      selectedDep: item,
      dataAreas: areasEncontrada,
    });
  };
  const handleChangeArea = (item) => {
    setDatos({
      ...datos,
      selectedArea: item,
    });
    setArea(item.id);
  };
  const handleSacarValor = (id) => {
    const response = state.data.filter((item) => {
      if (item.id === id) {
        return item.opcion;
      }
    });

    return response[0];
  };

  const setChecksValue = (id, value) => {
    setState({
      ...state,
      data: state.data.map((item) => {
        if (item.id === id) {
          if (checkvalue[value]) {
            delete checkvalue[value];
          } else {
            checkvalue[value] = value;
          }
          item.valor = Object.keys(checkvalue);

          return item;
        } else {
          return item;
        }
      }),
    });
  };

  const setValuesFiles = async (id, value) => {
    setState({
      ...state,
      data: state.data.map((item) => {
        if (item.id === id) {
          const typeFile = value.target.files[0].name.split(".");

          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64String = await reader.result
              .replace("data:", "")
              .replace(/^.+,/, "");

            item.valor = base64String;
            item.extencion = typeFile[typeFile.length - 1];
          };
          reader.readAsDataURL(value.target.files[0]);

          return item;
        } else {
          return item;
        }
      }),
      documentos: false,
    });
  };

  const setDataToDB = async () => {
    const body = {
      plantilla_id: state.id,
      area_id: area,
      elementos: state.data.map((item) => {
        if (item.tipo_input === "radio") {
          let id = item.options.find((opcion) => item.valor === opcion.opcion);
          return {
            [item.name]: id ? id.id : "",
          };
        } else if (item.tipo_input === "switch") {
          // let id = item.options.find((opcion) => item.valor === opcion.opcion);
          return {
            [item.name]: (item.valor === "" || item.valor === null) ? false : item.valor,
          };
        } else {
          return {
            [item.name]: item.valor ? item.valor : "",
          };
        }
      }),
    };

    // let response = await services('FREEAPIPOST', `tramites/crear_editar`, body)
    let response = await services(
      "POST",
      `gratuitos/tramites/crear_editar`,
      body
    );

    if (response.data.error) {
      if (typeof response.data.error == "object") {
        setState({
          ...state,
          loading: false,
          data: state.data.map((item) => {
            if (Object.keys(response.data.error).includes(item.name)) {
              let dataerror = response.data.error[item.name];

              item["error"] = true;
              item["mensajeError"] = `${dataerror[0]}`;

              return item;
            } else {
              item["error"] = false;
              item["mensajeError"] = "";

              return item;
            }
          }),
        });
      } else {
        setState({
          ...state,
          loading: false,
        });
        swal(response.data.message, response.data.error, "error");
      }
    } else {
      setState({
        ...state,
        loading: false,
      });
      swal(response.data.title, response.data.message, "success");
      history.push("/TramitesGratuitos");
    }
  };

  const nextSteap = async () => {
    // setState({ ...state, loading: true });

    setState({ ...state, loading: true });

    setDataToDB();
  };

  const setComponent = (component) => {
    switch (component.tipo_input) {
      case "text":
      case "password":
      case "number":
      case "email":
      case "url":
      case "tel":
        return (
          <>
            <InputLabel className="mx-2  FormularioDinamicoRadios">
              {component.label}
            </InputLabel>
            <TextField
              id={component.id}
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              type={component.tipo_input}
              fullWidth
              
              error={component.error ? true : false}
              defaultValue={component.valor}
              helperText={component.mensajeError ? component.mensajeError : ""}
              onChange={(e) => {
                setValues(component.id, e.target.value);
              }}

            />
          </>
        );

      case "textarea":
        return (
          <>
            <InputLabel className="mx-2  FormularioDinamicoRadios">
              {component.label}
            </InputLabel>
            <TextField
              id={component.id}
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              type={component.tipo_input}
              fullWidth
              multiline
              maxRows={4}
              error={component.error ? true : false}
              helperText={component.mensajeError ? component.mensajeError : ""}
              defaultValue={component.valor}
              onChange={(e) => {
                setValues(component.id, e.target.value);
              }}
            />
          </>
        );

      case "file":
        return (
          <>
            {folio ? (
              state.documentos ? (
                <CardActionArea onClick={console.log}>
                  {component.extencion !== "pdf" &&
                  component.extencion !== "doc" &&
                  component.extencion !== "xmls" &&
                  component.extencion !== "docx" ? (
                    <CardMedia
                      component="img"
                      height="150"
                      src={component.valor}
                      alt={`Documento ${component.label}`}
                    />
                  ) : component.extencion !== "pdf" ? (
                    <a
                      href={component.valor}
                      target="_blank"
                      style={{
                        backgroundColor: colorUI,
                        color: "white",
                        borderRadius: "12px",
                        cursor: "pointer",
                        padding: "5px 7px",
                        marginBottom: "10px",
                        marginLeft: "25%",
                        marginTop: "6%",
                      }}
                    >
                      <CloudDownloadIcon /> Descargar Documento
                    </a>
                  ) : (
                    <>
                      <CardMedia
                        component="iframe"
                        height="150"
                        src={component.valor}
                        alt={`Documento ${component.label}`}
                      />
                      <a
                        href={component.valor}
                        target="_blank"
                        style={{
                          backgroundColor: colorUI,
                          color: "white",
                          borderRadius: "12px",
                          cursor: "pointer",
                          padding: "5px 7px",
                          marginBottom: "10px",
                        }}
                      >
                        Abrir PDF
                      </a>
                    </>
                  )}
                </CardActionArea>
              ) : null
            ) : (
              <>
                <InputLabel className="mx-2  FormularioDinamicoRadios">
                  {component.label}
                </InputLabel>
                <TextField
                  id={component.id}
                  fullWidth
                  required={component.validaciones == "required" ? true : false}
                  name={component.name}
                  type={component.tipo_input}
                  error={component.error ? true : false}
                  // disabled={state.estatusID !== "5" &&  state.estatusID !== ""  ? true : false}

                  defaultValue={folio ? "" : component.valor}
                  helperText={
                    component.mensajeError ? component.mensajeError : ""
                  }
                  onChange={(e) => {
                    setValuesFiles(component.id, e);
                  }}
                />
              </>
            )}

            {state.estatusID === "5" ? (
              <>
                <InputLabel className="mx-2  FormularioDinamicoRadios">
                  {component.label}
                </InputLabel>
                <TextField
                  id={component.id}
                  fullWidth
                  required={component.validaciones == "required" ? true : false}
                  name={component.name}
                  type={component.tipo_input}
                  error={component.error ? true : false}
                  // disabled={state.estatusID !== "5" &&  state.estatusID !== "" ? true : false}

                  defaultValue={folio ? "" : component.valor}
                  helperText={
                    component.mensajeError ? component.mensajeError : ""
                  }
                  onChange={(e) => {
                    setValuesFiles(component.id, e);
                  }}
                />
              </>
            ) : null}
          </>
        );

      case "date":
      case "datetime-local":
        return (
          <>
            <InputLabel className="mx-2  FormularioDinamicoRadios">
              {component.label}
            </InputLabel>
            <TextField
              id={component.id}
              //disabled={state.estatusID !== "5" &&  state.estatusID !== "" ? true : false}
              fullWidth
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              type={component.tipo_input}
              defaultValue={component.valor}
              placeholder="DD/MM/YYYY"
              onChange={(e) => {
                setValues(component.id, e.target.value);
              }}
              error={component.error ? true : false}
              helperText={component.mensajeError ? component.mensajeError : ""}
            />
          </>
        );

      case "select":
        return (
          <>
            <InputLabel className="mx-2 FormularioDinamicoRadios">
              {component.label}
            </InputLabel>

            <Select
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              value={handleSacarValor(component.id)}
              id={component.id}
              onChange={(selectedOption) => {
                setValues(component.id, selectedOption.id);
              }}
              options={component.options}
              placeholder="Seleccione una opción"
              getOptionValue={(options) => options.id}
              getOptionLabel={(options) => options.opcion}
              error={component.error ? true : false}
              helperText={component.mensajeError ? component.mensajeError : ""}
            />
            {component.error ? (
              <div className="errorFormDinamicComponent">
                <spam>{component.mensajeError}</spam>
              </div>
            ) : null}
          </>
        );

      case "switch":
        return (
          <>
            <FormGroup>
              <FormControlLabel
                name={component.name}
                error={component.error ? true : false}
                helperText={
                  component.mensajeError ? component.mensajeError : ""
                }
                // disabled={state.estatusID !== "5" &&  state.estatusID !== ""  ? true : false} sx={{ marginLeft: "25%" }}
                id={component.id}
                required={component.validaciones == "required" ? true : false}
                onChange={(e, value) => {
                  setValues(component.id, value);
                }}
                control={<Switch />}
                label={component.label}
              />
            </FormGroup>
            {component.error ? (
              <div className="errorFormDinamicComponent">
                <spam>{component.mensajeError}</spam>
              </div>
            ) : null}
          </>
        );

      case "checkbox":
        return (
          <>
            <FormLabel className="FormularioDinamicoRadios" component="legend">
              {component.label}
            </FormLabel>

            <FormGroup
              row
              sx={{ marginLeft: "25%" }}
              name={component.name}
              onChange={(e) => {
                setChecksValue(component.id, e.target.value);
              }}
            >
              {component.options.map((item) => (
                <>
                  <FormControlLabel
                    label={item.opcion}
                    id={item.id}
                    error={component.error ? true : false}
                    helperText={
                      component.mensajeError ? component.mensajeError : ""
                    }
                    value={item.opcion}
                    checked={
                      component.valor !== null
                        ? component.valor.includes(item.opcion)
                        : false
                    }
                    required={
                      component.validaciones == "required" ? true : false
                    }
                    control={<Checkbox />}
                  />
                </>
              ))}
            </FormGroup>
            {component.error ? (
              <div className="errorFormDinamicComponent">
                <spam>{component.mensajeError}</spam>
              </div>
            ) : null}
          </>
        );

      case "radio":
        return (
          <>
            <FormControl
              className="FormularioDinamicoFormControl"
              //   disabled={state.estatusID !== "5" &&  state.estatusID !== "" ? true : false}
              error={component.error ? true : false}
              helperText={component.mensajeError ? component.mensajeError : ""}
            >
              <FormLabel
                className="FormularioDinamicoRadios"
                id="radio-buttons-group"
              >
                {component.label}
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                row
                name="radio-buttons-group"
                onChange={(e) => {
                  setValues(component.id, e.target.value);
                }}
              >
                {component.options.map((item) => (
                  <FormControlLabel
                    value={item.opcion}
                    checked={component.valor === item.opcion}
                    control={<Radio />}
                    label={item.opcion}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {component.error ? (
              <div className="errorFormDinamicComponent">
                <spam>{component.mensajeError}</spam>
              </div>
            ) : null}
          </>
        );
    }
  };

  const api = async (url) => {
    let response = await services("GET", url, null);

    if (localStorage.getItem("rol") === "Administrador") {
      let dependencias = await services("GET", `dependencia/listar`, null);
      // let areas = await services("FREEAPIGET", "areas/index", null);
      let areas = await services("GET", "gratuitos/areas/index", null);
      if (dependencias.status === 200) {
        setDatos({
          ...state,
          dataDependencias: dependencias.data,
        });
        setAreas(areas.data.data);
      }
    }

    if (response.error) {
      history.replace("/");
    } else {
      setState({
        ...state,
        nombre: response.data.data.plantillaTramite.nombre,
        data: response.data.data.elementos,
        id: response.data.data.plantillaTramite.id,
        loading: false,
        descripcion: response.data.data.plantillaTramite.descripcion,
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (id) {
        api(`gratuitos/tramites/nuevoTramite/${id}`);
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {state.loading && <GifLoader />}
      <div
        className="w-75 mx-auto"
        style={{ height: "1200px", overflow: "auto" }}
      >
        <div
          style={{ overflow: "hidden" }}
          ref={containerRef}
          className=" d-flex justify-content-center card m-3 text-center"
        >
          {!checked && (
            <Slide
              direction="right"
              in={!checked}
              container={containerRef.current}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h3 className="content_title content_titleForm_dinamic">
                  {state.nombre}
                </h3>
                <p className="formDinamicoDescripc">{state.descripcion}</p>

                {state.data !== undefined ? (
                  <Box
                    sx={{ width: "100%", marginTop: "5%", marginBottom: "5%" }}
                  >
                    <Grid
                      direction="row"
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                      columns={{ xs: 1, sm: 2, md: 2 }}
                    >
                      {/* <Grid className="formDinamicoaire" item xs={1} direction="row">
                        <InputLabel className="mx-2  FormularioDinamicoRadios">Correo Electronico</InputLabel>
                        <TextField id="email-form"
                          required
                          // label={"Correo Electronico"}
                          value={state.email}
                          name={"correo"}
                          type="email"
                          fullWidth
                          error={state.erroremail}
                          helperText={state.erroremailMensaje}
                          onChange={(e) => { setState({ ...state, email: e.target.value }) }}
                          disabled={folio ? true : false}
                        />
                      </Grid> */}

                      {state.data.map((item) => (
                        <Grid className="formDinamicoaire" item xs={1}>
                          {setComponent(item)}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ) : (
                  <></>
                )}

                {state.estatusID === ""
                  ? //   <div className='cta cta--guinda mt-2' onClick={nextSteap}> Continuar </div>
                    null
                  : null}
                {localStorage.getItem("rol") === "Administrador" ? (
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={12} key={0}>
                      <InputLabel
                        style={{ marginLeft: 0, marginTop: 35 }}
                        id="demo-simple-select-helper-label"
                      >
                        Dependencias *
                      </InputLabel>
                      <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                        <Select
                          style={{ zIndex: 110 }}
                          options={datos.dataDependencias}
                          placeholder="Seleccione Dependencia"
                          getOptionValue={(options) => options.claveDependencia}
                          getOptionLabel={(options) => options.nombre}
                          onChange={(options) =>
                            handleChangeDependencia(options)
                          }
                          id="id_dependencia"
                          name="id_dependencia"
                          components={{ NoOptionsMessage }}
                          value={state.selectedDep}
                        />

                        <FormHelperText>
                          Selecciona una dependencia.
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} sm={4} md={12} key={0}>
                      <InputLabel
                        style={{ marginLeft: 0, marginTop: 35 }}
                        id="demo-simple-select-helper-label"
                      >
                        Área *
                      </InputLabel>
                      <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                        <Select
                          style={{ zIndex: 110 }}
                          options={datos.dataAreas}
                          placeholder="Seleccione un Area"
                          getOptionValue={(options) => options.id}
                          getOptionLabel={(options) => options.nombre}
                          onChange={(options) => handleChangeArea(options)}
                          id="id_areas"
                          name="id_areas"
                          components={{ NoOptionsMessage }}
                          value={state.selectedArea}
                        />
                        <FormHelperText>Selecciona un área.</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : null}
                {state.estatusID !== "6" && state.estatusID !== "" ? (
                  //   <div className='cta cta--guinda mt-2' onClick={() => {state.estatusID === "5" ? nextSteap() : history.push('/')}}> {state.estatusID === "5" ? "Guardar Cambios" : "Regresar"}</div>
                  <div className="cta cta--guinda mt-2" onClick={nextSteap}>
                    {" "}
                    Enviar Datos
                  </div>
                ) : null}
              </div>
            </Slide>
          )}
        </div>
      </div>
    </>
  );
};

export default FormularioGratuito;
