import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import IconUI from "./ui/icon_ui";
import audiopadoru from "../assets/navidadSong.mp3";
import { useContext } from "react";
import capturaContext from "../context/captura_context/capturaContext";
import logoGobMich from "../assets/img/LogoGris.png";
import logoGobDigital from "../assets/img/Sello.png";
import logoTeleferico from "../assets/img/LogoTeleferico.png";
import { useFooterVisibility } from "../context/footer_context/footerVisibilityContext";

export const Footer = () => {
  const { currentUser } = useContext(capturaContext);
  const currentYear = new Date().getFullYear();
  const { isFooterVisible } = useFooterVisibility();

  if (!isFooterVisible) return null;
  // console.log(currentUser,"currentUser")
  return (
    <>
      {currentUser ? null : (
        <>
          <div
            id="footer"
            className="shadow-sm bg-white border-top--rosa  p-4 margin-on-search mt-4"
          >
            <div className="container">
              <div className="d-md-flex d-none align-items-center justify-content-center">
                <div className="mensajeGobiernoNormal d-flex flex-column flex-lg-row justify-content-center align-items-center gap-lg-5">
                  <div>
                    <img
                      width="240px"
                      height="120px"
                      className="img-fluid"
                      src={logoTeleferico}
                      alt="Gobierno De Michoacán"
                    />
                  </div>
                  <div className="d-none d-lg-block">
                    <img
                      width="130px"
                      className="img-fluid"
                      src={logoGobDigital}
                      alt="Gobierno Digital"
                    />
                  </div>
                  <div className="text-footer text-small my-2">
                    © Desarrollado por{" "}
                    <span
                      className="text-footer cursor-pointer"
                      onClick={() => {
                        window.open(
                          "https://gobiernodigital.michoacan.gob.mx/",
                          "_blank"
                        );
                      }}
                    >
                      Dirección General de Gobierno Digital
                    </span>{" "}
                    <br /> Gobierno del Estado de Michoacán {currentYear}
                  </div>
                </div>
                <div>
                  <div className="redes text-center d-flex justify-content-center gap-3 mb-2">
                    <div
                      className="me-2 icon_btn"
                      onClick={() => {
                        window.open(
                          "https://www.facebook.com/gobmichoacan/",
                          "_blank"
                        );
                      }}
                    >
                      <IconUI size={"22px"}>
                        <FaFacebookF />
                      </IconUI>
                    </div>
                    <div
                      className="me-2 icon_btn"
                      onClick={() => {
                        window.open(
                          "https://www.instagram.com/gobmichoacan/?hl=es",
                          "_blank"
                        );
                      }}
                    >
                      <IconUI size={"25px"}>
                        <AiOutlineInstagram />
                      </IconUI>
                    </div>
                    <div
                      className="me-2 icon_btn"
                      onClick={() => {
                        window.open(
                          "https://twitter.com/GobMichoacan",
                          "_blank"
                        );
                      }}
                    >
                      <IconUI size={"22px"}>
                        <BsTwitter />
                      </IconUI>
                    </div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center text-small  text-footer">
                      Enlaces de comunicación
                    </div>
                    <a target="_blank" href="Aviso-de-Privacidad.pdf">
                      <div className="text-center enlace-login">
                        Aviso de Privacidad
                      </div>
                    </a>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="text-footer h5 my-2">#HonestidadyTrabajo</div>
                </div>
              </div>
              <div className="d-flex justify-content-center aling-items-center d-none w-100">
                <div className="mx-auto">
                  <img
                    width="240px"
                    height="120px"
                    src={logoTeleferico}
                    alt="Gobierno De Michoacán"
                  />
                </div>
                <div className="mx-auto ">
                  <img
                    width="130px"
                    src={logoGobDigital}
                    alt="Gobierno Digital"
                  />
                </div>
                <div className="text-footer text-center text-small my-2 d-sm-none">
                  © Desarrollado por{" "}
                  <span
                    className="cursor-pointer text-footer"
                    onClick={() => {
                      window.open(
                        "https://gobiernodigital.michoacan.gob.mx/",
                        "_blank"
                      );
                    }}
                  >
                    Dirección General de Gobierno Digital
                  </span>{" "}
                  <br /> Gobierno del Estado de Michoacán {currentYear}
                </div>
              </div>
              <div className="d-md-none d-flex  flex-column align-items-center justify-content-center">
                <div className="d-flex flex-column justify-content-center aling-items-center gap-3">
                  <div className="mx-auto">
                    <img
                      width="240px"
                      height="90px"
                      src={logoTeleferico}
                      alt="Gobierno De Michoacán"
                    />
                  </div>
                  <div className="mx-auto">
                    <img
                      width="130px"
                      src={logoGobDigital}
                      alt="Gobierno Digital"
                    />
                  </div>
                  <div className="text-footer text-center text-small my-2">
                    © Desarrollado por{" "}
                    <span
                      className="text-footer cursor-pointer"
                      onClick={() => {
                        window.open(
                          "https://gobiernodigital.michoacan.gob.mx/",
                          "_blank"
                        );
                      }}
                    >
                      Dirección General de Gobierno Digital
                    </span>{" "}
                    <br /> Gobierno del Estado de Michoacán {currentYear}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="redes text-center d-flex justify-content-center gap-3">
                    <div
                      className="me-2 icon_btn"
                      onClick={() => {
                        window.open(
                          "https://www.facebook.com/gobmichoacan/",
                          "_blank"
                        );
                      }}
                    >
                      <IconUI size={"22px"}>
                        <FaFacebookF />
                      </IconUI>
                    </div>
                    <div
                      className="me-2 icon_btn"
                      onClick={() => {
                        window.open(
                          "https://www.instagram.com/gobmichoacan/?hl=es",
                          "_blank"
                        );
                      }}
                    >
                      <IconUI size={"25px"}>
                        <AiOutlineInstagram />
                      </IconUI>
                    </div>
                    <div
                      className="me-2 icon_btn"
                      onClick={() => {
                        window.open(
                          "https://twitter.com/GobMichoacan",
                          "_blank"
                        );
                      }}
                    >
                      <IconUI size={"22px"}>
                        <BsTwitter />
                      </IconUI>
                    </div>
                  </div>
                  <div className="mt-1">
                    {/* <div className='text-center text-small text-gray'>Correo Institucional</div> */}
                    <div className="text-center text-small text-footer">
                      Enlaces de comunicación
                    </div>
                    <a target="_blank" href="Aviso-de-Privacidad.pdf">
                      <div className="text-center text-small  text-footer">
                        Aviso de Privacidad
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="backgrounPlaca">
            <br />
            <br />
            <br />
          </div>
        </>
      )}
    </>
  );
};
