import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PaymentIcon from "@mui/icons-material/Payment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useHistory } from "react-router-dom";
import GifLoader from "../components/ui/gifLoader";
// context
import { NotificacionContext } from "../context/notificacion_context";
//pdf
import CapturaContext from "../context/captura_context/capturaContext";
import PaymentsIcon from "@mui/icons-material/Payments";

const colorUI = "#6f1d46";
// const colorUI="#8d949e";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const ColorButtonSecond = styled(Button)(({ theme }) => ({
  color: colorUI,
  backgroundColor: "#ffc3d0",
  border: "none",
  "&:hover": {
    transform: "scale(1.005)",
    boxShadow: "3px 3px 6px 0px rgba(0, 0, 0, 0.2)",
  },
}));

const Captura = () => {
  const history = useHistory();
  const capturactx = useContext(CapturaContext);
  const [state, setState] = useState({
    error: false,
  });
  const { pago_linea } = capturactx;
  const pdfFake = "";
  const {
    CONCEPTOPLINEA,
    FECHAPLINEA,
    FORMATO,
    IMPORTE,
    LINEA_CAPTURA,
    URLRETORNO,
  } = pago_linea;

  const TOKEN = pago_linea.TOKEN ? pago_linea.TOKEN : "";
  const { showNotification } = useContext(NotificacionContext);

  localStorage.removeItem("tokenPago");

  const regresar = () => {
    history.replace("/home");
  };

  const verificarPago = () => {    
    if(parseInt(IMPORTE) >= 50000){
      showNotification("error", "Compra Declinada","No se aceptan pagos PINPAD mayores a $50,000", null, 4);
      return;
    };
    localStorage.setItem("lineaCaptura", LINEA_CAPTURA);
    localStorage.setItem("importe", IMPORTE);

    const referencia = localStorage.getItem("lineaCaptura");
    if (referencia) {
      window.open(`/verificarPago/${referencia}`, "_blank");
    } else {
      history.push("/");
    }

    // window.open("/verificarPago", "_blank");
  };

  const { usuario_pinpad, password_pinpad } = JSON.parse(
    localStorage.getItem("usuario")
  );
  const serial = localStorage.getItem("serial");

  return (
    <div className=" h-100">
      {!FORMATO ? (
        regresar()
      ) : (
        <Container maxWidth="md">
          <Box sx={{ height: "auto", paddingTop: "6%" }}>
            <Box>
              <Grid
                container
                spacing={1}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                {/* <Grid item xs="auto" >
                                    <ColorButtonSecond variant="outlined" onClick={() => { regresar() }} endIcon={<KeyboardReturnIcon />} >Regresar </ColorButtonSecond>
                                </Grid> */}
                <Grid item xs="auto">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={FORMATO ? `${FORMATO}/2` : pdfFake}
                  >
                    <ColorButtonSecond
                      variant="outlined"
                      endIcon={<CloudDownloadIcon />}
                    >
                      {" "}
                      Descargar mi formato de pago
                    </ColorButtonSecond>
                  </a>
                </Grid>
                <Grid item xs="auto">
                  <form action={process.env.REACT_APP_URLPAGO} method="post">
                    <input
                      type="hidden"
                      name="concepto"
                      value={CONCEPTOPLINEA}
                    />
                    <input
                      type="hidden"
                      name="lcaptura"
                      value={LINEA_CAPTURA}
                    />
                    <input type="hidden" name="monto" value={IMPORTE} />

                    <input
                      type="hidden"
                      name="fecha_vencimiento"
                      value={FECHAPLINEA}
                    />
                    <input type="hidden" name="tkn" value={TOKEN} />
                    {/* <input type="hidden" name="urlDiversos" value={process.env.REACT_APP_URLREDIRECT}/> */}
                    <input
                      type="hidden"
                      name="urlDiversos"
                      value={URLRETORNO}
                    />
                    <input type="hidden" name="urlRetorno" value={URLRETORNO} />
                    <input type="hidden" name="email" value="" />

                    <div className="d-flex justify-content-center align-items-center">
                      <ColorButton
                        variant="outlined"
                        type="submit"
                        endIcon={<PaymentIcon />}
                      >
                        {" "}
                        Pagar en Línea
                      </ColorButton>

                      {usuario_pinpad && password_pinpad && serial === "1" && (
                        <ColorButton
                          className="ms-2"
                          variant="outlined"
                          onClick={verificarPago}
                          endIcon={<PaymentsIcon />}
                        >
                          {" "}
                          Pagar con PinPad
                        </ColorButton>
                      )}
                    </div>
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div className="pt-2 border-bottom--guinda text-big mb-2 text-bold">
            Formato de pago
          </div>
          {!state.error ? (
            <div className="d-flex justify-content-center aling-items-center py-3 vh-100">
              <object
                data={FORMATO}
                type="application/pdf"
                height="100%"
                width="100%"
              ></object>
              {/* <PDFViewer
                document={{
                  url: { FORMATO },
                }}
                scale={1.2}
                loader={<GifLoader />}
                hideNavbar={true}
                alert={(err) => (
                  <Container>
                    <img
                      alt="errorImg"
                      style={{ width: "90%" }}
                      src="mensajeError.png"
                    ></img>{" "}
                    {setState({ ...state, error: true })}
                  </Container>
                )}
              /> */}
            </div>
          ) : (
            <div className="vh-100 d-flex justify-content-center aling-items-center pt-3 pb-3">
              <object
                data={FORMATO}
                type="application/pdf"
                height="100%"
                width="100%"
              ></object>
              {/* <a href={FORMATO} target="_blank" rel="noopener noreferrer"><div className='cta cta--guinda'>VER FORMATO DE PAGO</div></a> */}
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

export default Captura;
