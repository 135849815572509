import React, { useState, useEffect, useContext } from "react";
import { services } from "../services/api";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PopUP from "../components/ui/popUp";
import LineaCaptura from "../components/linea_captura";
import CrudUsers from "../components/crud_users";
import { useHistory } from "react-router-dom";
import CapturaContext from "../context/captura_context/capturaContext";
import GifLoader from "../components/ui/gifLoader";

const colorUI = "#6f1d46";
// const colorUI="#8d949e";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const ColorButtonSecond = styled(Button)(({ theme }) => ({
  color: colorUI,
  backgroundColor: "#ffc3d0",
  border: "none",
  "&:hover": {
    transform: "scale(1.005)",
    boxShadow: "3px 3px 6px 0px rgba(0, 0, 0, 0.2)",
  },
}));

const Administrador = () => {
  const {
    setDatosTabla,
    AdminTableData,
    currentUser,
    setAreas,
    AdminTableAreas,
  } = useContext(CapturaContext);
  const history = useHistory();

  const [data, setdata] = useState({
    lineaCaptura: false,
    admin: false,
    showPopUp: false,
    showPopUpUpdate: false,
    bodyUpdate: {},
    dataUsers: [],
  });

  const [loading, setLoading] = useState(false);

  const consumoApiUsers = async () => {
    setLoading(true);
    let dataUsersApi = await services("GET", `usuarios/listar`, null);
    // let areas = await services("FREEAPIGET", "areas/index", null);
    let areas = await services("GET", "gratuitos/areas/index", null);
    if (dataUsersApi.status === 200 && areas.status === 200) {
      setDatosTabla(dataUsersApi.data);
      setAreas(areas.data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      consumoApiUsers();

      // if(!(currentUser.rol === 'Administrador')){
      //   history.replace("/")
      // }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const lineasCaptura = () => {
    if (data.lineaCaptura) {
      setdata({ ...data, lineaCaptura: false });
    } else {
      setdata({ ...data, admin: false, lineaCaptura: true });
    }
  };

  const admin = () => {
    if (data.admin) {
      setdata({ ...data, admin: false });
    } else {
      setdata({ ...data, admin: true, lineaCaptura: false });
    }
  };

  return (
    <>
      {loading ? <GifLoader /> : null}
      <div className={data.admin || data.lineaCaptura ? "" : "adminview"}>
        <div className="container my-3">
          <div className="border-bottom--guinda text-big mb-2 text-bold">
            Usuarios
          </div>
        </div>

        {/* <Container maxWidth="md">
        
                <Box sx={{ height: 'auto', paddingTop: '3%', paddingBottom: '3%'}}>
                        <Box >
                            <Grid container spacing={1} sx= {{justifyContent: 'right', alignItems: 'right'}}>
                                    
                                    <Grid item xs={6} md='auto' className='botonesAdmin'>
                                        <ColorButtonSecond className='botonusuariosadmin' variant="outlined" onClick={() => {admin()}}
                                        //  endIcon={<KeyboardReturnIcon />}
                                         > Administrar Usuarios</ColorButtonSecond>
                                    </Grid>
                                    <Grid item xs={6}  md='auto' className='botonesAdmin'>
                                        <ColorButton 
                                        onClick={() => {lineasCaptura()}}  
                                        variant="outlined" 
                                        // endIcon={<CloudDownloadIcon />} 
                                        >Canjear Linea de Captura</ColorButton>
                                    </Grid>
                                    <Grid item xs='auto'  md={3}></Grid>
                                    
                            </Grid>
                        </Box>
                    </Box>
          </Container> */}
        {data.lineaCaptura ? (
          <div className=" h-100 pb-4">
            <LineaCaptura rol="Administrador" id="1" />
          </div>
        ) : (
          <></>
        )}

        <CrudUsers
          dataUsers={AdminTableData ? AdminTableData : []}
          areas={AdminTableAreas ? AdminTableAreas : []}
          showPopUpOnView={() => {
            setdata({ ...data, showPopUp: true });
          }}
          showPopUpUpdateOnView={(bodyUpdate) => {
            setdata({ ...data, showPopUpUpdate: true, bodyUpdate: bodyUpdate });
          }}
        />

        {data.showPopUp ? (
          <PopUP
            html={null}
            onclose={() => {
              setdata({ ...data, showPopUp: false });
            }}
            tipo="form"
            bodyUpdate={null}
          />
        ) : null}

        {data.showPopUpUpdate ? (
          <PopUP
            html={null}
            onclose={() => {
              setdata({ ...data, showPopUpUpdate: false });
            }}
            tipo="update"
            bodyUpdate={data.bodyUpdate}
          />
        ) : null}
      </div>
    </>
  );
};

export default Administrador;
