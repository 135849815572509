// import {GrServices} from 'react-icons/gr'
// import gifLogo1 from '../../assets/img/Logo3.gif'
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

// import gifLogo1 from '../assets/img/Logo3.gif'
// import gifLogo1 from '../assets/img/Logo-1.gif'
import gifLogo1 from "../assets/img/Logo-1.gif";
import Grid from "@mui/material/Grid";
// import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { emailValidation } from "../utils/inputsRules";
import { NotificacionContext } from "../context/notificacion_context";
import capturaContext from "../context/captura_context/capturaContext";
import { services } from "../services/api";
import { usePINPAD } from "../hooks/usePINPAD";
// import mantenimiento from "../assets/img/mantenimiento.jpg";
import ReactRecaptcha3 from "react-google-recaptcha3";

import GifLoader from "../components/ui/gifLoader.js";

// const colorUI="#4a001f";
const colorUI = "#6f1d46";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));
const WelcomeView = () => {
  // toTop()

  const history = useHistory();
  const { setCurrentUser } = useContext(capturaContext);
  const { showNotification } = useContext(NotificacionContext);
  const [state, setState] = useState({
    usuario: "",
    showPassword: false,
    password: "",
    passError: false,
    userError: false,
    loader: false,
  });
  const { cargarPuertoPinpad } = usePINPAD({});

  const handleChangeUsuario = (event) => {
    setState({ ...state, usuario: event.target.value });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleChangePassword = (event) => {
    setState({ ...state, password: event.target.value });
  };

  const login = async (e) => {
    e.preventDefault();

    const valid = validForm();
    if (valid.check) {
      //logeo con api

      let recaptcha_token = "";

      await ReactRecaptcha3.getToken().then(
        (token) => {
          recaptcha_token = token;
        },
        (error) => {
          console.log(error);
        }
      );

      const body = {
        email: state.usuario,
        password: state.password,
        recaptcha_token: recaptcha_token,
      };

      setState({ ...state, loader: true });

      const dataUserApi = await services("POST", "login", body);

      if (dataUserApi) {
        localStorage.setItem("usuario", JSON.stringify(dataUserApi.data));
        localStorage.setItem("rol", dataUserApi.data.rol);
        localStorage.setItem("token", dataUserApi.data.token);

        setCurrentUser(dataUserApi.data);

        setState({ ...state, loader: false });

        const { usuario_pinpad, password_pinpad } = dataUserApi.data;

        if (usuario_pinpad && password_pinpad) {
          await cargarPuertoPinpad();
        }

        if (dataUserApi.data.rol === "Ventanilla") {
          history.push("/home");
        } else {
          history.push("/educacion/solicitudCertificado");
        }
        //   history.push("/home");
        // } else if (dataUserApi.data.rol == "Administrador") {
        //   history.push("/home");
        // } else if (dataUserApi.data.rol == "adminRevisor") {
        //   history.push("/home");
        // } else {
        //   history.push("/Turnado-Tramite");
        //   history.push("/educacion/solicitudCertificado");
        // }

        // history.push("/Turnado-Tramite");
        // history.push("/educacion/solicitudCertificado");

        // history.push("/educacion/solicitudCertificado");
      } else {
        showNotification(
          "error",
          "Error",
          "Sus credenciales son inválidas",
          null,
          4
        );

        setState({ ...state, loader: false });
      }

      //  fallo en DB
      // showNotification("error", "Contraseña o Usuario incorrectos","Verifique su información", null, 4);
    } else {
      showNotification("error", "Campos faltantes", valid.message, null, 4);
      setState({ ...state, loader: false });
    }
  };

  const validForm = () => {
    let email = state.usuario !== "" ? emailValidation(state.usuario) : false;
    let pass = state.password !== "";
    let messageValid = "";

    setState({ ...state, passError: !pass, userError: !email });

    messageValid = !email
      ? "Por favor ingrese un correo valido"
      : !pass
      ? "Por favor su contraseña"
      : !pass && !email
      ? "Por favor ingrese sus credenciales"
      : "";

    return { check: email && pass ? true : false, message: messageValid };
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
        (status) => {}
      );
      let token = localStorage.getItem("token");
      if (token) {
        history.push("/home");
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <form onSubmit={login}>
        <div className="container vh-100">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="justify-content-center align-items-center h-100">
                <img alt="gifLogo" src={gifLogo1} className="logoGiftView" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center mt-3">
              <p>
                Bienvenidos a la{" "}
                <b style={{ color: colorUI }}>Ventanilla Digital de Trámites</b>
                . Inicie sesión para ingresar.
              </p>
            </div>
          </div>
          <div className="row">
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "0.5%",
              }}
            >
              <Grid item md={10} xs={12}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "left",
                    alignItems: "left",
                    paddingTop: "1%",
                  }}
                >
                  <Grid item md={3} xs={0}></Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="password">Email</InputLabel>
                      <Input
                        error={state.userError}
                        id="usuario"
                        variant="standard"
                        type="email"
                        value={state.usuario}
                        defaultValue=""
                        onChange={handleChangeUsuario}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "left",
                    alignItems: "left",
                    paddingTop: "1%",
                  }}
                >
                  <Grid item md={3} xs={0}></Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                        error={state.passError}
                        id="password"
                        value={state.password}
                        type={state.showPassword ? "text" : "password"}
                        onChange={handleChangePassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {state.showPassword ? (
                                <VisibilityOff sx={{ color: colorUI }} />
                              ) : (
                                <Visibility sx={{ color: colorUI }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "2%",
                  }}
                >
                  <Grid item xs={6}>
                    <ColorButton variant="outlined" fullWidth type="submit">
                      Iniciar Sesión{" "}
                    </ColorButton>
                  </Grid>

                  <Grid item xs={6}>
                    <Link
                      className="text-guinda bold"
                      to="/recuperarContraseña"
                    >
                      ¿Olvidaste tu contraseña?
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        {state.loader ? <GifLoader></GifLoader> : null}
      </form>
    </>
  );
};

export default WelcomeView;
