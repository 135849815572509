import React, { useState, useEffect } from "react";
import { services } from "../services/api";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import MUIDataTable from "mui-datatables";
import { FaNetworkWired } from "react-icons/fa";
import IconUI from "../components/ui/icon_ui";
import { AiOutlineEdit } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, FormHelperText } from "@mui/material";
import { AiFillPlusCircle, AiFillEdit } from "react-icons/ai";
import swal from "sweetalert";
import Select, { components } from "react-select";
import { GrClose } from "react-icons/gr";
import SelectBusqueda from "../components/SelectConBusqueda";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import CheckIcon from "@mui/icons-material/Check";
import GifLoader from "../components/ui/gifLoader.js";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SaveIcon from "@mui/icons-material/Save";

const Areas = () => {
  const { filtro } = useParams();

  const [state, setDatos] = useState({
    dataUsers: [],
    type: "",
    nombre: "",
    descripcion: "",
    codigo: "",
    responsable: "",
    telefono: "",
    email: "",
    idEdit: 0,
    conceptos: [],
    categorias: [],
  });

  const [loader, setLoader] = useState(false);

  const [modalLuis, setModalLuis] = useState({
    open: false,
    id: "",
    areasSelected: null,
    conceptosData: [],
    descripcion: "",
    clave: "",
    nombreDepe: "",
    idDepe: "",
    conceptosSelect: [],
    categoriasSelect: null,
    conceptoSelect: null,
    nombreArea: "",
    handleShowConcepto: false,
  });

  const [modal, setModal] = useState(false);
  const [data, setdata] = useState({
    apidependencias: null,
    dependencia: "",
  });

  const [stateUpdate, setStateUpdate] = useState({
    dependenciaEdit: "",
  });

  const [templates, setTemplate] = useState({
    dependencia_id: "",
    SelectedDep: null,
  });

  const colorUI = "#6f1d46";

  const optionsAreas = {
    rowsPerPage: 4,
    selectableRows: false,
    tableBodyHeight: "auto",
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "tablaUsuarios.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
  };

  const options = {
    selectableRows: false,
    tableBodyHeight: "auto",
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "tablaUsuarios.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
    searchText: filtro ?? "",
  };

  const styleListado = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "white",
    height: "70%",
    overflow: "hidden",
    overflowY: "scroll",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "white",
    height: "70%",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    overflow: "hidden",
    overflowY: "scroll",
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let id = state.dataUsers[dataIndex].id;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {id}
            </div>
          );
        },
      },
    },
    {
      name: "dependenciaNombre",
      label: "Nombre Dependencia",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.dataUsers[dataIndex].dependenciaNombre;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "nombre",
      label: "Areas",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.dataUsers[dataIndex].nombre;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                  : "w-100 d-flex justify-content-center align-items-center "
              }
            >
              <div
                style={{ height: 30, width: 30, zIndex: 0 }}
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  setDatos({
                    ...state,
                    type: "edit",
                    nombre: state.dataUsers[dataIndex].nombre,
                    idEdit: state.dataUsers[dataIndex].id,
                  });

                  if (localStorage.getItem("rol") == "Administrador") {
                    const depIdBuscado =
                      state.dataUsers[dataIndex].id_dependencia;
                    const dependenciaEncontrada = data.apidependencias.find(
                      (dep) => dep.id === depIdBuscado
                    );
                    handleModalConceptos(
                      state.dataUsers[dataIndex].id,
                      null,
                      null,
                      "edit",
                      state.dataUsers[dataIndex].nombre
                    );
                    setTemplate({
                      ...templates,
                      dependencia_id: state.dataUsers[dataIndex].id_dependencia,
                      SelectedDep: dependenciaEncontrada,
                    });
                  }

                  setModal(true);
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <AiOutlineEdit />
                </IconUI>
                <div className="icon_btn_help">Editar</div>
              </div>

              <div
                style={{ height: 30, width: 30, zIndex: 0, marginLeft: "3px" }}
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  if (!state.dataUsers[dataIndex]) {
                    return;
                  }

                  handleModalConceptos(
                    state.dataUsers[dataIndex].id,
                    state.dataUsers[dataIndex].id_dependencia,
                    state.dataUsers[dataIndex].dependenciaNombre,
                    null,
                    state.dataUsers[dataIndex].nombre
                  );
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <AiFillPlusCircle />
                </IconUI>
                <div className="icon_btn_help">Añadir Conceptos</div>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  // CONCEPTOS ACTIVOS
  const columnsAreas = [
    {
      name: "observaciones",
      label: "Conceptos Activos",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = modalLuis.conceptosData[dataIndex].observaciones;
          let inhabilitado =
            modalLuis.conceptosData[dataIndex].fecha_baja !== null;

          return (
            <div
              style={{ height: "60px", fontSize: "10px" }}
              className={`${
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
                  ${inhabilitado ? "opacity-25" : ""}
                  
                  `}
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                  : "w-100 d-flex justify-content-center align-items-center"
              }
            >
              <div
                style={{ height: 30, width: 30, zIndex: 0 }}
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  modalLuis.conceptosData[dataIndex].fecha_baja !== null
                    ? UpdateStatus(
                        {
                          clave_concepto:
                            modalLuis.conceptosData[dataIndex].clave_concepto,
                          area_id: modalLuis.conceptosData[dataIndex].area_id,
                          observaciones:
                            modalLuis.conceptosData[dataIndex].observaciones,
                        },
                        modalLuis.conceptosData[dataIndex].id
                      )
                    : handleDeleteArea(
                        modalLuis.conceptosData[dataIndex].clave_concepto,
                        modalLuis.conceptosData[dataIndex].id,
                        {
                          clave_concepto:
                            modalLuis.conceptosData[dataIndex].clave_concepto,
                          area_id: modalLuis.conceptosData[dataIndex].area_id,
                          observaciones:
                            modalLuis.conceptosData[dataIndex].observaciones,
                          id: modalLuis.conceptosData[dataIndex].id,
                        }
                      );
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  {" "}
                  {modalLuis.conceptosData[dataIndex].fecha_baja ? (
                    <CheckIcon />
                  ) : (
                    <MdDelete />
                  )}{" "}
                </IconUI>
                {modalLuis.conceptosData[dataIndex].fecha_baja ? (
                  <div className="icon_btn_help">Habilitar</div>
                ) : (
                  <div className="icon_btn_help">Inhabilitar</div>
                )}
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  // AL ABRIR EL MODAL DE AÑADIR CONCEPTOS SE HACE LA PETICION PARA QUE SE REFLEJEN LOS QUE YA ESTAN
  const handleModalConceptos = async (
    id,
    idDep = "",
    nombreDep = "",
    type = null,
    area = ""
  ) => {
    setLoader(true);

    let dataApiConceptos = await services("POST", `areas/conceptos/list/${id}`);
    if (dataApiConceptos.status === 200) {
      if (type === null) {
        setModalLuis({
          ...modalLuis,
          open: true,
          id: id,
          conceptosData: dataApiConceptos.data,
          nombreDepe: nombreDep,
          idDepe: idDep,
          nombreArea: area,
        });
      } else {
        setModalLuis({
          ...modalLuis,
          conceptosData: dataApiConceptos.data,
          nombreArea: area,
        });
      }

      setLoader(false);
    } else {
      setModalLuis({
        ...modalLuis,
        open: true,
        id: id,
        conceptosData: [],
        nombreDepe: nombreDep,
        idDepe: idDep,
        nombreArea: area,
      });
      setLoader(false);
    }
  };

  const handleClose = () => {
    setDatos({
      ...state,
      type: "",
      nombre: "",
      descripcion: "",
      codigo: "",
      responsable: "",
      telefono: "",
      email: "",
      idEdit: 0,
    });
    setTemplate({
      dependencia_id: "",
      SelectedDep: null,
    });
    setModal(false);
  };

  const consumoApi = async () => {
    let categoriasData = await services("GET", "categorias", null);
    let conceptosData = await services("GET", `categorias/3`, null);

    let dataApi = await services("GET", `gratuitos/areas/index`, null);
    let dependencias = null;
    if (localStorage.getItem("rol") == "Administrador") {
      dependencias = await services("GET", `dependencia/listar`, null);
    }
    let test = await services("POST", "areas/conceptos/list/7", null);

    if (dataApi.status === 200) {
      // console.log(state.dataUsers, "us")
      setLoader(false);
      setDatos({
        ...state,
        dataUsers: dataApi.data.data.reverse(),
        conceptos: conceptosData.data.TB_CATEGORIAS,
        categorias: categoriasData.data.TB_CATEGORIA,
      });
      if (localStorage.getItem("rol") == "Administrador") {
        setdata({ apidependencias: dependencias.data });
      }
    } else {
      setLoader(false);
    }
  };
  const handleChangeDependencia = (item) => {
    setTemplate({ ...templates, dependencia_id: item.id, SelectedDep: item });
  };

  // HANDLE DE CATEGORIA
  const handleChangeCategoria = (item) => {
    const filterConcept = state.conceptos.filter((concepto) => {
      return item.ID_CATEGORIA === concepto.ID_CATEGORIA;
    });

    setModalLuis({
      ...modalLuis,
      conceptosSelect: filterConcept[0].TB_CONCEPTOS,
      categoriasSelect: item,
      conceptoSelect: "",
      clave: "",
      descripcion: "",
      handleShowConcepto: true,
    });
  };

  const handleChangeConcepto = (item) => {
    setModalLuis({
      ...modalLuis,
      conceptoSelect: item,
      clave: item.TP_INGRESO,
      descripcion: item.TEXTO_CONCEPTO,
    });
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoader(true);
      consumoApi();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const limpiar = async () => {
    let dataApi = await services("GET", `gratuitos/areas/index`, null);

    setDatos({
      ...state,
      nombre: "",
      type: "",
      idEdit: 0,
      dataUsers: dataApi.data.data.reverse(),
    });
    setModal(false);
  };

  const isValid = () => {
    let name = state.nombre !== "";
    let dependencia = templates.dependencia_id !== "";

    if (localStorage.getItem("rol") == "Administrador")
      return name && dependencia;
    else return name;
  };

  const AddAndEditArea = async () => {
    if (isValid()) {
      const url = `gratuitos/areas/crear_editar`;
      let body = {};
      localStorage.getItem("rol") == "Administrador"
        ? (body = {
            nombre: state.nombre,
            dependencia_id: templates.dependencia_id,
          })
        : (body = {
            nombre: state.nombre,
          });
      const bodyEdit = {
        area_id: state.idEdit,
        nombre: state.nombre,
        dependencia_id: templates.dependencia_id,
      };

      let dataApi = await services(
        "POST",
        url,
        state.type === "add" ? body : bodyEdit
      );

      if (dataApi.status === 200) {
        swal("Ok", dataApi.data.message, "success");

        let dataApis = await services("GET", `gratuitos/areas/index`, null);
        let dependencias = await services("GET", `dependencia/listar`, null);

        if (dataApis.status === 200) {
          setDatos({
            ...state,
            nombre: "",
            type: "",
            idEdit: 0,
            dataUsers: dataApis.data.data.reverse(),
          });
          setdata({ apidependencias: dependencias.data });
          setTemplate({ dependencia_id: "", SelectedDep: null });
          setModal(false);
        }
      } else if (dataApi.status === 500) {
        swal(dataApi.data.message, dataApi.data.error, "error");
      }
    } else {
      swal("¡Atención!", "Faltan llenar datos", "warning");
    }
  };

  const handleCloseAAAA = () => {
    setModalLuis({
      ...modalLuis,
      open: false,
      id: "",
      areasSelected: null,
      conceptosData: [],
      descripcion: "",
      clave: "",
      nombreDepe: "",
      idDepe: "",
      conceptosSelect: [],
      categoriasSelect: null,
      conceptoSelect: null,
      handleShowConcepto: false,
    });
  };

  const handleDeleteArea = async (e, id, objectOld) => {
    swal({
      title: "Atención",
      text: "¿Estás seguro de que deseas inhabilitar el concepto?",
      icon: "info",
      buttons: ["Cancelar", "Aceptar"],
    }).then(async (result) => {
      if (result) {
        let body = {
          idDependencia: modalLuis.idDepe,
          nombre: modalLuis.nombreDepe,
          claveCategoria: e,
        };
        let dataApi = await services("DELETE", `areas/conceptos/delete/${id}`, [
          body,
        ]);

        if (dataApi.status === 200) {
          let array = modalLuis.conceptosData.filter((item) => item.id !== id);

          objectOld["fecha_baja"] = "eliminado";
          array.unshift(objectOld);

          setModalLuis({ ...modalLuis, conceptosData: array });

          swal("Actualizado", "Concepto Inhabilitado", "success");
        } else {
          swal("Error", "Ups Sin Servidores", "error");
          setLoader(false);
        }
      } else {
        return;
      }
    });
    document.querySelector(".swal-button--confirm").style.backgroundColor =
      colorUI;
  };

  const UpdateStatus = async (body, id) => {
    swal({
      title: "Atención",
      text: "¿Estás seguro de que deseas habilitar el concepto?",
      icon: "info",
      buttons: ["Cancelar", "Aceptar"],
    }).then(async (result) => {
      if (result) {
        setLoader(true);

        let dataApi = await services(
          "DELETE",
          `areas/conceptos/delete/${id}`,
          body
        );

        if (dataApi.status === 200) {
          let array = modalLuis.conceptosData.filter(
            (item) => item.clave_concepto !== body.clave_concepto
          );

          body["fecha_baja"] = null;
          body[id] = id;
          array.unshift(body);

          setLoader(false);
          setModalLuis({
            ...modalLuis,
            conceptosData: array,
            descripcion: "",
            clave: "",
          });
          swal("Actualizado", "Concepto Habilitado", "success");
        } else {
          swal("Error", dataApi.data, "error");
          setLoader(false);
        }
      } else {
        return;
      }
    });
    document.querySelector(".swal-button--confirm").style.backgroundColor =
      colorUI;
  };

  // FUNCION PARA AGREGAR CONCEPTOS
  const AgregarConcepto = async () => {
    if (modalLuis.clave !== "" && modalLuis.descripcion !== "") {
      setLoader(true);

      let body = {
        clave_concepto: modalLuis.clave,
        area_id: modalLuis.id,
        observaciones: modalLuis.descripcion,
      };

      let dataApi = await services("POST", `areas/conceptos/asignar`, body);

      if (dataApi.status === 200) {
        let array = modalLuis.conceptosData.map((e) => {
          return e;
        });
        array.unshift(dataApi.data);

        setLoader(false);
        setModalLuis({
          ...modalLuis,
          conceptosData: array,
          descripcion: "",
          clave: "",
        });
        handleCloseAAAA();
        swal("Actualizado", "El concepto se agregó con éxito", "success");
      } else {
        swal("Error", dataApi.data, "error");
        setLoader(false);
      }
    } else {
      swal("precaución", "LLena todos los campos", "warning");
    }
  };

  return (
    <div
      style={
        state.dataUsers.length > 4 ? { height: "100%" } : { height: "100vh" }
      }
    >
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Áreas
        </div>
      </div>

      <div className="container">
        <Grid sx={{ marginTop: 3 }} container spacing={2}>
          <Grid align="center" item xs={12} sm={6} md={6}></Grid>
          <Grid align="right" item xs={12} sm={6} md={6}>
            <div
              style={{ width: 200 }}
              className="cta cta--guinda"
              onClick={() => {
                setDatos({ ...state, type: "add" });
                setModal(true);
              }}
            >
              <div className="me-2">
                <IconUI size={"20px"} color={"white"}>
                  <FaNetworkWired />
                </IconUI>
              </div>{" "}
              Agregar
            </div>
          </Grid>
        </Grid>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <Grid className="container mt-5 mb-5">
          <Grid item xs={8}>
            <MUIDataTable
              style={{ Overflow: "hidden" }}
              title={"Listado de áreas"}
              data={state.dataUsers}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </Box>

      {/* MODAL DE CREAAAAR NUEVA AREAAAAAAA */}
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-add-new-item"
        aria-describedby="modal-add-item"
      >
        <Box sx={style}>
          <div className="d-flex mb-3">
            <div className="col"></div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleClose();
              }}
            >
              <IconUI size={15}>
                <GrClose></GrClose>
              </IconUI>
            </div>
          </div>
          <div className="container my-3">
            <div className="border-bottom--guinda text-big mb-2 text-bold">
              {state.type === "add"
                ? "Crear nueva área"
                : `Editar Área (${modalLuis.nombreArea})`}
            </div>
          </div>
          <Box sx={{ flexGrow: 1, marginTop: "3%" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={12} key={0}>
                <TextField
                  value={state.nombre}
                  onChange={(e) => {
                    setDatos({ ...state, nombre: e.target.value });
                  }}
                  id="nombre"
                  label="Nombre"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              {localStorage.getItem("rol") == "Administrador" ? (
                <Grid align="center" item xs={2} sm={4} md={12}>
                  <InputLabel
                    style={{ marginLeft: 0, marginTop: 35 }}
                    id="demo-simple-select-helper-label"
                  >
                    {state.type === "add" ? "Dependencias *" : "Dependencia"}
                  </InputLabel>
                  <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                    <Select
                      style={{ zIndex: 110 }}
                      options={data.apidependencias}
                      placeholder="Seleccione Dependencia"
                      getOptionValue={(options) => options.id}
                      getOptionLabel={(options) => options.nombre}
                      isDisabled={state.type === "add" ? false : true}
                      onChange={(options) => handleChangeDependencia(options)}
                      id="id_dependencia"
                      name="id_dependencia"
                      components={{ NoOptionsMessage }}
                      value={templates.SelectedDep}
                    />
                    <FormHelperText>Selecciona una dependencia.</FormHelperText>
                  </FormControl>
                </Grid>
              ) : null}

              <Grid align="center" item xs={2} sm={4} md={12}>
                <MUIDataTable
                  style={{ Overflow: "hidden" }}
                  //title={"Listado"}
                  data={modalLuis.conceptosData}
                  columns={columnsAreas}
                  options={optionsAreas}
                />
              </Grid>
            </Grid>
          </Box>

          <div className="container">
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
              <Grid
                align="center"
                item
                xs={12}
                sm={6}
                md={12}
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{ width: 200 }}
                  className="cta cta--guinda"
                  onClick={() => {
                    AddAndEditArea();
                  }}
                >
                  <div className="me-2">
                    <IconUI size={"20px"} color={"white"}>
                      {state.type === "add" ? (
                        <AiFillPlusCircle />
                      ) : (
                        <AiFillEdit />
                      )}{" "}
                    </IconUI>
                  </div>{" "}
                  {state.type === "add" ? "Agregar" : `Guardar`}
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>

      {/* MODAL DE AREAS PARA AÑADIR CONCEPTOOOOOS */}
      <Modal
        open={modalLuis.open}
        onClose={handleCloseAAAA}
        aria-labelledby="modal-add-new-item"
        aria-describedby="modal-add-item"
      >
        <Box sx={styleListado}>
          <div className="d-flex mb-3">
            <div className="col"></div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleCloseAAAA();
              }}
            >
              <IconUI size={15}>
                <GrClose></GrClose>
              </IconUI>
            </div>
          </div>
          <div className="container my-3">
            {/* {console.log(modalLuis.nombreArea)} */}
            <div className="border-bottom--guinda text-big mb-2 text-bold">{`Añadir Conceptos al área (${modalLuis.nombreArea})`}</div>
          </div>
          <Box sx={{ flexGrow: 1, marginTop: "3%" }}>
            <Grid
              className="d-flex flex-column flex-md-row"
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6} sm={8} md={4} key={0}>
                <TextField
                  value={modalLuis.descripcion}
                  onChange={(e) => {
                    setModalLuis({
                      ...modalLuis,
                      descripcion: e.target.value,
                    });
                  }}
                  id="des_concepto"
                  label="Descripción"
                  variant="standard"
                  fullWidth
                />

                <TextField
                  value={modalLuis.clave}
                  onChange={(e) => {
                    setModalLuis({
                      ...modalLuis,
                      clave: e.target.value,
                    });
                  }}
                  id="clave_concepto"
                  label="Clave"
                  variant="standard"
                  fullWidth
                />
              </Grid>

              <Grid item xs={6} sm={8} md={5} key={0}>
                {/* SELECT DE CATEGORIAS */}
                <SelectBusqueda
                  opciones={state.categorias}
                  funcion={handleChangeCategoria}
                  descripcion={"DESC_CATEGORIA"}
                  id={"ID_CATEGORIA"}
                  titulo={"Categoría"}
                  selectOpcion={modalLuis.categoriasSelect}
                />
                {/* SELECT DE CONCEPTOS */}
                {modalLuis.handleShowConcepto ? (
                  <SelectBusqueda
                    opciones={modalLuis.conceptosSelect}
                    funcion={handleChangeConcepto}
                    descripcion={"TEXTO_CONCEPTO"}
                    id={"TP_INGRESO"}
                    titulo={"Conceptos"}
                    selectOpcion={modalLuis.conceptoSelect}
                  />
                ) : null}
              </Grid>
              <Grid
                className="d-flex d-lg-block justify-content-center pt-lg-5"
                item
                xs={6}
                sm={8}
                md={3}
                key={0}
              >
                <div
                  className="cta cta--guinda w-100"
                  onClick={() => {
                    AgregarConcepto();
                    // CERRAR MODAL
                  }}
                >
                  <div className="me-2 ">
                    <IconUI size={"20px"} color={"white"}>
                      <AiFillPlusCircle />
                    </IconUI>
                  </div>{" "}
                  Agregar
                </div>
              </Grid>
              <Grid align="center" item xs={6} sm={4} md={12}>
                <MUIDataTable
                  style={{ Overflow: "hidden" }}
                  //title={"Listado"}
                  data={modalLuis.conceptosData}
                  columns={columnsAreas}
                  options={optionsAreas}
                />
              </Grid>
            </Grid>
          </Box>

          <div className="container">
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
              <Grid
                align="center"
                item
                xs={12}
                sm={6}
                md={12}
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{ width: 200 }}
                  className="cta cta--guinda"
                  onClick={() => {
                    handleCloseAAAA();
                  }}
                >
                  <div className="me-2">
                    <IconUI size={"20px"} color={"white"}>
                      {state.type === "add" ? (
                        <AiFillPlusCircle />
                      ) : (
                        <ExitToAppIcon />
                      )}{" "}
                    </IconUI>
                  </div>{" "}
                  {state.type === "add" ? "Agregar" : `Salir`}
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>

      {loader ? <GifLoader></GifLoader> : null}
    </div>
  );
};

export default Areas;
