import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Card, TextField } from "@mui/material";
import GifLoader from "../../components/ui/gifLoader";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import swal from "sweetalert";
import { usePINPAD } from "../../hooks/usePINPAD";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#6f1d46",
  "&:hover": {
    backgroundColor: "#6f1d46",
  },
}));

export const CancelacionPago = () => {
  const [loaderPinpad, setLoaderPinpad] = useState(false);
  const [validate, setValidate] = useState(false);
  const [form, setForm] = useState({
    numReferencia: "",
    numAutorizacion: "",
    numOperacion: "",
    importe: "",
  });
  const { urlPINPAD, cancelarPago } = usePINPAD({ setLoaderPinpad });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[0-9\b]+$/;

    if (value === "" || regex.test(value)) {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };

  const consultarReferencia = () => {
    setLoaderPinpad(true);

    const allFieldsFilled = Object.values(form).every((value) => value !== "");

    if (allFieldsFilled) {
      cancelarPago(
        form.numOperacion,
        form.numAutorizacion,
        form.importe,
        form.numReferencia
      );
      // setTimeout(() => {
      //   setValidate(true);
      //   loaderPinpad(false);
      // }, 3000);
    } else {
      swal({
        title: "Error",
        text: "Por favor, complete todos los campos",
        icon: "error",
        buttons: false,
        timer: 2000,
      });
      setLoaderPinpad(false);
    }
  };

  return (
    <>
      {loaderPinpad && <GifLoader />}
      <iframe
        id="iframeTo"
        src={urlPINPAD}
        title="Pinpad"
        width="0"
        height="0"
        sx={{
          display: "none",
        }}
        allow="serial;geolocation;"
      ></iframe>
      <Grid container justifyContent="center" className="my-3">
        <Card
          style={{
            padding: 30,
          }}
          variant="outlined"
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>Linea de Captura:</InputLabel>
              <TextField
                fullWidth
                type="text"
                required
                name="numReferencia"
                value={form.numReferencia}
                onChange={handleChange}
                InputProps={{
                  sx: {
                    borderRadius: "6px",
                    height: "40px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Número de autorización:</InputLabel>
              <TextField
                fullWidth
                type="text"
                required
                name="numAutorizacion"
                value={form.numAutorizacion}
                onChange={handleChange}
                InputProps={{
                  sx: {
                    borderRadius: "6px",
                    height: "40px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Número de operación:</InputLabel>
              <TextField
                fullWidth
                type="text"
                required
                name="numOperacion"
                value={form.numOperacion}
                onChange={handleChange}
                InputProps={{
                  sx: {
                    borderRadius: "6px",
                    height: "40px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Importe:</InputLabel>
              <TextField
                fullWidth
                type="text"
                required
                name="importe"
                value={form.importe}
                onChange={handleChange}
                InputProps={{
                  sx: {
                    borderRadius: "6px",
                    height: "40px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <ColorButton variant="contained" onClick={consultarReferencia}>
                Cancelar Referencia
              </ColorButton>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};
