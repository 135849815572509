import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import { services } from "../../services/api";
import { styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import GifLoader from "../../components/ui/gifLoader.js";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SelectBusqueda from "../../components/SelectConBusqueda";
import ReactRecaptcha3 from "react-google-recaptcha3";

// const colorUI = "#661d40";
const colorUI = "#8d949e";

// import SelectBusqueda from '../components/SelectConBusqueda';

const SolicitudCedulas = () => {
  const colorUI = "#6f1d46";
  // const colorUI = "#8d949e";

  const CardFile = styled(Card)(({ theme }) => ({
    height: "200px",
    width: "250px",
    margin: "auto",
    marginTop: "20px",
    borderRadius: "12px",
    boxShadow: "1px 0px 7px 0px rgba(0, 0, 0, 0.75)",
    "& .MuiCardContent-root": {
      // borderColor: '#6A0F49',
      borderColor: "#8d949e",
      borderStyle: "dotted",
      height: "80%",
      margin: "20px 20px 20px 20px",
    },
  }));

  const { folio } = useParams();
  const [fecha_tit, setFechaTit] = useState(dayjs(new Date()));
  const [correo_dis, setCorreoDis] = useState(false);
  const [fecha_nac, setFechaNac] = useState(dayjs(new Date()));
  const [fecha_ingreso, setFechaIngreso] = useState(dayjs(new Date()));
  const [fecha_egreso, setFechaEgreso] = useState(dayjs(new Date()));
  const [fecha_examen, setFechaExamen] = useState(dayjs(new Date()));
  const [fecha_expedicion_titulo, setFechaExpedicion] = useState(
    dayjs(new Date())
  );
  const [fecha_ingreso_ant, setFechaIngresoAnt] = useState(dayjs(new Date()));
  const [fecha_egreso_ant, setFechaEgresoAnt] = useState(dayjs(new Date()));

  const [form, setForm] = useState({
    curp: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: dayjs(fecha_nac).format("YYYY-MM-DD"),
    lugar_nacimiento: "",
    calle: "",
    numero: "",
    codigo_postal: "",
    id_colonia: "",
    colonia: "",
    id_entidad_federativa: "",
    entidad_federativa: "",
    municipio: "",
    id_localidad: "",
    localidad: "",
    telefono: "",
    correo: "",
    fotografia_actual: "",
    fotografia: "",
    nombre_fotografia: "Fotografía",
    grado_solicita: "",
    carrera_posgrado: "",
    duracion: "",
    periodicidad: "",
    otro_periodicidad: "",
    institucion: "",
    tipo_institucion: "",
    servicio_social: "",
    fecha_ingreso: dayjs(fecha_ingreso).format("YYYY-MM-DD"),
    fecha_egreso: dayjs(fecha_egreso).format("YYYY-MM-DD"),
    fecha_examen: dayjs(fecha_egreso).format("YYYY-MM-DD"),
    fecha_expedicion_titulo: dayjs(fecha_egreso).format("YYYY-MM-DD"),
    folio_certificado: "",
    modalidad: "",
    otro_modalidad: "",
    titulacion: "",
    otro_titulacion: "",
    institucion_educativa: "",
    localidad_institucion: "",
    cedula_anterior: "",
    fecha_ingreso_ant: dayjs(fecha_ingreso).format("YYYY-MM-DD"),
    fecha_egreso_ant: dayjs(fecha_egreso).format("YYYY-MM-DD"),
    acta_nacimiento_actual: "",
    acta_nacimiento: "",
    nombre_acta_nacimiento: "Acta de nacimiento",
    identificacion_oficial_actual: "",
    identificacion_oficial: "",
    nombre_identificacion_oficial: "Identificación Oficial",
    formato_firma_actual: "",
    formato_firma: "",
    nombre_formato_firma: "Formato de firma",
    certificado_grado_solicita_actual: "",
    certificado_grado_solicita: "",
    nombre_certificado_grado_solicita:
      "Certificado de estudios(del grado que solicita)",
    servicio_social_archivo_actual: "",
    servicio_social_archivo: "",
    nombre_servicio_social_archivo:
      "Liberación de servicio social (cuando aplique)",
    acta_examen_actual: "",
    acta_examen: "",
    nombre_acta_examen: "Acta de examen profesional",
    titulo_actual: "",
    titulo: "",
    nombre_titulo: "Título, diploma o grado original",
    certificado_grado_anterior_actual: "",
    certificado_grado_anterior: "",
    nombre_certificado_grado_anterior:
      "Certificado de estudios anterior al grado que solicita)",
    cedula_anterior_actual: "",
    cedula_anterior: "",
    nombre_cedula_anterior: "Cédula anterior (cuando aplique)",
    otro_periodo: "",
    id_titulacion: "",
    grado_antecedente_id: "",
    id_modalidad: "",
    id_institucion: "",
    selectOpcion: "",
  });

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [solicita, setSoicita] = useState([]);

  const [grado_antecedente, setGrado_antecedente] = useState([]);

  const [titulacion, setTitulacion] = useState([]);

  const [modalidad, setModalidad] = useState([]);

  const [periodicidad, SetPeriodicidad] = useState([]);

  const [institucionesEducativas, setInstitucionesEducativas] = useState([]);

  const [localidades, setLocalidades] = useState([]);
  const [colonias, setColonias] = useState([]);
  const [profesiones, setProfesiones] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  const [periodicidad_otro, setPeriodicidadOtro] = useState(false);
  const [modalidad_otro, setModalidadOtro] = useState(false);
  const [titulacion_otro, setTitulacionOtro] = useState(false);

  const formData = new FormData();
  const [errors, setErrors] = useState({});
  const [observaciones, setObservaciones] = useState({});

  const [loader, setLoader] = useState({
    loading: false,
  });

  const keyFiles = [
    { key: "ActaNacimiento", name: "Acta de Nacimiento" },
    { key: "IdentificacionOficial", name: "Identificación Oficial" },
    { key: "CertificadoEstudios", name: "Certificado de Estudios" },
    { key: "LiberacionServicio", name: "Liberación de Servicio Social" },
    { key: "ActaExamen", name: "Acta de Examen Profesional" },
    { key: "Titulo", name: "Título, Diploma o Grado Original" },
    {
      key: "CertificadoEstudiosAnterior",
      name: "Certificado de estudios anterior al que solicita)",
    },
    { key: "CedulaAnterior", name: "Cédula Anterior (cuando aplique)" },
    { key: "Curp", name: "Archivo CURP" },
  ];

  const KeyFilesPNG = [{ key: "FormatoFirma", name: "Formato de firma" }];

  const [Files, setFiles] = useState({
    ActaNacimiento: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    IdentificacionOficial: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    FormatoFirma: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    CertificadoEstudios: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    LiberacionServicio: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    ActaExamen: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    Titulo: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    CertificadoEstudiosAnterior: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    CedulaAnterior: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    Fotografia: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    Curp: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChangeFiles = (name, file) => {
    setFiles({
      ...Files,
      [name]: {
        ...Files[name],
        previewicono: URL.createObjectURL(file),
        frameicono: false,
        Archivo: file,
      },
    });
  };

  const handleDragOverFather = (event) => {
    event.preventDefault();
  };

  const handleDragEnterFather = (event, card) => {
    event.preventDefault();
    setIsDraggingOver(true);
    if (card == "file") {
      event.currentTarget.style.border = `2px dashed ${colorUI}`;
      event.currentTarget.style.filter = "blur(1px)";
    } else if (card == "content") {
    }
  };

  const handleDragLeaveFather = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (!isDraggingOver) {
      event.currentTarget.style.border = "none";
      event.currentTarget.style.filter = "none";
    }
  };

  const handleDropFather = (event, name) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const file = event.dataTransfer.files[0];

    handleChangeFiles(name, file);

    event.currentTarget.style.border = "";
    event.currentTarget.style.filter = "";
  };

  const handleChange = async (e) => {
    let { name, value } = e.target;
    let caracteresPermitidos = "";
    switch (name) {
      case "curp":
        value = value.trim();
        value = value.toUpperCase();
        caracteresPermitidos = /^([A-Z\d]{0,18})$/;
        break;
      case "folio_certificado ":
        value = value.trim();
        caracteresPermitidos = /^([A-Z\d]{0,18})$/;
        break;
      case "codigo_postal":
        caracteresPermitidos = /^([0-9]{0,5})$/;
        break;
      case "numero":
        caracteresPermitidos = /^([0-9]{0,5})$/;
        break;
      case "duracion":
        caracteresPermitidos = /^([0-9]{0,2})$/;
        break;
      case "telefono":
        caracteresPermitidos = /^([0-9]{0,10})$/;
        break;
      case "correo":
        caracteresPermitidos =
          /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        break;
      default:
        break;
    }

    if (name === "periodicidad") {
      setForm({
        ...form,
        periodicidad: value,
      });
      if (value === periodicidad.length.toString()) {
        setPeriodicidadOtro(true);
      } else {
        setPeriodicidadOtro(false);
      }
      return;
    }

    if (name === "modalidad") {
      setForm({
        ...form,
        modalidad: value,
        id_modalidad: value,
      });

      if (value === modalidad.length.toString()) {
        setModalidadOtro(true);
      } else {
        setModalidadOtro(false);
      }
      return;
    }

    if (name === "titulacion") {
      setForm({
        ...form,
        titulacion: value,
        id_titulacion: value,
      });
      if (value === titulacion.length.toString()) {
        setTitulacionOtro(true);
      } else {
        setTitulacionOtro(false);
      }
      return;
    }

    if (name === "fotografia") {
      setForm({
        ...form,
        fotografia: e.target.files[0],
        nombre_fotografia: e.target.files[0].name,
      });
      return;
    }

    if (name === "curp") {
      if (caracteresPermitidos.test(value)) {
        if (value.length === 18) {
          setLoader({ loading: true });

          let recaptcha_token = "";

          await ReactRecaptcha3.getToken().then(
            (token) => {
              recaptcha_token = token;
            },
            (error) => {
              console.log(error);
            }
          );

          const dataCarpetas = await services(
            "POST",
            `catalogos/consultaCurp/${value}`,
            { recaptcha_token: recaptcha_token }
          );
          if (dataCarpetas.status !== 404) {
            const fechaString = dataCarpetas.data.fechaN;
            const [day, month, year] = fechaString.split("/").map(Number);

            setForm({
              ...form,
              curp: value,
              nombre: dataCarpetas.data.nombres,
              apellido_paterno: dataCarpetas.data.primerApellido,
              apellido_materno: dataCarpetas.data.segundoApellido,
              fecha_nacimiento: dayjs(`${year}-${month}-${day}`).format(
                "YYYY-MM-DD"
              ),
            });

            setFechaNac(dayjs(`${year}-${month}-${day}`));

            let newErrors = { ...errors }; // Hacemos una copia del state actual
            delete newErrors.curp; // Eliminamos el atributo 'curp'
            setErrors(newErrors);
            setLoader({ loading: false });
            return;
          } else {
            setForm({
              ...form,
              [name]: value,
              nombre: "",
              apellido_paterno: "",
              apellido_materno: "",
            });
            setErrors({
              ...errors,
              curp: dataCarpetas.mensaje,
            });
          }
          setLoader({ loading: false });
          return;
        }
        setForm({
          ...form,
          [name]: value,
        });
      }
      setErrors({
        ...errors,
        curp: "El formato no es correcto",
      });
      return false;
    }

    if (name === "codigo_postal") {
      if (caracteresPermitidos.test(value)) {
        if (value.length === 5) {
          setLoader({ loading: true });
          const dataCarpetas = await services(
            "POST",
            "catalogos/codigoPostal",
            { cp: value }
          );
          if (dataCarpetas.data) {
            setForm({
              ...form,
              codigo_postal: value,
              entidad_federativa: dataCarpetas.data.Estado[0].DESCRIPCION,
              id_entidad_federativa: dataCarpetas.data.Estado[0].IDENTIFICADOR,
              municipio: dataCarpetas.data.Municipio[0].DESCRIPCION,
              id_municipio: dataCarpetas.data.Municipio[0].IDENTIFICADOR,
            });
            setLocalidades({
              ...localidades,
              localidades: dataCarpetas.data.Localidad,
            });
            setColonias({
              ...colonias,
              colonias: dataCarpetas.data.Colonia,
            });
            setLoader({ loading: false });
            return;
          }
          setForm({
            ...form,
            [name]: value,
          });
          setErrors({
            ...errors,
            codigo_postal: dataCarpetas.error,
          });
          setLoader({ loading: false });
          return;
        }
        setErrors({
          ...errors,
          codigo_postal: "Debe de tener 5 digitos",
        });
        setForm({
          ...form,
          [name]: value,
        });
      }
      return false;
    }

    if (name === "numero") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
      }
      return;
    }

    if (name === "duracion") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
      }
      return;
    }

    if (name === "telefono") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        if (value.length === 10) {
          let newErrors = { ...errors };
          delete newErrors.telefono;
          setErrors(newErrors);
        } else {
          setErrors({
            ...errors,
            telefono: "El número telefonico debe tener 10 digitos",
          });
        }
      }
      return;
    }

    if (name === "correo") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        let newErrors = { ...errors };
        delete newErrors.correo;
        setErrors(newErrors);
        return;
      }
      setForm({
        ...form,
        [name]: value,
      });
      setErrors({
        ...errors,
        correo: "No cumple con el formato debido",
      });
      return;
    }

    if (name === "colonia") {
      const [identificador, descripcion] = value.split("-");
      setForm({
        ...form,
        id_colonia: identificador,
        colonia: descripcion,
      });
      return;
    }

    if (name === "localidad") {
      const [identificador, descripcion] = value.split("-");
      setForm({
        ...form,
        id_localidad: identificador,
        localidad: descripcion,
      });
      return;
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const LimpiarAll = () => {
    setFiles({
      ActaNacimiento: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      IdentificacionOficial: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      FormatoFirma: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      CertificadoEstudios: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      LiberacionServicio: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      ActaExamen: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      Titulo: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      CertificadoEstudiosAnterior: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      CedulaAnterior: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      Fotografia: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      Curp: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
    });

    setForm({
      curp: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: dayjs(fecha_nac).format("YYYY-MM-DD"),
      lugar_nacimiento: "",
      calle: "",
      numero: "",
      codigo_postal: "",
      id_colonia: "",
      colonia: "",
      id_entidad_federativa: "",
      entidad_federativa: "",
      municipio: "",
      id_localidad: "",
      localidad: "",
      telefono: "",
      correo: "",
      fotografia_actual: "",
      fotografia: "",
      nombre_fotografia: "Fotografía",
      grado_solicita: "",
      carrera_posgrado: "",
      duracion: "",
      periodicidad: "",
      otro_periodicidad: "",
      institucion: "",
      tipo_institucion: "",
      servicio_social: "",
      fecha_ingreso: dayjs(fecha_ingreso).format("YYYY-MM-DD"),
      fecha_egreso: dayjs(fecha_egreso).format("YYYY-MM-DD"),
      fecha_examen: dayjs(fecha_egreso).format("YYYY-MM-DD"),
      fecha_expedicion_titulo: dayjs(fecha_egreso).format("YYYY-MM-DD"),
      folio_certificado: "",
      modalidad: "",
      selectOpcion: "",
      otro_modalidad: "",
      titulacion: "",
      otro_titulacion: "",
      institucion_educativa: "",
      localidad_institucion: "",
      cedula_anterior: "",
      fecha_ingreso_ant: dayjs(fecha_ingreso).format("YYYY-MM-DD"),
      fecha_egreso_ant: dayjs(fecha_egreso).format("YYYY-MM-DD"),
      acta_nacimiento_actual: "",
      acta_nacimiento: "",
      nombre_acta_nacimiento: "Acta de nacimiento",
      identificacion_oficial_actual: "",
      identificacion_oficial: "",
      nombre_identificacion_oficial: "Identificación Oficial",
      formato_firma_actual: "",
      formato_firma: "",
      nombre_formato_firma: "Formato de firma",
      certificado_grado_solicita_actual: "",
      certificado_grado_solicita: "",
      nombre_certificado_grado_solicita:
        "Certificado de estudios(del grado que solicita)",
      servicio_social_archivo_actual: "",
      servicio_social_archivo: "",
      nombre_servicio_social_archivo:
        "Liberación de servicio social (cuando aplique)",
      acta_examen_actual: "",
      acta_examen: "",
      nombre_acta_examen: "Acta de examen profesional",
      titulo_actual: "",
      titulo: "",
      nombre_titulo: "Título, diploma o grado original",
      certificado_grado_anterior_actual: "",
      certificado_grado_anterior: "",
      nombre_certificado_grado_anterior:
        "Certificado de estudios anterior al grado que solicita)",
      cedula_anterior_actual: "",
      cedula_anterior: "",
      nombre_cedula_anterior: "Cédula anterior (cuando aplique)",
      otro_periodo: "",
      id_titulacion: "",
      grado_antecedente_id: "",
      id_modalidad: "",
      id_institucion: "",
    });
  };

  const obtenerCatalogos = async (e) => {
    const dataProfesiones = await services("POST", "catalogos/profesiones", {});
    const dataInstitusiones = await services(
      "POST",
      "catalogos/instituciones",
      {}
    );

    const Solicita = await services("POST", "catalogos/grados", {
      tipo_grado: "Academicos",
    });
    const Grado_antecedente = await services("POST", "catalogos/grados", {
      tipo_grado: "Antecedentes",
    });
    const Titulacion = await services("POST", "catalogos/titulacion", {});
    const Modalidad = await services("POST", "catalogos/modalidad", {});
    const Periodicidad = await services("POST", "catalogos/periodo", {});

    const institucionesEducativas = await services(
      "POST",
      "catalogos/instituciones",
      {}
    );

    setProfesiones({
      ...profesiones,
      profesion: dataProfesiones.data[0],
    });
    setInstituciones({
      ...instituciones,
      instituciones: dataInstitusiones.data,
    });

    setSoicita(Solicita.data);
    setGrado_antecedente(Grado_antecedente.data);
    setTitulacion(Titulacion.data);
    setModalidad(Modalidad.data);
    SetPeriodicidad(Periodicidad.data);

    setInstitucionesEducativas(institucionesEducativas.data);
    // console.log(institucionesEducativas.data, "institucionesEducativas.data");
    if (
      dataProfesiones &&
      dataInstitusiones &&
      Solicita &&
      Grado_antecedente &&
      Titulacion &&
      Modalidad &&
      Periodicidad &&
      institucionesEducativas
    ) {
      setLoader({ loading: false });
    }
  };

  const handleFechaExp = (e) => {
    const value = e.$d;
    setFechaNac(e);
    setForm({
      ...form,
      fecha_nacimiento: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFechaIngreso = (e) => {
    const value = e.$d;
    setFechaIngreso(e);
    setForm({
      ...form,
      fecha_ingreso: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFechaEgreso = (e) => {
    const value = e.$d;
    setFechaEgreso(e);
    setForm({
      ...form,
      fecha_egreso: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFechaIngresoAnt = (e) => {
    const value = e.$d;
    setFechaIngresoAnt(e);
    setForm({
      ...form,
      fecha_ingreso_ant: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFechaEgresoAnt = (e) => {
    const value = e.$d;
    setFechaEgresoAnt(e);
    setForm({
      ...form,
      fecha_egreso_ant: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFechaExamen = (e) => {
    const value = e.$d;
    setFechaExamen(e);
    setForm({
      ...form,
      fecha_examen: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFechaExpedicion = (e) => {
    const value = e.$d;
    setFechaExpedicion(e);
    setForm({
      ...form,
      fecha_expedicion_titulo: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: colorUI,
    "&:hover": {
      backgroundColor: colorUI,
    },
  }));

  const verificar = () => {
    const nombre = form.nombre !== "";
    const apellido_Pat = form.apellido_paterno !== "";
    const apellido_Mat = form.apellido_materno !== "";
    const colonia = form.colonia !== "";
    const curp = form.curp !== "";
    const calle = form.calle !== "";
    const no_ext = form.numero !== "";
    // const no_int =  "" !== "";
    const id_colonia = form.id_colonia !== "";
    const id_entidad_fede = form.id_entidad_federativa !== "";
    const entidad_fede = form.entidad_federativa !== "";
    const municipio = form.municipio !== "";
    const telefono = form.telefono !== "";
    const email = form.correo !== "";
    const codigo_Post = form.codigo_postal !== "";
    const fecha_nacimiento = form.fecha_nacimiento !== "";
    const lugar_nacimiento = form.lugar_nacimiento !== "";
    const id_localidad = form.id_localidad !== "";
    const localidad = form.localidad !== "";
    const fecha_ingreso_academicos = form.fecha_ingreso !== "";
    const fecha_egreso_academicos = form.fecha_egreso !== "";
    const folio_certificado = form.folio_certificado !== "";
    const id_periodo = form.periodicidad !== "";
    const id_modalidad = form.id_modalidad !== "";

    //Datos Academicos
    const tipo_insitucion = form.tipo_institucion !== "";
    const profesion = form.carrera_posgrado !== "";
    const institucion_academicos = form.id_institucion !== "";
    const duracion_carrera_posgrado = form.duracion !== "";
    const id_grado_academicos = form.grado_solicita !== "";
    const fecha_examen_profesional = form.fecha_examen !== "";
    const fecha_expedicion_titulo = form.fecha_expedicion_titulo !== "";
    const servicio_social = form.servicio_social !== "";

    return (
      nombre &&
      apellido_Pat &&
      apellido_Mat &&
      colonia &&
      curp &&
      calle &&
      no_ext &&
      id_colonia &&
      id_entidad_fede &&
      entidad_fede &&
      municipio &&
      telefono &&
      email &&
      codigo_Post &&
      fecha_nacimiento &&
      lugar_nacimiento &&
      id_localidad &&
      localidad &&
      fecha_ingreso_academicos &&
      fecha_egreso_academicos &&
      folio_certificado &&
      id_periodo &&
      id_modalidad &&
      tipo_insitucion &&
      profesion &&
      institucion_academicos &&
      duracion_carrera_posgrado &&
      id_grado_academicos &&
      fecha_examen_profesional &&
      fecha_expedicion_titulo &&
      servicio_social
    );
  };

  const handleChangeInstitucion = (e) => {
    setForm({
      ...form,
      selectOpcion: e,
      id_institucion: e.id,
    });
  };

  const guardar = async () => {
    setLoader({ loading: true });

    if (verificar()) {
      // personal Info

      formData.append("nombre", form.nombre);
      formData.append("apellido_Pat", form.apellido_paterno);
      formData.append("apellido_Mat", form.apellido_materno);
      formData.append("colonia", form.colonia);
      formData.append("curp", form.curp);
      formData.append("calle", form.calle);
      formData.append("no_ext", form.numero);
      formData.append("no_int", "");
      formData.append("id_colonia", form.id_colonia);
      formData.append("id_entidad_fede", form.id_entidad_federativa);
      formData.append("entidad_fede", form.entidad_federativa);
      formData.append("municipio", form.municipio);
      formData.append("telefono", form.telefono);
      formData.append("email", form.correo);
      formData.append("codigo_Post", form.codigo_postal);
      formData.append("fecha_nacimiento", form.fecha_nacimiento);
      formData.append("lugar_nacimiento", form.lugar_nacimiento);
      formData.append("id_localidad", form.id_localidad);
      formData.append("localidad", form.localidad);
      formData.append("fecha_ingreso_academicos", form.fecha_ingreso);
      formData.append("fecha_egreso_academicos", form.fecha_egreso);
      formData.append("folio_certificado", form.folio_certificado);
      formData.append("id_periodo", form.periodicidad);
      formData.append("otro_periodo", form.otro_periodo);
      formData.append("id_modalidad", form.id_modalidad);
      formData.append("otro_modalidad", form.otro_modalidad);

      //Datos Academicos
      formData.append("tipo_insitucion", form.tipo_institucion);
      formData.append("profesion", form.carrera_posgrado);
      formData.append("institucion_academicos", form.id_institucion);
      formData.append("duracion_carrera_posgrado", form.duracion);
      formData.append("id_grado_academicos", form.grado_solicita);
      formData.append("fecha_examen_profesional", form.fecha_examen);
      formData.append("fecha_expedicion_titulo", form.fecha_expedicion_titulo);
      formData.append("servicio_social", form.servicio_social);
      formData.append("otro_titulacion", form.otro_titulacion);
      formData.append("id_titulacion", form.id_titulacion);
      formData.append("fecha_ingreso_antecedentes", form.fecha_ingreso_ant);
      formData.append("fecha_egreso_antecedentes", form.fecha_egreso_ant);
      formData.append(
        "localidad_insitucion_antecedentes",
        form.localidad_institucion
      );
      formData.append("cedula_anterior_numero", form.cedula_anterior);
      formData.append("id_grado_antecedentes", form.grado_antecedente_id);
      formData.append(
        "institucion_educativa_antecedentes",
        form.institucion_educativa
      );

      if (folio) {
        formData.append("folio", folio);

        // Documentos
        if (Files.ActaNacimiento.Archivo) {
          formData.append(
            "acta_nacimiento_archivo",
            Files.ActaNacimiento.Archivo
          );
        }
        if (Files.IdentificacionOficial.Archivo) {
          formData.append(
            "identificacion_archivo",
            Files.IdentificacionOficial.Archivo
          );
        }
        if (Files.FormatoFirma.Archivo) {
          formData.append("firma_archivo", Files.FormatoFirma.Archivo);
        }
        if (Files.CertificadoEstudios.Archivo) {
          formData.append(
            "certificado_estudios_archivo",
            Files.CertificadoEstudios.Archivo
          );
        }
        if (Files.LiberacionServicio.Archivo) {
          formData.append(
            "servicio_social_archivo",
            Files.LiberacionServicio.Archivo
          );
        }
        if (Files.ActaExamen.Archivo) {
          formData.append(
            "acta_examen_profesional_archivo",
            Files.ActaExamen.Archivo
          );
        }
        if (Files.Fotografia.Archivo) {
          formData.append("fotografia_archivo", Files.Fotografia.Archivo);
        }
        if (Files.Titulo.Archivo) {
          formData.append("titulo_diploma_grado_archivo", Files.Titulo.Archivo);
        }
        if (Files.CertificadoEstudiosAnterior.Archivo) {
          formData.append(
            "certificado_estudios_anterior_archivo",
            Files.CertificadoEstudiosAnterior.Archivo
          );
        }
        if (Files.CedulaAnterior.Archivo) {
          formData.append(
            "cedula_anterior_archivo",
            Files.CedulaAnterior.Archivo
          );
        }
        if (Files.Curp.Archivo) {
          formData.append("curp_archivo", Files.Curp.Archivo);
        }
        const dataCarpetas = await services(
          "POST",
          "see/profesiones/actualizaCedulaRechazado",
          formData
        );

        setLoader({ loading: false });

        if (dataCarpetas.data) {
          swal("¡Éxito!", dataCarpetas.data.mensaje, "success");
          LimpiarAll();
        } else {
          swal("¡Error!", dataCarpetas.error, "error");
        }
      } else {
        // Documentos
        formData.append(
          "acta_nacimiento_archivo",
          Files.ActaNacimiento.Archivo
        );
        formData.append(
          "identificacion_archivo",
          Files.IdentificacionOficial.Archivo
        );
        formData.append("firma_archivo", Files.FormatoFirma.Archivo);
        formData.append(
          "certificado_estudios_archivo",
          Files.CertificadoEstudios.Archivo
        );
        formData.append(
          "servicio_social_archivo",
          Files.LiberacionServicio.Archivo
        );
        formData.append(
          "acta_examen_profesional_archivo",
          Files.ActaExamen.Archivo
        );
        formData.append("fotografia_archivo", Files.Fotografia.Archivo);
        formData.append("titulo_diploma_grado_archivo", Files.Titulo.Archivo);
        formData.append(
          "certificado_estudios_anterior_archivo",
          Files.CertificadoEstudiosAnterior.Archivo
        );
        formData.append("curp_archivo", Files.Curp.Archivo);

        if (Files.CedulaAnterior.Archivo) {
          formData.append(
            "cedula_anterior_archivo",
            Files.CedulaAnterior.Archivo
          );
        }

        const GoToDB = await services(
          "POST",
          "catalogos/RegistroCedulaD",
          formData
        );

        if (GoToDB.status === 200) {
          if (GoToDB.data) {
            swal("¡Éxito!", GoToDB.data.mensaje, "success").then(
              (result) => {}
            );
            LimpiarAll();

            setLoader({ loading: false });
          } else {
            swal("Error!", GoToDB.error, "error");
            setLoader({ loading: false });
          }
        }
        if (GoToDB.status === 422) {
          setLoader({ loading: false });
          swal("Error!", GoToDB.data, "error");
        }
      }
    } else {
      swal("Faltan Campos!", "Por favor llena todos los campos", "warning");
      setLoader({ loading: false });
    }
  };

  const obtDatMod = async () => {
    setLoader({ loading: true });

    const dataCarpetas = await services("POST", "solicitudes/detalle", {
      folio: folio,
    });

    if (dataCarpetas.status === 200) {
      setFiles({
        ActaNacimiento: {
          previewicono:
            dataCarpetas.data.data.solicitante.acta_nacimiento_archivo,
          frameicono: dataCarpetas.data.data.solicitante.acta_nacimiento_archivo
            ? false
            : true,
          Archivo: null,
        },
        IdentificacionOficial: {
          previewicono:
            dataCarpetas.data.data.solicitante.identificacion_archivo,
          frameicono: dataCarpetas.data.data.solicitante.identificacion_archivo
            ? false
            : true,
          Archivo: null,
        },
        FormatoFirma: {
          previewicono: dataCarpetas.data.data.solicitante.firma_archivo,
          frameicono: dataCarpetas.data.data.solicitante.firma_archivo
            ? false
            : true,
          Archivo: null,
        },
        CertificadoEstudios: {
          previewicono: dataCarpetas.data.data.certificado_estudios_archivo,
          frameicono: dataCarpetas.data.data.certificado_estudios_archivo
            ? false
            : true,
          Archivo: null,
        },
        LiberacionServicio: {
          previewicono: dataCarpetas.data.data.servicio_social_archivo,
          frameicono: dataCarpetas.data.data.servicio_social_archivo
            ? false
            : true,
          Archivo: null,
        },
        ActaExamen: {
          previewicono: dataCarpetas.data.data.acta_examen_profesional_archivo,
          frameicono: dataCarpetas.data.data.acta_examen_profesional_archivo
            ? false
            : true,
          Archivo: null,
        },
        Titulo: {
          previewicono: dataCarpetas.data.data.titulo_diploma_grado_archivo,
          frameicono: dataCarpetas.data.data.titulo_diploma_grado_archivo
            ? false
            : true,
          Archivo: null,
        },
        CertificadoEstudiosAnterior: {
          previewicono:
            dataCarpetas.data.data.certificado_estudios_anterior_archivo,
          frameicono: dataCarpetas.data.data
            .certificado_estudios_anterior_archivo
            ? false
            : true,
          Archivo: null,
        },
        CedulaAnterior: {
          previewicono: dataCarpetas.data.data.cedula_anterior_archivo,
          frameicono: dataCarpetas.data.data.cedula_anterior_archivo
            ? false
            : true,
          Archivo: null,
        },
        Fotografia: {
          previewicono: dataCarpetas.data.data.solicitante.fotografia_archivo,
          frameicono: dataCarpetas.data.data.solicitante.fotografia_archivo
            ? false
            : true,
          Archivo: null,
        },
        Curp: {
          previewicono: dataCarpetas.data.data.solicitante.curp_archivo,
          frameicono: dataCarpetas.data.data.solicitante.curp_archivo
            ? false
            : true,
          Archivo: null,
        },
      });

      setForm({
        ...form,
        // selectOpcion:{id: dataCarpetas.data.data.id_institucion, descripcion:`${dataCarpetas.data.data.clave_institucion}-${dataCarpetas.data.data.institucion}`},
        selectOpcion: {
          id: dataCarpetas.data.data.id_institucion,
          descripcion: `${dataCarpetas.data.data.institucion}`,
        },
        curp: dataCarpetas.data.data.solicitante.curp,
        nombre: dataCarpetas.data.data.solicitante.nombre,
        apellido_paterno: dataCarpetas.data.data.solicitante.apellido_Pat,
        apellido_materno: dataCarpetas.data.data.solicitante.apellido_Mat,
        fecha_nacimiento: dayjs(
          dataCarpetas.data.data.solicitante.fecha_nacimiento
        ).format("YYYY-MM-DD"),
        lugar_nacimiento: dataCarpetas.data.data.solicitante.lugar_nacimiento,
        calle: dataCarpetas.data.data.solicitante.calle,
        numero: dataCarpetas.data.data.solicitante.no_ext,
        codigo_postal: dataCarpetas.data.data.solicitante.codigo_Post,
        id_colonia: dataCarpetas.data.data.solicitante.id_colonia,
        colonia: dataCarpetas.data.data.solicitante.colonia,
        id_entidad_federativa:
          dataCarpetas.data.data.solicitante.id_entidad_fede,
        entidad_federativa: dataCarpetas.data.data.solicitante.entidad_fede,
        municipio: dataCarpetas.data.data.solicitante.municipio,
        id_localidad: dataCarpetas.data.data.solicitante.id_localidad,
        localidad: dataCarpetas.data.data.solicitante.localidad,
        telefono: dataCarpetas.data.data.solicitante.telefono,
        correo: dataCarpetas.data.data.solicitante.email,
        //antecedentes

        cedula_anterior: dataCarpetas.data.data.antecedente.cedula_anterior,
        institucion_educativa:
          dataCarpetas.data.data.antecedente.institucion_educativa,
        localidad_institucion:
          dataCarpetas.data.data.antecedente.localidad_institucion,
        fecha_ingreso_ant: dayjs(
          dataCarpetas.data.data.antecedente.fecha_ingreso
        ).format("YYYY-MM-DD"),
        fecha_egreso_ant: dayjs(
          dataCarpetas.data.data.antecedente.fecha_egreso
        ).format("YYYY-MM-DD"),
        grado_antecedente_id: dataCarpetas.data.data.antecedente.id_grado,

        // Datos academicos

        duracion: dataCarpetas.data.data.duracion_carrera_posgrado,
        folio_certificado: dataCarpetas.data.data.folio_certificado,
        fecha_ingreso: dayjs(dataCarpetas.data.data.fecha_ingreso).format(
          "YYYY-MM-DD"
        ),
        fecha_egreso: dayjs(dataCarpetas.data.data.fecha_egreso).format(
          "YYYY-MM-DD"
        ),
        fecha_examen: dayjs(
          dataCarpetas.data.data.fecha_examen_profesional
        ).format("YYYY-MM-DD"),
        fecha_expedicion_titulo: dayjs(
          dataCarpetas.data.data.fecha_expedicion_titulo
        ).format("YYYY-MM-DD"),
        modalidad: dataCarpetas.data.data.modalidad,
        id_modalidad: dataCarpetas.data.data.id_modalidad,
        institucion: dataCarpetas.data.data.institucion,
        id_institucion: dataCarpetas.data.data.id_institucion,
        periodicidad: dataCarpetas.data.data.id_periodo,
        // id_periodo:dataCarpetas.data.data.id_periodo,

        id_titulacion: dataCarpetas.data.data.id_titulacion,
        titulacion: dataCarpetas.data.data.titulacion,

        id_grado: dataCarpetas.data.data.id_grado,
        grado_solicita: dataCarpetas.data.data.id_grado,

        otro_periodicidad: dataCarpetas.data.data.otro_periodo,

        otro_modalidad: dataCarpetas.data.data.otro_modalidad,
        otro_titulacion: dataCarpetas.data.data.otro_titulacion,
        otro_periodo: dataCarpetas.data.data.otro_periodo,
        periodo: dataCarpetas.data.data.periodo,

        carrera_posgrado: dataCarpetas.data.data.profesion,
        tipo_institucion: dataCarpetas.data.data.tipo_insitucion,
        servicio_social: dataCarpetas.data.data.servicio_social,
      });

      setModalidadOtro(dataCarpetas.data.data.otro_modalidad ? true : false);
      setTitulacionOtro(dataCarpetas.data.data.otro_titulacion ? true : false);

      setPeriodicidadOtro(dataCarpetas.data.data.otro_periodo ? true : false);

      setFechaIngresoAnt(
        dayjs(dataCarpetas.data.data.antecedente.fecha_ingreso)
      );

      setFechaTit(dayjs(dataCarpetas.data.data.fecha_expedicion_titulo));
      setFechaNac(dayjs(dataCarpetas.data.data.solicitante.fecha_nacimiento));
      setFechaIngreso(dayjs(dataCarpetas.data.data.fecha_ingreso));
      setFechaEgreso(dayjs(dataCarpetas.data.data.fecha_egreso));
      setFechaExamen(dayjs(dataCarpetas.data.data.fecha_examen_profesional));
      setFechaExpedicion(dayjs(dataCarpetas.data.data.fecha_expedicion_titulo));
      setFechaEgresoAnt(dayjs(dataCarpetas.data.data.antecedente.fecha_egreso));

      const dataCP = await services("POST", "catalogos/codigoPostal", {
        cp: dataCarpetas.data.data.solicitante.codigo_Post,
      });

      setLocalidades({
        ...localidades,
        localidades: dataCP.data.Localidad,
      });
      setColonias({
        ...colonias,
        colonias: dataCP.data.Colonia,
      });
      setObservaciones({
        ...observaciones,
        mensaje: dataCarpetas.data.observaciones,
      });

      setLoader({ loading: false });
    } else {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
        (status) => {}
      );
      setLoader({ loading: true });
      obtenerCatalogos();
    }
    if (folio) {
      obtDatMod();
      obtenerCatalogos();
      setCorreoDis(true);
    }
  }, []);

  return (
    <>
      <h3 className="content_title">Solicitud de Cédula Profesional</h3>
      {observaciones.mensaje && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <p style={{ color: "red", fontSize: 20 }}>
            Observaciones: {observaciones.mensaje}
          </p>
        </div>
      )}
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Datos Personales
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto" }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    label="CURP"
                    disabled={correo_dis}
                    variant="standard"
                    id="curp"
                    name="curp"
                    value={form.curp}
                    onChange={handleChange}
                    helperText={errors.curp}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Nombre"
                    variant="standard"
                    id="nombre"
                    name="nombre"
                    value={form.nombre}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellido Paterno"
                    variant="standard"
                    id="apellido_paterno"
                    name="apellido_paterno"
                    value={form.apellido_paterno}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellido Materno"
                    variant="standard"
                    id="apellido_materno"
                    name="apellido_materno"
                    value={form.apellido_materno}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_nacimiento"
                      name="fecha_nacimiento"
                      value={fecha_nac}
                      label="Fecha de nacimiento"
                      onChange={handleFechaExp}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Lugar de nacimiento"
                    variant="standard"
                    id="lugar_nacimiento"
                    name="lugar_nacimiento"
                    value={form.lugar_nacimiento}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Teléfono"
                    variant="standard"
                    id="telefono"
                    name="telefono"
                    value={form.telefono}
                    onChange={handleChange}
                    helperText={errors.telefono}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Correo"
                    disabled={correo_dis}
                    variant="standard"
                    id="correo"
                    name="correo"
                    value={form.correo}
                    onChange={handleChange}
                    helperText={errors.correo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={4}>
                <Grid
                  container
                  spacing={1}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <label
                      htmlFor={`inputFotografia`}
                      style={{
                        backgroundColor: colorUI,
                        color: "white",
                        borderRadius: "12px",
                        cursor: "pointer",
                        padding: "5px 7px",
                        marginBottom: "10px",
                      }}
                    >
                      cargar archivo
                    </label>
                    <input
                      type="file"
                      id={`inputFotografia`}
                      style={{ display: "none" }}
                      accept=".png, .jpg"
                      onChange={(e) =>
                        handleChangeFiles("Fotografia", e.target.files[0])
                      }
                    />
                    <DeleteForeverOutlinedIcon
                      style={{ color: "red", cursor: "pointer" }}
                      fontSize="large"
                      onClick={() => {
                        setFiles({
                          ...Files,
                          Fotografia: {
                            previewicono: "",
                            frameicono: true,
                            Archivo: null,
                          },
                        });
                      }}
                      sx={
                        Files.Fotografia.frameicono ? { display: "none" } : {}
                      }
                    />
                  </div>
                  <CardFile
                    hidden={!Files.Fotografia.frameicono}
                    onDrop={(event) => {
                      handleDropFather(event, "Fotografia");
                    }}
                    onDragOver={(event) => {
                      handleDragOverFather(event);
                      handleDragEnterFather(event, "file");
                    }}
                    onDragEnter={(event) => {
                      handleDragEnterFather(event, "file");
                    }}
                    onDragLeave={handleDragLeaveFather}
                  >
                    <CardContent
                      onDrop={(event) => {
                        handleDropFather(event, "Fotografia");
                      }}
                      onDragOver={handleDragOver}
                      onDragEnter={(event) => {
                        handleDragEnterFather(event, "content");
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 15, color: colorUI }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Desliza la imagen en PNG o JPG
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: colorUI, fontWeight: "bold" }}
                      >
                        Fotografía
                      </Typography>
                    </CardContent>
                  </CardFile>
                  <iframe
                    id="frameiconoFiles"
                    src={Files.Fotografia.previewicono}
                    className="preview-iframe"
                    hidden={Files.Fotografia.frameicono}
                  ></iframe>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Domicilio Particular
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Calle"
                    variant="standard"
                    id="calle"
                    name="calle"
                    value={form.calle}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={1}>
                <FormControl fullWidth>
                  <TextField
                    label="Número"
                    variant="standard"
                    id="numero"
                    name="numero"
                    value={form.numero}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={1}>
                <FormControl fullWidth>
                  <TextField
                    label="Código postal"
                    variant="standard"
                    id="codigo_postal"
                    name="codigo_postal"
                    value={form.codigo_postal}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    label="Municipio"
                    variant="standard"
                    id="municipio"
                    name="municipio"
                    value={form.municipio}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    label="Entidad Federativa"
                    variant="standard"
                    id="entidad_federativa"
                    name="entidad_federativa"
                    value={form.entidad_federativa}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-colonia">Colonia</InputLabel>
                  <Select
                    labelId="demo-colonia"
                    id="colonia"
                    name="colonia"
                    value={`${form.id_colonia}-${form.colonia}`}
                    label="Colonia"
                    onChange={handleChange}
                  >
                    {colonias.colonias?.map((item) => (
                      <MenuItem
                        value={`${item.IDENTIFICADOR}-${item.DESCRIPCION}`}
                      >
                        {item.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.colonia}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-localidad">Localidad</InputLabel>
                  <Select
                    labelId="demo-localidad"
                    id="localidad"
                    name="localidad"
                    value={`${form.id_localidad}-${form.localidad}`}
                    label="Localidad"
                    onChange={handleChange}
                  >
                    {localidades.localidades?.map((item) => (
                      <MenuItem
                        value={`${item.IDENTIFICADOR}-${item.DESCRIPCION}`}
                      >
                        {item.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.localidad}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Datos Académicos
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Grado del que solicita cédula
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="grado_solicita"
                    onChange={handleChange}
                    value={form.grado_solicita}
                  >
                    {solicita.map((elemento) => {
                      return (
                        <FormControlLabel
                          value={elemento.id}
                          control={<Radio />}
                          label={elemento.nombre}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Nombre de la carrera o posgrado"
                    variant="standard"
                    id="carrera_posgrado"
                    name="carrera_posgrado"
                    value={form.carrera_posgrado}
                    onChange={handleChange}
                    helperText={errors.carrera_posgrado}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Duración de carrera o posgrado"
                    variant="standard"
                    id="duracion"
                    name="duracion"
                    value={form.duracion}
                    onChange={handleChange}
                    helperText={errors.duracion}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-IE">Institución Educativa</InputLabel>

                  <SelectBusqueda
                    opciones={institucionesEducativas}
                    funcion={handleChangeInstitucion}
                    selectOpcion={form.selectOpcion}
                  />

                  {/* <Select
                    id="institucion"
                    name="id_institucion"
                    value={form.id_institucion}
                    label="Institución educativa"
                    onChange={handleChange}
                  >
                    {institucionesEducativas.map(item => (
                      <MenuItem value={`${item.id}`}>{item.descripcion}</MenuItem>
                    ))}
                  </Select> */}
                  <FormHelperText>{errors.institucion}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={periodicidad_otro ? 9 : 12}
                sx={{ textAlign: "center" }}
              >
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Periodicidad
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="periodicidad"
                    onChange={handleChange}
                    value={form.periodicidad}
                  >
                    {periodicidad.map((elemento) => (
                      <FormControlLabel
                        value={elemento.id}
                        control={<Radio />}
                        label={elemento.nombre}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {periodicidad_otro && (
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      label="Especifique cual otro"
                      variant="standard"
                      id="otro_periodo"
                      name="otro_periodo"
                      value={
                        form.otro_periodo === "null" ||
                        form.otro_periodo === null
                          ? ""
                          : form.otro_periodo
                      }
                      onChange={handleChange}
                      helperText={errors.duracion}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Tipo de institución
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="tipo_institucion"
                    onChange={handleChange}
                    value={form.tipo_institucion}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label={"Pública"}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={"Privada"}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    ¿Cumplió servicio social?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="servicio_social"
                    onChange={handleChange}
                    value={form.servicio_social}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label={"Si"}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={"No"}
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label={"No aplica"}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Folio certificado de estudios"
                    variant="standard"
                    id="folio_certificado"
                    name="folio_certificado"
                    value={form.folio_certificado}
                    onChange={handleChange}
                    helperText={errors.folio_certificado}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_ingreso"
                      name="fecha_ingreso"
                      value={fecha_ingreso}
                      label="Fecha de ingreso"
                      onChange={handleFechaIngreso}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_egreso"
                      name="fecha_egreso"
                      value={fecha_egreso}
                      label="Fecha de egreso"
                      onChange={handleFechaEgreso}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_examen"
                      name="fecha_examen"
                      value={fecha_examen}
                      label="Fecha de examen profesional"
                      onChange={handleFechaExamen}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_expedicion"
                      name="fecha_expedicion"
                      value={fecha_expedicion_titulo}
                      label="Fecha de expedición de título"
                      onChange={handleFechaExpedicion}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={modalidad_otro ? 9 : 12}
                sx={{ textAlign: "center" }}
              >
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Modalidad
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="modalidad"
                    onChange={handleChange}
                    value={form.id_modalidad}
                  >
                    {modalidad.map((elemento) => (
                      <FormControlLabel
                        value={elemento.id}
                        control={<Radio />}
                        label={elemento.nombre}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {modalidad_otro && (
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      label="Especifique cual otro"
                      variant="standard"
                      id="otro_modalidad"
                      name="otro_modalidad"
                      value={form.otro_modalidad}
                      onChange={handleChange}
                      helperText={errors.duracion}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={titulacion_otro ? 9 : 12}
                sx={{ textAlign: "center" }}
              >
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Titulación por
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="titulacion"
                    onChange={handleChange}
                    value={form.id_titulacion}
                  >
                    {titulacion.map((elemento) => (
                      <FormControlLabel
                        value={elemento.id}
                        control={<Radio />}
                        label={elemento.nombre}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {titulacion_otro && (
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      label="Especifique cual otro"
                      variant="standard"
                      id="otro_titulacion"
                      name="otro_titulacion"
                      value={form.otro_titulacion}
                      onChange={handleChange}
                      helperText={errors.duracion}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Datos Antecedentes de Estudios
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Nivel antecedente de estudios
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="grado_antecedente_id"
                    onChange={handleChange}
                    value={form.grado_antecedente_id}
                  >
                    {grado_antecedente.map((elemento) => (
                      <FormControlLabel
                        value={elemento.id}
                        control={<Radio />}
                        label={elemento.nombre}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Institución Educativa"
                    variant="standard"
                    id="institucion_educativa"
                    name="institucion_educativa"
                    value={form.institucion_educativa}
                    onChange={handleChange}
                    helperText={errors.carrera_posgrado}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Localidad de la institución"
                    variant="standard"
                    id="localidad_institucion"
                    name="localidad_institucion"
                    value={form.localidad_institucion}
                    onChange={handleChange}
                    helperText={errors.carrera_posgrado}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Cédula anterior (si aplica)"
                    variant="standard"
                    id="cedula_anterior"
                    name="cedula_anterior"
                    value={form.cedula_anterior}
                    onChange={handleChange}
                    helperText={errors.carrera_posgrado}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_ingreso_ant"
                      name="fecha_ingreso_ant"
                      value={fecha_ingreso_ant}
                      label="Fecha de ingreso"
                      onChange={handleFechaIngresoAnt}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_egreso_ant"
                      name="fecha_egreso_ant"
                      value={fecha_egreso_ant}
                      label="Fecha de egreso"
                      onChange={handleFechaEgresoAnt}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Archivos adjuntos
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              {keyFiles.map((item) => {
                return (
                  <Grid item xs={12} sm={8} md={4}>
                    <Grid
                      container
                      spacing={1}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CardFile
                        hidden={!Files[item.key].frameicono}
                        onDrop={(event) => {
                          handleDropFather(event, item.key);
                        }}
                        onDragOver={(event) => {
                          handleDragOverFather(event);
                          handleDragEnterFather(event, "file");
                        }}
                        onDragEnter={(event) => {
                          handleDragEnterFather(event, "file");
                        }}
                        onDragLeave={handleDragLeaveFather}
                      >
                        <CardContent
                          onDrop={(event) => {
                            handleDropFather(event, item.key);
                          }}
                          onDragOver={handleDragOver}
                          onDragEnter={(event) => {
                            handleDragEnterFather(event, "content");
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 15, color: colorUI }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Desliza el Documento en formato PDF
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: colorUI, fontWeight: "bold" }}
                          >
                            {item.name}
                          </Typography>
                        </CardContent>
                      </CardFile>
                      {Files[item.key].frameicono ? null : (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          {/* ESTE es tuyo Tebitan */}
                          {/* <label style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', padding: '5px 7px', marginBottom: '10px' }}>{Files[item.key].Archivo.name}</label> */}
                          <label
                            style={{
                              backgroundColor: "white",
                              color: colorUI,
                              borderRadius: "12px",
                              padding: "5px 7px",
                              marginBottom: "10px",
                            }}
                          >
                            {item.name}
                          </label>
                        </div>
                      )}
                      <iframe
                        id="frameiconoFiles"
                        src={Files[item.key].previewicono}
                        className="preview-iframe"
                        hidden={Files[item.key].frameicono}
                      ></iframe>
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <label
                          htmlFor={`input${item.key}`}
                          style={{
                            backgroundColor: colorUI,
                            color: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                            padding: "5px 7px",
                            marginBottom: "10px",
                          }}
                        >
                          cargar archivo
                        </label>
                        <input
                          type="file"
                          id={`input${item.key}`}
                          style={{ display: "none" }}
                          accept=".pdf"
                          onChange={(e) =>
                            handleChangeFiles(item.key, e.target.files[0])
                          }
                        />
                        <DeleteForeverOutlinedIcon
                          style={{ color: "red", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => {
                            setFiles({
                              ...Files,
                              [item.key]: {
                                previewicono: "",
                                frameicono: true,
                                Archivo: null,
                              },
                            });
                          }}
                          sx={
                            Files[item.key].frameicono
                              ? { display: "none" }
                              : {}
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}

              {KeyFilesPNG.map((item) => {
                return (
                  <Grid item xs={12} sm={8} md={4}>
                    <Grid
                      container
                      spacing={1}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CardFile
                        hidden={!Files[item.key].frameicono}
                        onDrop={(event) => {
                          handleDropFather(event, item.key);
                        }}
                        onDragOver={(event) => {
                          handleDragOverFather(event);
                          handleDragEnterFather(event, "file");
                        }}
                        onDragEnter={(event) => {
                          handleDragEnterFather(event, "file");
                        }}
                        onDragLeave={handleDragLeaveFather}
                      >
                        <CardContent
                          onDrop={(event) => {
                            handleDropFather(event, item.key);
                          }}
                          onDragOver={handleDragOver}
                          onDragEnter={(event) => {
                            handleDragEnterFather(event, "content");
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 15, color: colorUI }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Desliza tu firma en formato jpg, png o jepg
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: colorUI, fontWeight: "bold" }}
                          >
                            {item.name}
                          </Typography>
                        </CardContent>
                      </CardFile>
                      {Files[item.key].frameicono ? null : (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          {/* Este es tuyo tebitan */}
                          {/* <label style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', padding: '5px 7px', marginBottom: '10px' }}>{Files[item.key].Archivo.name}</label> */}
                          <label
                            style={{
                              backgroundColor: "white",
                              color: colorUI,
                              borderRadius: "12px",
                              padding: "5px 7px",
                              marginBottom: "10px",
                            }}
                          >
                            {item.name}
                          </label>
                        </div>
                      )}
                      <iframe
                        id="frameiconoFiles"
                        src={Files[item.key].previewicono}
                        className="preview-iframe"
                        hidden={Files[item.key].frameicono}
                      ></iframe>
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <label
                          htmlFor={`input${item.key}`}
                          style={{
                            backgroundColor: colorUI,
                            color: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                            padding: "5px 7px",
                            marginBottom: "10px",
                          }}
                        >
                          cargar archivo
                        </label>
                        <input
                          type="file"
                          id={`input${item.key}`}
                          style={{ display: "none" }}
                          accept=".jpg, .png, .jepg"
                          onChange={(e) =>
                            handleChangeFiles(item.key, e.target.files[0])
                          }
                        />
                        <DeleteForeverOutlinedIcon
                          style={{ color: "red", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => {
                            setFiles({
                              ...Files,
                              [item.key]: {
                                previewicono: "",
                                frameicono: true,
                                Archivo: null,
                              },
                            });
                          }}
                          sx={
                            Files[item.key].frameicono
                              ? { display: "none" }
                              : {}
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <ColorButton
        variant="outlined"
        sx={{ display: "flex", margin: "auto", marginTop: 2, marginBottom: 2 }}
        onClick={guardar}
      >
        Guardar
      </ColorButton>

      {loader.loading ? <GifLoader></GifLoader> : null}
    </>
  );
};

export default SolicitudCedulas;
