import { React, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { services } from "../services/api";
import { useHistory } from 'react-router-dom'
import { SubCategorias } from '../components/subCategorias_component';
import Container from '@mui/material/Container';
import GifLoader from "../components/ui/gifLoader";
import capturaContext from "../context/captura_context/capturaContext";
import imagenError from '../assets/img/mensajeError.png'
import { NotificacionContext } from '../context/notificacion_context';
import { toTop } from '../utils/animationFunctions'
import { onlyNumber, onlyDecimalNumber } from "../utils/inputsRules";
import IconUI from "../components/ui/icon_ui";
import { MdDelete } from "react-icons/md"
import { numberFormatMexico } from '../utils/formats'



export const SubCatalogo = () => {

  const { title, setTPDiversos, setIdCategoria } = useContext(capturaContext);
  const { id } = useParams();

  let history = useHistory()
  const { showNotification } = useContext(NotificacionContext)
  let { envioConceptos, envio_conceptos } = useContext(capturaContext)
 
  const [error, setError] = useState(false)
  const [state, setState] = useState({
    getSubCategorias: [],
    loading: false,
    error: false,
  })
  const getSubCategorias = async (id) => {
    setState({ ...state, loading: true })
    let subCategorias = await services('GET', `categorias/2/${id}`, null)

    if (subCategorias.status === 200) {
      setState({ ...state, getSubCategorias: subCategorias.data.TB_CONCEPTOS, loading: false })
      setIdCategoria(id)
      setTPDiversos(subCategorias.data.TP_DIVERSO)
    } else {
      setState({ ...state, getSubCategorias: [], loading: false, error: true })
    }
  }


  useEffect(() => {

    let isMounted = true

    if (isMounted) {
      envioConceptos([])
      if (!title) {
        history.replace("/")
        return
      }
      getSubCategorias(id)
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])





const nextStep = async () =>{
  
  let formularioDinamico = await services('GET', `formularios/formulario/${id}/${envio_conceptos[0].TP_INGRESO}`, null)

  // toTop()
  // history.push('/resumen')

  if(formularioDinamico.data.estatus === 404){
    toTop()
    history.push('/resumen')
  }else{
    history.push(`/tramite/${id}/${envio_conceptos[0].TP_INGRESO}`)
  }
  
}




  return (
    <>
      <div className="d-flex flex-column h-100 py-3">

        {state.loading ?
          <>
            <div className='d-flex flex-column align-items-center justify-content-center vh-100'>
              <GifLoader></GifLoader>
            </div>
          </>

          :
          <>
            {state.error ?
              <div>
                <Container>
                  <div style={{ height: '800px' }} className='d-flex align-items-center justify-content-center'>
                    <img src={imagenError} alt="ImagenDeError"></img>
                  </div>
                </Container>
              </div>
              :
              <div className="container my-3 h-100">
                <div className='border-bottom--guinda text-big mt-4 mb-2 text-bold'>{title}</div>

                <div className="text-small text-gray-20 my-2">Selecciona la categoria que deseas agregar: </div>
                <div className="card">
                  <SubCategorias services={state.getSubCategorias} serviciosSelected={(items) => {
                    
                    setState({ ...state, getSubCategorias: items })
                  }} />
                  {envio_conceptos.length === 0 ?
                    <div className="d-flex ">
                      <div className="col"></div>
                      <div className='cta cta--rosa mx-3' onClick={() => {
                        history.replace('/home')
                      }}>Cancelar</div>
                    </div>
                    :
                    null
                  }

                </div>

                {envio_conceptos.length === 0 ?
                  null
                  :

                  <div id="tablaConceptos" className="card mt-3">
                    <div className="border-bottom--guinda text-big mt-4 mb-2 text-bold">CONCEPTOS AÑADIDOS</div>
                    <div className="d-none d-lg-flex my-2">
                      <div className="tableHead" >Concepto</div>
                      <div className="tableHeadItem text-center " style={{ width: '100px' }}>Cantidad</div>
                      <div className="tableHeadItem text-center " style={{ width: '100px' }}>Importe</div>
                    </div>

                    {envio_conceptos.map((row, i) => {
                      return (
                        <div key={row.TP_INGRESO} className={i % 2 === 0 ? "d-lg-flex justify-content-center align-items-center my-2 bg-rosaClaro border-bottom--rosa1px" : "d-lg-flex justify-content-center align-items-center my-2 border-bottom--rosa1px"}>
                          <div className="tableHead d-flex justify-content-center align-items-center">
                            <div className="ms-2 me-3 d-lg-none" style={{ width: '90px' }}>Concepto</div>
                            <div>{row.TEXTO_CONCEPTO}</div>
                          </div>
                          <div className="tableHeadItem d-flex justify-content-start align-items-center">
                            {
                              row.CL_IMPORTE === "FIJO" ?
                                <div className="d-flex  align-items-center w-100">
                                  <div className="ms-2 d-lg-none" style={{ width: '90px' }}>Cantidad</div>
                                  <div className="col"></div>
                                  <div className="d-flex justify-content-center align-items-center" style={{ width: '110px' }}>
                                    <input
                                      type="text"
                                      className={error && row.ERROR === true ? 'form-input form-input--short text-center form-input--error' : 'form-input form-input--short text-center'}
                                      name="input2"
                                      value={row.CANTIDAD ? row.CANTIDAD : row.CANTIDAD === "" ? "" : row.CANTIDAD = 1}
                                      // placeholder="1" 
                                      onKeyPress={onlyNumber}
                                      onChange={(event) => {
                                        // row.CANTIDAD = event.currentTarget.value
                                        envio_conceptos.map((item) => {
                                          if (item === row) {
                                            item.CANTIDAD = event.currentTarget.value
                                          }
                                          return true
                                        })
                                       
                                        envioConceptos(envio_conceptos)

                                      }}
                                    />
                                  </div>
                                  <div className="col"></div>
                                </div>
                                :
                                <div className="d-flex justify-content-start align-items-center w-100">
                                  <div className="ms-2  d-lg-none" style={{ width: '115px' }}>Cantidad</div>
                                  <div className="d-flex justify-content-center align-items-center text-center w-100">
                                    <div className="" style={{ width: '110px' }}>
                                      1
                                    </div>
                                  </div>
                                </div>
                            }
                          </div>
                          <div className="tableHeadItem">
                            {
                              row.CL_IMPORTE === "MANUAL" ?
                                <div className="d-flex justify-content-start align-items-center">
                                  <div className="ms-2 d-lg-none" style={{ width: '90px' }}>Importe</div>
                                  <div className="col"></div>
                                  <div className="d-flex justify-content-center align-items-center me-1 marginleft2rem" style={{ width: '110px'}}>
                                    $ <input
                                      type="text"
                                      className={error && row.ERROR === true ? 'form-input form-input--short text-center form-input--error' : 'form-input form-input--short text-center'}
                                      name="input1"
                                      onPaste={(e) => { e.preventDefault() }}
                                      onKeyPress={(e) => onlyDecimalNumber(e.currentTarget.value, e)} onChange={(event) => {
                                        // row.IMPORTE =  event.currentTarget.value
                                        envio_conceptos.map((item) => {
                                          if (item === row) {
                                            item.CANTIDAD = "1"
                                            item.IMPORTE = event.currentTarget.value
                                          }
                                          return true
                                        })
                                        envioConceptos(envio_conceptos)

                                      }}
                                    />
                                    
                                  </div>
                                  <div className="d-lg-none">mxn</div>
                                  <div className="col"></div>
                                </div>
                                :
                                <div className="d-flex justify-content-start align-items-center w120px" >
                                  <div className="ms-2 me-4 d-lg-none " >Importe</div>
                                  <div className="d-flex justify-content-center align-items-center text-center w-100" >
                                    <div className="" style={{ width: '110px' }}>
                                      ${numberFormatMexico(row.IMPORTE)}
                                    </div>
                                  </div>
                                </div>
                            }

                          </div>
                          <div className="tableHeadItem">
                            <div className="d-flex justify-content-start align-items-center my-2">
                              <div className="ms-2 d-lg-none " style={{ width: '90px' }}>Acción</div>
                              <div className="col"></div>
                              <div className="d-flex justify-content-center align-items-center " style={{ width: '110px' }}>
                                <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }} className='cta cta--icon icon_btn' onClick={
                                  () => {
                                  
                                    envio_conceptos = envio_conceptos.filter((item) => {
                                      return item !== row
                                    })
                                    envioConceptos(envio_conceptos)
                                    // const newArray = state.getSubCategorias.slice()
                                    // console.log("Completo",state.getSubCategorias, envio_conceptos, )
                                    // newArray.push(row)
                                    // console.log("Eliminado",newArray)
                                    // setState({ loading:false, getSubCategorias: newArray })
                                    toTop()
                                    showNotification("success", "Conceptos", "Se eliminó correctamente", null, 4)
                                  }
                                }>
                                  <IconUI size={'20px'} ><MdDelete /></IconUI>
                                  <div className='icon_btn_help'>Eliminar</div>
                                </div>
                              </div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className='d-flex my-3'>
                      <div className="col"></div>
                      <div className='cta cta--rosa mx-3' onClick={() => {
                        history.replace('/home')
                      }}>Cancelar</div>
                      <div className='cta cta--guinda' onClick={() => {
                        if (envio_conceptos.length === 0) {
                          showNotification("warning", "Selecciona un Concepto", "Elige al menos una opción.", null, 4)

                        } else {
                          let errorZero = false;

                          // VALIDAR CUANDO NO HAN PUESTO CANTIDAD O IMPORTE
                          let errorCantidad = false
                          let errorImporte = false
                          setError(false)
                          envio_conceptos.map((item) => {

                            if (item.CL_IMPORTE === "FIJO") {

                              if (!item.CANTIDAD) {
                                errorCantidad = true
                                item.ERROR = true
                              } else {
                                if (item.CANTIDAD === '0') {
                                  errorZero = true
                                  item.ERROR = true
                                } else {
                                  if (!parseInt(item.CANTIDAD)) {
                                    errorCantidad = true
                                    item.ERROR = true
                                  }
                                }
                                item.ERROR = false
                              }


                            } else {
                              if (!item.IMPORTE || item.IMPORTE === ".") {
                                errorImporte = true
                                item.ERROR = true
                              } else {
                                if (parseFloat(item.IMPORTE) < 1) {
                                  errorZero = true
                                  item.ERROR = true
                                } else {

                                  if (!parseInt(item.IMPORTE)) {
                                    errorImporte = true
                                    item.ERROR = true

                                  }
                                }
                                item.ERROR = false
                              }

                            }

                            return true
                          })
                         
                          if (errorCantidad) {
                            setError(true)
                            showNotification("warning", "Selecciona una Cantidad", "Escribe la cantidad que deseas.", null, 4)
                            return
                          } else if (errorImporte) {
                            setError(true)
                            showNotification("warning", "Selecciona un Importe", "Escribe el importe que deseas.", null, 4)
                            return
                          } else if (errorZero) {
                            errorZero = false
                            setError(true)
                            showNotification("warning", "Selecciona una Cantidad", "El importe debe ser mayor a 0.", null, 4)
                            return
                          }


                          nextStep();

                        }

                      }}>Continuar</div>
                    </div>
                  </div>
                }
              </div>
            }
          </>
        }
      </div>
    </>
  )
}
