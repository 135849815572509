import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";

// Componente de Información de Identificación
const IdentificacionTable = ({ identificacionData, infoData }) => (
  <React.Fragment>
    <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
      <Table sx={{ minWidth: 300 }}>
        <TableBody>
          {identificacionData.map((item, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#e0e0e0",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "2px",
                  }}
                >
                  {item.header}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: "2px",
                  }}
                >
                  {item.value}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      <Table sx={{ minWidth: 300, mt: 2 }}>
        <TableHead>
          <TableRow>
            {infoData.map((item, index) => (
              <TableCell
                key={index}
                sx={{
                  backgroundColor: "#e0e0e0",
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: "2px",
                }}
              >
                {item.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {infoData.map((item, index) => (
              <TableCell
                key={index}
                sx={{
                  textAlign: "center",
                  padding: "2px",
                }}
              >
                {item.value}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </React.Fragment>
);

// Componente de Cabecera de Sección
const SectionHeader = ({ title }) => (
  <>
    {title && (
      <TableContainer component={Paper} sx={{ overflowX: "auto", mt: 2 }}>
        <Table sx={{ minWidth: 300, width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={3}
                sx={{
                  backgroundColor: "#e0e0e0",
                  textAlign: "center",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                {title}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    )}
  </>
);

// Componente de Tabla de Información
const InfoTable = ({ rows }) => (
  <>
    {rows.map((row, index) => (
      <TableContainer
        component={Paper}
        key={index}
        sx={{ overflowX: "auto", mt: 2 }}
      >
        <Table sx={{ minWidth: row.cols.length > 4 ? 450 : 300 }}>
          <TableBody>
            <TableRow>
              {row.cols.map((col, i) => (
                <TableCell
                  key={i}
                  sx={{
                    textAlign: "center",
                    height: "50px",
                    padding: "4px",
                  }}
                >
                  <strong>{col}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              {row.headers.map((header, i) => (
                <TableCell
                  key={i}
                  sx={{
                    textAlign: "center",
                    width: `${100 / row.headers.length}%`,
                    padding: "4px",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    ))}
  </>
);

export default { IdentificacionTable, SectionHeader, InfoTable };
