import React, { useState, useEffect, useContext } from "react";
import OverLayIU from "./overlay_ui";
import IconUI from "../ui/icon_ui";
import { GrClose } from "react-icons/gr";
import DotLoader from "../../components/ui/dotloader";
import { services } from "../../services/api";
import { NotificacionContext } from "../../context/notificacion_context";
import SelectBusqueda from "../SelectConBusqueda";
import MUIDataTable from "mui-datatables";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
import CheckIcon from "@mui/icons-material/Check";

const PopUpDependenciasCategorias = ({ data, onClose }) => {
  const { showNotification } = useContext(NotificacionContext);
  const [state, setState] = useState({
    loading: false,
    categorias: [],
    categoriaIDSelected: "",
    categoriasUser: [],
    idCategoriaDelete: null,
  });

  const getCategorias = async () => {
    let categoriasUsuario = [];
    let categoriasData = await services("GET", "categorias", null);

    let categoriasUser = await services(
      "GET",
      `dependencias/categoria/${data.id}`,
      null
    );

    if (categoriasUser.status === 200) {
      categoriasUsuario = categoriasUser.data;
      // setState({
      //   ...state,
      //   idCategoriaDelete: categoriasUser.data.id
      // })
    }
    // console.log("CATEGORIAS", categoriasUser);

    setState({
      ...state,
      categorias: categoriasData.data.TB_CATEGORIA,
      categoriasUser: categoriasUsuario,
    });
  };

  const crearDependencia = async () => {
    const body = {
      idDependencia: data.id,
      claveCategoria: state.categoriaIDSelected.ID_CATEGORIA,
    };

    let categoriasCrear = await services(
      "POST",
      "dependencia/categorias/crear",
      body
    );

    showNotification(
      "success",
      "Acción Exitosa!",
      "La operación se realizó correctamente",
      null,
      4
    );
    onClose();
  };

  // FUNCION DE LA PETICION PARA ELIMINAR
  const deletePeticion = async (idCategory, message) => {
    let categoriasUser = await services(
      "DELETE",
      `dependencia/categorias/eliminar/${idCategory}`,
      null
    );

    if (categoriasUser.status === 200) {
      showNotification(
        "success",
        `Se ha ${message === 1 ? "Inhabilitado" : "Habilitado"} la categoria`,
        "La operación se realizó correctamente",
        null,
        4
      );
      getCategorias();
    }
  };

  // FUNCION DEL ONCLICK PARA PASAR EL ID
  const deleteDependency = async (idDelete, message) => {
    swal({
      title: "¡Atención!",
      text: "¿Estás seguro de que deseas borrar esta dependencia?",
      icon: "warning",
      buttons: ["Cancelar", "Continuar"],
      confirmButton: true,
      className: "alertFormularioEncontrado",
    }).then((aceptar) => {
      if (aceptar) {
        deletePeticion(idDelete, message);
      }
    });
  };

  const handleChangeCategoria = (item) => {
    setState({ ...state, categoriaIDSelected: item });
  };

  const columnsCategorias = [
    {
      name: "observaciones",
      label: "Categorias",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.categoriasUser[dataIndex].claveCategoria;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => {
          let id = state.categoriasUser[dataIndex].id;
          let deleteCategory = state.categoriasUser[dataIndex].deleted_at;

          // console.log(deleteCategory);
          return (
            <>
              <div className="d-flex justify-content-center gap-2">
                <div
                  style={{ height: "60px" }}
                  className={`${
                    i % 2 === 0
                      ? "d-flex align-items-center justify-content-center bg-rosaClaro "
                      : "d-flex justify-content-center align-items-center "
                  } ${
                    deleteCategory !== null ? "opacity-25 pe-none" : "pe-auto"
                  }`}
                >
                  <div
                    style={{ height: 30, width: 30, zIndex: 0 }}
                    className={`cta cta--primary cta--icon icon_btn`}
                    onClick={() => deleteDependency(id, 1)}
                  >
                    <IconUI size={"20px"} color={"white"}>
                      {" "}
                      <MdDelete />{" "}
                    </IconUI>
                    <div className="icon_btn_help">Inhabilitar</div>
                  </div>
                </div>
                <div
                  style={{ height: "60px" }}
                  className={`${
                    i % 2 === 0
                      ? "d-flex align-items-center justify-content-center bg-rosaClaro "
                      : "d-flex justify-content-center align-items-center "
                  } ${
                    deleteCategory !== null ? "pe-auto" : "opacity-25 pe-none"
                  }`}
                >
                  <div
                    style={{ height: 30, width: 30, zIndex: 0 }}
                    className={`cta cta--primary cta--icon icon_btn`}
                    onClick={() => deleteDependency(id, 2)}
                  >
                    <IconUI size={"20px"} color={"white"}>
                      {" "}
                      <CheckIcon />
                    </IconUI>
                    <div className="icon_btn_help">Habilitar</div>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    },
  ];

  const optionsCategorias = {
    rowsPerPage: 4,
    selectableRows: false,
    tableBodyHeight: "auto",
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "tablaUsuarios.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCategorias();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <OverLayIU
        onClick={() => {
          onClose();
        }}
      />
      <div className="wizard">
        {state.loading ? (
          <DotLoader />
        ) : (
          <>
            <div className="d-flex mb-3">
              <div className="col"></div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  onClose();
                }}
              >
                <IconUI size={15}>
                  <GrClose></GrClose>
                </IconUI>
              </div>
            </div>

            <div className="border-bottom--guinda text-big mb-2 text-bold">
              {" "}
              {`Edición de Dependencia (${data.nombre})`}
            </div>

            <SelectBusqueda
              opciones={state.categorias}
              funcion={handleChangeCategoria}
              descripcionMultiple={["ID_CATEGORIA", "DESC_CATEGORIA"]}
              // descripcion = {"DESC_CATEGORIA"}
              id={"ID_CATEGORIA"}
              titulo={"Categorias"}
              selectOpcion={state.categoriaIDSelected}
            />

            <div className="d-flex justify-content-center align-items-center my-3">
              <div
                className="cta cta--guinda cta--short"
                onClick={() => {
                  crearDependencia();
                }}
              >
                Asignar Categoria
              </div>
            </div>

            <MUIDataTable
              style={{ Overflow: "hidden" }}
              data={state.categoriasUser}
              columns={columnsCategorias}
              options={optionsCategorias}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PopUpDependenciasCategorias;
