import * as axios from "axios";

export const token = () => {
  return window.localStorage.getItem("tokenLlave");
};

export const config = (photo) => {
  let token = window.localStorage.getItem("token");
  if (token) {
    return {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": photo ? "multipart/form-data" : "application/json",
      },
    };
  } else {
    return {
      headers: {
        "Access-Control-Allow-Origin": "*",
        // "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
};

export const services = (method, service, body, photo = false) => {
  switch (method) {
    case "GET":
      let url = `${process.env.REACT_APP_URLAPI}${service}`;
      return axios
        .get(url, config(photo))
        .then((r) => {
          return response(r);
        })
        .catch((err) => {
          return response(err.response);
        });

    case "POST":
      return axios
        .post(`${process.env.REACT_APP_URLAPI}${service}`, body, config())
        .then((r) => {
          return response(r);
        })
        .catch((err) => {
          return response(err.response);
        });

    case "PUT":
      return axios
        .put(`${process.env.REACT_APP_URLAPI}${service}`, body, config())
        .then((r) => {
          return response(r);
        })
        .catch((err) => {
          return response(err.response);
        });

    case "DELETE":
      return axios
        .delete(`${process.env.REACT_APP_URLAPI}${service}`, config())
        .then((r) => {
          return response(r);
        })
        .catch((err) => {
          return response(err.response);
        });

    case "FREEAPIGET":
      let urlfree = `${process.env.REACT_APP_URLAPIFREE}${service}`;
      return axios
        .get(urlfree, config(photo))
        .then((r) => {
          return response(r);
        })
        .catch((err) => {
          return response(err.response);
        });

    case "FREEAPIPOST":
      return axios
        .post(`${process.env.REACT_APP_URLAPIFREE}${service}`, body, config())
        .then((r) => {
          return response(r);
        })
        .catch((err) => {
          return response(err.response);
        });

    default:
      break;
  }
};

const response = (r) => {
  if (r === undefined) {
    return false;
  }

  if (r.status === 401) {
    if (window.location.pathname !== "/login") {
      window.localStorage.clear();
      window.location.replace("/login");
    }
    // window.location.replace(process.env.REACT_APP_URLLLAVEGLOBAL);
    return false;
  }

  if (r.status === 200) {
    return { status: r.status, data: r.data };
  }
  if (r.status === 402) {
    return { status: r.status, data: r.data };
  }
  if (r.status === 404) {
    return { status: r.status, data: r.data };
  }
  if (r.status === 422) {
    return { status: r.status, data: r.data.error ?? r.data.mensage };
  }

  if (r.status === 201) {
    window.location.replace("/login");
    // window.localStorage.clear();
    return false;
  }

  if (r.status === 500) {
    // console.log(r);
    // window.location.replace('/serverError');

    return { status: r.status, data: r?.data };
  }

  return { status: r.status, error: r.data.error };
};
