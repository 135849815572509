const buscarCategorias = (categorias, palabra) => {
  /* 
    Funcion para buscar dentro de las categorias 
    @version                               1.0
    @param {string} palabra 
    @param {array} categorias 

    @return {array} 
    */

  const originalString = palabra;

  // const splitString = originalString.split(" ");

  const primerfiltro = categorias.filter((categoria) => {
    const name = categoria.DESC_CATEGORIA.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    // let response = splitString.filter((palabra) => {
    //     if (name.includes(palabra)) {
    //         return true;
    //     }
    // });

    // return name.includes(palabra) || response.length > 0;
    return name.includes(palabra);
  });

  return primerfiltro;
};

const buscarSubCategorias = (subcategorias, busqueda_input) => {
  /* 
        Funcion para buscar dentro de las subcategorias
        @version                               1.0 
        @param {string} busqueda_input 
        @param {array} subcategorias 
        
        @return {array} 
    */

  let conceptoarraytest = [];
  const originalString = busqueda_input;

  const splitString = originalString.split(" ");

  subcategorias.filter((item) => {
    item.TB_CONCEPTOS.filter((sub) => {
      splitString.map((palabra, index) => {
        if (index === 0) {
          if (
            sub.TEXTO_CONCEPTO.toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(palabra)
          ) {
            conceptoarraytest.push(sub);
          }
          // index = false
        } else {
          conceptoarraytest.filter((obj2) => {
            if (
              !obj2.TEXTO_CONCEPTO.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(palabra)
            ) {
              conceptoarraytest = conceptoarraytest.filter(
                (item) => item !== obj2
              );
            }
          });
        }
      });
    });
  });

  return conceptoarraytest;
};

export { buscarCategorias, buscarSubCategorias };
