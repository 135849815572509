import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import GifLoader from "../components/ui/gifLoader";
import ActaTable from "../components/ActaTableComponent";
import swal from "sweetalert";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { services } from "../services/api";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#6f1d46",
  "&:hover": {
    backgroundColor: "#6f1d46",
  },
}));

const { IdentificacionTable, InfoTable, SectionHeader } = ActaTable;

const ValidacionActas = () => {
  const [identificador, setIdentificador] = useState("");
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  /* const [captchaText, setCaptchaText] = useState("");
  const [captchaInput, setCaptchaInput] = useState(""); */

  // Genera una cadena aleatoria para el captcha
  /* const generateCaptcha = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }; */

  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
      (status) => {
        // console.log(status);
      }
    );
    /* setCaptchaText(generateCaptcha()); */
  }, []);

  // Handles para los inputs
  const handleChange = (event) => {
    setIdentificador(event.target.value);
  };
  /* const handleCaptchaChange = (event) => {
    setCaptchaInput(event.target.value);
  }; */

  // Función de validación de captcha
  const handleSearch = () => {
    /* if (captchaInput !== captchaText) {
      swal({
        title: "Error",
        text: "El texto del captcha no coincide. Inténtelo de nuevo.",
        icon: "error",
        buttons: false,
        timer: 2000,
      });
      setCaptchaInput("");
      setCaptchaText(generateCaptcha());
    } else { */
    sendFolio(identificador);
    /* }
    setCaptchaInput("");
    setCaptchaText(generateCaptcha()); */
  };

  // Función para enviar el folio
  const sendFolio = async (identificador) => {
    let recaptcha_token = "";
    await ReactRecaptcha3.getToken().then(
      (token) => {
        // console.log(token);
        recaptcha_token = token;
      },
      (error) => {
        // console.log(error);
      }
    );

    setLoading(true);
    const formData = new FormData();
    formData.append("folio", identificador);
    formData.append("recaptcha_token", recaptcha_token);
    try {
      const result = await services("POST", `validarActa`, formData);

      const { data, status } = result;
      // const result = await response.json();

      if (status !== 200) {
        swal({
          title: "Error",
          text: data,
          icon: "error",
          buttons: false,
          timer: 3000,
        });
      } else {
        setData(data);
      }
    } catch (error) {
      console.error("Error de solicitud:", error);
    } finally {
      setIdentificador("");
      setLoading(false);
    }
  };

  // Función para obtener el tipo de acta
  const tipoActa = () => {
    const actaTipo = {
      "01": "Nacimiento",
      "02": "Matrimonio",
      "03": "Divorcio",
      "04": "Defunción",
    };

    return actaTipo[data.t_acta];
  };

  // Función para obtener el nombre de las secciones
  const firstSeccionName = () => {
    const seccion = {
      "01": "Datos de la Persona Registrada:",
      "02": "Datos de los Contrayentes:",
      "03": "Datos de los Divorciados:",
      "04": "Datos del Fallecido:",
    };

    return seccion[data.t_acta] || "";
  };
  const secondSeccionName = () => {
    const seccion = {
      "01": "Datos de Filiación de la Persona Registrada:",
      "02": null,
      "03": null,
      "04": "Datos de la Defunción:",
    };

    return seccion[data.t_acta] || "";
  };

  const history = useHistory();

  //funcion redireccio a Mas informacion
  const masInformacion = () => {
    history.push("/masInformacion-Actas");
  };

  // Función para limpiar los datos
  const clearData = () => {
    setData();
  };
  // Función para obtener la información de la tabla de identificación
  const identificacionTable = () => {
    let identificacionData;

    if (data.t_acta === "01") {
      identificacionData = [
        {
          header: "Identificador Electrónico",
          value: data.Consulta.folio || "---",
        },
        {
          header: "Clave Única de Registro de Población",
          value: data.Consulta.curp || "---",
        },
        {
          header: "Número de Certificado de Nacimiento",
          value: data.Consulta.certificado || "---",
        },
        {
          header: "Entidad de Registro",
          value: data.Consulta.entidad_reg || "---",
        },
        {
          header: "Municipio de Registro",
          value: data.Consulta.mun_nac_reg || "---",
        },
      ];
    } else if (data.t_acta === "02") {
      identificacionData = [
        {
          header: "Identificador Electrónico",
          value: data.Consulta.folio || "---",
        },
        {
          header: "Clave Única de Registro de Población De Los Contrayentes",
          value: data.Consulta.curp || "---",
        },
        {
          header: "Entidad de Registro",
          value: data.Consulta.entidad_reg || "---",
        },
        {
          header: "Municipio de Registro",
          value: data.Consulta.municipio_reg || "---",
        },
        {
          header: "Fecha de Inscripción del Matrimonio",
          value: data.Consulta.fecha_reg || "---",
        },
      ];
    } else if (data.t_acta === "03") {
      identificacionData = [
        {
          header: "Identificador Electrónico",
          value: data.Consulta.folio || "---",
        },
        {
          header: "Clave Única de Registro de Población De Los Divorciados",
          value: data.Consulta.curp || "---",
        },
        {
          header: "Entidad de Registro",
          value: data.Consulta.entidad_reg || "---",
        },
        {
          header: "Municipio de Registro",
          value: data.Consulta.municipio_reg || "---",
        },
        {
          header: "Fecha de Inscripción",
          value: data.Consulta.fecha_reg || "---",
        },
      ];
    } else if (data.t_acta === "04") {
      identificacionData = [
        {
          header: "Identificador Electrónico",
          value: data.Consulta.folio || "---",
        },
        {
          header: "Clave Única de Registro de Población",
          value: data.Consulta.curp_reg || "---",
        },
        {
          header: "N° de Certificado de Defunción de la SSA",
          value: data.Consulta.certificado || "---",
        },
        {
          header: "Entidad de Registro",
          value: data.Consulta.entidad_reg || "---",
        },
        {
          header: "Municipio de Registro",
          value: data.Consulta.municipio_reg || "---",
        },
      ];
    }

    //Tabla de información
    const infoData = [
      { header: "Oficialia", value: data.Consulta.oficialia || "---" },
      { header: "Libro", value: data.Consulta.libro || "---" },
      { header: "Acta", value: data.Consulta.acta_num || "---" },
      {
        header:
          data.t_acta === "01" || data.t_acta === "04"
            ? "Fecha de Registro"
            : null,
        value:
          data.t_acta === "01" || data.t_acta === "04"
            ? data.Consulta.fecha_reg || "---"
            : null,
      },
    ];

    return { infoData, identificacionData };
  };
  const { infoData, identificacionData } = data
    ? identificacionTable()
    : { infoData: [], identificacionData: [] };

  // Función para obtener la información de las tablas
  const infoTable = () => {
    let rows = [];
    let rows2 = [];
    let rows3 = [];

    if (data.t_acta === "01") {
      rows = [
        {
          cols: [
            data.Consulta.nombre_reg,
            data.Consulta.primer_ap_reg,
            data.Consulta.segundo_ap_reg,
          ],
          headers: ["Nombre:", "Primer Apellido:", "Segundo Apellido:"],
        },
        {
          cols: [
            data.Consulta.sexo,
            data.Consulta.fecha_nac,
            data.Consulta.ent_nac_reg,
          ],
          headers: ["Sexo:", "Fecha de Nacimiento:", "Lugar de Nacimiento:"],
        },
      ];
      rows2 = [
        {
          cols: [
            data.Consulta.nombre_padre,
            data.Consulta.primer_ap_padre,
            data.Consulta.segundo_ap_padre,
            data.Consulta.nacionalidad_padre,
            data.Consulta.curp_padre || "---",
          ],
          headers: [
            "Nombre del Padre:",
            "Primer Apellido del Padre:",
            "Segundo Apellido del Padre:",
            "Nacionalidad del Padre:",
            "CURP:",
          ],
        },
        {
          cols: [
            data.Consulta.nombre_madre,
            data.Consulta.primer_ap_madre,
            data.Consulta.segundo_ap_madre,
            data.Consulta.nacionalidad_madre,
            data.Consulta.curp_madre || "---",
          ],
          headers: [
            "Nombre de la Madre:",
            "Primer Apellido de la Madre:",
            "Segundo Apellido de la Madre:",
            "Nacionalidad de la Madre:",
            "CURP:",
          ],
        },
      ];
      rows3 = [
        {
          cols: [data.Consulta.notas_marginales || "---"],
          headers: ["Antotaciones Marginales:"],
        },
      ];
    } else if (data.t_acta === "02") {
      rows = [
        {
          cols: [
            data.Consulta.nombre_cty1,
            data.Consulta.primer_ap_cty1,
            data.Consulta.segundo_ap_cty1,
          ],
          headers: ["Nombre:", "Primer Apellido:", "Segundo Apellido:"],
        },
        {
          cols: [
            `${data.Consulta.mun_nac_cty1}, ${data.Consulta.ent_nac_cty1}`,
            data.Consulta.nacionalidad_cty1,
            data.Consulta.sexoCy1,
            data.Consulta.edad_cty1,
          ],
          headers: ["Lugar de Nacimiento:", "Nacionalidad:", "Sexo:", "Edad:"],
        },
      ];
      rows2 = [
        {
          cols: [
            data.Consulta.nombre_cty2,
            data.Consulta.primer_ap_cty2,
            data.Consulta.segundo_ap_cty2,
          ],
          headers: ["Nombre:", "Primer Apellido:", "Segundo Apellido:"],
        },
        {
          cols: [
            `${data.Consulta.mun_nac_cty2}, ${data.Consulta.ent_nac_cty2}`,
            data.Consulta.nacionalidad_cty2,
            data.Consulta.sexoCy2,
            data.Consulta.edad_cty2,
          ],
          headers: ["Lugar de Nacimiento:", "Nacionalidad:", "Sexo:", "Edad:"],
        },
        {
          cols: [data.Consulta.regimen_mat],
          headers: ["Régimen Patrimonial:"],
        },
      ];
      rows3 = [
        {
          cols: [data.Consulta.notas_marginales || "---"],
          headers: ["Antotaciones Marginales:"],
        },
      ];
    } else if (data.t_acta === "03") {
      rows = [
        {
          cols: [
            data.Consulta.nombre_div1,
            data.Consulta.primer_ap_div1,
            data.Consulta.segundo_ap_div1,
          ],
          headers: ["Nombre:", "Primer Apellido:", "Segundo Apellido:"],
        },
        {
          cols: [
            `${data.Consulta.mun_nac_div1 || "---"}, ${
              data.Consulta.ent_nac_div1 || "---"
            }`,
            data.Consulta.nacionalidad_div1,
            data.Consulta.sexo_div1,
            data.Consulta.edad_div1,
          ],
          headers: ["Lugar de Nacimiento:", "Nacionalidad:", "Sexo:", "Edad:"],
        },
        {
          cols: [
            data.Consulta.nombre_div2,
            data.Consulta.primer_ap_div2,
            data.Consulta.segundo_ap_div2,
          ],
          headers: ["Nombre:", "Primer Apellido:", "Segundo Apellido:"],
        },
        {
          cols: [
            `${data.Consulta.mun_nac_div2 || "---"}, ${
              data.Consulta.ent_nac_div2 || "---"
            }`,
            data.Consulta.nacionalidad_div2,
            data.Consulta.sexo_div2,
            data.Consulta.edad_div2,
          ],
          headers: ["Lugar de Nacimiento:", "Nacionalidad:", "Sexo:", "Edad:"],
        },
      ];
      rows2 = [
        {
          cols: [
            data.Consulta.mat_estado || "---",
            data.Consulta.mat_municipio || "---",
            data.Consulta.fecha_resolucion,
            data.Consulta.via_disolucion,
          ],
          headers: [
            "Entidad Federativa:",
            "Municipio:",
            "Fecha de Resolución:",
            "Vía de Disolución:",
          ],
        },
      ];
      rows3 = [
        {
          cols: [data.Consulta.notas_marginales || "---"],
          headers: ["Antotaciones Marginales:"],
        },
      ];
    } else if (data.t_acta === "04") {
      rows = [
        {
          cols: [
            data.Consulta.nombre_reg,
            data.Consulta.primer_ap_reg,
            data.Consulta.segundo_ap_reg,
          ],
          headers: ["Nombre:", "Primer Apellido:", "Segundo Apellido:"],
        },
        {
          cols: [
            data.Consulta.sexo,
            data.Consulta.edo_civil,
            data.Consulta.fecha_nac,
          ],
          headers: ["Sexo:", "Estado Civil:", "Fecha de Nacimiento:"],
        },
        {
          cols: [
            `${data.Consulta.municipio_nacimiento || "---"}, ${
              data.Consulta.entidad_nacimiento || "---"
            }`,
            data.Consulta.entidad_reg,
            data.Consulta.nacionalidad,
          ],
          headers: [
            "Lugar de Nacimiento:",
            "Entidad de Registro:",
            "Nacionalidad:",
          ],
        },
      ];
      rows2 = [
        {
          cols: [
            data.Consulta.fecha_defuncion,
            data.Consulta.hora || "---",
            data.Consulta.domicilio_defuncion_calle,
            data.Consulta.destino_cadaver,
          ],
          headers: [
            "Fecha de Defunción:",
            "Hora:",
            "Domicilio de Defunción:",
            "Destino del Cadáver:",
          ],
        },
        {
          cols: [data.Consulta.causas_defuncion],
          headers: ["Causas de la Defunción:"],
        },
      ];
      rows3 = [
        {
          cols: [data.Consulta.notas_marginales || "---"],
          headers: ["Antotaciones Marginales:"],
        },
      ];
    }

    return { rows, rows2, rows3 };
  };

  const { rows, rows2, rows3 } = data
    ? infoTable()
    : { rows: [], rows2: [], rows3: [] };

  return (
    <>
      {isLoading && <GifLoader />}
      <Grid
        container
        spacing={2}
        columns={16}
        justifyContent="center"
        alignContent="center"
        sx={{ minHeight: "70vh" }}
      >
        {data && (
          <Grid item xs={15} justifyContent="start" className="mx-4">
            <Typography
              variant="outlined"
              sx={{
                width: "120px",
                cursor: "pointer",
                margin: ".2rem",
                color: "#6f1d46",
              }}
              onClick={() => clearData()}
            >
              <ArrowBackIcon sx={{ marginRight: "0.5rem" }} />
              Regresar
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={16}
          md={6}
          lg={4}
          className="sombrasprincipal card d-flex flex-column m-4 h-100"
        >
          <h5 className="bg-guindaActas text-center bold my-4 px-4">
            Búsqueda por Folio Emisor
          </h5>
          <TextField
            type="text"
            required
            label="Folio Emisor"
            variant="outlined"
            value={identificador}
            onChange={handleChange}
            InputProps={{
              sx: {
                borderRadius: "14px",
                backgroundColor: "#f9f9f9",
                width: "100%",
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#6f1d46",
              padding: "0.5rem",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              masInformacion();
            }}
          >
            Más Información
          </Typography>
          {/* <Typography
            variant="h6"
            sx={{
              marginY: "1rem",
              // marginX: "6rem",
              textAlign: "center",
              fontWeight: "bold",
              color: "#6f1d46",
              borderRadius: "14px",
              border: "1px solid #6f1d46",
              backgroundColor: "#e0e0e0",
              padding: "0.5rem",
            }}
          >
            {captchaText}
          </Typography> 
          <TextField
            type="text"
            required
            label="Ingrese el texto del captcha"
            variant="outlined"
            value={captchaInput}
            onChange={handleCaptchaChange}
            InputProps={{
              sx: {
                borderRadius: "14px",
                backgroundColor: "#f9f9f9",
                width: "100%",
              },
            }}
          />*/}
          <Grid
            sx={{ paddingY: "1rem" }}
            container
            spacing={2}
            columns={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} className="d-flex justify-content-center">
              <ColorButton
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  width: "40%",
                  cursor: "pointer",
                }}
                onClick={handleSearch}
              >
                Buscar
              </ColorButton>
            </Grid>
          </Grid>
        </Grid>

        {data && (
          <Grid
            item
            xs={16}
            md={10}
            lg={10}
            className="sombrasprincipal card d-flex flex-column m-4 px-md-5 py-5 w-100"
          >
            <h5 className="bg-guindaActas text-center bold mb-4 p-3">
              Registro
            </h5>

            {/* Tipo de acta y tabla superior */}
            <Grid
              item
              className="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
              <Grid item xs={16} md={8} className="my-3">
                <h5 className="text-center bold">Estados Unidos Mexicanos</h5>
                <h1 className="text-center bold">Acta de {tipoActa()}</h1>
              </Grid>

              {/* Tabla de Identificación */}
              <Grid
                item
                xs={16}
                md={8}
                className="d-flex flex-column justify-content-end"
              >
                <IdentificacionTable
                  identificacionData={identificacionData}
                  infoData={infoData}
                />
              </Grid>
            </Grid>

            {/* Tabla de Contenido */}
            <SectionHeader title={firstSeccionName()} />
            <InfoTable rows={rows} />

            <SectionHeader title={secondSeccionName()} />
            {data.t_acta === "03" && (
              <SectionHeader title="Datos del acto de divorcio:" />
            )}
            <InfoTable rows={rows2} />

            <SectionHeader title="Antotaciones Marginales" />
            <InfoTable rows={rows3} />

            <Grid className="my-3 d-flex justify-content-end">
              <Typography
                variant="caption"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#6f1d46",
                  padding: "0.5rem",
                }}
              ></Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ValidacionActas;
