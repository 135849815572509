import { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconUI from "./ui/icon_ui";
import { BsSearch } from "react-icons/bs";
import { toBottom } from "../utils/animationFunctions";
import capturaContext from "../context/captura_context/capturaContext";
import { NotificacionContext } from "../context/notificacion_context";
import { numberFormatMexico } from "../utils/formats";

function descendingComparator(a, b, orderBy) {
  if (orderBy === "concepto") {
    if (b.TEXTO_CONCEPTO < a.TEXTO_CONCEPTO) {
      return -1;
    }
    if (b.TEXTO_CONCEPTO > a.TEXTO_CONCEPTO) {
      return 1;
    }
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: '#FFE6EC',
    backgroundColor: "#ebedf0",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "concepto",
    numeric: false,
    disablePadding: true,
    label: "Concepto",
  },
  {
    id: "Tipo",
    numeric: false,
    disablePadding: true,
    label: "Tipo",
  },
  {
    id: "Importe",
    numeric: false,
    disablePadding: true,
    label: "Importe",
  },
];

export function SubCategorias(props) {
  const { showNotification } = useContext(NotificacionContext);
  let { envioConceptos, envio_conceptos, concept } = useContext(capturaContext);
  let rows = [];
  if (!props.services.length) {
    rows.push(props.services);
  } else {
    rows = props.services;
    let newArray = rows.filter((item) => {
      return item.TEXTO_CONCEPTO !== undefined;
    });
    rows = newArray;
  }
  // const history = useHistory()

  let [state, setState] = useState(rows);
  let [subCategorias, setSubcategorias] = useState(rows);
  let [input, setInput] = useState(concept ? concept : "");
  // const [error, setError] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("concepto");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [last, setLast] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      // envioConceptos([])
      if (concept) {
        if (subCategorias.length !== 0) {
          const filtered = subCategorias.filter((subCategoria) => {
            return subCategoria.TEXTO_CONCEPTO === concept;
          });
          props.serviciosSelected(state);
          setSubcategorias(filtered);
        }
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log(props.services, subCategorias, concept)
    if (props.services.length !== subCategorias.length && concept !== "") {
      setSubcategorias(props.services);
    } else if (last.length !== 0) {
      // console.log(last)
      const newArray = props.services.slice();
      newArray.push(last);
      setSubcategorias(newArray);
    }
    
  }, [envio_conceptos]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, concepto, row) => {
    const selectedIndex = selected.indexOf(concepto);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, concepto);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    let array = envio_conceptos;
    let ExistOnArray = array.filter((item) => {
      return row === item;
    });

    if (event.target.checked === false && ExistOnArray.length !== 0) {
      // ELIMNADO
      envio_conceptos = envio_conceptos.filter((item) => {
        return item !== row;
      });
    } else {
      // HACER PUSH

      if (envio_conceptos.length === 0) {
        // solo para agregar un solo concepto
        envio_conceptos.push(row);
        subCategorias = subCategorias.filter((item) => {
          return item !== row;
        });
        state = state.filter((item) => {
          return item !== row;
        });
      } else {
        return true;
      }
    }
    if (!concept) {
      props.serviciosSelected(subCategorias);
    }

    setState(state);

    setSubcategorias(subCategorias);
    envioConceptos(envio_conceptos);
    setSelected(newSelected);
    showNotification(
      "success",
      "Conceptos",
      "Se agregó correctamente",
      null,
      4
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subCategorias.length) : 0;

  const filterSubCategoria = (subCategoria, valueSearch) => {
    if (!valueSearch) {
      return subCategoria;
    }

    return subCategoria.filter((subCategoria) => {
      const name = subCategoria.TEXTO_CONCEPTO.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return name.includes(valueSearch);
    });
  };

  const searchInput = (evt) => {
    let textValue = "";
    if (evt.currentTarget?.value) {
      textValue = evt.currentTarget.value
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    }

    setInput(textValue);
    const filtered = filterSubCategoria(state, textValue);
    if (textValue === "") {
      setSubcategorias(state);
    } else {
      setSubcategorias(filtered);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <input
          type="text"
          id="buscar"
          className="form-input--search"
          placeholder="Buscador por Nombre"
          value={input}
          onChange={(evt) => {
            searchInput(evt);
          }}
        />
        <div className="form-input--searchIcon">
          <IconUI size={"25px"} color={"gray"}>
            <BsSearch />
          </IconUI>
        </div>
      </div>
      {subCategorias.length === 0 ? (
        <>
          {props.services.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div
                className=" d-flex justify-content-center align-items-center flex-column"
                style={{ height: "400px" }}
              >
                <div className="text-big text-bold text-guinda text-center">
                  {" "}
                  ¡ Ya agregaste todos los conceptos !
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div
                className=" d-flex justify-content-center align-items-center flex-column"
                style={{ height: "400px" }}
              >
                <div className="text-big text-bold text-guinda text-center">
                  {" "}
                  ¡ Lo sentimos !
                </div>
                <div className="text my-3">No encontramos ningún registro.</div>
                <IconUI
                  size={"70px"}
                  // color={'#4A001F'}
                  color={"#8d949e"}
                >
                  <BsSearch />
                </IconUI>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <TableContainer>
            <div
              style={
                subCategorias.length <= 5
                  ? { height: "400px" }
                  : { height: "auto" }
              }
            >
              <Table aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={subCategorias.length}
                />
                <TableBody>
                  {stableSort(subCategorias, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.TEXTO_CONCEPTO);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <StyledTableRow
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox"></TableCell>

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            style={{ padding: "5px" }}
                            align="justify"
                          >
                            {row.TEXTO_CONCEPTO}
                          </TableCell>
                          <TableCell align="center">{row.CL_IMPORTE}</TableCell>
                          {row.CL_IMPORTE === "MANUAL" ? (
                            <TableCell align="center">
                              {row.IMPORTE
                                ? numberFormatMexico(row.IMPORTE)
                                : "$0.00"}
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              {row.MONEDA === "UMA"
                                ? ` ${Math.trunc(row.IMPORTE)} UMA ($ ${(
                                    row.IMPORTE * row.UMA
                                  ).toFixed(2)} MXN)`
                                : `$ ${numberFormatMexico(
                                    parseFloat(row.IMPORTE)
                                  )}`}
                            </TableCell>
                          )}
                          <TableCell padding="checkbox">
                            {envio_conceptos.length === 0 ? (
                              <div
                                className="cta cta--guinda cta--light me-2"
                                onClick={(event) => {
                                  setInput("");
                                  setLast(row);
                                  handleClick(event, row.TEXTO_CONCEPTO, row);
                                  toBottom();
                                  searchInput("");
                                }}
                              >
                                Agregar
                              </div>
                            ) : null}
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>

          <TablePagination
            component="div"
            count={subCategorias.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
}
function EnhancedTableHead(props) {
  const { onRequestSort } = props;

  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            key={i}
            align={headCell.label === "Concepto" ? "left" : "center"}
            style={
              headCell.label === "Tipo" || headCell.label === "Importe"
                ? { width: "120px" }
                : {}
            }
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
