import React, { useState, useEffect, useContext } from "react";
import { services } from "../services/api";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PopUPDependencia from "../components/ui/popUpDependiencias";
import CrudDependencias from "../components/crud_dependencias";
import { useHistory } from "react-router-dom";
import CapturaContext from "../context/captura_context/capturaContext";

const colorUI = "#6f1d46";
// const colorUI="#8d949e";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const ColorButtonSecond = styled(Button)(({ theme }) => ({
  color: colorUI,
  backgroundColor: "#ffc3d0",
  border: "none",
  "&:hover": {
    transform: "scale(1.005)",
    boxShadow: "3px 3px 6px 0px rgba(0, 0, 0, 0.2)",
  },
}));

const Dependencia = () => {
  const { setDatosTabla, AdminTableData, currentUser } =
    useContext(CapturaContext);
  const history = useHistory();

  const [data, setdata] = useState({
    lineaCaptura: false,
    admin: false,
    showPopUp: false,
    showPopUpUpdate: false,
    bodyUpdate: {},
    dataUsers: [],
  });

  const consumoApiDependencias = async () => {
    let dataDependenciasApi = await services("GET", `dependencia/listar`, null);

    if (dataDependenciasApi.status === 200) {
      setDatosTabla(dataDependenciasApi.data.reverse());
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      consumoApiDependencias();

      // if(!(currentUser.rol === 'Dependencia')){
      //   history.replace("/")
      // }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const admin = () => {
    if (data.admin) {
      setdata({ ...data, admin: false });
    } else {
      setdata({ ...data, admin: true });
    }
  };

  return (
    // <div className={data.admin || data.lineaCaptura ? '' : 'adminview'}>
    <div className={""}>
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Dependencias
        </div>
      </div>

      <CrudDependencias
        dataUsers={AdminTableData ? AdminTableData : []}
        showPopUpOnView={() => {
          setdata({ ...data, showPopUp: true });
        }}
        showPopUpUpdateOnView={(bodyUpdate) => {
          setdata({ ...data, showPopUpUpdate: true, bodyUpdate: bodyUpdate });
        }}
      />

      {data.showPopUp ? (
        <PopUPDependencia
          html={null}
          onclose={() => {
            setdata({ ...data, showPopUp: false });
          }}
          tipo="form"
          bodyUpdate={null}
        />
      ) : null}

      {data.showPopUpUpdate ? (
        <PopUPDependencia
          html={null}
          onclose={() => {
            setdata({ ...data, showPopUpUpdate: false });
          }}
          tipo="update"
          bodyUpdate={data.bodyUpdate}
        />
      ) : null}
    </div>
  );
};

export default Dependencia;
