
const downloadPDF = (documentLink, name, callback = null, extension) => {
    try {
        const linkSource = `data:application/${extension};base64,${documentLink}`
        const downloadLink = document.createElement("a")
        const fileName = `${name}.${extension}`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        if (callback !== null) {
            callback()
        }
    } catch (ex) {
        console.log(ex)
    }
}


export default downloadPDF