import { useState } from "react";

function useSharedState() {
  const [switchActivatePinpad, setSwitchActivatePinpad] = useState(false);

  const activarUsuarioPinPad = () => {
    setSwitchActivatePinpad(switchActivatePinpad(!switchActivatePinpad));
  };

  return {
    switchActivatePinpad,
    setSwitchActivatePinpad,
    activarUsuarioPinPad,
  };
}

export default useSharedState;
