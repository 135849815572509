import { useContext, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Box,
  Dialog,
  TextField,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import Select, { components } from "react-select";
import { services } from "../services/api";
import { NotificacionContext } from "../context/notificacion_context";
import GifLoader from "../components/ui/gifLoader";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import swal from "sweetalert";
import Slide from "@mui/material/Slide";

const colorUI = "#6f1d46";
// const colorUI = "#8d949e";

export const SecretariaEducacionView = () => {
  const { showNotification } = useContext(NotificacionContext);
  const [hideButton, setHide] = useState(false);
  const [showMessage, setMessage] = useState(false);
  const [openDialog, setOpen] = useState(false);
  const [state, setState] = useState({
    curp: "",
    level: "",
    clave: "",
    email: "",
    infoCerticado: {},
    nivelEducativo: 0,
    loading: false,
    consulta: false,
    lineaCaptura: false,
  });

  const niveles = [
    {
      id: 1,
      nombre: "PREESCOLAR",
    },
    {
      id: 2,
      nombre: "PRIMARIA",
    },
    {
      id: 3,
      nombre: "SECUNDARIA",
    },
  ];

  const handleChangelvl = (item) => {
    setState({ ...state, level: item.id });
  };

  const handleChangelvlDig = (item) => {
    setStateDig({ ...stateDig, nivel: item.id });
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };
  /* --------------------------- Lo de Digitalizacion  ------------------------------------------------ */

  const containerRef = useRef(null);

  const [stateDig, setStateDig] = useState({
    curp: "",
    nivel: "",
    email: "",
    ciclo: "",
    clave: "",
    archivo: "",
  });

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDigitalizacion = async () => {
    setState({ ...state, loading: true });
    let formData = new FormData();
    formData.append("curp", stateDig.curp);
    formData.append("nivel", stateDig.nivel);
    formData.append("email", stateDig.email);
    formData.append("ciclo", stateDig.ciclo);
    formData.append("clave", stateDig.clave);
    formData.append("archivo", stateDig.archivo);
    let peticionDigitalizacion = await services(
      "POST",
      "see/generaSolicitud",
      formData,
      true
    );
    // console.log(peticionDigitalizacion);
    if (peticionDigitalizacion.status === 200) {
      if (peticionDigitalizacion.data?.Folio) {
        swal(
          "Solicitud Generada",
          `${peticionDigitalizacion.data.mensaje}`,
          "success"
        );
      } else {
        swal(
          "Solicitud Generada",
          `${peticionDigitalizacion.data.mensaje ?? "N/D"}`,
          "success"
        );
      }
      setStateDig({
        ...state,
        curp: "",
        nivel: "",
        email: "",
        ciclo: "",
        clave: "",
        archivo: "",
      });
    } else {
      showNotification(
        "error",
        "Error en consulta",
        peticionDigitalizacion.error,
        null,
        4
      );
    }
    setState({ ...state, loading: false });
  };

  /* -----------------------------------fin Digitalizacion---------------------------------------- */
  const handleConsulta = async () => {
    setMessage(false);
    setState({ ...state, loading: true, consulta: false, lineaCaptura: false });
    const body = {
      curp: state.curp,
      nivel: state.level,
      clave: state.clave,
    };

    let dataConsulta = await services("POST", `see/certificado`, body);

    if (dataConsulta.status !== 200) {
      setState({ ...state, loading: false });
      showNotification("error", "Certificado", dataConsulta.error, null, 4);
      handleClose();
      return;
    }

    if (dataConsulta.status === 200) {
      if (dataConsulta.data.created === false) {
        setState({ ...state, loading: false });
        showNotification(
          "error",
          "Certificado",
          dataConsulta.data.error,
          null,
          4
        );
        handleClose();
        return;
      }
    }

    if (dataConsulta.errors || dataConsulta.data.error) {
      showNotification("error", "Certificado", dataConsulta.errors, null, 4);
      setState({ ...state, loading: false });
      return true;
    }

    if (dataConsulta.data.mensaje) {
      // showNotification("error", "Certificado", dataConsulta.data.mensaje, null, 4);
      setMessage(true);
      setState({ ...state, loading: false, consulta: false });
    } else if (dataConsulta.data.mensage) {
      setState({
        ...state,
        loading: false,
        consulta: false,
        lineaCaptura: true,
        infoCerticado: dataConsulta.data,
      });
    } else {
      const indexLvl = dataConsulta.data.nivel;
      const nivelSelected = niveles.filter((item) => {
        return item.nombre === indexLvl;
      });

      setState({
        ...state,
        infoCerticado: dataConsulta.data,
        nivelEducativo: nivelSelected,
        consulta: true,
        lineaCaptura: false,
        loading: false,
      });
      setHide(true);
      if (dataConsulta.data?.created === false) {
        setHide(false);
        setState({ ...state, consulta: false, lineaCaptura: false });
        showNotification("error", "Error", dataConsulta.data.error, null, 4);
      }
    }

    // hacer la ppeticion
  };
  const handleButton = () => {
    setHide(false);
    setState({ ...state, consulta: false, lineaCaptura: false });
  };

  const generatePay = async (data) => {
    setState({ ...state, loading: true });
    let body = {
      curp: state?.infoCerticado?.curp,
      // "nivel":state?.infoCerticado?.nivel,
      nivel: state.nivelEducativo[0].id,
      clave: state?.infoCerticado?.clave,
      email: state?.email,
    };

    let dataCertificado = await services(
      "POST",
      `see/guardarSolicitudCertificado`,
      body
    );
    if (dataCertificado.status === 400) {
      setState({ ...state, loading: false });
      showNotification("error", "Certificado", dataCertificado.error, null, 4);
      handleClose();
    } else {
      if (dataCertificado.status !== 200) {
        setState({ ...state, loading: false });
        showNotification(
          "error",
          "Certificado",
          dataCertificado.error,
          null,
          4
        );
        handleClose();
        return;
      }

      setState({ ...state, loading: false });
      handleClose();
      swal("Se generó línea de captura con éxito", {
        buttons: {
          linea: "Ver Línea de Captura.",
        },
      }).then((result) => {
        window.location = dataCertificado.data?.intento;
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const downloadCertificado = async () => {
    setState({ ...state, loading: true });
    let { infoCerticado } = state;
    let body = {
      lcaptura: infoCerticado?.lcaptura,
      tkn: infoCerticado?.tkn,
    };
    // downloadPDF(base64Doc, `Certificado_${state.curp}`, null, "pdf")

    let serviceDownload = await services(
      "POST",
      `see/descargarcertificado`,
      body
    );

    if (serviceDownload.status === 400) {
      setState({ ...state, loading: false });
      showNotification(
        "error",
        "Error en la descarga",
        "Hubo un problema, intentar mas tarde",
        null,
        4
      );
      handleClose();
    } else {
      let base64Doc = serviceDownload.data;
      showNotification(
        "success",
        "Descarga Exitosa",
        "En breve comenzara la descarga",
        null,
        4
      );
      downloadPDF(base64Doc, "Certificado", null, "pdf");
    }
    setState({ ...state, loading: false });
  };

  const downloadPDF = (documentLink, name, callback, extension) => {
    try {
      const linkSource = `data:application/${extension};base64,${documentLink}`;
      const downloadLink = document.createElement("a");
      const fileName = `${name}.${extension}`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      if (callback !== null) {
        callback();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <>
      {state.loading ? <GifLoader></GifLoader> : null}
      <div className={`${state.consulta ? "" : "vh-100"}  `}>
        <div
          style={{ width: "80%", margin: "15px auto", overflow: "hidden" }}
          className="card text-center"
          ref={containerRef}
        >
          <div className=" text-big mb-2 text-bold">
            {/* <Button sx={{backgroundColorp}}> Solicitud de duplicados</Button> */}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                aria-label="basic tabs example"
              >
                <Tab label="Solicitud de Duplicados" {...a11yProps(0)} />
                <Tab label="Solicitud de digitalización" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </div>
          {value === 0 && (
            <Slide
              direction="right"
              in={value === 0}
              container={containerRef.current}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h3>Solicitud de Duplicados</h3>
                <FormControl
                  sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                >
                  <InputLabel htmlFor="my-input">CURP</InputLabel>
                  <Input
                    error={state.curp}
                    onChange={(event) => {
                      setState({ ...state, curp: event.target.value });
                    }}
                    onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    id="nombre"
                    name="nombre"
                    aria-describedby="my-helper-text"
                  />
                  <FormHelperText id="my-helper-text">
                    Ingresa tu curp.
                  </FormHelperText>
                </FormControl>
                <div className="mt-3 pt-3">
                  <FormControl sx={{ width: 300, maxWidth: "100%" }}>
                    <div style={{ zIndex: "10" }}>
                      <Select
                        options={[
                          {
                            id: 1,
                            name: "Preescolar",
                          },
                          {
                            id: 2,
                            name: "Primaria",
                          },
                          {
                            id: 3,
                            name: "Secundaria",
                          },
                        ]}
                        placeholder="Seleccione un Nivel Educativo"
                        getOptionValue={(options) => options.id}
                        getOptionLabel={(options) => options.name}
                        onChange={(options) => handleChangelvl(options)}
                        id="rol"
                        name="rol"
                        components={{ NoOptionsMessage }}
                      />
                    </div>

                    <FormHelperText>
                      Selecciona el nivel educativo
                    </FormHelperText>
                  </FormControl>
                </div>
                <FormControl
                  sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                >
                  <InputLabel htmlFor="my-input">Centro Educativo</InputLabel>
                  <Input
                    error={state.clave}
                    onChange={(event) => {
                      setState({ ...state, clave: event.target.value });
                    }}
                    onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    id="nombre"
                    name="nombre"
                    aria-describedby="my-helper-text"
                  />
                  <FormHelperText id="my-helper-text">
                    Clave de centro educativo.
                  </FormHelperText>
                </FormControl>
                {!hideButton && (
                  <div
                    className="cta cta--guinda"
                    onClick={() => {
                      handleConsulta();
                    }}
                  >
                    Consultar
                  </div>
                )}
              </div>
            </Slide>
          )}
          {value === 1 && (
            <Slide
              direction="left"
              in={value === 1}
              container={containerRef.current}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h3>Solicitud de Digitalización</h3>
                <FormControl
                  sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                >
                  <InputLabel htmlFor="my-input">CURP</InputLabel>
                  <Input
                    onChange={(event) => {
                      setStateDig({ ...stateDig, curp: event.target.value });
                    }}
                    onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    id="nombre"
                    name="nombre"
                    aria-describedby="my-helper-text"
                  />
                  <FormHelperText id="my-helper-text">
                    Ingresa tu curp.
                  </FormHelperText>
                </FormControl>
                <div className="mt-3 pt-3">
                  <FormControl sx={{ width: 300, maxWidth: "100%" }}>
                    <div style={{ zIndex: "10" }}>
                      <Select
                        options={[
                          {
                            id: 1,
                            name: "Preescolar",
                          },
                          {
                            id: 2,
                            name: "Primaria",
                          },
                          {
                            id: 3,
                            name: "Secundaria",
                          },
                        ]}
                        placeholder="Seleccione un Nivel Educativo"
                        getOptionValue={(options) => options.id}
                        getOptionLabel={(options) => options.name}
                        onChange={(options) => handleChangelvlDig(options)}
                        id="rol"
                        name="rol"
                        components={{ NoOptionsMessage }}
                      />
                    </div>

                    <FormHelperText>
                      Selecciona el nivel educativo
                    </FormHelperText>
                  </FormControl>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-center">
                  <FormControl
                    sx={{ marginRight: 3, width: 250, maxWidth: "100%" }}
                  >
                    <InputLabel htmlFor="my-input">Centro Educativo</InputLabel>
                    <Input
                      onChange={(event) => {
                        setStateDig({ ...stateDig, clave: event.target.value });
                      }}
                      onKeyPress={(event) => {
                        // onlyLetter(event, 29);
                      }}
                      inputProps={{
                        style: { textTransform: "uppercase" },
                        maxLength: 10,
                      }}
                      placeholder="Ej. 11AaA1111A"
                      id="nombre"
                      name="nombre"
                      aria-describedby="my-helper-text"
                    />
                    <FormHelperText id="my-helper-text">
                      Clave de centro educativo.
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    sx={{ marginRight: 3, width: 250, maxWidth: "100%" }}
                  >
                    <InputLabel htmlFor="my-input">Ciclo</InputLabel>
                    <Input
                      onChange={(event) => {
                        setStateDig({ ...stateDig, ciclo: event.target.value });
                      }}
                      onKeyPress={(event) => {
                        // onlyLetter(event, 29);
                      }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      placeholder="Ej.2013-2014"
                      id="ciclo"
                      name="ciclo"
                      aria-describedby="my-helper-text"
                    />
                    <FormHelperText id="my-helper-text">
                      Ciclo Escolar.
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className=""
                    sx={{ marginRight: 3, width: 250, maxWidth: "100%" }}
                  >
                    <InputLabel htmlFor="my-input">Correo</InputLabel>
                    <Input
                      onChange={(event) => {
                        setStateDig({ ...stateDig, email: event.target.value });
                      }}
                      onKeyPress={(event) => {
                        // onlyLetter(event, 29);
                      }}
                      id="email"
                      name="email"
                      aria-describedby="my-helper-text"
                    />
                    <FormHelperText id="my-helper-text">
                      Correo Electronico.
                    </FormHelperText>
                  </FormControl>
                  <TextField
                    className="my-4"
                    type="file"
                    label="Archivo Digitalizacion"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      setStateDig({
                        ...stateDig,
                        archivo: event.target.files[0],
                      });
                    }}
                  />
                </div>

                <div
                  className="cta cta--guinda"
                  onClick={() => {
                    handleDigitalizacion();
                  }}
                >
                  Consultar
                </div>
              </div>
            </Slide>
          )}
        </div>

        {(showMessage || state.consulta || state.lineaCaptura) && (
          <div className="card m-3">
            {showMessage && (
              <div style={{ width: "400px", margin: "10px auto" }}>
                <h3>
                  No existe ningún certificado, por favor comunícate con tu
                  centro educativo
                </h3>
              </div>
            )}
            {state.consulta && (
              <div
                style={{
                  width: "400px",
                  margin: "10px auto",
                  textAlign: "center",
                }}
              >
                <p>Resultados</p>
                <ul className="m-0 p-0">
                  <li className="m-0 p-0">
                    Centro Educativo: <br />
                    <strong style={{ textTransform: "capitalize" }}>
                      {" "}
                      {state.infoCerticado?.centroEducativo ?? ""}
                    </strong>
                  </li>
                  <li className="m-0 p-0">
                    Clave: <br />
                    <strong style={{ textTransform: "capitalize" }}>
                      {state.infoCerticado?.clave ?? ""}{" "}
                    </strong>
                  </li>
                  <li className="m-0 p-0">
                    CURP: <br />
                    <strong style={{ textTransform: "capitalize" }}>
                      {state.infoCerticado?.curp?.toUpperCase() ?? ""}{" "}
                    </strong>
                  </li>
                  <li className="m-0 p-0">
                    Calificación:
                    <br />
                    <strong style={{ textTransform: "capitalize" }}>
                      {state.infoCerticado?.calificacion ?? ""}{" "}
                    </strong>
                  </li>
                  <li className="m-0 p-0">
                    Fecha Certificaión: <br />
                    <strong style={{ textTransform: "capitalize" }}>
                      {state.infoCerticado?.fechaCertificacion ?? ""}{" "}
                    </strong>
                  </li>
                  <li className="m-0 p-0">
                    Nivel: <br />
                    <strong style={{ textTransform: "capitalize" }}>
                      {state.infoCerticado?.nivel ?? ""}{" "}
                    </strong>
                  </li>
                  <li className="m-0 p-0">
                    Nombre: <br />
                    <strong style={{ textTransform: "capitalize" }}>
                      {state.infoCerticado?.nombre ?? ""}{" "}
                    </strong>
                  </li>
                </ul>
                <div
                  className="m-auto mt-3 cta cta--guinda"
                  onClick={() => {
                    handleButton();
                  }}
                >
                  Volver a Consultar
                </div>
                <div
                  className="m-auto mt-3 cta cta--guinda"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Generar Línea de Captura
                </div>
              </div>
            )}
            {state.lineaCaptura && (
              <div
                style={{
                  margin: "10px auto",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h5>
                  {state.infoCerticado?.mensage ?? ""}. <br /> Para dar
                  seguimiento, entre al siguiente enlace:
                </h5>
                {state.infoCerticado?.estatus === 1 && (
                  <a
                    style={{
                      color: colorUI,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    href={state.infoCerticado.intento}
                  >
                    Aqui
                  </a>
                )}
                {state.infoCerticado?.estatus !== 1 && (
                  <p
                    style={{
                      color: colorUI,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => downloadCertificado()}
                  >
                    Aqui
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Generar Línea de Captura
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Para generar un línea de Captura, necesitamos que nos proporciones
            un correo electrónico
          </DialogContentText>
          <TextField
            fullWidth
            label="Email"
            size="small"
            variant="standard"
            value={state.email}
            onChange={(event) =>
              setState({ ...state, email: event.target.value })
            }
          />
          <small>*Verifica que tu correo este correctamente</small>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={generatePay} autoFocus>
            Generar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
