import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PrintIcon from "@mui/icons-material/Print";
import { useState } from "react";
import { usePINPAD } from "../../hooks/usePINPAD";
import GifLoader from "../../components/ui/gifLoader";
import swal from "sweetalert";

export const VerificadorPago = () => {
  const colorUI = "#6f1d46";
  const [error, setError] = useState("");

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const importe = (localStorage.getItem("importe") || "").trim();
  const referencia = localStorage.getItem("lineaCaptura");

  const [loaderPinpad, setLoaderPinpad] = useState(false);

  const [state, setState] = useState({
    showmodal: false,
    showmodalAfiliaciones: false,
    readChip: false,
    cobrar: true,
    rePrint: true,
    merchant: "",
    folio: "",
    referencia: referencia,
    monto: importe,
    displayMonto: importe ? `$${importe}` : "$0",
    noTarjeta: "",
    nombreTarjetaHabiente: "",
    fechaVencimiento: "",
    tipoPago: "",
    contado: null,
    msi: null,
    activateButton: true,
  });

  const { urlPINPAD, readCard, cobrar, rePrintVoucher } = usePINPAD({
    state,
    setState,
    setLoaderPinpad,
  });

  const [button, setStateButton] = useState(false);

  const closeModal = (typeModal) => {
    if (typeModal === "tipoPago") {
      setState({ ...state, showmodal: false });
    } else {
      setState({
        ...state,
        showmodalAfiliaciones: false,
      });
      setStateButton(false);
    }
  };

  const maskCardNumber = (number) => {
    if (number.length <= 4) return number;

    return number.slice(0, -4).replace(/./g, "*") + number.slice(-4);
  };

  const handleChangeReference = (e) => {
    setState({ ...state, referencia: e.target.value });
  };

  const handleChangeMonto = (e) => {
    let inputValue = e.target.value;

    if (inputValue.startsWith("$0")) {
      inputValue = inputValue.slice(2);
    } else {
      inputValue = inputValue.replace(/[^0-9.]/g, "");
    }

    if (inputValue === "") {
      setState((prevState) => ({
        ...prevState,
        monto: "",
        displayMonto: "$0",
      }));
    } else {
      const displayValue = `$${inputValue}`;
      setState((prevState) => ({
        ...prevState,
        monto: inputValue,
        displayMonto: displayValue,
      }));
    }

    setError("");
  };

  const handleChangeAfiliacion = (e) => {
    setState({
      ...state,
      merchant: e.target.value,
      tipoPago: e.target.getAttribute("data-custom"),
      cobrar: false,
    });
  };

  const leer = (monto) => {
    if (!state.monto) {
      swal({
        title: "Atención",
        text: "El importe no puede ser 0",
        icon: "error",
        button: "Aceptar",
        confirmButton: true,
      });
    } else {
      swal({
        title: "Atención",
        text: "¿El importe es correcto?",
        icon: "info",
        buttons: ["Cancelar", "Aceptar"],
        confirmButton: true,
      }).then((result) => {
        if (result) {
          setLoaderPinpad(true);
          setState({
            ...state,
            merchant: "",
            tipoPago: "",
            cobrar: true,
          });
          readCard(monto);
        }
      });
    }
    document.querySelector(".swal-button--confirm").style.backgroundColor =
      colorUI;
  };

  const venta = () => {
    setLoaderPinpad(true);
    cobrar();
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: colorUI,
    "&:hover": {
      backgroundColor: colorUI,
    },
  }));

  return (
    <>
      {loaderPinpad && (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <GifLoader></GifLoader>
          </div>
        </>
      )}
      <iframe
        id="iframeTo"
        src={urlPINPAD}
        width="0"
        height="0"
        sx={{
          display: "none",
        }}
        allow="serial;geolocation;"
      ></iframe>
      <div className="container mx-auto vh-100">
        <div className="bg-white rounded p-4">
          <h2 className="fw-bold h3">Detalles del pago</h2>
          <Grid container spacing={3}>
            {/* REFERENCIA */}
            <Grid align="right" item xs={12} sm={12} md={6}>
              <FormControl sx={{ marginTop: 3, width: "100%" }}>
                <InputLabel htmlFor="my-input">Referencia</InputLabel>
                <Input
                  fullWidth
                  onChange={(e) => handleChangeReference(e)}
                  value={state.referencia}
                  disabled
                  id="referencia"
                  name="referencia"
                  aria-describedby="my-helper-text"
                  required
                />
              </FormControl>
            </Grid>
            {/* MONTO */}
            <Grid align="right" item xs={12} sm={12} md={6}>
              <FormControl
                sx={{ marginTop: 3, width: "100%" }}
                error={Boolean(error)}
              >
                <InputLabel htmlFor="my-input">Monto</InputLabel>
                <Input
                  fullWidth
                  onChange={handleChangeMonto}
                  value={state.displayMonto}
                  id="monto"
                  name="monto"
                  aria-describedby="my-helper-text"
                  inputProps={{ maxLength: 10 }}
                  disabled
                  required
                />
              </FormControl>
              {error && (
                <FormHelperText className="text-danger" id="monto-helper-text">
                  {error}
                </FormHelperText>
              )}
            </Grid>
            {/* NUMERO DE TARJETA */}
            <Grid align="right" item xs={12} sm={12} md={6}>
              <FormControl sx={{ marginTop: 3, width: "100%" }}>
                <InputLabel htmlFor="my-input">Número de tarjeta</InputLabel>
                <Input
                  fullWidth
                  value={maskCardNumber(state.noTarjeta)}
                  disabled
                  id="noTarjeta"
                  name="noTarjeta"
                  aria-describedby="my-helper-text"
                  required
                />
              </FormControl>
            </Grid>
            {/* NOMBRE TARJETAHABIENTE */}
            <Grid align="right" item xs={12} sm={12} md={6}>
              <FormControl sx={{ marginTop: 3, width: "100%" }}>
                <InputLabel htmlFor="my-input">
                  Nombre tarjetahabiente
                </InputLabel>
                <Input
                  fullWidth
                  value={state.nombreTarjetaHabiente}
                  disabled
                  id="nombreTarjetaHabiente"
                  name="nombreTarjetaHabiente"
                  aria-describedby="my-helper-text"
                  required
                />
              </FormControl>
            </Grid>
            {/* FECHA DE VENCIMIENTO */}
            <Grid align="right" item xs={12} sm={12} md={6}>
              <FormControl sx={{ marginTop: 3, width: "100%" }}>
                <InputLabel htmlFor="my-input">Fecha de vencimiento</InputLabel>
                <Input
                  fullWidth
                  value={state.fechaVencimiento}
                  disabled
                  id="fechaVencimiento"
                  name="fechaVencimiento"
                  aria-describedby="my-helper-text"
                  required
                />
              </FormControl>
            </Grid>
            {/* TIPO DE PAGO */}
            <Grid align="right" item xs={12} sm={12} md={6}>
              <FormControl sx={{ marginTop: 3, width: "100%" }}>
                <InputLabel htmlFor="my-input">Tipo de pago</InputLabel>
                <Input
                  fullWidth
                  disabled
                  value={state.tipoPago}
                  id="tipoPago"
                  name="tipoPago"
                  aria-describedby="my-helper-text"
                  required
                />
              </FormControl>
            </Grid>
          </Grid>
          <div className="d-flex flex-column flex-md-row justify-content-end">
            {/* BOTON LEER CHIP */}
            <ColorButton
              className={`mt-5 text-white ${
                state.readChip ? "opacity-25" : null
              }`}
              sx={{
                marginRight: "6px",
              }}
              variant="outlined"
              type="submit"
              disabled={state.readChip}
              endIcon={<CheckIcon />}
              onClick={() => leer(state.monto)}
            >
              Leer Chip / Banda
            </ColorButton>
            {/* BOTON COBRAR */}
            <ColorButton
              className={`mt-5 ml-2 text-white ${
                state.cobrar ? "opacity-25" : null
              }`}
              sx={{
                marginRight: "6px",
              }}
              variant="outlined"
              type="submit"
              disabled={state.cobrar}
              endIcon={<AttachMoneyIcon />}
              onClick={venta}
            >
              Cobrar
            </ColorButton>
            {/* BOTON REIMPRIMIR TICKET */}
            <ColorButton
              className={`mt-5 text-white ${
                state.rePrint ? "opacity-25" : null
              }`}
              variant="outlined"
              type="submit"
              disabled={state.rePrint}
              endIcon={<PrintIcon />}
              onClick={() => rePrintVoucher(state.folio)}
            >
              Reimprimir Ticket
            </ColorButton>
          </div>
        </div>
      </div>
      {/* Modal afiliaciones */}
      <Modal
        open={state.showmodalAfiliaciones}
        onClose={() => closeModal("afiliacion")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <h2 className="mb-4">Selecciona la afiliación</h2>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleChangeAfiliacion}
            >
              {state.contado?.af && (
                <>
                  <FormLabel component="legend">Pago de contado</FormLabel>
                  <FormControlLabel
                    value={state.contado?.af?.merchant}
                    control={
                      <Radio
                        onChange={() => {
                          setStateButton(true);
                        }}
                        inputProps={{ "data-custom": state.contado?.af?.desc }}
                      />
                    }
                    label={state.contado?.af?.desc}
                    required
                  />
                </>
              )}

              {state.msi?.af && (
                <>
                  <FormLabel component="legend">
                    Pago a Meses sin Intereses
                  </FormLabel>
                  {Array.isArray(state.msi.af) ? (
                    state.msi.af.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={item.merchant}
                        control={
                          <Radio
                            onChange={() => {
                              setStateButton(true);
                            }}
                            inputProps={{ "data-custom": item.desc }}
                          />
                        }
                        label={item.desc}
                        required
                      />
                    ))
                  ) : (
                    <FormControlLabel
                      value={state.msi.af.merchant}
                      control={
                        <Radio
                          onChange={() => {
                            setStateButton(true);
                          }}
                          inputProps={{ "data-custom": state.msi.af.desc }}
                        />
                      }
                      label={state.msi.af.desc}
                      required
                    />
                  )}
                </>
              )}
            </RadioGroup>
          </FormControl>

          {button ? (
            <ColorButton
              className="position-absolute end-0 bottom-0 mb-4 me-4"
              variant="outlined"
              onClick={() => closeModal("afiliacion")}
            >
              Aceptar
            </ColorButton>
          ) : (
            <p className="text-end fw-bold mb-0">
              Seleccione una afiliacion para continuar
            </p>
          )}
        </Box>
      </Modal>
    </>
  );
};
