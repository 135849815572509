import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import GifLoader from "../../components/ui/gifLoader";
import { FormControl, InputLabel, Input, FormHelperText } from "@mui/material";
import Slide from "@mui/material/Slide";
import { NotificacionContext } from "../../context/notificacion_context";
import { services } from "../../services/api";
import swal from "sweetalert";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { onlyNumberLinea } from "../../utils/inputsRules";

const ValidarLineaDeCaptura = () => {
  const { folio } = useParams();

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    Folio: folio === "Comprobante-Digital" ? "" : folio,
    FolioError: false,
  });
  const [openDialog, setopenDialog] = useState({
    data: [],
    openmodal: false,
  });

  const notification = useContext(NotificacionContext);

  const [checked, setchecked] = useState(false);
  const containerRef = useRef(null);

  const handleConsulta = async () => {
    if (state.Folio === "") {
      setState({ ...state, FolioError: true });
      notification.showNotification(
        "warning",
        "Campos Vacios",
        "Por favor rellene los campos",
        null,
        4
      );
    } else {
      setLoading(true);

      let recaptcha_token = "";
      await ReactRecaptcha3.getToken().then(
        (token) => {
          // console.log(token)

          recaptcha_token = token;
        },
        (error) => {
          console.log(error);
        }
      );

      let body = {
        linea_captura: state.Folio,
        recaptcha_token: recaptcha_token,
      };

      let apiValidar = await services(
        "POST",
        "comprobante/reimpresion/consulta",
        body
      );

      if (apiValidar.data.error) {
        notification.showNotification(
          "warning",
          "Alerta",
          apiValidar.data.error,
          null,
          4
        );
      } else if (apiValidar.status == 422) {
        swal({
          title: "Alerta",
          text: apiValidar.data,
          icon: "warning",
          button: "Aceptar",
        });
      } else {
        if (apiValidar.data.length <= 0) {
          swal({
            title: " Folio no valido",
            text: "Este trámite no está dado de alta, acercate a la Secretaría de Finanzas y Administración para que den de alta el nuevo trámite y poder usar el servicio",
            icon: "warning",
            button: "Aceptar",
          });
        } else {
          swal({
            title: apiValidar.data.mensaje,
            icon: "success",
            buttons: ["Cancelar", "Ir al comprobante"],
            confirmButton: true,
          }).then((goTO) => {
            if (goTO) {
              window.open(apiValidar.data.url);
            }
          });
        }

        setState({ ...state, FolioError: false });
      }
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
        (status) => {}
      );

      if (folio === "Comprobante-Digital") {
      } else {
        handleConsulta();
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const limpiarform = () => {
    setState({ Folio: "", FolioError: false });
  };

  return (
    <>
      {loading && <GifLoader />}
      <div className="vh-100 w-75 mx-auto">
        <div
          style={{ overflow: "hidden" }}
          ref={containerRef}
          className="h-75 d-flex justify-content-center card m-3 text-center"
        >
          {!checked && (
            <Slide
              direction="right"
              in={!checked}
              container={containerRef.current}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h3 className="content_title">
                  Verificador de Comprobantes Digitales De Pago
                </h3>
                <FormControl
                  sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                >
                  <InputLabel htmlFor="my-input">Línea De Captura</InputLabel>
                  <Input
                    error={state.FolioError}
                    value={state.Folio}
                    onChange={(event) => {
                      setState({ ...state, Folio: event.target.value });
                    }}
                    onKeyPress={onlyNumberLinea}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    id="folio"
                    name="folio-input"
                    aria-describedby="ingresa tu folio aqui"
                  />
                  <FormHelperText id="my-helper-text">
                    Ingresa tu línea De captura.
                  </FormHelperText>
                </FormControl>

                <div
                  className="cta cta--guinda mt-2"
                  onClick={() => {
                    handleConsulta();
                  }}
                >
                  Verificar Línea De Captura
                </div>
              </div>
            </Slide>
          )}
        </div>
      </div>
    </>
  );
};

export default ValidarLineaDeCaptura;
