import {useState, useRef, useContext } from 'react'
import GifLoader from '../components/ui/gifLoader'
import { FormControl, InputLabel, Input, FormHelperText, Dialog} from '@mui/material';
import Slide from '@mui/material/Slide';
import { services } from '../services/api';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { NotificacionContext } from '../context/notificacion_context';
import { TextField } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import imgInfo from '../assets/img/IMG.png'

function CertificadoVehicularView() {
  const notification = useContext(NotificacionContext);
  const [loading, setLoading] = useState(false);
  const [openDialog, setopenDialog] = useState(false)
  const [state, setState] = useState({
    "concesion":"",
    "placa":"",
    "serie":"",
    email:""
  });
  const [response, setResponse] = useState({
    estatus:"",
    "NOMBRE": "",
    "RFC": "",
    "rfc":"",
    "comercial":"",
    "contrato":"",
    "modelo": "" 
  });
  const [bodyLinea, setbodyLinea] = useState({
    email: "",
    rfc: "",
    nombre: ""
  })

  const handleBody = ({target}) =>{
    let {value, name} = target;
    setState({...state, [name]: value.toUpperCase()})
  }

  const handleRFCBody = ({target}) =>{
    let {value, name} = target;
    setbodyLinea({...bodyLinea, [name]: value.toUpperCase()})
  }

  const [checked, setchecked] = useState(false);
  const containerRef = useRef(null)
  const handleConsulta = async() => {
    if( state.concesion === "" || state.placa === "" || state.serie === ""){
      notification.showNotification("warning", "Campos Vacíos", "Por favor rellene todos los campos requeridos", null, 4);
    }else{
      setLoading(true)
      let body = {
        "concesion": state.concesion.toUpperCase(),
        "placa": state.placa.toUpperCase(),
        "serie":  state.serie.toUpperCase(),
        
      }
      let apiVehicular = await services('POST', "vehicular/concesion/consulta" ,body)
      // console.log(apiVehicular)
      if(apiVehicular.data.error){
          notification.showNotification("warning", "Error", apiVehicular.data.error, null, 4);
      }
      else if(apiVehicular.data.create === 'false'){
        notification.showNotification("error", "Erroe", apiVehicular.data.error, null, 4);
      }else{
          
            setResponse(apiVehicular.data)
          
          setchecked(true);
      }
      setLoading(false)
      // setLoading(true)
    }

    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }

  const generateLinea = async () =>{
    setLoading(true)
    let body = {
      ...state
    }
    let apiGenerar = await services("POST", "vehicular/concesion/generaCertificado", body);
    // console.log(apiGenerar)
    setLoading(false)
    let base64Doc = apiGenerar.data.certificado
  
    if(apiGenerar.data){
    downloadPDF(base64Doc, "Solicitud_de_impuesto_estatal", null, "pdf")
      setopenDialog(false)
      swal("En breve se descargara tu constancia", {
        buttons: {
            linea: "Cerrar."
        }
    }).then((result) => {
        // window.location = apiGenerar.data?.intento;
    })
    }else{
      notification.showNotification("error", "Certificado", "Hubo un error, intente más tarde", null, 4);
    }
  }

  const handleIntento = async() => {
    // if(response.estatus === 1){
    //   window.open( response.intento)
    // }else if(response.estatus === 2){
    //   downloadCertificado()
    // }
    setLoading(true)
    let body = {
      "tp_cuenta": value === 0 ? "03" : "04",
      "rfc": state.rfc,
      "interlocutor":  state.comercial,
      "email": state.email
    }
    let apiVehicular = await services('POST', "nomina/generaConstancia" ,body)
    // console.log(apiVehicular)
    if(apiVehicular.data.constancia){
      notification.showNotification("success", "Listo", "Tu Documento se descargara en breve", null, 4);
      let base64Doc = apiVehicular.data.constancia
      downloadPDF(base64Doc, "Solicitud_de_impuesto_estatal", null, "pdf")
        
    }
    setLoading(false)
  }

const downloadPDF = (documentLink, name, callback, extension) => {
    try {
        const linkSource = `data:application/${extension};base64,${documentLink}`
        const downloadLink = document.createElement("a")
        const fileName = `${name}.${extension}`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        if (callback !== null) {
            callback()
        }
    } catch (ex) {
        console.log(ex)
    }
}

const [value, setValue] = useState(0);

const handleChange = (event, newValue) => {
    setValue(newValue);
};

const [openImgInfo, setopenImgInfo ] = useState(false)

const a11yProps = (index) => {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

const handleImage = () =>{
  setopenImgInfo(true)
  // let image = document.createElement('a')
  // image.setAttribute('href', imgInfo )
  // image.setAttribute('target', '_blank')
  // image.click()
}
  return (
    <>
      {loading &&
        <GifLoader />
      }
      <div className="vh-100 w-75 mx-auto" >
        <div style={{ overflow: "hidden" }} ref={containerRef} className="h-75 d-flex justify-content-center card m-3 text-center" >
        {/* {!checked &&
        <div className=' text-big mb-2 text-bold'>
                    
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} centered aria-label="basic tabs example">
                                <Tab label="Nómina" {...a11yProps(0)} />
                                <Tab label="Hospedaje" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

        </div>
        } */}
          {!checked &&
            <div className="my-auto">

            {value === 0 &&
              <Slide direction="right" in={value === 0} container={containerRef.current}>
                
                <div className='d-flex flex-column align-items-center justify-content-center'>
                  <h3 className='content_title'>Solicitud de Constacia de No Adeudo Concesiones</h3>


                  <FormControl sx={{ marginTop: 3, width: 400, maxWidth: '100%' }}>
                    <InputLabel htmlFor="my-input">Concesión</InputLabel>
                    <Input  onChange={(event) => {
                      setState({ ...state, concesion: event.target.value });
                    }} onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}  inputProps={{ style: { textTransform: "uppercase" }, maxLength:18 }} id="nombre" name="nombre" aria-describedby="my-helper-text" />
                    <FormHelperText id="my-helper-text">Ingresa tu número de concesión.</FormHelperText>
                  </FormControl>


                  <FormControl sx={{ marginTop: 3, width: 400, maxWidth: '100%' }}>
                    <InputLabel htmlFor="my-input">Placa</InputLabel>
                    <Input  onChange={(event) => {
                      setState({ ...state, placa: event.target.value });
                    }} onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}  inputProps={{ style: { textTransform: "uppercase" }}} id="nombre" name="nombre" aria-describedby="my-helper-text" />
                    <FormHelperText id="my-helper-text">Ingresa tu número de placa.</FormHelperText>
                  </FormControl>
                  
                  <FormControl sx={{ marginTop: 3, width: 400, maxWidth: '100%' }}>
                    <InputLabel htmlFor="my-input">Serie</InputLabel>
                    <Input  onChange={(event) => {
                      setState({ ...state, serie: event.target.value });
                    }} onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}  id="nombre" name="nombre" aria-describedby="my-helper-text" />
                    <FormHelperText id="my-helper-text">Ingresa tu número de serie.</FormHelperText>
                  </FormControl>

                  <div className='cta cta--guinda mt-2' onClick={() => { handleConsulta() }}>Consultar</div>
                </div>
              </Slide>

            }
            {/* {value === 1 &&
              <Slide direction="left" in={value === 1} container={containerRef.current}>
                
                <div className='d-flex flex-column align-items-center justify-content-center'>
                  <h3 className='content_title'>Solicitud del Impuesto Estatal Sobre Hospedaje</h3>

                  <FormControl sx={{ marginTop: 3, width: 400, maxWidth: '100%' }}>
                    <InputLabel htmlFor="my-input">RFC</InputLabel>
                    <Input error={state.serie} onChange={(event) => {
                      setState({ ...state, rfc: event.target.value });
                    }} onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}  inputProps={{ style: { textTransform: "uppercase" }, maxLength:18 }} id="nombre" name="nombre" aria-describedby="my-helper-text" />
                    <FormHelperText id="my-helper-text">Ingresa tu RFC.</FormHelperText>
                  </FormControl>


                  <FormControl sx={{ marginTop: 3, width: 400, maxWidth: '100%' }}>
                    <InputLabel htmlFor="my-input">Interlocutor Comercial</InputLabel>
                    <Input error={state.placa} onChange={(event) => {
                      setState({ ...state, comercial: event.target.value });
                    }} onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }} endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title="¿De dónde saco este dato? Haz clic en el icono">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleImage}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                         <HelpOutlineIcon />
                        </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    } inputProps={{ style: { textTransform: "uppercase" }}} id="nombre" name="nombre" aria-describedby="my-helper-text" />
                    <FormHelperText id="my-helper-text">Ingresa tu Interlocutor Comercial.</FormHelperText>
                  </FormControl>
                  
                  <FormControl sx={{ marginTop: 3, width: 400, maxWidth: '100%' }}>
                    <InputLabel htmlFor="my-input">Correo Electronico</InputLabel>
                    <Input error={state.email} onChange={(event) => {
                      setState({ ...state, email: event.target.value });
                    }} onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}  id="nombre" name="nombre" aria-describedby="my-helper-text" />
                    <FormHelperText id="my-helper-text">Ingresa tu correo electronico.</FormHelperText>
                  </FormControl>

                  <div className='cta cta--guinda mt-2' onClick={() => { handleConsulta() }}>Consultar</div>
                </div>
              </Slide>

            } */}
            </div>
          }
        
          {checked &&
            <Slide direction="left" in={checked} container={containerRef.current}>
            <div className='d-flex flex-column align-items-center justify-content-center'>
            
                 <div className='subContainer p-3'>
                  <h3>Datos del Contribuyente</h3>
                  <div className='d-flex flex-wrap mt-2 justify-content-center'>
                    <span className='m-3'>
                      Numero de concesión: <strong>{response?.CONCESION}</strong>
                    </span>
                    <span className='m-3'>
                     Modalidad: <strong> {response?.MODALIDAD}</strong>
                    </span>
                   
                  </div>
                 
                 
                  <div className='d-flex mt-2 justify-content-center'>
                    <div className='cta cta--guinda mx-2' onClick={() => { setchecked(false) }}>Volver a Consultar </div>
                    <div className='cta cta--guinda ' onClick={() => { setopenDialog(true) }}>Generar Constancia</div>
                  </div>
  
                </div>
              
             
              </div>
            </Slide>
          }
        </div>
      </div>
      <Dialog
                open={openImgInfo}
                onClose={() => setopenImgInfo(false)}
                aria-labelledby="alert-dialog-title"
                maxWidth="lg"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Como identificar el interlocutor comercial</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <img style={{width:"100%", height:"100%"}} src={imgInfo} alt="info" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={()=> setopenImgInfo(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog>
      <Dialog
          open={openDialog}
          onClose={() => setopenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Generar Línea de Captura</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Para generar un línea de Captura, necesitamos que nos proporciones un correo electrínico
                    </DialogContentText>
                    <TextField
                        fullWidth
                        label="Email"
                        size='small'
                        variant="standard"
                        value={state.email}
                        name="email"
                        onChange={handleBody}
                    />
                    
                    <small>*Verifica que tu informacion este bien</small>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={()=> setopenDialog(false)}>Cancelar</Button>
                    <Button onClick={()=> generateLinea() } >
                        Generar
                    </Button>
                </DialogActions>
            </Dialog>
    </>
  )
}

export default CertificadoVehicularView