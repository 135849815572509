import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import IconUI from "../components/ui/icon_ui";
import { BsBuilding } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { FcCancel } from "react-icons/fc";
import MUIDataTable from "mui-datatables";
import { services } from "../services/api";
import { NotificacionContext } from "../context/notificacion_context";
import CapturaContext from "../context/captura_context/capturaContext";
import PopUpDependenciasCategorias from "./ui/popUpDependenciasCategorias";
import { AiOutlinePlusCircle } from "react-icons/ai";
import GifLoader from "../components/ui/gifLoader.js";

const options = {
  selectableRows: false,
  tableBodyHeight: "auto",
  textLabels: {
    body: {
      noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
      toolTip: "Clasificar",
      columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
    },
    pagination: {
      next: "Siguiente",
      previous: "Anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    filter: {
      all: "Todo",
      title: "Filtros",
      reset: "Restaurar",
    },
    viewColumns: {
      title: "Ver columnas",
      titleAria: "Mostrar/Ocultar columnas en la tabla",
    },
    selectedRows: {
      text: "columna(s) selecciona(as)",
      delete: "Eliminar",
      deleteAria: "Eliminar filas seleccionadas",
    },
  },
  responsive: "standard",
  downloadOptions: {
    filename: "tablaUsuarios.csv",
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: false,
    },
  },
};

const Crud = (props) => {
  const { deletDatosTabla } = useContext(CapturaContext);

  const [state, setState] = useState({
    showPopUp: false,
    idDependenciaSelected: "",
  });

  const { showNotification } = useContext(NotificacionContext);

  const [loader, setLoader] = useState(false);

  const habilitarUser = async (body) => {
    let eliminarUsuario = await services(
      "DELETE",
      `dependencia/eliminar/${body.id}`
    );
    if (eliminarUsuario.status === 200) {
      showNotification(
        "success",
        "Acción Exitosa!",
        "La operación se realizó correctamente",
        null,
        4
      );
      let dataDependenciasApi = await services(
        "GET",
        `dependencia/listar`,
        null
      );

      deletDatosTabla(dataDependenciasApi.data);
    } else {
      showNotification(
        "error",
        "Acción No Exitosa!",
        "La operación no se realizó correctamente",
        null,
        4
      );
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let id = props.dataUsers[dataIndex].id;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {id}
            </div>
          );
        },
      },
    },
    {
      name: "claveDependencia",
      label: "Clave Dependencia",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let claveDependencia = props.dataUsers[dataIndex].claveDependencia;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {claveDependencia}
            </div>
          );
        },
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = props.dataUsers[dataIndex].nombre;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    // {
    //   name: "gratuito",
    //   label: "Gratuito",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRenderLite: (dataIndex, i) => (
    //       <>
    //         {props.dataUsers[dataIndex].gratuito == 'Si' ?
    //           <div style={{ height: '60px' }} className={i % 2 === 0 ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro " : "w-100 d-flex justify-content-center align-items-center "}>
    //             <div style={{ height: 30, width: 30, zIndex: 0, cursor: 'default' }} className='cta cta--success cta--icon '>
    //               <IconUI size={'20px'} color={'white'} ><AiOutlineCheckCircle /></IconUI>
    //             </div>
    //           </div>
    //           :
    //           <div className={i % 2 === 0 ? "w-100 d-flex justify-content-center p-3 bg-rosaClaro" : "w-100 d-flex justify-content-center p-3"}>
    //             <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5, cursor: 'default' }} className='cta cta--danger cta--icon '>
    //               <IconUI size={'15px'} color={'white'}><AiOutlineCloseCircle /></IconUI>
    //             </div>
    //           </div>
    //         }
    //       </>
    //     )
    //   }
    // },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            {props.dataUsers[dataIndex].fechaBaja === null ? (
              <div
                style={{ height: "60px" }}
                className={
                  i % 2 === 0
                    ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                    : "w-100 d-flex justify-content-center align-items-center "
                }
              >
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    props.showPopUpUpdateOnView(props.dataUsers[dataIndex]);
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <AiOutlineEdit />
                  </IconUI>
                  <div className="icon_btn_help">Editar</div>
                </div>
                <div
                  style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }}
                  className="cta cta--warning cta--icon icon_btn"
                  onClick={() => {
                    showNotification(
                      "option",
                      "¿Estás seguro de realizar esta acción?",
                      "Al dar click en Aceptar se habilitará esta dependencia",
                      () => {
                        let body = {
                          curp: props.dataUsers[dataIndex].curp,
                        };

                        habilitarUser(body);
                      },
                      null
                    );
                  }}
                >
                  <IconUI size={"20px"} color={"black"}>
                    <FcCancel />
                  </IconUI>
                  <div className="icon_btn_help">Bloquear</div>
                </div>
              </div>
            ) : (
              <div
                className={
                  i % 2 === 0
                    ? "w-100 d-flex justify-content-center p-3 bg-rosaClaro"
                    : "w-100 d-flex justify-content-center p-3"
                }
              >
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--primary cta--icon icon_btn"
                  onClick={() => {
                    props.showPopUpUpdateOnView(props.dataUsers[dataIndex]);
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <AiOutlineEdit />
                  </IconUI>
                  <div className="icon_btn_help">Editar</div>
                </div>
                {/* <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }} className='cta cta--danger cta--icon icon_btn' onClick={
                  () => {
                    showNotification("option", "Estás seguro de realizar esta acción?", "Al dar click en Aceptar se inhabilitará esta dependencia", () => {
                      let body = {
                        'id': props.dataUsers[dataIndex].id,
                        'claveDependencia': props.dataUsers[dataIndex].claveDependencia,
                        'nombre': props.dataUsers[dataIndex].nombre,
                      }

                      habilitarUser(body)
                    }, null)
                  }
                }>
                  <IconUI size={'15px'} color={'white'}><AiOutlineDelete /></IconUI>
                  <div className='icon_btn_help'>Eliminar</div>
                </div> */}
                <div
                  style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }}
                  className="cta cta--guinda cta--icon icon_btn"
                  onClick={() => {
                    setState({
                      ...state,
                      showPopUp: true,
                      idDependenciaSelected: props.dataUsers[dataIndex],
                    });
                  }}
                >
                  <IconUI size={"15px"} color={"white"}>
                    <AiOutlinePlusCircle />
                  </IconUI>
                  <div className="icon_btn_help">Agregar</div>
                </div>
              </div>
            )}
          </>
        ),
      },
    },
  ];

  return (
    <>
      {loader ? <GifLoader></GifLoader> : null}
      {state.showPopUp ? (
        <PopUpDependenciasCategorias
          data={state.idDependenciaSelected}
          onClose={() => {
            setState({ ...state, showPopUp: false });
          }}
        ></PopUpDependenciasCategorias>
      ) : null}
      <div className="container">
        <Grid sx={{ marginTop: 3 }} container spacing={2}>
          <Grid align="center" item xs={12} sm={6} md={6}></Grid>
          <Grid align="right" item xs={12} sm={6} md={6}>
            <div
              style={{ width: 200 }}
              className="cta cta--guinda"
              onClick={() => {
                props.showPopUpOnView();
              }}
            >
              <div className="me-2">
                <IconUI size={"20px"} color={"white"}>
                  <BsBuilding />
                </IconUI>
              </div>{" "}
              Agregar
            </div>
          </Grid>
        </Grid>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <Grid className="container mt-5 mb-5">
          <Grid item xs={8}>
            <MUIDataTable
              style={{ Overflow: "hidden" }}
              title={"Lista de Dependencias"}
              data={props.dataUsers}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Crud;
