export const blue = "#1946bb"
export const purple = "#6A0F49"
export const lightBlue = "#4d75f2"
export const white = "#FFFFFF"
export const green = "#198754"
export const gray00 = "#F5F6F7"
export const gray05 = "#F2F3F5"
export const gray10 = "#EBEDF0"
export const gray20 = "#DADDE1"
export const gray25 = "#CCD0D5"
export const gray30 = "#BEC3C9"
export const gray45 = "#8D949E"
export const gray70 = "#606770"
export const gray80 = "#444950"
export const gray90 = "#303338"
export const gray100 = "#1C1E21"
export const lightOrange = "#ffc107"
export const orange = "#fc7700"
export const red = "#dc3545"
export const yellow = "#EEC610"