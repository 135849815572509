import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useParams } from "react-router-dom";
import { services } from "../services/api";
import swal from "sweetalert";
import GifLoader from "../components/ui/gifLoader.js";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import logo4 from "../assets/img/logo4.png";

import ReactRecaptcha3 from "react-google-recaptcha3";
import { NotificacionContext } from "../context/notificacion_context";

const colorUI = "#6f1d46";
// const colorUI="#8d949e";

const CardFile = styled(Card)(({ theme }) => ({
  height: "200px",
  width: "250px",
  margin: "auto",
  marginTop: "20px",
  borderRadius: "12px",
  boxShadow: "1px 0px 7px 0px rgba(0, 0, 0, 0.75)",
  "& .MuiCardContent-root": {
    // borderColor: '#6A0F49',
    borderColor: "#8d949e",
    borderStyle: "dotted",
    height: "80%",
    margin: "20px 20px 20px 20px",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const RegistroCedulasProvisionales = () => {
  const { showNotification } = useContext(NotificacionContext);

  const { folio } = useParams();
  // console.log(folio, "folio paramatros");
  const [fecha_tit, setFechaTit] = useState(dayjs(new Date()));
  const [correo_dis, setCorreoDis] = useState(false);
  const [fecha_exp, setFechaExp] = useState(dayjs(new Date()));
  // const [fecha_naci, setFechaNaci] = useState(dayjs(new Date()));
  const [fecha_naci, setFechaNaci] = useState(null);
  const [curpsRegistradas, setCurpsRegistradas] = useState([]);
  const [foreigner, setForeigner] = useState(false);

  const [form, setForm] = useState({
    curp: "",
    extranjero: 0,
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    calle: "",
    id_colonia: "",
    colonia: "",
    id_entidad_federativa: "",
    entidad_federativa: "",
    telefono: "",
    fecha_nacimiento: dayjs(fecha_naci).format("YYYY-MM-DD"),
    correo: "",
    codigo_postal: "",
    institucion_educativa: "",
    selectOpcion: "",
    numero_cedula: "",
    fecha_examen: dayjs(fecha_tit).format("YYYY-MM-DD"),
    // fecha_expedicion: dayjs(fecha_exp).format('YYYY-MM-DD'),
    numero: "",
    municipio: "",
    id_localidad: "",
    localidad: "",
    sexo: "",
    lugar_nacimiento: "",
    municipio_donde_labora: "",
    calle_laboral: "",
    numero_laboral: "",
    telefono_laboral: "",
    correo_laboral: "",
    carrera: "",
    profesion: "",
    numero_autorizacion: "",
    numero_expediente: "",
    libro: "",
    foja: "",
    identificacion_archivo_actual: "",
    identificacion_archivo: "",
    nombre_identificacion_archivo: "Identificación Oficial",
    acta_nacimiento_archivo: "",
    nombre_acta_archivo: "Acta de nacimiento",
    acta_archivo_actual: "",
    acta_examen_archivo: "",
    nombre_examen_archivo: "Acta de examen Profesional",
    examen_archivo_actual: "",
    constancia_servicio_social_archivo: "",
    nombre_servicio_archivo: "Constancia de servicio social",
    servicio_archivo_actual: "",
    fotografia_infantil_archivo: "",
    nombre_fotografia_archivo: "Fotografía Infantil",
    fotografia_archivo_actual: "",
    curp_archivo: "",
    nombre_curp_archivo: "Curp",
    curp_archivo_actual: "",
    trabaja_actualmente: "",
    sector_trabajo: "",
    selected: 1,
    selected2: 1,
  });

  const [localidades, setLocalidades] = useState([]);
  const [colonias, setColonias] = useState([]);
  const [profesiones, setProfesiones] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  const formData = new FormData();

  const [errors, setErrors] = useState({});
  const [observaciones, setObservaciones] = useState({});

  const [loader, setLoader] = useState({
    loading: false,
  });

  // Documentos
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const keyFiles = [
    { key: "ActaNacimiento", name: "Acta de Nacimiento" },
    { key: "Curp", name: "Archivo CURP" },
    { key: "ServicioSocial", name: "Constancia de Servicio Social" },
    { key: "ActaExamenProfesional", name: "Acta de Examen Profesional" },
  ];
  // { key: "IdentificacionOficial", name: "Identificación Oficial" },
  const KeyFilesPNG = [
    { key: "FotografiaInfantil", name: "Fotografía Infantil" },
  ];

  const [Files, setFiles] = useState({
    IdentificacionOficial: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    ActaNacimiento: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    FotografiaInfantil: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    ServicioSocial: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    ActaExamenProfesional: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    Curp: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChangeFiles = (name, file) => {
    setFiles({
      ...Files,
      [name]: {
        ...Files[name],
        previewicono: URL.createObjectURL(file),
        frameicono: false,
        Archivo: file,
      },
    });
  };

  const handleDragOverFather = (event) => {
    event.preventDefault();
  };

  const handleDragEnterFather = (event, card) => {
    event.preventDefault();
    setIsDraggingOver(true);
    if (card == "file") {
      event.currentTarget.style.border = `2px dashed ${colorUI}`;
      event.currentTarget.style.filter = "blur(1px)";
    } else if (card == "content") {
    }
  };

  const handleDragLeaveFather = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (!isDraggingOver) {
      event.currentTarget.style.border = "none";
      event.currentTarget.style.filter = "none";
    }
  };

  const handleDropFather = (event, name) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const file = event.dataTransfer.files[0];

    handleChangeFiles(name, file);

    event.currentTarget.style.border = "";
    event.currentTarget.style.filter = "";
  };

  const handleEliminarDoc = (key) => {
    swal({
      icon: "warning",
      title: "Alerta",
      text: `Si elimina este archivo, tendrá que seleccionar uno distinto para poder guardar.`,
      buttons: ["Cancelar", "Aceptar"],
      confirmButton: true,
    }).then((result) => {
      if (result) {
        setFiles({
          ...Files,
          [key]: {
            previewicono: "",
            frameicono: true,
            Archivo: null,
          },
        });
      } else {
      }
    });
  };

  /// Fin documentos

  const handleChangeSexo = (e) => {
    setForm({
      ...form,
      sexo: e.target.value,
    });
  };

  const handleFechaExamen = (e) => {
    // const value = e.$id;
    // console.log(e, "evento")
    setFechaTit(e);
    setForm({
      ...form,
      // fecha_examen: dayjs(value).format('YYYY-MM-DD'),
      fecha_examen: dayjs(e).format("YYYY-MM-DD"),
    });
  };

  const handleFechaNacimiento = (e) => {
    // console.log(e, "evento")
    if (e.isAfter(dayjs())) {
      // Mostrar algún tipo de mensaje de error o manejar la situación según tu aplicación
      showNotification(
        "error",
        "¡Atención!",
        "La fecha de nacimiento no puede ser reciente",
        null,
        4
      );
      // Puedes decidir qué hacer en este caso, como resetear la fecha o dejarla como está
      // Por ejemplo, si quieres resetear la fecha:
      // setValue(null);
    } else {
      // La fecha seleccionada es válida, puedes proceder con el resto de la lógica
      setFechaNaci(e);
      setForm({
        ...form,
        // fecha_nacimiento: fecha,
        fecha_nacimiento: dayjs(e).format("YYYY-MM-DD"),
      });
    }
  };

  const handleChange = async (e) => {
    let { name, value } = e.target;
    let caracteresPermitidos = "";
    switch (name) {
      // case "curp":
      //   value = value.trim();
      //   value = value.toUpperCase();
      //   caracteresPermitidos = /^([A-Z\d]{18})$/;
      //   break;
      case "curp":
        value = value.trim().toUpperCase();
        caracteresPermitidos = /^[A-Z\d]{18}$/;
        break;
      case "codigo_postal":
        caracteresPermitidos = /^([0-9]{0,5})$/;
        break;
      case "numero":
        caracteresPermitidos = /^([0-9]{0,5})$/;
        break;
      case "numero_laboral":
        caracteresPermitidos = /^([0-9]{0,5})$/;
        break;
      case "telefono":
        caracteresPermitidos = /^([0-9]{0,10})$/;
        break;
      case "telefono_laboral":
        caracteresPermitidos = /^([0-9]{0,10})$/;
        break;
      case "numero_cedula":
        caracteresPermitidos = /^[a-zA-Z0-9]$/;
        break;
      case "correo":
        // caracteresPermitidos = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        caracteresPermitidos = /^[a-zA-Z0-9._%-@]*$/;
        break;
      case "correo_laboral":
        // caracteresPermitidos =
        //   /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        caracteresPermitidos = /^[a-zA-Z0-9._%+-@]*$/;
        break;
      case "calle":
        caracteresPermitidos = /^[a-zA-Z0-9\s.,'-]+$/;
        break;
      case "calle_laboral":
        caracteresPermitidos = /^[a-zA-Z0-9\s.,'-]+$/;
        break;

      // VALIDACION CARACTERES ESPECIALES - LUIS ADRIAN
      case "nombre":
        caracteresPermitidos = /^[a-zA-Z\s]*$/;
        break;
      case "apellido_paterno":
        caracteresPermitidos = /^[a-zA-Z\s]*$/;
        break;
      case "apellido_materno":
        caracteresPermitidos = /^[a-zA-Z\s]*$/;
        break;
      default:
        break;
    }

    // antes estaba asi
    // if (caracteresPermitidos && value && !caracteresPermitidos.test(value)) {
    //   setErrors({
    //     ...errors,
    //     [name]: "El valor contiene caracteres no permitidos",
    //   });
    //   return;
    // } else {
    //   let newErrors = { ...errors };
    //   delete newErrors[name];
    //   setErrors(newErrors);
    // }

    // Validación
    if (caracteresPermitidos && value && !caracteresPermitidos.test(value)) {
      if (name !== "telefono") {
        setErrors({
          ...errors,
          [name]: "El valor contiene caracteres no permitidos",
        });
        return;
      }
    } else {
      let newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }

    // NUEVO SETEO COLOCADO EN LA CHAMBA
    setForm({
      ...form,
      [name]: value,
    });

    if (name === "numero_cedula") {
      if (value.length < 21) {
        setForm({
          ...form,
          numero_cedula: value,
        });
        return;
      }
      return;
    }

    if (name === "curp") {
      if (curpsRegistradas.includes(value)) {
        setErrors({
          ...errors,
          curp: "Esta CURP ya está registrada",
        });
        return false;
      }
      if (caracteresPermitidos.test(value)) {
        if (value.length === 18) {
          setLoader({ loading: true });

          let recaptcha_token = "";

          await ReactRecaptcha3.getToken().then(
            (token) => {
              recaptcha_token = token;
            },
            (error) => {
              console.log(error);
            }
          );

          const dataCarpetas = await services(
            "POST",
            `catalogos/consultaCurp/${value}`,
            { recaptcha_token: recaptcha_token }
          );
          // console.log(dataCarpetas, "curp")
          if (dataCarpetas.status !== 404) {
            const newFecha = dataCarpetas.data.fechaN.replaceAll("/", "-");
            const string = newFecha.split("-");
            string[0] = parseInt(string[0]) + 1;
            const newDate = string[2] + "-" + string[1] + "-" + string[0];
            // console.log(newDate, "fecha curp")
            // console.log(new Date(newDate), "fecha curp")
            setFechaNaci(dayjs(new Date(newDate)));
            let newErrors = { ...errors }; // Hacemos una copia del state actual
            delete newErrors.curp; // Eliminamos el atributo 'curp'
            setErrors(newErrors);
            setCurpsRegistradas([...curpsRegistradas, value]);
            setLoader({ loading: false });

            setForm({
              ...form,
              curp: value,
              nombre: dataCarpetas.data.nombres,
              apellido_paterno: dataCarpetas.data.primerApellido,
              apellido_materno: dataCarpetas.data.segundoApellido,
              fecha_nacimiento: dayjs(new Date(newDate)).format("YYYY-MM-DD"),
            });
            showNotification(
              "success",
              "CURP Encontrada",
              "La CURP ha sido encontrada éxitosamete",
              null,
              4
            );

            return;
          } else {
            showNotification(
              "error",
              "CURP No encontrada",
              "No se ha podido encontrar la CURP",
              null,
              4
            );
            setForm({
              ...form,
              [name]: value,
              nombre: "",
              apellido_paterno: "",
              apellido_materno: "",
              fecha_nacimiento: dayjs(new Date()).format("YYYY-MM-DD"),
              //aqui compita
            });

            setFechaNaci(dayjs(new Date()));
            setErrors({
              ...errors,
              curp: dataCarpetas.mensaje,
            });
            setCurpsRegistradas((curps) =>
              curps.filter((curp) => curp !== value)
            ); // Elimina la CURP de las registradas si hay un error
          }
          setLoader({ loading: false });
          return;
        }
        setForm({
          ...form,
          [name]: value,
        });
      }
      setErrors({
        ...errors,
        curp: "El formato no es correcto",
      });
      return false;
    }

    if (name === "codigo_postal") {
      if (caracteresPermitidos.test(value)) {
        if (value.length === 5) {
          setLoader({ loading: true });

          try {
            const dataCarpetas = await services(
              "POST",
              "catalogos/codigoPostal",
              { cp: value }
            );

            if (dataCarpetas.status === 200) {
              setForm({
                ...form,
                codigo_postal: value,
                entidad_federativa: dataCarpetas.data.Estado[0].DESCRIPCION,
                id_entidad_federativa:
                  dataCarpetas.data.Estado[0].IDENTIFICADOR,
                municipio: dataCarpetas.data.Municipio[0].DESCRIPCION,
                id_municipio: dataCarpetas.data.Municipio[0].IDENTIFICADOR,
              });
              setLocalidades({
                ...localidades,
                localidades: dataCarpetas.data.Localidad,
              });
              setColonias({
                ...colonias,
                colonias: dataCarpetas.data.Colonia,
              });
            } else {
              showNotification(
                "error",
                "Error",
                dataCarpetas.data.error ||
                  "No se pudo obtener el código postal."
              );
            }
          } catch (error) {
            console.error("Error al consultar código postal:", error);
            showNotification(
              "error",
              "Error",
              "No se pudo consultar el código postal. Verifica tu conexión o inténtalo más tarde."
            );
          } finally {
            setLoader({ loading: false });
          }

          return;
        }
        setErrors({
          ...errors,
          codigo_postal: "Debe de tener 5 dígitos",
        });
        setForm({
          ...form,
          [name]: value,
        });
      }

      return false;
    }

    if (name === "numero_laboral") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
      }
      return;
    }

    if (name === "numero") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
      }
      return;
    }

    if (name === "telefono") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        if (value.length === 10) {
          let newErrors = { ...errors };
          delete newErrors.telefono;
          setErrors(newErrors);
        } else {
          setErrors({
            ...errors,
            telefono: "Colocar el número telefónico a 10 dígitos",
          });
        }
      }
      return;
    }

    if (name === "telefono_laboral") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        if (value.length === 10) {
          let newErrors = { ...errors };
          delete newErrors.telefono;
          setErrors(newErrors);
        } else {
          setErrors({
            ...errors,
            telefono_laboral: "El número telefonico debe tener 10 digitos",
          });
        }
      }
      return;
    }

    if (name === "correo") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        let newErrors = { ...errors };
        delete newErrors.correo;
        setErrors(newErrors);
        return;
      }
      setForm({
        ...form,
        [name]: value,
      });
      setErrors({
        ...errors,
        correo: "No cumple con el formato debido",
      });
      return;
    }

    if (name === "correo_laboral") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        let newErrors = { ...errors };
        delete newErrors.correo;
        setErrors(newErrors);
        return;
      }
      setForm({
        ...form,
        [name]: value,
      });
      setErrors({
        ...errors,
        correo: "No cumple con el formato debido",
      });
      return;
    }

    if (name === "correo_laboral") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        let newErrors = { ...errors };
        delete newErrors.correo;
        setErrors(newErrors);
        return;
      }
      setForm({
        ...form,
        [name]: value,
      });
      setErrors({
        ...errors,
        correo_laboral: "No cumple con el formato debido",
      });
      return;
    }

    if (name === "colonia") {
      const [identificador, descripcion] = value.split("-");
      setForm({
        ...form,
        id_colonia: identificador,
        colonia: descripcion,
      });
      return;
    }

    if (name === "localidad") {
      const [identificador, descripcion] = value.split("-");
      setForm({
        ...form,
        id_localidad: identificador,
        localidad: descripcion,
      });
      return;
    }

    if (name === "identificacion") {
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const obtenerCatalogos = async (e) => {
    const dataProfesiones = await services("POST", "catalogos/profesiones", {});
    const dataInstitusiones = await services(
      "POST",
      "catalogos/instituciones",
      {}
    );
    setProfesiones({
      ...profesiones,
      profesion: dataProfesiones.data[0],
    });
    setInstituciones({
      ...instituciones,
      instituciones: dataInstitusiones.data,
    });
  };

  // const miMetodoParaCambiarFechaAgnoMesDia = (fechaNacimiento) => {
  //     // Dividir la cadena en partes usando el guion "-"
  //     var partes = fechaNacimiento.split("-");
  //     // console.log(partes, "partes")
  //     var nuevaFecha;
  //     if (partes[2].length > 2) {
  //         nuevaFecha = partes[2] + "-" + partes[1] + "-" + partes[0];
  //     } else {
  //         nuevaFecha = partes[0] + "-" + partes[1] + "-" + partes[2];
  //     }
  //     // Reorganizar las partes en el nuevo formato
  //     // console.log(nuevaFecha, "nuevaFecha")

  //     return nuevaFecha;
  // }
  const guardar = async () => {
    // console.log('aaaaa', form.numero_laboral);

    setLoader({ loading: true });

    form.fecha_nacimiento = form.fecha_nacimiento.replace("/", "-");
    form.fecha_nacimiento = form.fecha_nacimiento.replace("/", "-");
    form.fecha_nacimiento = form.fecha_nacimiento.replace("/", "-");
    form.fecha_nacimiento = form.fecha_nacimiento.trim();

    formData.append("extranjero", form.extranjero);
    formData.append("curp", form.curp);
    formData.append("nombre", form.nombre);
    formData.append("apellido_Pat", form.apellido_paterno);
    formData.append("apellido_Mat", form.apellido_materno);
    // formData.append('fecha_nacimiento', fecha_naci);
    const fechaNacimiento = dayjs(form.fecha_nacimiento);
    if (!fechaNacimiento.isValid()) {
      swal(
        "¡Error!",
        "La fecha de nacimiento es requerida y debe ser válida.",
        "error"
      );
      setLoader({ loading: false });
      return;
    }
    formData.append("fecha_nacimiento", fechaNacimiento.format("YYYY-MM-DD"));

    formData.append("lugar_nacimiento", form.lugar_nacimiento);
    formData.append("sexo", form.sexo);
    formData.append("telefono", form.telefono);
    formData.append("calle", form.calle);
    formData.append("id_colonia", form.id_colonia);
    formData.append("colonia", form.colonia);
    formData.append("entidad_fede", form.entidad_federativa);
    formData.append("id_entidad_fede", form.id_entidad_federativa);
    // formData.append('telefono', form.telefono
    formData.append("email", form.correo);
    formData.append("codigo_Post", form.codigo_postal);

    // aqui me quede
    formData.append("institucion", form.institucion_educativa);
    formData.append("carrera", form.carrera);
    // formData.append('profesion', form.profesion)
    formData.append("id_localidad", form.id_localidad);
    formData.append("localidad", form.localidad);
    formData.append("fecha_examen_profesional", form.fecha_examen);
    formData.append("no_ext", form.numero);
    formData.append("municipio", form.municipio);
    formData.append("municipio_trabajo", form.municipio_donde_labora);
    formData.append("calle_trabajo", form.calle_laboral);
    formData.append("numero_ext_trabajo", form.numero_laboral);
    formData.append("telefono_trabajo", form.telefono_laboral);
    formData.append("correo_trabajo", form.correo_laboral);
    formData.append("numero_autorizacion", form.numero_autorizacion);
    formData.append("numero_expediente", form.numero_expediente);
    formData.append("foja", form.foja);
    formData.append("libro", form.libro);
    formData.append("trabajo", form.selected);
    formData.append("sector_trabajo", form.selected2);

    // console.log(form, "form data que le envio a luis")
    setLoader({ loading: false });
    if (folio) {
      // ACTA DE NACIMIENTO
      if (Files.ActaNacimiento.Archivo) {
        const actaFile = Files.ActaNacimiento.Archivo;
        if (actaFile && actaFile.size > 5 * 1024 * 1024) {
          swal(
            "¡Error!",
            "El archivo acta de nacimiento no debe superar los 5 MB.",
            "error"
          );
          return;
        }
        if (actaFile)
          formData.append(
            "acta_nacimiento_archivo",
            Files.ActaNacimiento.Archivo
          );
      }

      // FOTOGRACIA INFANTIL
      if (Files.FotografiaInfantil.Archivo) {
        const infantilFile = Files.FotografiaInfantil.Archivo;
        if (infantilFile && infantilFile.size > 5 * 1024 * 1024) {
          swal(
            "¡Error!",
            "El archivo fotografía infantil no debe superar los 5 MB.",
            "error"
          );
          return;
        }
        if (infantilFile)
          formData.append(
            "fotografia_archivo",
            Files.FotografiaInfantil.Archivo
          );
      }

      // CURP
      if (Files.Curp.Archivo) {
        const curpFile = Files.Curp.Archivo;
        if (curpFile && curpFile.size > 5 * 1024 * 1024) {
          swal(
            "¡Error!",
            "El archivo de CURP no debe superar los 5 MB.",
            "error"
          );
          return;
        }
        if (curpFile) formData.append("curp_archivo", curpFile);
      }

      // IDENTIFICACION
      if (Files.IdentificacionOficial.Archivo) {
        const identificacionFile = Files.IdentificacionOficial.Archivo;
        if (identificacionFile && identificacionFile.size > 5 * 1024 * 1024) {
          swal(
            "¡Error!",
            "El archivo de identificación oficial no debe superar los 5 MB.",
            "error"
          );
          return;
        }
        if (identificacionFile)
          formData.append("identificacion_archivo", identificacionFile);
      }

      // SERVICIO SOCIAL
      if (Files.ServicioSocial.Archivo) {
        const servicioFile = Files.ServicioSocial.Archivo;
        if (servicioFile && servicioFile.size > 5 * 1024 * 1024) {
          swal(
            "¡Error!",
            "El archivo de constancia de servicio social no debe superar los 5 MB.",
            "error"
          );
          return;
        }
        if (servicioFile)
          formData.append("CSS_archivo", Files.ServicioSocial.Archivo);
      }

      // ACTA DE EXAMEN PROFESIONAL
      if (Files.ActaExamenProfesional.Archivo) {
        const actaFile = Files.ActaExamenProfesional.Archivo;
        if (actaFile && actaFile > 5 * 1024 * 1024) {
          swal(
            "¡Error!",
            "El archivo de acta de examen Profesional no debe superar los 5 MB.",
            "error"
          );
          return;
        }
        if (actaFile)
          formData.append(
            "acta_examen_profesional_archivo",
            Files.ActaExamenProfesional.Archivo
          );
      }

      formData.append("folio", folio);
      // console.log(form, "form")

      let recaptcha_token = "";

      await ReactRecaptcha3.getToken().then(
        (token) => {
          // console.log(token);

          recaptcha_token = token;
        },
        (error) => {
          console.log(error);
        }
      );

      formData.append("recaptcha_token", recaptcha_token);

      const dataCarpetas = await services(
        "POST",
        "see/profesiones/actualizaRegistroCedulaProvisionalRechazado",
        formData
      );

      setLoader({ loading: false });
      if (dataCarpetas.status === 200) {
        if (dataCarpetas.data.error) {
          // window.location.href = '/';
          swal("¡Atención!", dataCarpetas.data.error, "error");
        } else {
          swal({
            icon: "success",
            title: "¡Éxito!",
            text: `${dataCarpetas.data.mensaje}`,
            confirmButtonText: "Aceptar",
          }).then((result) => {
            setForm({
              curp: "",
              nombre: "",
              apellido_paterno: "",
              apellido_materno: "",
              calle: "",
              id_colonia: "",
              colonia: "",
              id_entidad_federativa: "",
              entidad_federativa: "",
              telefono: "",
              fecha_nacimiento: form.fecha_nacimiento,
              correo: "",
              codigo_postal: "",
              institucion_educativa: "",
              selectOpcion: "",
              numero_cedula: "",
              fecha_examen: "",
              // fecha_expedicion: dayjs(fecha_exp).format('YYYY-MM-DD'),
              numero: "",
              municipio: "",
              id_localidad: "",
              localidad: "",
              sexo: "",
              lugar_nacimiento: "",
              municipio_donde_labora: "",
              calle_laboral: "",
              numero_laboral: "",
              telefono_laboral: "",
              correo_laboral: "",
              carrera: "",
              // profesion:"",
              numero_autorizacion: "",
              numero_expediente: "",
              libro: "",
              foja: "",
              identificacion_archivo_actual: "",
              identificacion_archivo: "",
              nombre_identificacion_archivo: "Identificación Oficial",
              acta_nacimiento_archivo: "",
              nombre_acta_archivo: "Acta de nacimiento",
              acta_archivo_actual: "",
              acta_examen_archivo: "",
              nombre_examen_archivo: "Acta de examen Profesional",
              examen_archivo_actual: "",
              constancia_servicio_social_archivo: "",
              nombre_servicio_archivo: "Constancia de servicio social",
              servicio_archivo_actual: "",
              fotografia_infantil_archivo: "",
              nombre_fotografia_archivo: "Fotografía Infantil",
              fotografia_archivo_actual: "",
              curp_archivo: "",
              nombre_curp_archivo: "Curp",
              curp_archivo_actual: "",
              trabaja_actualmente: "",
              sector: "",
              selected: "",
              selected2: "",
            });

            setFiles({
              IdentificacionOficial: {
                previewicono: "",
                frameicono: true,
                Archivo: null,
              },
              ActaNacimiento: {
                previewicono: "",
                frameicono: true,
                Archivo: null,
              },
              FotografiaInfantil: {
                previewicono: "",
                frameicono: true,
                Archivo: null,
              },
              ServicioSocial: {
                previewicono: "",
                frameicono: true,
                Archivo: null,
              },
              ActaExamenProfesional: {
                previewicono: "",
                frameicono: true,
                Archivo: null,
              },
              Curp: {
                previewicono: "",
                frameicono: true,
                Archivo: null,
              },
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 5000);
          });
        }
      } else if (dataCarpetas.status === 422) {
        swal("¡Error!", dataCarpetas.data, "error");
      } else {
        swal("¡Error!", dataCarpetas.error, "error");
      }
    } else {
      // DOCUMENTOS

      // ACTA DE NACIMIENTO
      const actaFile = Files.ActaNacimiento.Archivo;
      if (actaFile && actaFile.size > 5 * 1024 * 1024) {
        swal(
          "¡Error!",
          "El archivo acta de nacimiento no debe superar los 5 MB.",
          "error"
        );
        return;
      }
      if (actaFile) formData.append("acta_nacimiento_archivo", actaFile);

      // FOTOGRAFIA INFANTIL
      const infantilFile = Files.FotografiaInfantil.Archivo;
      if (infantilFile && infantilFile.size > 5 * 1024 * 1024) {
        swal(
          "¡Error!",
          "El archivo fotografía infantil no debe superar los 5 MB.",
          "error"
        );
        return;
      }
      if (infantilFile) formData.append("fotografia_archivo", infantilFile);

      // CURP
      const curpFile = Files.Curp.Archivo;
      if (curpFile && curpFile.size > 5 * 1024 * 1024) {
        swal(
          "¡Error!",
          "El archivo de CURP no debe superar los 5 MB.",
          "error"
        );
        return;
      }
      if (curpFile) formData.append("curp_archivo", curpFile);

      // IDENTIFICACION
      const identificacionFile = Files.IdentificacionOficial.Archivo;
      if (identificacionFile && identificacionFile.size > 5 * 1024 * 1024) {
        swal(
          "¡Error!",
          "El archivo de identificación oficial no debe superar los 5 MB.",
          "error"
        );
        return;
      }
      if (identificacionFile)
        formData.append("identificacion_archivo", identificacionFile);

      // SERVICIO SOCIAL
      const servicioFile = Files.ServicioSocial.Archivo;
      if (servicioFile && servicioFile.size > 5 * 1024 * 1024) {
        swal(
          "¡Error!",
          "El archivo de constancia de servicio social no debe superar los 5 MB.",
          "error"
        );
        return;
      }
      if (servicioFile) formData.append("CSS_archivo", servicioFile);

      // EXAMEN PROFESIONAL
      const examenFile = Files.ActaExamenProfesional.Archivo;
      if (examenFile && examenFile > 5 * 1024 * 1024) {
        swal(
          "¡Error!",
          "El archivo de acta de examen profesional no debe superar los 5 MB.",
          "error"
        );
        return;
      }
      if (examenFile)
        formData.append("acta_examen_profesional_archivo", examenFile);

      let recaptcha_token = "";

      await ReactRecaptcha3.getToken().then(
        (token) => {
          // console.log(token);

          recaptcha_token = token;
          // send token with form data
          // dataToSend.token = token
          // fetch(url, { method: 'POST', body: JSON.stringify(dataToSend) })
        },
        (error) => {
          // handle error here
          console.log(error);
        }
      );

      formData.append("recaptcha_token", recaptcha_token);

      const dataCarpetas = await services(
        "POST",
        "see/profesiones/registroCedulaProvisional",
        formData
      );

      setLoader({ loading: false });
      if (dataCarpetas.status === 200) {
        swal({
          icon: "success",
          title: "¡Éxito!",
          text: `${dataCarpetas.data.mensaje}`,
          confirmButtonText: "Aceptar",
        }).then((result) => {
          setForm({
            curp: "",
            nombre: "",
            apellido_paterno: "",
            apellido_materno: "",
            calle: "",
            id_colonia: "",
            colonia: "",
            id_entidad_federativa: "",
            entidad_federativa: "",
            telefono: "",
            fecha_nacimiento: form.fecha_nacimiento,
            correo: "",
            codigo_postal: "",
            institucion_educativa: "",
            selectOpcion: "",
            numero_cedula: "",
            fecha_examen: "",
            // fecha_expedicion: dayjs(fecha_exp).format('YYYY-MM-DD'),
            numero: "",
            municipio: "",
            id_localidad: "",
            localidad: "",
            sexo: "",
            lugar_nacimiento: "",
            municipio_donde_labora: "",
            calle_laboral: "",
            numero_laboral: "",
            telefono_laboral: "",
            correo_laboral: "",
            carrera: "",
            // profesion:"",
            numero_autorizacion: "",
            numero_expediente: "",
            libro: "",
            foja: "",
            identificacion_archivo_actual: "",
            identificacion_archivo: "",
            nombre_identificacion_archivo: "Identificación Oficial",
            acta_nacimiento_archivo: "",
            nombre_acta_archivo: "Acta de nacimiento",
            acta_archivo_actual: "",
            acta_examen_archivo: "",
            nombre_examen_archivo: "Acta de examen Profesional",
            examen_archivo_actual: "",
            constancia_servicio_social_archivo: "",
            nombre_servicio_archivo: "Constancia de servicio social",
            servicio_archivo_actual: "",
            fotografia_infantil_archivo: "",
            nombre_fotografia_archivo: "Fotografía Infantil",
            fotografia_archivo_actual: "",
            curp_archivo: "",
            nombre_curp_archivo: "Curp",
            curp_archivo_actual: "",
            trabaja_actualmente: "",
            sector: "",
            selected: "",
            selected2: "",
          });

          setFiles({
            IdentificacionOficial: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
            ActaNacimiento: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
            FotografiaInfantil: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
            ServicioSocial: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
            ActaExamenProfesional: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
            Curp: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        });
      } else if (dataCarpetas.status === 422) {
        swal("¡Error!", dataCarpetas.data, "error");
      } else {
        swal("¡Atención!", dataCarpetas.data, "error");
      }
    }
  };

  const obtDatMod = async () => {
    setLoader({ loading: true });
    const dataCarpetas = await services("POST", "solicitudes/detalle", {
      folio: folio,
    });
    // console.log(dataCarpetas, "obtDatos");

    if (dataCarpetas.data.estatus === "En cambio") {
      swal("¡Atención!", "Tu solicitud ya ha sido enviada.", "error");
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    }

    if (dataCarpetas.data.folio) {
      setForm({
        ...form,
        selectOpcion: {
          id: dataCarpetas.data.data.id_institucion,
          descripcion: `${dataCarpetas.data.data.clave_institucion}-${dataCarpetas.data.data.institucion}`,
        },
        curp: dataCarpetas.data.data.solicitante.curp,
        nombre: dataCarpetas.data.data.solicitante.nombres,
        apellido_paterno: dataCarpetas.data.data.solicitante.apellido_Pat,
        apellido_materno: dataCarpetas.data.data.solicitante.apellido_Mat,
        fecha_nacimiento: dataCarpetas.data.data.solicitante.fecha_nacimiento,

        telefono: dataCarpetas.data.data.solicitante.telefono,
        correo: dataCarpetas.data.data.solicitante.email,
        municipio: dataCarpetas.data.data.solicitante.municipio,
        correo_laboral: dataCarpetas.data.data.solicitante.trabajo.correo,
        calle: dataCarpetas.data.data.solicitante.calle,
        calle_laboral: dataCarpetas.data.data.solicitante.trabajo.calle,
        numero: dataCarpetas.data.data.solicitante.no_ext,
        codigo_postal: dataCarpetas.data.data.solicitante.codigo_Post,
        id_entidad_federativa:
          dataCarpetas.data.data.solicitante.id_entidad_fede,
        entidad_federativa: dataCarpetas.data.data.solicitante.entidad_fede,
        id_colonia: dataCarpetas.data.data.solicitante.id_colonia,
        colonia: dataCarpetas.data.data.solicitante.colonia,
        id_localidad: dataCarpetas.data.data.solicitante.id_localidad,
        localidad: dataCarpetas.data.data.solicitante.localidad,
        institucion_educativa: dataCarpetas.data.data.id_institucion,
        carrera: dataCarpetas.data.data.solicitante.carrera,
        numero_autorizacion: dataCarpetas.data.data.numero_autorizacion,
        numero_expediente: dataCarpetas.data.data.numero_expediente,
        foja: dataCarpetas.data.data.foja,
        libro: dataCarpetas.data.data.libro,
        fecha_examen: dataCarpetas.data.data.fecha_examen_profesional,
        lugar_nacimiento: dataCarpetas.data.data.solicitante.lugar_nacimiento,
        sexo: dataCarpetas.data.data.solicitante.sexo,

        telefono_laboral: dataCarpetas.data.data.solicitante.trabajo.telefono,
        numero_laboral: dataCarpetas.data.data.solicitante.trabajo.numero_ext,
        selected: dataCarpetas.data.data.solicitante.trabajo.trabajo,
        municipio_donde_labora:
          dataCarpetas.data.data.solicitante.trabajo.municipio,
        // select
      });

      setFiles({
        IdentificacionOficial: {
          previewicono:
            dataCarpetas.data.data.solicitante.identificacion_archivo,
          frameicono: dataCarpetas.data.data.solicitante.identificacion_archivo
            ? false
            : true,
          Archivo: null,
        },
        ActaNacimiento: {
          previewicono:
            dataCarpetas.data.data.solicitante.acta_nacimiento_archivo,
          frameicono: dataCarpetas.data.data.solicitante.acta_nacimiento_archivo
            ? false
            : true,
          Archivo: null,
        },
        FotografiaInfantil: {
          previewicono: dataCarpetas.data.data.solicitante.fotografia_archivo,
          frameicono: dataCarpetas.data.data.solicitante.fotografia_archivo
            ? false
            : true,
          Archivo: null,
        },
        ServicioSocial: {
          previewicono: dataCarpetas.data.data.CSS_archivo,
          frameicono: dataCarpetas.data.data.CSS_archivo ? false : true,
          Archivo: null,
        },
        ActaExamenProfesional: {
          previewicono: dataCarpetas.data.data.acta_examen_profesional_archivo,
          frameicono: dataCarpetas.data.data.acta_examen_profesional_archivo
            ? false
            : true,
          Archivo: null,
        },
        Curp: {
          previewicono: dataCarpetas.data.data.solicitante.curp_archivo,
          frameicono: dataCarpetas.data.data.solicitante.curp_archivo
            ? false
            : true,
          Archivo: null,
        },
      });
      // const splitDate = dataCarpetas.data.data.solicitante.fecha_nacimiento.split('-')
      // console.log(splitDate)
      // splitDate[2] = parseInt(splitDate[2]) + 1
      // console.log(splitDate, "splitDate")
      // console.log(dayjs(new Date(dataCarpetas.data.data.solicitante.fecha_nacimiento)))
      setFechaNaci(
        dayjs(dataCarpetas.data.data.solicitante.fecha_nacimiento).format(
          "YYYY-MM-DD"
        )
      );
      setFechaTit(dayjs(dataCarpetas.data.data.fecha_examen_profesional));

      const dataCat = await services("POST", "catalogos/codigoPostal", {
        cp: dataCarpetas.data.data.solicitante.codigo_Post,
      });

      if (dataCat.status === 200) {
        setLocalidades({
          ...localidades,
          localidades: dataCat.data.Localidad,
        });
        setColonias({
          ...colonias,
          colonias: dataCat.data.Colonia,
        });
      } else {
        swal("¡Atención!", dataCarpetas.data.error, "error");
      }

      setObservaciones({
        ...observaciones,
        mensaje: dataCarpetas.data.observaciones,
      });
    } else {
      // console.log("entro en el redireccionamiento")
      // window.location.href = '/';
    }
    setLoader({ loading: false });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
        (status) => {
          // status: success/error
          // success - script is loaded and greaptcha is ready
          // error - script is not loaded
          // console.log(status);
        }
      );
      setLoader({ loading: true });
      obtenerCatalogos();
      setLoader({ loading: false });
    }
    if (folio) {
      obtDatMod();
      setCorreoDis(true);
    }
  }, []);

  // VALIDAR CUANDO SE PIERDE EL FOCO DEL COFFEO
  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === "correo") {
      const correoRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!correoRegex.test(value)) {
        setErrors({
          ...errors,
          correo: "No cumple con el formato debido",
        });
      } else {
        let newErrors = { ...errors };
        delete newErrors.correo;
        setErrors(newErrors);
      }
    }
  };

  return (
    <div>
      {/* <h3 className="content_title">
        Registro de Solicitud de Cédula Provisional
      </h3> */}
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 my-4">
        <img
          className="imgLogo4 m-0 me-md-3"
          alt="Imagen Decorativa"
          src={logo4}
          style={{
            width: "120px",
            Height: "120px",
            marginRight: "20px",
          }}
        />
        <h3 className="h4 text-center text-vinoGob text-bold">
          Registro de Solicitud de Cédula Provisional
        </h3>
      </div>

      {observaciones.mensaje && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <p style={{ color: "red", fontSize: 20 }}>
            Observaciones: {observaciones.mensaje}
          </p>
        </div>
      )}

      <div className="container my-3">
        <p className="my-3 h5 text-center text-md-end fw-bold text-danger link-underline-primary ">
          NOTA:{" "}
          <span className="text-decoration-underline">
            No fotografías. Documentos digitalizados por ambos lados
          </span>
        </p>
        <div className="border-bottom--guinda text-big mb-2 text-bold text-center text-md-start">
          Información Personal
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto" }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid
                className="d-flex flex-column flex-md-row justify-content-center align-items-center"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <FormControl fullWidth>
                  <TextField
                    label="CURP"
                    disabled={correo_dis || foreigner}
                    variant="standard"
                    id="curp"
                    name="curp"
                    value={form.curp}
                    onChange={handleChange}
                    helperText={errors.curp}
                  />
                </FormControl>
                <div className="ms-4">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          setForeigner(!foreigner);
                          setForm({
                            ...form,
                            extranjero: foreigner ? 0 : 1,
                            curp: "",
                          });
                        }}
                      />
                    }
                    label="¿Eres extranjero?"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Nombre"
                    variant="standard"
                    id="nombre"
                    name="nombre"
                    value={form.nombre}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellido Paterno"
                    variant="standard"
                    id="apellido_paterno"
                    name="apellido_paterno"
                    value={form.apellido_paterno}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellido Materno"
                    variant="standard"
                    id="apellido_materno"
                    name="apellido_materno"
                    value={form.apellido_materno}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="YYYY-MM-DD"
                      id="fecha_nacimiento"
                      name="fecha_nacimiento"
                      locale="es"
                      // value={fecha_naci}
                      value={fecha_naci ? dayjs(fecha_naci) : null}
                      label="Fecha de Nacimiento"
                      onChange={handleFechaNacimiento}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {/* <FormControl fullWidth>
                                    <TextField
                                        label="Fecha de Nacimiento"
                                        variant="standard"
                                        id="fecha_nacimiento"
                                        name="fecha_nacimiento"
                                        type="text"
                                        value={form.fecha_nacimiento}
                                        onChange={handleChange}
                                    />
                                </FormControl> */}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Lugar de Nacimiento"
                    variant="standard"
                    id="lugar_nacimiento"
                    name="lugar_nacimiento"
                    value={form.lugar_nacimiento}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={form.sexo}
                    label="Age"
                    onChange={handleChangeSexo}
                  >
                    <MenuItem value={"Mujer"}>Mujer</MenuItem>
                    <MenuItem value={"Hombre"}>Hombre</MenuItem>
                    <MenuItem value={"No Binario"}>No Binario</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Teléfono"
                    variant="standard"
                    id="telefono"
                    name="telefono"
                    value={form.telefono}
                    onChange={handleChange}
                    helperText={errors.telefono}
                    inputProps={{ maxLength: 10 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Correo"
                    disabled={correo_dis}
                    variant="standard"
                    id="correo"
                    name="correo"
                    value={form.correo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.correo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={4}>
                <Grid
                  container
                  spacing={1}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <p className="m-0 mt-4 text-center text-black-50 legend-font">
                    Fotografía blanco y negro, papel mate con retoque.
                    Digitalizada
                  </p>
                  <CardFile
                    className="box-t"
                    hidden={!Files["IdentificacionOficial"].frameicono}
                    onDrop={(event) => {
                      handleDropFather(event, "IdentificacionOficial");
                    }}
                    onDragOver={(event) => {
                      handleDragOverFather(event);
                      handleDragEnterFather(event, "file");
                    }}
                    onDragEnter={(event) => {
                      handleDragEnterFather(event, "file");
                    }}
                    onDragLeave={handleDragLeaveFather}
                  >
                    <CardContent
                      onDrop={(event) => {
                        handleDropFather(event, "IdentificacionOficial");
                      }}
                      onDragOver={handleDragOver}
                      onDragEnter={(event) => {
                        handleDragEnterFather(event, "content");
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 15, color: colorUI }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Desliza tu identificación en formato .jpg, .png o .jpeg
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: colorUI, fontWeight: "bold" }}
                      >
                        Identificación Oficial
                      </Typography>
                      <Typography
                        className="m-0 fw-bold text-danger"
                        sx={{ fontSize: 12, color: colorUI }}
                        color="text.secondary"
                        gutterBottom
                      >
                        El tamaño máximo debe de ser de 5MB
                      </Typography>
                    </CardContent>
                  </CardFile>
                  {Files["IdentificacionOficial"].frameicono ? null : (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      {/* Este es tuyo tebitan */}
                      {/* <label style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', padding: '5px 7px', marginBottom: '10px' }}>{Files["IdentificacionOficial"].Archivo.name}</label> */}
                      <label
                        style={{
                          backgroundColor: "white",
                          color: colorUI,
                          borderRadius: "12px",
                          padding: "5px 7px",
                          marginBottom: "10px",
                        }}
                      >
                        {" "}
                        Identificación Oficial{" "}
                      </label>
                    </div>
                  )}
                  <iframe
                    id="frameiconoFiles"
                    src={Files["IdentificacionOficial"].previewicono}
                    className="preview-iframe"
                    hidden={Files["IdentificacionOficial"].frameicono}
                  ></iframe>
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <label
                      htmlFor={`input${"IdentificacionOficial"}`}
                      style={{
                        backgroundColor: colorUI,
                        color: "white",
                        borderRadius: "12px",
                        cursor: "pointer",
                        padding: "5px 7px",
                        marginBottom: "10px",
                      }}
                    >
                      cargar archivo
                    </label>
                    <input
                      type="file"
                      id={`input${"IdentificacionOficial"}`}
                      style={{ display: "none" }}
                      accept=".jpg, .png, .jepg"
                      onChange={(e) =>
                        handleChangeFiles(
                          "IdentificacionOficial",
                          e.target.files[0]
                        )
                      }
                    />
                    <DeleteForeverOutlinedIcon
                      style={{ color: "red", cursor: "pointer" }}
                      fontSize="large"
                      onClick={() => {
                        handleEliminarDoc("IdentificacionOficial");
                      }}
                      sx={
                        Files["IdentificacionOficial"].frameicono
                          ? { display: "none" }
                          : {}
                      }
                    />
                  </div>
                </Grid>
              </Grid>

              {/* <Grid container spacing={1} >
                                    <Grid item sm={4} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography>Identificación Oficial</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={6}>
                                        {folio ? <Link href={form.identificacion_archivo_actual} target="_blank" rel="noopener noreferrer">
                                            <ColorButton>Archivo actual</ColorButton></Link> : <TextField disabled label={form.nombre_identificacion_archivo}
                                                variant="standard"
                                                helperText={errors.identificacion_archivo}
                                        />}
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={6}>
                                        <Grid className='border pt-2'> 
                                            <ColorButton component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                                                {folio ? 'Nuevo' : 'Identificación'}
                                                <VisuallyHiddenInput name="identificacion_archivo"
                                                    onChange={handleChange}
                                                    type="file"
                                                    ref={fileIne} />
                                            </ColorButton>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold text-center text-md-start">
          Domicilio Particular
        </div>
      </div>

      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Calle"
                    variant="standard"
                    id="calle"
                    name="calle"
                    value={form.calle}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={1}>
                <FormControl fullWidth>
                  <TextField
                    label="Número"
                    variant="standard"
                    id="numero"
                    name="numero"
                    value={form.numero}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={1}>
                <FormControl fullWidth>
                  <TextField
                    label="Código postal"
                    variant="standard"
                    id="codigo_postal"
                    name="codigo_postal"
                    value={form.codigo_postal}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    label="Municipio"
                    variant="standard"
                    id="municipio"
                    name="municipio"
                    value={form.municipio}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    label="Entidad Federativa"
                    variant="standard"
                    id="entidad_federativa"
                    name="entidad_federativa"
                    value={form.entidad_federativa}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-colonia">Colonia</InputLabel>
                  <Select
                    labelId="demo-colonia"
                    id="colonia"
                    name="colonia"
                    value={`${form.id_colonia}-${form.colonia}`}
                    label="Colonia"
                    onChange={handleChange}
                  >
                    {colonias.colonias?.map((item) => (
                      <MenuItem
                        value={`${item.IDENTIFICADOR}-${item.DESCRIPCION}`}
                      >
                        {item.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.colonia}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-localidad">Localidad</InputLabel>
                  <Select
                    labelId="demo-localidad"
                    id="localidad"
                    name="localidad"
                    value={`${form.id_localidad}-${form.localidad}`}
                    label="Localidad"
                    onChange={handleChange}
                  >
                    {localidades.localidades?.map((item) => (
                      <MenuItem
                        value={`${item.IDENTIFICADOR}-${item.DESCRIPCION}`}
                      >
                        {item.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.localidad}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold text-center text-md-start">
          Datos Laborales
        </div>
      </div>

      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <label className="text-guinda">Trabaja Actualmente :</label>
                  <div className="d-flex flex-column flex-lg-row  aling-content-center mt-2">
                    <div className="rb1 ">
                      <input
                        name="trabajo"
                        id="radio1"
                        type="radio"
                        checked={form.selected === 1}
                        onChange={(evt) => {
                          // console.log(evt.target.checked)
                          setForm({ ...form, selected: 1 });
                          // console.log(globalState.selected4, "aqui")
                        }}
                      />
                      <label className="me-5" for="radio1">
                        Si
                      </label>
                    </div>

                    <div className="rb1">
                      <input
                        name="trabajo"
                        id="radio2"
                        type="radio"
                        checked={form.selected === 0}
                        onChange={(evt) => {
                          // console.log(evt.target.checked)
                          setForm({ ...form, selected: 0 });
                        }}
                      />{" "}
                      <label for="radio2">No</label>
                    </div>
                  </div>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                style={{ display: form.selected === 1 ? "block" : "none" }}
              >
                <FormControl fullWidth>
                  <label className="text-guinda">
                    Sector en que presta sus servicios:
                  </label>
                  <div className="d-flex flex-column flex-lg-row  aling-content-center mt-2">
                    <div className="rb1 ">
                      <input
                        name="sector"
                        id="radio3"
                        type="radio"
                        checked={form.selected2 === 1}
                        onChange={() => {
                          setForm({ ...form, selected2: 1 });
                          // console.log(globalState.selected4, "aqui")
                        }}
                      />
                      <label className="me-5" for="radio3">
                        Público
                      </label>
                    </div>

                    <div className="rb1">
                      <input
                        name="sector"
                        id="radio4"
                        type="radio"
                        checked={form.selected2 === 0}
                        onChange={() => {
                          setForm({ ...form, selected2: 0 });
                        }}
                      />{" "}
                      <label for="radio4">Privado</label>
                    </div>
                  </div>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                style={{ display: form.selected === 1 ? "block" : "none" }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Municipio donde labora:"
                    variant="standard"
                    id="municipio_donde_labora"
                    name="municipio_donde_labora"
                    value={form.municipio_donde_labora}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                style={{ display: form.selected === 1 ? "block" : "none" }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Calle"
                    variant="standard"
                    id="calle_laboral"
                    name="calle_laboral"
                    value={form.calle_laboral}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                style={{ display: form.selected === 1 ? "block" : "none" }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Número"
                    variant="standard"
                    id="numero_laboral"
                    name="numero_laboral"
                    value={form.numero_laboral}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                style={{ display: form.selected === 1 ? "block" : "none" }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Teléfono"
                    variant="standard"
                    id="telefono_laboral"
                    name="telefono_laboral"
                    value={form.telefono_laboral}
                    onChange={handleChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                style={{ display: form.selected === 1 ? "block" : "none" }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Correo Electrónico"
                    variant="standard"
                    id="correo_laboral"
                    name="correo_laboral"
                    value={form.correo_laboral}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold text-center text-md-start">
          DATOS DE ACTA DE TITULACIÓN
        </div>
      </div>

      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              {/* <Grid className='mt-2' item xs={12} sm={6} md={6}>
                                
                                <SelectBusqueda
                                    opciones={instituciones.instituciones}
                                    funcion={handleChangeInstitucion}
                                    selectOpcion={form.selectOpcion}
                                />
                            </Grid> */}

              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Carrera"
                    helperText="El nombre de la carrera debe ser idéntico al que se presenta en su Acta de Titulación"
                    variant="standard"
                    id="carrera"
                    name="carrera"
                    value={form.carrera}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Número de oficio de autorización"
                                        variant="standard"
                                        id="numero_autorizacion"
                                        name="numero_autorizacion"
                                        value={form.numero_autorizacion}
                                        onChange={handleChange} />
                                </FormControl>
                            </Grid> */}
              {/* <Grid item xs={12} sm={12} md={4} sx={{ mt: { xs: 2, md: 3 } }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Número de expediente (Normal y CBTIS)"
                                        variant="standard"
                                        id="numero_expediente"
                                        name="numero_expediente"
                                        value={form.numero_expediente}
                                        onChange={handleChange} />
                                </FormControl>
                            </Grid> */}
              {/* <Grid item xs={12} sm={12} md={4} sx={{ mt: { xs: 2, md: 3 } }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Foja"
                                        variant="standard"
                                        id="foja"
                                        name="foja"
                                        value={form.foja}
                                        onChange={handleChange} />
                                </FormControl>
                            </Grid> */}
              {/* <Grid item xs={12} sm={12} md={4} sx={{ mt: { xs: 2, md: 3 } }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Libro"
                                        variant="standard"
                                        id="libro"
                                        name="libro"
                                        value={form.libro}
                                        onChange={handleChange} />
                                </FormControl>
                            </Grid> */}
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ paddingTop: "2% !important" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="YYYY-MM-DD"
                      id="fecha_titulacion"
                      name="fecha_titulacion"
                      value={dayjs(fecha_tit)}
                      label="Fecha de examen profesional"
                      onChange={handleFechaExamen}
                      locale="es"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold text-center text-md-start">
          Archivos Adjuntos{" "}
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              {keyFiles.map((item) => {
                return (
                  <Grid
                    item
                    xs={folio ? 12 : 12}
                    md={folio ? 4 : 4}
                    sm={folio ? 4 : 4}
                  >
                    <Grid
                      container
                      spacing={1}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CardFile
                        className="box-t"
                        hidden={!Files[item.key].frameicono}
                        onDrop={(event) => {
                          handleDropFather(event, item.key);
                        }}
                        onDragOver={(event) => {
                          handleDragOverFather(event);
                          handleDragEnterFather(event, "file");
                        }}
                        onDragEnter={(event) => {
                          handleDragEnterFather(event, "file");
                        }}
                        onDragLeave={handleDragLeaveFather}
                      >
                        <CardContent
                          onDrop={(event) => {
                            handleDropFather(event, item.key);
                          }}
                          onDragOver={handleDragOver}
                          onDragEnter={(event) => {
                            handleDragEnterFather(event, "content");
                          }}
                        >
                          <div className="h-100 d-flex flex-column justify-content-between">
                            <Typography
                              sx={{ fontSize: 15, color: colorUI }}
                              color="text.secondary"
                              gutterBottom
                            >
                              Desliza el Documento en formato PDF
                            </Typography>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{ color: colorUI, fontWeight: "bold" }}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              className="m-0 fw-bold text-danger"
                              sx={{ fontSize: 12, color: colorUI }}
                              color="text.secondary"
                              gutterBottom
                            >
                              El tamaño máximo debe de ser de 5MB
                            </Typography>
                          </div>
                        </CardContent>
                      </CardFile>
                      {Files[item.key].frameicono ? null : (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          <label
                            style={{
                              backgroundColor: "white",
                              color: colorUI,
                              borderRadius: "12px",
                              padding: "5px 7px",
                              marginBottom: "10px",
                            }}
                          >
                            {item.name}
                          </label>
                        </div>
                      )}
                      <iframe
                        id="frameiconoFiles"
                        src={Files[item.key].previewicono}
                        className="preview-iframe"
                        hidden={Files[item.key].frameicono}
                      ></iframe>
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <label
                          htmlFor={`input${item.key}`}
                          style={{
                            backgroundColor: colorUI,
                            color: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                            padding: "5px 7px",
                            marginBottom: "10px",
                          }}
                        >
                          cargar archivo
                        </label>
                        <input
                          type="file"
                          id={`input${item.key}`}
                          style={{ display: "none" }}
                          accept=".pdf"
                          onChange={(e) =>
                            handleChangeFiles(item.key, e.target.files[0])
                          }
                        />
                        <DeleteForeverOutlinedIcon
                          style={{ color: "red", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => {
                            handleEliminarDoc(item.key);
                          }}
                          sx={
                            Files[item.key].frameicono
                              ? { display: "none" }
                              : {}
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
              {KeyFilesPNG.map((item) => {
                return (
                  <Grid item xs={12} sm={8} md={4}>
                    <Grid
                      container
                      spacing={1}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CardFile
                        className="box-t"
                        hidden={!Files[item.key].frameicono}
                        onDrop={(event) => {
                          handleDropFather(event, item.key);
                        }}
                        onDragOver={(event) => {
                          handleDragOverFather(event);
                          handleDragEnterFather(event, "file");
                        }}
                        onDragEnter={(event) => {
                          handleDragEnterFather(event, "file");
                        }}
                        onDragLeave={handleDragLeaveFather}
                      >
                        <CardContent
                          onDrop={(event) => {
                            handleDropFather(event, item.key);
                          }}
                          onDragOver={handleDragOver}
                          onDragEnter={(event) => {
                            handleDragEnterFather(event, "content");
                          }}
                        >
                          <div className="h-100 d-flex flex-column justify-content-between">
                            <Typography
                              sx={{ fontSize: 15, color: colorUI }}
                              color="text.secondary"
                              gutterBottom
                            >
                              Fotografía (jpg, png o jpeg) dimensiones de 94 x
                              113 píxeles (2.3 x 3 cm).
                            </Typography>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{ color: colorUI, fontWeight: "bold" }}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              className="m-0 fw-bold text-danger"
                              sx={{ fontSize: 12, color: colorUI }}
                              color="text.secondary"
                              gutterBottom
                            >
                              El tamaño máximo debe de ser de 5MB
                            </Typography>
                          </div>
                        </CardContent>
                      </CardFile>
                      {Files[item.key].frameicono ? null : (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          {/* Este es tuyo tebitan */}
                          {/* <label style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', padding: '5px 7px', marginBottom: '10px' }}>{Files[item.key].Archivo.name}</label> */}
                          <label
                            style={{
                              backgroundColor: "white",
                              color: colorUI,
                              borderRadius: "12px",
                              padding: "5px 7px",
                              marginBottom: "10px",
                            }}
                          >
                            {item.name}
                          </label>
                        </div>
                      )}
                      <iframe
                        id="frameiconoFiles"
                        src={Files[item.key].previewicono}
                        className="preview-iframe"
                        hidden={Files[item.key].frameicono}
                      ></iframe>
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <label
                          htmlFor={`input${item.key}`}
                          style={{
                            backgroundColor: colorUI,
                            color: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                            padding: "5px 7px",
                            marginBottom: "10px",
                          }}
                        >
                          cargar archivo
                        </label>
                        <input
                          type="file"
                          id={`input${item.key}`}
                          style={{ display: "none" }}
                          accept=".jpg, .png, .jepg"
                          onChange={(e) =>
                            handleChangeFiles(item.key, e.target.files[0])
                          }
                        />
                        <DeleteForeverOutlinedIcon
                          style={{ color: "red", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => {
                            handleEliminarDoc(item.key);
                          }}
                          sx={
                            Files[item.key].frameicono
                              ? { display: "none" }
                              : {}
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
              <div className="container my-3">
                <div className="text-medium mb-2 mt-2 text-bold">
                  Nota importante: El día de su cita, recuerde presentar
                  físicamente en ORIGINAL y COPIA los documentos guardados en
                  esta solicitud además de la orden de pago.
                </div>
              </div>
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <ColorButton
        variant="outlined"
        sx={{ display: "flex", margin: "auto", marginTop: 2, marginBottom: 2 }}
        onClick={guardar}
      >
        Guardar
      </ColorButton>

      {loader.loading ? <GifLoader></GifLoader> : null}
    </div>
  );
};

export default RegistroCedulasProvisionales;
