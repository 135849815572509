import { useState, useRef, useContext } from "react";
import GifLoader from "../components/ui/gifLoader";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Dialog,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { services } from "../services/api";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NotificacionContext } from "../context/notificacion_context";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import logo4 from "../assets/img/logo4.png";

function CertificadoVehicularView() {
  const notification = useContext(NotificacionContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [state, setState] = useState({
    serie: "",
    placa: "",
  });
  const [response, setResponse] = useState({
    estatus: "",
    NOMBRE: "",
    RFC: "",
    placa: "",
    serie: "",
    marca: "",
    modelo: "",
  });
  const [bodyLinea, setbodyLinea] = useState({
    email: "",
    rfc: "",
    nombre: "",
  });

  const handleBody = ({ target }) => {
    let { value, name } = target;
    setbodyLinea({ ...bodyLinea, [name]: value });
  };

  const handleRFCBody = ({ target }) => {
    let { value, name } = target;
    setbodyLinea({ ...bodyLinea, [name]: value.toUpperCase() });
  };

  const [checked, setchecked] = useState(false);
  const containerRef = useRef(null);
  const handleConsulta = async () => {
    if (state.serie === "" || state.placa === "") {
      notification.showNotification(
        "warning",
        "Campos Vacios",
        "Por favor rellene los campos",
        null,
        4
      );
    } else {
      setLoading(true);
      let apiVehicular = await services("POST", "vehicular/certificado", state);

      if (apiVehicular.data.error) {
        Object.keys(apiVehicular.data.error).forEach((key) => {
          notification.showNotification(
            "warning",
            key,
            apiVehicular.data.error[key][0],
            null,
            4
          );
        });
      } else if (apiVehicular.data.V1_MENS) {
        notification.showNotification(
          "error",
          apiVehicular.data.V1_MENS,
          apiVehicular.data.V2_MENS,
          null,
          4
        );
      } else {
        setResponse(apiVehicular.data);
        setchecked(true);
      }
      setLoading(false);
      // setLoading(true)
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const generateLinea = async () => {
    let body = {
      ...bodyLinea,
      placa: state.placa.toUpperCase(),
      serie: state.serie.toUpperCase(),
    };
    let apiGenerar = await services("POST", "vehicular/generaLineacap", body);

    if (apiGenerar.data) {
      setopenDialog(false);
      swal("Se genero línea de captura con éxito", {
        buttons: {
          linea: "Ver Línea de Captura.",
        },
      }).then((result) => {
        window.location = apiGenerar.data?.intento;
      });
    } else {
      notification.showNotification(
        "error",
        "Certificado",
        "Hubo un error, intente más tarde",
        null,
        4
      );
    }
  };

  const handleIntento = () => {
    if (response.estatus === 1) {
      window.open(response.intento);
    } else if (response.estatus === 2) {
      // LOADER
      // setLoading(true);
      downloadCertificado();
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
    }
  };

  const downloadCertificado = async () => {
    let body = {
      lcaptura: response?.lcaptura,
      tkn: response?.tkn,
    };
    // downloadPDF(base64Doc, `Certificado_${state.curp}`, null, "pdf")

    setLoading(true);
    let serviceDownload = await services("POST", `vehicular/descargarcv`, body);

    if (serviceDownload.status === 400) {
      notification.showNotification(
        "error",
        "Error en la descarga",
        "Hubo un problema, intentar mas tarde",
        null,
        4
      );
      // handleClose()
    } else {
      let base64Doc = serviceDownload.data;
      notification.showNotification(
        "success",
        "Descarga Exitosa",
        "En breve comenzara la descarga",
        null,
        4
      );
      downloadPDF(base64Doc, "Certificado_Vehicular", null, "pdf");
      setchecked(false);
    }
    setLoading(false);
  };

  const downloadPDF = (documentLink, name, callback, extension) => {
    try {
      const linkSource = `data:application/${extension};base64,${documentLink}`;
      const downloadLink = document.createElement("a");
      const fileName = `${name}.${extension}`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      if (callback !== null) {
        callback();
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  return (
    <>
      {loading && <GifLoader />}
      <div className="vh-100 w-75 mx-auto">
        <div
          style={{ overflow: "hidden" }}
          ref={containerRef}
          className="h-75 d-flex justify-content-center card m-3 text-center"
        >
          {!checked && (
            <Slide
              direction="right"
              in={!checked}
              container={containerRef.current}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                {/* <h3 className="">Solicitud Constancia de Interés Particular</h3> */}
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2">
                  <img
                    className="imgLogo4"
                    alt="Imagen Decorativa"
                    src={logo4}
                    style={{
                      width: "150px",
                      Height: "150px",
                      marginRight: "20px",
                    }}
                  />
                  <span className="h3 text-vinoGob text-bold sizeletrasgobTitle navidad">
                    Solicitud Constancia de Interés Particular
                  </span>
                </div>
                <FormControl
                  sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                >
                  <InputLabel htmlFor="my-input">Serie</InputLabel>
                  <Input
                    error={state.serie}
                    onChange={(event) => {
                      setState({
                        ...state,
                        serie: event.target.value.toUpperCase(),
                      });
                    }}
                    onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}
                    inputProps={{
                      style: { textTransform: "uppercase" },
                      maxLength: 18,
                    }}
                    id="nombre"
                    name="nombre"
                    aria-describedby="my-helper-text"
                  />
                  <FormHelperText id="my-helper-text">
                    Ingresa tu serie.
                  </FormHelperText>
                </FormControl>
                <FormControl
                  sx={{ marginTop: 3, width: 400, maxWidth: "100%" }}
                >
                  <InputLabel htmlFor="my-input">Placa</InputLabel>
                  <Input
                    error={state.placa}
                    onChange={(event) => {
                      setState({
                        ...state,
                        placa: event.target.value.toUpperCase(),
                      });
                    }}
                    onKeyPress={(event) => {
                      // onlyLetter(event, 29);
                    }}
                    inputProps={{
                      style: { textTransform: "uppercase" },
                      maxLength: 7,
                    }}
                    id="nombre"
                    name="nombre"
                    aria-describedby="my-helper-text"
                  />
                  <FormHelperText id="my-helper-text">
                    Ingresa tu placa.
                  </FormHelperText>
                </FormControl>
                <div
                  className="cta cta--guinda mt-2"
                  onClick={() => {
                    handleConsulta();
                  }}
                >
                  Consultar
                </div>
              </div>
            </Slide>
          )}

          {checked && (
            <Slide
              direction="left"
              in={checked}
              container={containerRef.current}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                {response.estatus === 1 && (
                  <div className="subContainer">
                    <span>{response.mensage}</span>

                    <div className="d-flex mt-2 justify-content-center">
                      <div
                        className="cta cta--guinda mx-2"
                        onClick={() => {
                          setchecked(false);
                        }}
                      >
                        Volver a Consultar{" "}
                      </div>
                      <div
                        className="cta cta--guinda "
                        onClick={() => {
                          handleIntento();
                        }}
                      >
                        Ver pago Vigente
                      </div>
                    </div>
                  </div>
                )}
                {response.estatus === 2 && (
                  <div className="subContainer">
                    <span>{response.mensage}</span>

                    <div className="d-flex mt-2 justify-content-center">
                      <div
                        className="cta cta--guinda mx-2"
                        onClick={() => {
                          setchecked(false);
                        }}
                      >
                        Volver a Consultar{" "}
                      </div>
                      <div
                        className="cta cta--guinda "
                        onClick={() => {
                          handleIntento();
                        }}
                      >
                        Descargar Certificado
                      </div>
                    </div>
                  </div>
                )}
                {response.marca && (
                  <div className="subContainer">
                    <span>
                      <DriveEtaIcon />
                      Datos del Vehiculo
                    </span>
                    <div className="d-flex flex-wrap  text-start">
                      {/* <div className="col-md-6 px-2 mt-2">Nombre:{response?.NOMBRE}</div>
                <div className="col-md-6 px-2 mt-2">RFC:{response?.RFC}</div> */}
                      <div className="col-md-6 px-2 mt-2">
                        Placa:{response?.placa}
                      </div>
                      <div className="col-md-6 px-2 mt-2">
                        Serie:{response?.serie}
                      </div>
                      <div className="col-md-6 px-2 mt-2">
                        Modelo:{response?.modelo}
                      </div>
                      <div className="col-md-6 px-2 mt-2">
                        Marca:{response?.marca}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-2">
                      <div
                        className="cta cta--guinda mx-2"
                        onClick={() => {
                          setchecked(false);
                        }}
                      >
                        Volver a Consultar{" "}
                      </div>
                      <div
                        className="cta cta--guinda "
                        onClick={() => {
                          setopenDialog(true);
                        }}
                      >
                        Generar Línea de captura
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Slide>
          )}
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setopenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Generar Línea de Captura
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Para generar un línea de Captura, necesitamos que nos proporciones
            un correo electrónico, nombre y RFC
          </DialogContentText>
          <TextField
            fullWidth
            label="Email"
            size="small"
            variant="standard"
            value={bodyLinea.email}
            name="email"
            onChange={handleBody}
          />
          <TextField
            fullWidth
            label="RFC"
            size="small"
            variant="standard"
            value={bodyLinea.rfc}
            name="rfc"
            onChange={handleRFCBody}
          />
          <TextField
            fullWidth
            label="Nombre"
            size="small"
            variant="standard"
            value={bodyLinea.nombre}
            name="nombre"
            onChange={handleBody}
          />
          <small>*Verifica que tu informacion este bien</small>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setopenDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={() => generateLinea()}>Generar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CertificadoVehicularView;
