import React, { useState, useEffect, useContext } from "react";

import Box from "@mui/material/Box";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import GifLoader from "../components/ui/gifLoader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { onlyNumberLinea } from "../utils/inputsRules";
// scaner
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
import { NotificacionContext } from "../context/notificacion_context";
import Select, { components } from "react-select";

import { services } from "../services/api";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#6f1d46",
  // backgroundColor: "#8d949e",
  "&:hover": {
    backgroundColor: "#6f1d46",
    // backgroundColor: "#8d949e",
  },
}));

const LineaCaptura = ({
  rol,
  id,
  user = "TEST",
  municipio = "Morelia",
  oficina = "Gobierno Digital Salon #2",
}) => {
  const [data, setdata] = useState({
    apimunicipios: null,
    municipioSelect: null,
    oficinas: { IDENTIFICADOR: "0", DESCRIPCION: "Seleccione Oficina" },
    result: "No result",
    errorFolio: false,
    errorLinea: false,
    errorMunicipio: false,
    errorOficina: false,
    enviandoDatos: false,
  });

  const [folioCaptura, setFolioCaptura] = useState({
    lineaCaptura: null,
    folio: null,
    cameraon: false,
  });

  const [oficinaState, setOficina] = useState({
    apioficinas: null,
    buscando: true,
  });

  const [folios, setFolios] = useState([]);
  const { showNotification } = useContext(NotificacionContext);

  // const handleChangeFolio = (event) => {
  //   setFolioCaptura({ ...folioCaptura, folio: event.target.value });
  // };

  const handleChangeOficina = (oficina) => {
    setdata({ ...data, oficinas: oficina });
  };

  const handleChangeMunicipio = (municipio) => {
    setdata({
      ...data,
      municipioSelect: municipio,
      oficinas: { IDENTIFICADOR: "0", DESCRIPCION: "Seleccione" },
    });
    setOficina({ ...oficinaState, buscando: true });
    consumoApiOficina(municipio.IDENTIFICADOR);
  };

  const handleChangeLineaCaptura = (event) => {
    setFolioCaptura({ ...folioCaptura, lineaCaptura: event.target.value });
  };

  const handleScaner = (code) => {
    if (code) {
      if (!(code.length === 20)) {
        code = "";
        showNotification(
          "error",
          "Codigo No Valido",
          "favor de escanear el codigo de barras del formato",
          null,
          4
        );
      }
      setFolioCaptura({ ...folioCaptura, cameraon: false, lineaCaptura: code });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const validar = () => {
    // flag para envio de datos 1 = cajero y 2 = Admin
    // let errorFolio = false;
    let errorLinea = false;
    let errorMunicipio = false;
    let errorOficina = false;

    if (rol === "Administrador") {
      if (
        folioCaptura.lineaCaptura === null ||
        folioCaptura.lineaCaptura === ""
      ) {
        errorLinea = true;
      }
      if (data.municipio === null || data.municipio === "") {
        errorMunicipio = true;
      }
      if (data.oficinas === null || data.oficinas === "") {
        errorOficina = true;
      }
      // if (folioCaptura.folio === null || folioCaptura.folio === '') { errorFolio = true }

      if (!(errorOficina && errorMunicipio && errorLinea)) {
        setdata({ ...data, enviandoDatos: true });
        setDataSap(0);
      } else {
        showNotification(
          "error",
          "Faltan Campos",
          "favor de ingresar todos los campos",
          null,
          4
        );
        setdata({
          ...data,
          errorLinea: errorLinea,
          errorMunicipio: errorMunicipio,
          errorOficina: errorOficina,
        });
      }
    } else {
      if (
        folioCaptura.lineaCaptura === null ||
        folioCaptura.lineaCaptura === ""
      ) {
        errorLinea = true;
      }
      // if (folioCaptura.folio === null || folioCaptura.folio === '') { errorFolio = true }

      if (!errorLinea) {
        setdata({ ...data, enviandoDatos: true });
        setDataSap(1);
      } else {
        showNotification(
          "error",
          "Faltan Campos",
          "favor de ingresar todos los campos",
          null,
          4
        );
        setdata({
          ...data,
          errorLinea: errorLinea,
          errorMunicipio: errorMunicipio,
          errorOficina: errorOficina,
        });
      }
    }
  };

  const setDataSap = async (flag) => {
    // envio de datos a SAP
    // let enviofolios = [];
    // if (folios.length > 1) {

    //   enviofolios.push(folioCaptura.folio);

    //   folios.map(item => {
    //     enviofolios.push(item.value);
    //   })

    // } else {
    //   enviofolios.push(folioCaptura.folio);
    // }

    if (flag === 0) {
      // envio de datos a SAP Admin
      // folios: enviofolios,
      let bodyadmin = {
        lcaptura: folioCaptura.lineaCaptura,
        id_oficina: data.oficinas.IDENTIFICADOR,
        folios: [],
      };

      let envioFolios = await services("POST", `ventanilla/canje`, bodyadmin);

      if (envioFolios.status === 200) {
        if (envioFolios.data.exito) {
          showNotification(
            "success",
            "Acción Exitosa!",
            envioFolios.data.mensaje,
            null,
            4
          );
          limpiarinputs();
        } else {
          showNotification(
            "error",
            "Acción No Realizada",
            envioFolios.data.error,
            null,
            4
          );
        }

        setdata({ ...data, enviandoDatos: false });
      }
    } else {
      // envio de datos a SAP Cajero

      let bodycajero = { lcaptura: folioCaptura.lineaCaptura, folios: [] };

      let envioFolios = await services("POST", `ventanilla/canje`, bodycajero);

      if (envioFolios.status === 200) {
        if (envioFolios.data.exito) {
          showNotification(
            "success",
            "Acción Exitosa!",
            envioFolios.data.mensaje,
            null,
            4
          );
          limpiarinputs();
        } else {
          showNotification(
            "error",
            "Acción No Realizada",
            envioFolios.data.error,
            null,
            4
          );
        }
        setdata({ ...data, enviandoDatos: false });
      } else {
        limpiarinputs();
        showNotification(
          "error",
          "Acción No Realizada",
          envioFolios.data.error,
          null,
          4
        );
        setdata({ ...data, enviandoDatos: false });
      }
    }
  };

  const onCamera = () => {
    if (folioCaptura.cameraon) {
      setFolioCaptura({ ...folioCaptura, cameraon: false });
    } else {
      setFolioCaptura({ ...folioCaptura, cameraon: true });
    }
  };

  // const agregar = () => {
  //   if (folios.length < 10) {
  //     setFolios((s) => {
  //       return [
  //         ...s,
  //         {
  //           id: folios.length,
  //           type: "text",
  //           value: "",
  //         },
  //       ];
  //     });
  //   }
  // };

  // const handleChangeDinamicInput = (e) => {
  //   e.preventDefault();

  //   const index = parseInt(e.target.id);
  //   setFolios((s) => {
  //     const newArr = s.slice();
  //     newArr[index].value = e.target.value;

  //     return newArr;
  //   });
  // };

  // const handleDeleteInput = (e, item) => {
  //   e.preventDefault();

  //   const filterFolios = folios.filter((input) => {
  //     return input !== item;
  //   });

  //   setFolios(filterFolios);
  // };

  const SortArray = (x, y) => {
    if (x.DESCRIPCION < y.DESCRIPCION) {
      return -1;
    }
    if (x.DESCRIPCION > y.DESCRIPCION) {
      return 1;
    }
    return 0;
  };

  const consumoApi = async () => {
    let municipios = await services("GET", `catalago/MUNICIPIO`, null);

    if (municipios.status === 200) {
      let array_ordered = municipios.data.TB_RESULTADO;
      array_ordered = array_ordered.sort(SortArray);

      setdata({ ...data, apimunicipios: array_ordered });
    }
  };
  const consumoApiOficina = async (oficina) => {
    let oficinas = await services("GET", `catalago/OFICINA/${oficina}`, null);
    let oficinasDataApi = [];
    if (oficinas.status === 200) {
      if (!Array.isArray(oficinas.data.TB_RESULTADO)) {
        oficinasDataApi = [oficinas.data.TB_RESULTADO];
      } else {
        oficinasDataApi = oficinas.data.TB_RESULTADO;
      }
    }
    setOficina({
      ...oficinaState,
      apioficinas: oficinasDataApi,
      buscando: false,
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (rol === "Administrador") {
        consumoApi();
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const limpiarinputs = () => {
    setFolioCaptura({ ...folioCaptura, lineaCaptura: null, folio: null });
    setFolios([]);
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <>
      <Container>
        <Card
          style={{
            paddingTop: 30,
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 30,
          }}
          variant="outlined"
        >
          {/* select municipios y oficina  */}

          {rol === "Administrador" ? (
            <>
              {data.apimunicipios ? (
                <Grid
                  container
                  sx={{ paddingBottom: "3rem", paddingTop: "2rem" }}
                  spacing={2}
                  columns={16}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* ojo */}

                  <div className="d-lg-flex justify-content-center w-100">
                    <div>
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="mx-2"
                      >
                        Municipio
                      </InputLabel>
                      <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                        <Select
                          options={data.apimunicipios}
                          placeholder="Seleccione Municipio"
                          getOptionValue={(options) => options.IDENTIFICADOR}
                          getOptionLabel={(options) => options.DESCRIPCION}
                          onChange={(options) => handleChangeMunicipio(options)}
                          components={{ NoOptionsMessage }}
                        />
                        {/* <FormHelperText>Selecciona un municipio asd</FormHelperText> */}
                      </FormControl>
                    </div>
                    <div>
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="mx-2"
                      >
                        Oficina
                      </InputLabel>
                      <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                        <Select
                          placeholder="Seleccione Oficina"
                          isDisabled={oficinaState.buscando}
                          options={oficinaState.apioficinas}
                          getOptionValue={(options) => options.IDENTIFICADOR}
                          getOptionLabel={(options) => options.DESCRIPCION}
                          value={data.oficinas}
                          onChange={(options) => handleChangeOficina(options)}
                          components={{ NoOptionsMessage }}
                        />

                        {/* <FormHelperText>Selecciona una oficina</FormHelperText> */}
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              ) : (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                    <GifLoader></GifLoader>
                  </div>
                </>
              )}
            </>
          ) : (
            <Grid
              container
              sx={{ paddingBottom: "3rem", paddingTop: "2rem" }}
              spacing={2}
              columns={16}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs="auto">
                <InputLabel id="demo-simple-select-helper-label">
                  Cajero: {user}
                </InputLabel>
              </Grid>
              <Grid item xs="auto">
                <InputLabel id="demo-simple-select-helper-label">
                  Municio: {municipio}
                </InputLabel>
              </Grid>
              <Grid item xs="auto">
                <InputLabel id="demo-simple-select-helper-label">
                  Oficina: {oficina}
                </InputLabel>
              </Grid>
            </Grid>
          )}

          {/* linea de captura  inputs  */}

          <Grid
            container
            sx={{ paddingBottom: "1rem" }}
            spacing={2}
            columns={16}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid className="adminlineaCaptura" item xs="auto">
              <InputLabel id="demo-simple-select-helper-label">
                Línea de Captura:
              </InputLabel>
            </Grid>
            <Grid item xs="auto">
              <Box
                sx={{
                  width: 250,
                  maxWidth: "100%",
                }}
              >
                <Input
                  fullWidth
                  error={data.errorLinea}
                  id="linea-captura"
                  type="text"
                  helperText="Ingresa la línea de captura"
                  value={
                    folioCaptura.lineaCaptura ? folioCaptura.lineaCaptura : ""
                  }
                  onKeyPress={onlyNumberLinea}
                  onChange={handleChangeLineaCaptura}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="camara activa"
                        onClick={() => {
                          onCamera();
                        }}
                      >
                        <PhotoCameraIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
            </Grid>
            {/* <Grid item xs="auto">
              <InputLabel id="demo-simple-select-helper-label">Folio:</InputLabel>
            </Grid>
            <Grid item xs="auto">
              <Box
                sx={{
                  width: 250,
                  maxWidth: '100%',
                }}
              >
                <Input
                  fullWidth
                  error={data.errorFolio}
                  helperText='Ingresa el folio'
                  id='inputFolio'
                  type='text'
                  value={folioCaptura.folio ? folioCaptura.folio : ''}
                  onChange={handleChangeFolio}
                />
              </Box>
            </Grid>
            <Grid item xs='auto'>
              <IconButton
                aria-label="add folio"
                onClick={() => { agregar() }}
                sx={{ color: '#4a001f', backgroundColor: '#ffc3d0' }}
              >
                <AddIcon />
              </IconButton>
            </Grid> */}
          </Grid>
          {/* <Grid sx={{ paddingBottom: '3rem' }} container spacing={2} columns={16} display="flex" justifyContent="center" alignItems="center">
            <Grid item xs={4}></Grid>
            <Grid item xs={2}></Grid>
            <Grid className="boxfolios" item xs="auto">
              {folios.length > 0 ? folios.map((item, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      width: 370,
                      maxWidth: '100%',
                      display: 'flex',
                      alignItems: 'flex-end'
                    }}
                  >
                    <InputLabel id={`labelFolio-${i + 1}`}>Folio {i + 2}:</InputLabel>
                    <Input
                      sx={{ width: 260 }}
                      id={i.toString()}
                      type={item.type}
                      value={item.value ? item.value : ''}
                      onChange={handleChangeDinamicInput}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="delete input"
                            onClick={(e) => handleDeleteInput(e, item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                );
              }) : <></>}
            </Grid>
          </Grid> */}
          <Grid
            sx={{ paddingBottom: "3rem" }}
            container
            spacing={2}
            columns={16}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}></Grid>
            <Grid item xs="auto">
              <ColorButton
                variant="outlined"
                onClick={() => {
                  validar();
                }}
                // endIcon={<KeyboardReturnIcon />}
              >
                Validar{" "}
              </ColorButton>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>

          {folioCaptura.cameraon ? (
            <div>
              {/* <BarcodeScannerComponent
                width={500}
                height={500}
                delay={250}
                onUpdate={(err, res) => {
                  if (res) {
                    handleScaner(res.text);
                  } else {
                    setdata({ ...data, result: "Not Found" });
                  }
                  if (err) {
                    handleError(handleError);
                  }
                }}
                onError={(err) => {
                  showNotification(
                    "error",
                    "Camara o Scanner desconectado",
                    "favor de conectar tu camara o scanner",
                    null,
                    4
                  );
                  setFolioCaptura({ ...folioCaptura, cameraon: false });
                }}
              /> */}
            </div>
          ) : (
            <></>
          )}
        </Card>
      </Container>

      {data.enviandoDatos ? (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
          <GifLoader></GifLoader>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LineaCaptura;
