import React from 'react';

import Container from '@mui/material/Container';

const Error500 = () => {

    return (
        <Container>
            <div className='d-flex justify-content-center align-items-center vh-100'>
                <img src='mensajeError.png'></img>
            </div>
        </Container> 
    );
}



export default Error500;