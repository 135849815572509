import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRouteAdmin = ({children, ...propierties}) => {
   
    try {
     
        const usuario = JSON.parse(localStorage.getItem('usuario'));

        if ( usuario.nombre && usuario.token && usuario.rol && usuario.id_municipio && usuario.id_oficina ? true : false) {
            return <Route {...propierties}>{children}</Route>
        } else {
            return <Redirect to="/login" />
        }

    } catch (error) {
        return <Redirect to="/login" />
    }
}

export default PrivateRouteAdmin;

