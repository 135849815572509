import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FaNetworkWired } from "react-icons/fa";
import IconUI from "../components/ui/icon_ui";
import { AiOutlineEdit, AiOutlineLock, AiOutlineUnlock } from "react-icons/ai";
import { GrClose } from "react-icons/gr";

import { AiFillPlusCircle, AiFillEdit } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { components } from "react-select";
import { services } from "../services/api";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import swal from "sweetalert";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import EditIcon from "@mui/icons-material/Edit";
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import GifLoader from "../components/ui/gifLoader";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { IoReturnDownBack } from "react-icons/io5";

const colorUI = "#6f1d46";
// const colorUI="#8d949e";

const styleModalItem = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: 500,
  overflow: "hidden",
  overflowY: "scroll",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "150px",
  maxHeight: "150px",
}));

const ItemInicio = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "15px",
  minHeight: "250px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const ItemOrdenProceso = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
  width: "100%",
  borderRadius: "30px",
  lineHeight: "60px",
}));

const ConfiguraTramiteGratuito = () => {
  const [open, setOpen] = useState(false);
  const [nuevo, setNuevo] = useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleClose1 = () => {
    setOpen(false);
    setOpciones([]);
    setItemState({
      name: "",
      elemento_id: "",
      validaciones: "",
      label: "",
      tipo_input: "",
      value: "",
      options: [],
    });
  };

  const [templates, setTemplate] = useState({
    plantilla_id: null,
    nombre: "",
    descripcion: "",
    formulario_id: null,
    active: 1,
    dependencia_id: "",
    SelectedDep: null,
  });

  const [cantidadValidar, setCcantidad] = useState({
    min: false,
    size: false,
    valuemin: "",
    valuesize: "",
  });
  const [opciones, setOpciones] = useState([]);

  const [Configstate, setConfigstate] = useState({
    Formulario: {
      pagoLinea: 0,
      Firma: 1,
      categoria: "",
      claveIngreso: "",
      validacion: 1,
      observaciones: "",
      documento: 0,
      Formularios: [
        {
          nombre: "",
          campos: [],
        },
      ],
    },
    nuevo: false,
  });

  const [ItemState, setItemState] = useState({
    name: "",
    elemento_id: "",
    validaciones: "",
    label: "",
    tipo_input: "",
    value: "",
    options: [],
  });

  const [openModalItem, setOpenModalItem] = useState({
    opcion: 0,
    open: false,
    type: "",
    size: false,
    min: false,
    edit: false,
    opcionEdit: "",
  });

  const [stateProceso, setStateProceso] = useState({
    validacionTramite: true,
    pagoLinea: false,
    entregaResultado: false,
  });

  const [state, setState] = useState({
    categoriaSelected: "",
    ConceptoSelected: "",
    categorias: [],
    conceptos: [],
    validarCategoria: true,
    conceptosSelect: [],
    categoriasInputs: [],
    validaciones: [],
    categoria: "",
    concepto: "",
    nombreForm: "",
    descripcionForm: "",
    Opcionuno: "",
    loading: true,
    dataForms: [],
    modal: false,
    type: "",
    nombre: "",
    descripcion: "",
    codigo: "",
    responsable: "",
    telefono: "",
    email: "",
    idEdit: 0,
    agregarElementos: false,
    plantillasTramites: [],
    dependencia: "",
    id_dependencia: "",
  });
  const [rol, setRol] = useState(localStorage.getItem("rol"));
  const [loadingSelect, setLoadingSelect] = useState(false);
  /* const [dependencias, setDep] = useState({
        dependencia: "",
        id_dependencia: ""
    }) */
  const [data, setdata] = useState({
    apidependencias: null,
    dependencia: "",
  });
  // const handleOpenModalItem = () => setOpenModalItem({...openModalItem, open:true});
  const handleCloseModalItem = () => {
    setOpenModalItem({
      ...openModalItem,
      open: false,
      size: false,
      min: false,
    });

    limpiarOpciones();
  };

  const handleCloseModalItemEdit = () => {
    setCcantidad({
      min: false,
      size: false,
    });

    setOpenModalItem({
      ...openModalItem,
      open: false,
      size: false,
      min: false,
      edit: false,
      opcionEdit: "",
    });

    setItemState({
      name: "",
      elemento_id: Configstate.Formulario.Formularios[0].campos.length,
      validaciones: "",
      label: "",
      type: "",
      value: "",
      options: [],
    });
  };

  const ItemSeleccionado = (id, type, idValidacion) => {
    setOpenModalItem({
      ...openModalItem,
      open: true,
      opcion: id,
      type: type,
      elemento_id: idValidacion,
    });

    if (idValidacion != "") {
      GetValidaciones(idValidacion);
    }
  };

  const handleDeleteComponent = (id) => {
    const itemEdit = Configstate.Formulario.Formularios[0].campos.filter(
      (item) => {
        return item.id === id;
      }
    );

    const newCampos = Configstate.Formulario.Formularios[0].campos.map(
      (item) => {
        if (item === itemEdit[0]) {
          item.active = 0;
        }
        return item;
      }
    );

    setConfigstate({
      Formulario: {
        ...Configstate.Formulario,
        Formularios: [
          {
            nombre: Configstate.Formulario.Formularios[0].nombre,
            campos: newCampos,
          },
        ],
      },
    });
  };

  const setComponent = (component) => {
    // component.id = component.elemento_id}

    // if(component.elemento_id === 12){
    //     component.type = "select"
    // }

    switch (component.tipo_input) {
      case "text":
      case "password":
      case "number":
      case "email":
      case "url":
      case "tel":
        return (
          <>
            <TextField
              id={component.id}
              sx={{ width: "350px" }}
              required={component.validaciones == "required" ? true : false}
              label={component.label}
              name={component.name}
              type={component.tipo_input}
              defaultValue={component.value}
              inputProps={{
                autocomplete: `new-${component.tipo_input}`,
                form: {
                  autocomplete: "off",
                },
              }}
            />

            <IconButton
              aria-label="delete"
              id={`eliminar${component.id}`}
              onClick={() => {
                handleDeleteComponent(component.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              id={`editar${component.id}`}
              onClick={() => {
                handleEditComponent(component.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      case "textarea":
        return (
          <>
            <TextField
              id={component.id}
              sx={{ width: "350px" }}
              required={component.validaciones == "required" ? true : false}
              label={component.label}
              name={component.name}
              type={component.tipo_input}
              multiline
              maxRows={4}
              defaultValue={component.value}
            />

            <IconButton
              aria-label="delete"
              id={`eliminar${component.id}`}
              onClick={() => {
                handleDeleteComponent(component.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              id={`editar${component.id}`}
              onClick={() => {
                handleEditComponent(component.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      case "file":
        return (
          <>
            <InputLabel className="mx-2  FormularioDinamicoRadios">
              {component.label}
            </InputLabel>
            <TextField
              id={component.id}
              fullWidth
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              type={component.tipo_input}
              error={component.error ? true : false}
              defaultValue={component.value}
              helperText={component.mensajeError ? component.mensajeError : ""}
            />

            <IconButton
              aria-label="delete"
              id={`eliminar${component.id}`}
              onClick={() => {
                handleDeleteComponent(component.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              id={`editar${component.id}`}
              onClick={() => {
                handleEditComponent(component.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      case "date":
      case "datetime-local":
        return (
          <>
            <InputLabel className="mx-2">{component.label}</InputLabel>
            <TextField
              id={component.id}
              sx={{ width: "350px" }}
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              type={component.tipo_input}
              defaultValue={component.value}
              placeholder="DD/MM/YYYY"
            />
            <IconButton
              aria-label="delete"
              id={`eliminar${component.id}`}
              onClick={() => {
                handleDeleteComponent(component.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              id={`editar${component.id}`}
              onClick={() => {
                handleEditComponent(component.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      case "select":
        return (
          <>
            <p style={{ color: "#6b1e49" }}>
              {component.label}
              <IconButton
                aria-label="delete"
                id={`eliminar${component.id}`}
                onClick={() => {
                  handleDeleteComponent(component.id);
                }}
              >
                <DeleteIcon />
              </IconButton>

              <IconButton
                aria-label="editar"
                id={`editar${component.id}`}
                onClick={() => {
                  handleEditComponent(component.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </p>
            <InputLabel className="mx-2"> </InputLabel>

            <Select
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              id={component.id}
              options={component.options}
              placeholder="Seleccione una opción"
              getOptionValue={(options) => options.id}
              getOptionLabel={(options) => options.opcion}
            />
          </>
        );

      case "switch":
        return (
          <>
            <div className="d-flex">
              <FormGroup>
                <FormControlLabel
                  name={component.name}
                  id={component.id}
                  required={component.validaciones == "required" ? true : false}
                  control={<Switch />}
                  label={component.label}
                />
              </FormGroup>
              <div>
                <IconButton
                  aria-label="delete"
                  id={`eliminar${component.id}`}
                  onClick={() => {
                    handleDeleteComponent(component.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  aria-label="editar"
                  id={`editar${component.id}`}
                  onClick={() => {
                    handleEditComponent(component.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          </>
        );

      case "checkbox":
        return (
          <>
            <FormLabel component="legend">
              {component.label}{" "}
              <IconButton
                aria-label="delete"
                id={`eliminar${component.id}`}
                onClick={() => {
                  handleDeleteComponent(component.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                aria-label="editar"
                id={`editar${component.id}`}
                onClick={() => {
                  handleEditComponent(component.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </FormLabel>

            <FormGroup name={component.name}>
              {component.options.map((item) => (
                <FormControlLabel
                  id={item.id}
                  value={item.opcion}
                  required={component.validaciones == "required" ? true : false}
                  control={<Checkbox />}
                  label={item.opcion}
                />
              ))}
            </FormGroup>
          </>
        );

      case "radio":
        return (
          <>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                {component.label}
                <IconButton
                  aria-label="delete"
                  id={`eliminar${component.id}`}
                  onClick={() => {
                    handleDeleteComponent(component.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  aria-label="editar"
                  id={`editar${component.id}`}
                  onClick={() => {
                    handleEditComponent(component.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                {component.options.map((item) => (
                  <FormControlLabel
                    value={item.opcion}
                    control={<Radio />}
                    label={item.opcion}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
    }
  };

  const [propsValidaciones, setPropsValidaciones] = useState({
    value: [],
  });

  const handleEditComponent = async (id) => {
    setPropsValidaciones({});
    const itemEdit = Configstate.Formulario.Formularios[0].campos.filter(
      (item) => {
        return item.id === id;
      }
    );
    setItemState(itemEdit[0]);
    let validaciones = await services(
      "GET",
      `formularios/validaciones/${itemEdit[0].elemento_id}`,
      null
    );
    let validacionesProps = itemEdit[0].validaciones.split("|");
    const newArrays = [];
    let newMap = [...validaciones.data];
    newMap.map((elements) => {
      validacionesProps.map((sub) => {
        if (sub.includes(elements.validacion)) {
          newArrays.push({
            validacion: elements.validacion,
            requiere_valor: elements.requiere_valor,
            values:
              sub.split(":")[0] === "size" || sub.split(":")[0] === "min"
                ? sub.split(":")[1]
                : null,
            descripcion: elements.descripcion,
          });
        }
      });
    });
    let cccantidades = {
      min: false,
      size: false,
      valuemin: "",
      valuesize: "",
    };
    newArrays.forEach((element) => {
      if (element.validacion === "min") {
        cccantidades.min = true;
        cccantidades.valuemin = element.values;
      }
      if (element.validacion === "size") {
        cccantidades.size = true;
        cccantidades.valuesize = element.values;
      }
    });
    setCcantidad(cccantidades);
    setPropsValidaciones({ value: newArrays });
    setOpenModalItem({
      ...openModalItem,
      edit: true,
      opcionEdit: itemEdit[0].label,
      tipo_input: itemEdit[0].tipo_input,
      validacionesInputSelected: validaciones.data,
    });
  };

  const ComponentesList = [
    {
      idValidacion: "",
      type: "",
      tipo: "INPUT",
      valor: "1",
      icon: (
        <p className="componentesmuestra">
          <TextField
            disabled
            sx={{ backgroundColor: "#fff" }}
            id="muestrainput"
            label="Nombre"
            defaultValue="Caja de entrada"
            variant="outlined"
          />
        </p>
      ),
    },
    {
      idValidacion: "11",
      type: "radio",
      tipo: "BOTÓN OPCIÓN",
      valor: "2",
      icon: (
        <p className="componentesmuestra">
          opción 1<RadioButtonCheckedIcon /> opción 2
          <RadioButtonUncheckedIcon />
        </p>
      ),
    },
    {
      idValidacion: "12",
      type: "select",
      tipo: "SELECCIÓN",
      valor: "3",
      icon: (
        <p className="componentesmuestra">
          <Select options={[]} placeholder="Seleccione una Categoría" />
        </p>
      ),
    },
    {
      idValidacion: "14",
      type: "switch",
      tipo: "ACTIVADOR",
      valor: "4",
      icon: (
        <p className="componentesmuestra">
          <FormGroup>
            <FormControlLabel
              sx={{ marginLeft: "25%" }}
              control={<Switch defaultChecked />}
              label="Es válido"
            />
          </FormGroup>
        </p>
      ),
    },
    {
      idValidacion: "10",
      type: "checkbox",
      tipo: "CHECKS",
      valor: "5",
      icon: (
        <p className="componentesmuestra">
          <FormGroup sx={{ marginLeft: "25%" }}>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Opción 1"
            />
            <FormControlLabel
              required
              control={<Checkbox />}
              label="Opción 2"
            />
          </FormGroup>
        </p>
      ),
    },
  ];

  const ColorButtonSecond = styled(Button)(({ theme }) => ({
    color: colorUI,
    backgroundColor: "#ffc3d0",
    border: "none",
    "&:hover": {
      transform: "scale(1.005)",
      boxShadow: "3px 3px 6px 0px rgba(0, 0, 0, 0.2)",
    },
  }));

  useEffect(async () => {
    let isMounted = true;

    if (isMounted) {
      let ListForm = await services(
        "GET",
        `gratuitos/formulariosGratuitos/list`,
        null
      );

      let TiposInput = await services("GET", `formularios/inputs`, null);
      let plantillasTramites = await services(
        "GET",
        `gratuitos/plantillaTramite/index`,
        null
      );
      if (localStorage.getItem("rol") == "Administrador") {
        let dependencias = await services("GET", `dependencia/listar`, null);
        setdata({ apidependencias: dependencias.data });
      }

      setState({
        ...state,
        categoriasInputs: TiposInput.data.filter(
          (item) =>
            item.tipo != "switch" &&
            item.tipo != "select" &&
            item.tipo != "radio" &&
            item.tipo != "checkbox"
        ),
        loading: false,
        dataForms: ListForm.data.data.reverse(),
        plantillasTramites: plantillasTramites.data.data,
      });
      setTemplate({ ...templates, dependencia_id: "", SelectedDep: null });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };

  const handleChangeCategoria = (id, item) => {
    const filterConcept = state.conceptos.filter((item) => {
      return item.ID_CATEGORIA === id;
    });
    // console.log(filterConcept, "filters")
    setState({
      ...state,
      categoriaSelected: item,
      validarCategoria: false,
      conceptosSelect: filterConcept[0].TB_CONCEPTOS,
      categoria: id,
    });
  };

  const handleAddItem = (type) => {
    if (ItemState.name !== "") {
      if (
        ItemState.tipo_input !== "" ||
        openModalItem.type === "select" ||
        openModalItem.type === "radio" ||
        openModalItem.type === "switch" ||
        openModalItem.type === "checkbox"
      ) {
        if (cantidadValidar.min && !cantidadValidar.valuemin) {
          return swal(
            "Ups",
            "El campo 'mínimo requerido' no puede estar vacío",
            "warning"
          );
        }
        if (cantidadValidar.size && !cantidadValidar.valuesize) {
          return swal(
            "Ups",
            "El campo 'máximo de letras permitidas' no puede estar vacío",
            "warning"
          );
        }

        if (type !== "") {
          ItemState.type = type;
        }
        if (state.Opcionuno !== "") {
          ItemState.options.push({ opcion: state.Opcionuno });
        }
        if (opciones.length > 0) {
          opciones.map((item) => {
            ItemState.options.push({ opcion: item.value });
          });
        }
        ItemState.name = ItemState.name.replace(" ", "-");

        if (cantidadValidar.min) {
          ItemState.validaciones = `${ItemState.validaciones}|min:${cantidadValidar.valuemin}`;
        }
        if (cantidadValidar.size) {
          ItemState.validaciones = `${ItemState.validaciones}|size:${cantidadValidar.valuesize}`;
        }
        if (ItemState.tipo_input === "") {
          ItemState.elemento_id = openModalItem.elemento_id;
          ItemState.tipo_input = openModalItem.type;
        }

        Configstate.Formulario.Formularios[0].campos.push(ItemState);

        setItemState({
          name: "",
          elemento_id: "",
          validaciones: "",
          label: "",
          tipo_input: "",
          value: "",
          options: [],
        });
        setOpenModalItem({
          ...openModalItem,
          open: false,
        });
        handleClose1();
        limpiar();
      } else {
        swal("Ups", "La categoría del campo es requerida", "warning");
      }
    } else {
      swal("Ups", "El nombre es requerido", "warning");
    }
  };

  const handleUpdateItem = (type, id) => {
    ItemState.validaciones = "";

    if (type !== "") {
      ItemState.type = type;
    }

    // Validar y agregar Opcionuno solo si es válida
    // if (state.Opcionuno && state.Opcionuno.trim() !== "") {
    //   ItemState.options.push({ id: state.Opcionuno, value: state.Opcionuno });
    // }
    if (opciones.length > 0) {
      opciones.forEach((item) => {
        if (item.value && item.value.trim() !== "") {
          ItemState.options.push({ id: item.value, value: item.value });
        }
      });
    }

    ItemState.name = ItemState.name.replace(/\s+/g, "-");

    if (cantidadValidar.min) {
      ItemState.validaciones = `${ItemState.validaciones}|min:${cantidadValidar.valuemin}`;
    }
    if (cantidadValidar.size) {
      ItemState.validaciones = `${ItemState.validaciones}|size:${cantidadValidar.valuesize}`;
    }

    setConfigstate({
      ...Configstate,
      Formulario: {
        ...Configstate.Formulario,
        Formularios: [
          {
            ...Configstate.Formulario.Formularios[0],
            campos: Configstate.Formulario.Formularios[0].campos.map((item) => {
              return item.id === ItemState.id ? ItemState : item;
            }),
          },
        ],
      },
    });

    limpiar();
    handleCloseModalItemEdit();
  };

  // const handleUpdateItem = (type, id) => {
  //   ItemState.validaciones = "";
  //   if (type != "") {
  //     ItemState.type = type;
  //   }
  //   if (state.Opcionuno != "") {
  //     ItemState.options.push({ id: state.Opcionuno, value: state.Opcionuno });
  //   }
  //   if (opciones.length > 0) {
  //     opciones.map((item) => {
  //       ItemState.options.push({ id: item.value, value: item.value });
  //     });
  //   }
  //   ItemState.name = ItemState.name.replace(" ", "-");

  //   if (cantidadValidar.min) {
  //     ItemState.validaciones = `${ItemState.validaciones}|min:${cantidadValidar.valuemin}`;
  //   }
  //   if (cantidadValidar.size) {
  //     ItemState.validaciones = `${ItemState.validaciones}|size:${cantidadValidar.valuesize}`;
  //   }

  //   setConfigstate({
  //     ...Configstate,
  //     Formulario: {
  //       ...Configstate.Formulario,
  //       Formularios: [
  //         {
  //           ...Configstate.Formulario.Formularios[0],
  //           campos: Configstate.Formulario.Formularios[0].campos.map((item) => {
  //             if (item.id === ItemState.id) {
  //               return ItemState;
  //             } else {
  //               return item;
  //             }
  //           }),
  //         },
  //       ],
  //     },
  //   });

  //   limpiar();
  //   handleCloseModalItemEdit();
  // };

  // const limpiar = () => {
  //     setItemState({
  //         name: "",
  //         id: Configstate.Formulario.Formularios[0].campos.length,
  //         validaciones: "",
  //         label: "",
  //         type: "",
  //         value: "",
  //         options: [],
  //     })
  //     handleCloseModalItem()
  //     limpiarvalidaciones()
  // }

  const limpiarvalidaciones = () => {
    setCcantidad({
      min: false,
      size: false,
    });
  };

  const setOldFormName = (nombre, descripcion, concepto, item) => {
    setState({
      ...state,
      nombreForm: nombre,
      descripcionForm: descripcion,
      concepto: concepto,
      ConceptoSelected: item,
    });
  };

  // const handleChangeConcepto = async (id) => {

  //     let response = await services('GET', `formularios/formulario/${state.categoria}/${id.TP_INGRESO}`, null)

  //     if (response.data.estatus != 404) {
  //         swal({
  //             title: "Formulario Encontrado",
  //             text: "Nuestro sistema detecto un formulario existente para este concepto, \n ¿Qué le gustaría hacer?",
  //             icon: "warning",
  //             buttons: ["Crear formulario desde cero", "Editar formulario existente"],
  //             confirmButton: true,
  //             className: "alertFormularioEncontrado"
  //         }).then((Editar) => {
  //             if (Editar) {

  //                 console.log(response.data)
  //                 setOldFormName(response.data.formulario[0].nombre, response.data.descripcion, id.TP_INGRESO, id)
  //                 setStateProceso({
  //                     validacionTramite: true,
  //                     pagoLinea: response.data.pagoLinea === 1 ? true : false,
  //                     entregaResultado: false,
  //                 })
  //                 setConfigstate({
  //                     Formulario:
  //                     {
  //                         "pagoLinea": 1,
  //                         "Firma": 1,
  //                         "categoria": "",
  //                         "id": response.data.id,
  //                         "claveIngreso": "",
  //                         "validacion": 1,
  //                         "observaciones": response.data.descripcion,
  //                         "documento": 1,
  //                         "Formularios": [
  //                             {
  //                                 "nombre": response.data.formulario[0].nombre,
  //                                 "campos": response.data.formulario[0].campos
  //                             }
  //                         ]
  //                     }

  //                 })

  //             }
  //             else {
  //                 setOldFormName(state.nombreForm, state.descripcionForm, id.TP_INGRESO, id)
  //                 setConfigstate({
  //                     Formulario:
  //                     {
  //                         "pagoLinea": 1,
  //                         "Firma": 1,
  //                         "id": response.data.id,
  //                         "categoria": "",
  //                         "claveIngreso": "",
  //                         "validacion": 1,
  //                         "observaciones": "",
  //                         "documento": 1,
  //                         "Formularios": [
  //                             {
  //                                 "nombre": "",
  //                                 "campos": []
  //                             }
  //                         ]
  //                     }

  //                 })

  //             }
  //         });
  //     } else {
  //         setOldFormName(state.nombreForm, state.descripcionForm, id.TP_INGRESO, id)
  //     }

  // }

  const agregar = () => {
    if (opciones.length < 10) {
      setOpciones((s) => {
        return [
          ...s,
          {
            id: opciones.length,
            type: "text",
            value: "",
          },
        ];
      });
    }
  };

  const limpiarOpciones = () => {
    setOpciones([]);
    setCcantidad({
      min: false,
      size: false,
    });
    setItemState({
      name: "",
      elemento_id: "",
      validaciones: "",
      label: "",
      tipo_input: "",
      value: "",
      options: [],
    });
    handleClose();
  };

  const handleChangeDinamicInputEdit = (e) => {
    e.preventDefault();
    console.log(e, e.target.id);

    const index = parseInt(e.target.id);
    const optionsArray = ItemState.options;
    optionsArray[index].opcion = e.target.value;
    console.log(optionsArray);
    setItemState({ ...ItemState, options: optionsArray });
  };

  const handleChangeDinamicInput = (e) => {
    e.preventDefault();
    console.log(e, e.target.id);

    const index = parseInt(e.target.id);
    setOpciones((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;

      return newArr;
    });
  };

  const handleDeleteInput = (e, item) => {
    e.preventDefault();

    const filterOpciones = opciones.filter((input) => {
      return input !== item;
    });

    setOpciones(filterOpciones);
  };
  const handleDeleteInputOnEdit = (e, item) => {
    e.preventDefault();

    const filterOpciones = ItemState.options.filter((input) => {
      console.log(item, input);
      return input !== item;
    });
    console.log(filterOpciones);
    setItemState({ ...ItemState, options: filterOpciones });
    // setOpciones(filterOpciones);
  };

  // const handleDelete = () => {
  //     console.info('You clicked the delete icon.');
  //   };

  const [validacionesFile, setValidacionesFile] = useState({
    max: false,
    types: false,
  });
  const validacionesInputSet = (item) => {
    let array = "";
    let verificar = [];
    let min = false;
    let size = false;

    item.map((item) => {
      verificar.push(item.validacion);
      if (item.requiere_valor == 1) {
      } else {
        array = `${array}${item.validacion}|`;
      }
    });

    if (verificar.includes("min")) {
      min = true;
    }
    if (verificar.includes("size")) {
      size = true;
    }
    if (verificar.includes("max")) {
      min = true;
      setValidacionesFile({
        ...validacionesFile,
        types: true,
      });
    }
    if (verificar.includes("mimes")) {
      size = true;
      setValidacionesFile({
        ...validacionesFile,
        max: true,
      });
    }

    setCcantidad({ ...cantidadValidar, min: min, size: size });
    setPropsValidaciones(array);
    enviarNuevoComponent(array);
  };

  const enviarNuevoComponent = (array) => {
    setItemState({
      ...ItemState,
      validaciones: array.substring(0, array.length - 1),
    });
  };

  const validForm = () => {
    // return state.nombreForm != "" && state.descripcionForm != "" && state.categoria != "" && state.concepto != ""
    return true;
  };

  const AlertPagoLinea = () => {
    // console.log("asdsad")
    if (stateProceso.pagoLinea) {
      swal({
        title: "Precaución",
        text: "Al seleccionar pago en línea el sistema agregara automaticamente los campos de RFC y Razón Social, \n Si usted agrego alguno de estos campos seleccione CANCELAR y eliminelos. \n Si no agrego estos campos o ya los elimino presioné CONTINUAR",
        icon: "warning",
        buttons: ["Cancelar", "Continuar"],
        confirmButton: true,
        className: "alertFormularioEncontrado",
      }).then((aceptar) => {
        if (aceptar) {
          SetToBackend();
        }
      });
    } else {
      // console.log(Configstate.Formulario.Formularios[0].campos.length, "length")
      if (Configstate.nuevo) {
        SetToBackend();
      } else {
        EditForm();
      }
    }
  };

  const EditForm = async () => {
    // console.log(Configstate.Formulario, " Configstate.Formulario")
    // console.log(ItemState.validaciones);
    const newConfigState = Configstate.Formulario.Formularios[0].campos.map(
      (item) => {
        // console.log(item, "item aqui")
        const newObjt = {
          name: "",
          elemento_id: "",
          validaciones: "",
          label: "",
          type: "",
          value: "",
          active: "",
          // "options": []
        };
        // if(!nuevo){
        if (item.id) {
          newObjt.detalleid = item.id;
        }
        newObjt.elemento_id = item.elemento_id;
        newObjt.name = item.name;
        newObjt.label = item.label;
        newObjt.type = item.tipo_input;
        newObjt.value = item.value;
        if (item.active !== undefined) {
          newObjt.active = item.active === 1 ? 1 : 0;
        } else {
          newObjt.active = 1;
        }
        // console.log(item.options, "");
        // newObjt.validaciones = item.validaciones ? item.validaciones : ItemState.validaciones;
        newObjt.validaciones = item.validaciones;
        if (item.options.length !== 0) {
          newObjt.options = item.options;
        }
        return newObjt;
      }
    );

    const body = {
      elementos: newConfigState,
    };

    // console.log(body, "body");

    const response = await services(
      "POST",
      `gratuitos/formulariosGratuitos/detalle_formulario_editar/${state.idEdit}`,
      body
    );

    // console.log(response.data, "data")
    // console.log(response.status, "status")

    if (response.status == 422) {
      swal("Ups", response.error, "warning");
    }
    if (response.status == 200) {
      swal("Ok", "Formulario Editado", "success");
      limpiarProceso();
      limpiarOpciones();
      limpiar();
      // limpiarJson();
      // limpiarAllState();
    }
  };

  const SetToBackend = async () => {
    if (validForm()) {
      const newConfigState = Configstate.Formulario.Formularios[0].campos.map(
        (item) => {
          // console.log(item.id, "item.id")
          const newObjt = {
            name: "",
            elemento_id: "",
            validaciones: "",
            label: "",
            type: "",
            value: "",
            options: [],
          };
          newObjt.elemento_id = item.elemento_id;
          newObjt.name = item.name;
          newObjt.label = item.label;
          newObjt.type = item.type;
          newObjt.value = item.value;
          newObjt.options = item.options;
          return newObjt;
        }
      );

      const body = {
        elementos: newConfigState,
      };

      // console.log(body, "body");

      const response = await services(
        "POST",
        `gratuitos/formulariosGratuitos/detalle_formulario_agregar/${state.idEdit}`,
        body
      );

      if (response.status == 422) {
        swal("Ups", response.error, "warning");
      }
      if (response.status == 200) {
        swal("Ok", "Formulario creado", "success");
        limpiarProceso();
        limpiarOpciones();
        limpiar();
        // limpiarJson();
        // limpiarAllState();
      }

      // }
    } else {
      swal("Ups", "LLena todos los campos", "warning");
    }
  };

  const limpiarJson = () => {
    setConfigstate({
      Formulario: {
        pagoLinea: 1,
        Firma: 1,
        observaciones: "",
        categoria: "",
        claveIngreso: "",
        validacion: 1,
        documento: 1,
        Formularios: [
          {
            nombre: "",
            campos: [],
          },
        ],
      },
    });
  };
  const limpiarProceso = () => {
    setStateProceso({
      validacionTramite: true,
      pagoLinea: false,
      entregaResultado: false,
    });
  };

  const limpiarAllState = () => {
    setState({
      ...state,

      ConceptoSelected: "",
      categoriaSelected: "",
      validarCategoria: true,
      conceptosSelect: [],

      categoria: "",
      concepto: "",
      nombreForm: "",
      descripcionForm: "",
      Opcionuno: "",
    });
  };

  const handleOpcioneComponent = (e) => {
    setState({ ...state, Opcionuno: e.target.value });
  };

  const handlesetCategoriaCampo = (options) => {
    setState({ ...state, validaciones: [] });
    setLoadingSelect(true);
    setItemState({
      ...ItemState,
      tipo_input: options.tipo,
      elemento_id: options.id,
    });

    GetValidaciones(options.id);
  };

  const GetValidaciones = async (id) => {
    let validaciones = await services(
      "GET",
      `formularios/validaciones/${id}`,
      null
    );

    setState({ ...state, validaciones: validaciones.data });
    setLoadingSelect(false);
  };

  const options = {
    selectableRows: false,
    pagination: true,
    filter: false, // corregir esta parte
    tableBodyHeight: "auto",
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    download: false,
    print: false,
    viewColumns: false,
    downloadOptions: {
      filename: "tablaUsuarios.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "white",
    height: "60%",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    overflow: "hidden",
    overflowY: "scroll",
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let id = state.dataForms[dataIndex].id;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {id}
            </div>
          );
        },
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.dataForms[dataIndex].nombre;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "dependencia",
      label: "Dependencia",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.dataForms[dataIndex].dependencia.nombre;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                  : "w-100 d-flex justify-content-center align-items-center "
              }
            >
              <div
                style={{ height: 30, width: 30, zIndex: 0 }}
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  const depIdBuscado =
                    state.dataForms[dataIndex].dependencia.id;

                  setState({
                    ...state,
                    modal: true,
                    type: "edit",
                    nombre: state.dataForms[dataIndex].nombre,
                    dependencia: state.dataForms[dataIndex].dependencia.nombre,
                    id_dependencia: state.dataForms[dataIndex].dependencia.id,
                    idEdit: state.dataForms[dataIndex].id,
                  });
                  /* setDep({
                                        dependencia: state.dataForms[dataIndex].dependencia.nombre,
                                        id_dependencia: state.dataForms[dataIndex].dependencia.id
                                    }) */
                  if (localStorage.getItem("rol") == "Administrador") {
                    const dependenciaEncontrada = data.apidependencias.find(
                      (dep) => dep.id === depIdBuscado
                    );

                    setTemplate({
                      ...templates,
                      dependencia_id: state.dataForms[dataIndex].dependencia.id,
                      SelectedDep: dependenciaEncontrada,
                    });
                  }
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <AiOutlineEdit />
                </IconUI>
                <div className="icon_btn_help">Editar</div>
              </div>

              <div
                style={{
                  height: 30,
                  width: 30,
                  zIndex: 0,
                  backgroundColor: "#6f1d46",
                }}
                className="cta cta--purple cta--icon icon_btn mx-2"
                onClick={async () => {
                  // AQUI
                  window.scrollTo({ top: 0, behavior: "smooth" });

                  let response = await services(
                    "GET",
                    `gratuitos/formulariosGratuitos/detalle_formulario_consultar/${state.dataForms[dataIndex].id}`,
                    null
                  );
                  // console.log(response, "detalle formulario")

                  let newCampos;
                  let newForm;
                  // console.log(state.categoriasInputs)
                  if (
                    response.data.message ===
                    "No se encontraron detalles del formulario."
                  ) {
                    newCampos = [];
                    newForm = true;
                  } else {
                    newCampos = response.data.data.filter((item) => {
                      return item.active !== 0;
                    });
                  }

                  setConfigstate({
                    ...Configstate,
                    Formulario: {
                      ...Configstate.Formulario,
                      Formularios: [
                        {
                          ...Configstate.Formulario.Formularios[0],
                          campos: newCampos,
                        },
                      ],
                    },
                    nuevo: newForm,
                  });

                  setState({
                    ...state,
                    nombre: state.dataForms[dataIndex].nombre,
                    idEdit: state.dataForms[dataIndex].id,
                    agregarElementos: true,
                  });
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <AiOutlineAppstoreAdd />
                </IconUI>
                <div className="icon_btn_help">Añadir elementos</div>
              </div>
              {state.dataForms[dataIndex].active == 1 ? (
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--danger cta--icon icon_btn"
                  onClick={() => {
                    swal({
                      title: "¿Estás seguro de desactivar este formulario?",
                      buttons: {
                        cancel: "Cancelar",
                        desactivar: "Desactivar",
                      },
                    }).then(async (result) => {
                      if (result == "desactivar") {
                        let data = new FormData();
                        data.append(
                          "formulario_id",
                          state.dataForms[dataIndex].id
                        );
                        data.append(
                          "nombre",
                          state.dataForms[dataIndex].nombre
                        );
                        data.append("active", 0);
                        data.append(
                          "dependencia_id",
                          state.dataForms[dataIndex].dependencia.id
                        );
                        // console.log(
                        //   state.dataForms[dataIndex].id,
                        //   state.dataForms[dataIndex].nombre
                        // );
                        // let response = await services('FREEAPIPOST', `formulariosGratuitos/crear_editar`, data)
                        let response = await services(
                          "POST",
                          `gratuitos/formulariosGratuitos/crear_editar`,
                          data
                        );
                        // console.log(response);
                        if (response.status === 200) {
                          swal(response.data.message);
                          limpiar();
                        }
                      } else {
                        swal("Se canceló la desactivación del formulario");
                      }
                    });
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <AiOutlineLock />
                  </IconUI>
                  <div className="icon_btn_help">Desactivar</div>
                </div>
              ) : (
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--success cta--icon icon_btn"
                  onClick={() => {
                    swal({
                      title: "¿Estás seguro de activar este formulario?",
                      buttons: {
                        cancel: "Cancelar",
                        activar: "Activar",
                      },
                    }).then(async (result) => {
                      if (result == "activar") {
                        let data = new FormData();
                        data.append(
                          "formulario_id",
                          state.dataForms[dataIndex].id
                        );
                        data.append(
                          "nombre",
                          state.dataForms[dataIndex].nombre
                        );
                        data.append("active", 1);
                        data.append(
                          "dependencia_id",
                          state.dataForms[dataIndex].dependencia.id
                        );
                        // console.log(
                        //   state.dataForms[dataIndex].id,
                        //   state.dataForms[dataIndex].nombre
                        // );
                        // let response = await services('FREEAPIPOST', `formulariosGratuitos/crear_editar`, data)
                        let response = await services(
                          "POST",
                          `gratuitos/formulariosGratuitos/crear_editar`,
                          data
                        );
                        // console.log(response);
                        if (response.status === 200) {
                          swal(response.data.message);
                          limpiar();
                        }
                      } else {
                        swal("Se canceló la activación del formulario");
                      }
                    });
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <AiOutlineUnlock />
                  </IconUI>
                  <div className="icon_btn_help">Activar</div>
                </div>
              )}
            </div>
          </>
        ),
      },
    },
  ];

  const handleClose = () => {
    setState({
      ...state,
      modal: false,
      type: "",
      nombre: "",
      // descripcion: "",
      codigo: "",
      responsable: "",
      telefono: "",
      email: "",
      Opcionuno: "",
      // idEdit: 0
    });
    setTemplate({ ...templates, dependencia_id: "", SelectedDep: null });
  };

  const limpiar = async () => {
    let dataApi = await services(
      "GET",
      `gratuitos/formulariosGratuitos/list`,
      null
    );

    setState({
      ...state,
      nombre: "",
      // type: "",
      // idEdit: 0,
      modal: false,
      // agregarElementos: false,
      dataForms: dataApi.data.data.reverse(),
    });
    setTemplate({ ...templates, dependencia_id: "", SelectedDep: null });
  };

  const isValid = () => {
    let name = state.nombre !== "";

    return name;
  };

  const AddAndEditArea = async () => {
    // console.log(isValid())
    if (isValid()) {
      const url =
        state.type === "add"
          ? `gratuitos/formulariosGratuitos/crear_editar`
          : `gratuitos/formulariosGratuitos/crear_editar`;

      const body =
        rol !== "Administrador"
          ? {
              nombre: state.nombre,
            }
          : {
              nombre: state.nombre,
              dependencia_id: templates.dependencia_id,
            };

      const bodyEdit =
        rol !== "Administrador"
          ? {
              formulario_id: state.idEdit,
              nombre: state.nombre,
            }
          : {
              formulario_id: state.idEdit,
              nombre: state.nombre,
              dependencia_id: templates.dependencia_id,
            };

      let dataApi = await services(
        "POST",
        url,
        state.type === "add" ? body : bodyEdit
      );

      if (dataApi.status === 200) {
        limpiar();
        swal("Ok", dataApi.data.message, "success");
      } else if (dataApi.status === 500) {
        swal(dataApi.data.message, dataApi.data.error, "error");
      }
    } else {
      swal("Ups", "añade por lo menos un nombre", "warning");
    }
  };
  const handleChangeDependencia = (item) => {
    setTemplate({ ...templates, dependencia_id: item.id, SelectedDep: item });
  };

  return (
    <div className={"container"} style={{ height: "1200px" }}>
      {state.loading ? (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
          <GifLoader></GifLoader>
        </div>
      ) : (
        <></>
      )}

      <div className="text-big mt-4">Configura tu trámite Gratuito</div>

      <div className="container my-3 ">
        {state.agregarElementos ? (
          <div
            className="d-flex text-guinda cursor-pointer"
            onClick={() => {
              setState({ ...state, agregarElementos: false });
            }}
          >
            <IconUI size={"25px"}>
              <IoReturnDownBack />
            </IconUI>
            <div className="ms-3">Regresar</div>
          </div>
        ) : null}
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          {state.nombre && state.agregarElementos
            ? state.nombre
            : "Crear Formulario"}
        </div>
      </div>

      {state.agregarElementos ? null : (
        <>
          <div className="container">
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
              <Grid align="center" item xs={12} sm={6} md={6}></Grid>
              <Grid align="right" item xs={12} sm={6} md={6}>
                <div
                  style={{ width: 200 }}
                  className="cta cta--guinda"
                  onClick={() => {
                    setState({ ...state, modal: true, type: "add" });
                  }}
                >
                  <div className="me-2">
                    <IconUI size={"20px"} color={"white"}>
                      <FaNetworkWired />
                    </IconUI>
                  </div>{" "}
                  Agregar
                </div>
              </Grid>
            </Grid>
          </div>

          <Box sx={{ flexGrow: 1 }}>
            <Grid className="container mt-5 mb-5">
              <Grid item xs={8}>
                <MUIDataTable
                  style={{ Overflow: "hidden" }}
                  title={"Listado de formularios"}
                  data={state.dataForms}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Modal
        open={state.modal}
        onClose={handleClose}
        aria-labelledby="modal-add-new-item"
        aria-describedby="modal-add-item"
      >
        <Box sx={style}>
          <div className="d-flex mb-3">
            <div className="col"></div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleClose();
              }}
            >
              <IconUI size={15}>
                <GrClose></GrClose>
              </IconUI>
            </div>
          </div>
          <div className="container my-3">
            <div className="border-bottom--guinda text-big mb-2 text-bold">
              {state.type === "add"
                ? "Crear nuevo Formulario"
                : `Editar Formulario`}
            </div>
          </div>
          <Box sx={{ flexGrow: 1, marginTop: "3%" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={12} key={0}>
                <TextField
                  value={state.nombre}
                  onChange={(e) => {
                    const regex = /^[a-zA-Z0-9]*$/;
                    if (regex.test(e.target.value) || e.target.value === "") {
                      setState({ ...state, nombre: e.target.value });
                    }
                  }}
                  id="nombre"
                  label="Nombre"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              {rol == "Administrador" ? (
                <Grid align="center" item xs={2} sm={4} md={12}>
                  <InputLabel
                    style={{ marginLeft: 0, marginTop: 35 }}
                    id="demo-simple-select-helper-label"
                  >
                    Dependencias *
                  </InputLabel>
                  <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                    <Select
                      style={{ zIndex: 110 }}
                      options={data.apidependencias}
                      placeholder="Seleccione Dependencia"
                      getOptionValue={(options) => options.id}
                      getOptionLabel={(options) => options.nombre}
                      onChange={(options) => handleChangeDependencia(options)}
                      id="id_dependencia"
                      name="id_dependencia"
                      components={{ NoOptionsMessage }}
                      value={templates.SelectedDep}
                    />
                    <FormHelperText>Selecciona una dependencia.</FormHelperText>
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>
          </Box>

          <div className="container">
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
              <Grid
                align="center"
                item
                xs={12}
                sm={6}
                md={12}
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{ width: 200 }}
                  className="cta cta--guinda"
                  onClick={() => {
                    AddAndEditArea();
                  }}
                >
                  <div className="me-2">
                    <IconUI size={"20px"} color={"white"}>
                      {state.type === "add" ? (
                        <AiFillPlusCircle />
                      ) : (
                        <AiFillEdit />
                      )}{" "}
                    </IconUI>
                  </div>{" "}
                  {state.type === "add" ? "Agregar" : `Editar`}
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>

      {state.agregarElementos ? (
        <>
          <div className="mt-2">
            <ColorButtonSecond
              onClick={handleOpen}
              style={{
                marginTop: "2%",
                marginBottom: "2%",
                backgroundColor: "#6f1d46",
                color: "white",
              }}
              variant="outlined"
              endIcon={<AddBoxIcon />}
            >
              {" "}
              Añadir Elemento{" "}
            </ColorButtonSecond>
          </div>

          <div className="container my-3">
            <div className="border-bottom--guinda text-big mb-2 text-bold">
              Vista Previa
            </div>
          </div>

          <div className="my-5">
            <Box sx={{ width: "100%" }}>
              <Grid
                direction="row"
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                columns={{ xs: 1, sm: 2, md: 2 }}
              >
                {/* {console.log(Configstate)} */}
                {Configstate.Formulario.Formularios[0].campos.map((item) => {
                  // console.log(item.id, "item formulario")
                  if (item.active !== 0) {
                    return (
                      <Grid item xs={1} direction="row">
                        {setComponent(item)}
                      </Grid>
                    );
                  } else {
                    // console.log(item, "no activo");
                  }
                })}
              </Grid>
            </Box>
            <ColorButton
              sx={{ marginTop: "3%", marginLeft: "75%" }}
              onClick={AlertPagoLinea}
              variant="outlined"
            >
              {"Finalizar Configuración"}
            </ColorButton>
          </div>
        </>
      ) : null}

      <Modal
        open={open}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex mb-3">
            <div className="col"></div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleClose1();
              }}
            >
              <IconUI size={15}>
                <GrClose></GrClose>
              </IconUI>
            </div>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Selecciona el tipo de campo que quieres para tu formulario
          </Typography>
          <Box sx={{ flexGrow: 1, marginTop: "5%" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {ComponentesList.map((item, index) => {
                // console.log(item, "listado de tipos de inputs")
                return (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Item
                      className="cursor-pointer itemInput"
                      onClick={() => {
                        ItemSeleccionado(
                          item.tipo,
                          item.type,
                          item.idValidacion
                        );
                      }}
                    >
                      {item.tipo} {item.icon}{" "}
                    </Item>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {/* <ColorButton sx={{ marginTop: "3%", marginLeft: "85%" }} onClick={handleClose1} variant="outlined"> Terminar </ColorButton> */}
        </Box>
      </Modal>

      <Modal open={openModalItem.edit} onClose={handleCloseModalItemEdit}>
        <Box sx={styleModalItem}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edita tu Componente {openModalItem.opcionEdit}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              sx={{ marginBottom: "2%" }}
              fullWidth
              id="Nombre_Campo"
              label="Nombre del Campo"
              defaultValue=""
              variant="outlined"
              value={openModalItem.opcionEdit}
              onChange={(e) => {
                const value = e.target.value;
                if (!value.includes(".")) {
                  if (value.length <= 150) {
                    setItemState({
                      ...ItemState,
                      label: value,
                      name: value,
                    });
                    setOpenModalItem({
                      ...openModalItem,
                      opcionEdit: value,
                    });
                  }
                }
              }}
            />

            {console.log(ItemState)}
            {ItemState.options.length > 0 ? (
              ItemState.options.map((item, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      // width: 370,
                      maxWidth: "100%",
                      // display: 'flex',
                      // alignItems: 'flex-end'
                    }}
                  >
                    <InputLabel
                      sx={{ marginTop: "3%" }}
                      id={`labelFolio - ${i + 1} `}
                    >
                      Opción {i + 2}:
                    </InputLabel>
                    <Input
                      // sx={{ width: 260 }}
                      fullWidth
                      id={i.toString()}
                      type={item.type}
                      value={item.opcion ? item.opcion : ""}
                      onChange={handleChangeDinamicInputEdit}
                      inputProps={{ maxlength: 150 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="delete input"
                            onClick={(e) => handleDeleteInputOnEdit(e, item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                );
              })
            ) : (
              <></>
            )}

            <div className="mt-2">
              <InputLabel sx={{ marginTop: "10%" }} className="mx-2">
                Validaciones para el campo
              </InputLabel>
              <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                <Select
                  // closeMenuOnSelect={false}
                  {...propsValidaciones}
                  isMulti
                  options={openModalItem.validacionesInputSelected}
                  placeholder="Seleccione una Validación"
                  getOptionValue={(options) => options.validacion}
                  getOptionLabel={(options) => `${options.descripcion}`}
                  onChange={(options) => validacionesInputSet(options)}
                  components={{ NoOptionsMessage }}
                />
              </FormControl>
            </div>

            {cantidadValidar.min ? (
              <div className="mt-2">
                <TextField
                  sx={{ marginBottom: "2%" }}
                  fullWidth
                  id="min"
                  label="Ingrese el minimo de caracteres permitidos"
                  defaultValue=""
                  variant="outlined"
                  value={cantidadValidar.valuemin}
                  onChange={(e) =>
                    setCcantidad({
                      ...cantidadValidar,
                      valuemin: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <></>
            )}
            {cantidadValidar.size ? (
              <div className="mt-2">
                <TextField
                  sx={{ marginBottom: "2%" }}
                  fullWidth
                  id="size"
                  label="Ingrese el máximo de letras permitidas"
                  defaultValue=""
                  variant="outlined"
                  value={cantidadValidar.valuesize}
                  onChange={(e) =>
                    setCcantidad({
                      ...cantidadValidar,
                      valuesize: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </Typography>
          <ColorButton
            sx={{ marginTop: "3%", marginLeft: "75%" }}
            onClick={() =>
              handleUpdateItem(openModalItem.type, openModalItem.id)
            }
            variant="outlined"
          >
            {" "}
            Guardar{" "}
          </ColorButton>
        </Box>
      </Modal>

      {/* configura tu componente */}

      <Modal
        open={openModalItem.open}
        onClose={handleCloseModalItem}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalItem}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Configura tu Componente {openModalItem.opcion}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              sx={{ marginBottom: "2%" }}
              fullWidth
              id="Nombre_Campo"
              label="Nombre del Campo"
              defaultValue=""
              variant="outlined"
              value={ItemState.name}
              onChange={(e) => {
                let value = e.target.value;

                if (!value.includes(".")) {
                  if (value.length <= 150) {
                    setItemState({
                      ...ItemState,
                      label: value,
                      name: value,
                    });
                  }
                }
              }}
            />

            {openModalItem.opcion === "INPUT" ? (
              <div className="mt-2">
                <InputLabel className="mx-2">Categoria del campo</InputLabel>
                <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                  <Select
                    options={state.categoriasInputs}
                    placeholder="Seleccione una Categoría"
                    getOptionValue={(options) => options.id}
                    getOptionLabel={(options) => options.tipo}
                    onChange={handlesetCategoriaCampo}
                    components={{ NoOptionsMessage }}
                  />
                </FormControl>
              </div>
            ) : (
              <></>
            )}
            {openModalItem.opcion === "SELECCIÓN" ||
            openModalItem.opcion === "CHECKS" ||
            openModalItem.opcion === "BOTÓN OPCIÓN" ? (
              <>
                <InputLabel id="demo-simple-select-helper-label">
                  Opción 1:
                </InputLabel>
                <Input
                  fullWidth
                  helperText="Ingresa la opción"
                  id="inputOpcion1"
                  type="text"
                  inputProps={{ maxlength: 150 }}
                  // value={folioCaptura.folio ? folioCaptura.folio : ''}
                  onChange={handleOpcioneComponent}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="add folio"
                        onClick={() => {
                          agregar();
                        }}
                        sx={{ color: colorUI, backgroundColor: "#ffc3d0" }}
                      >
                        <AddToPhotosIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {opciones.length > 0 ? (
                  opciones.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          // width: 370,
                          maxWidth: "100%",
                          // display: 'flex',
                          // alignItems: 'flex-end'
                        }}
                      >
                        <InputLabel
                          sx={{ marginTop: "3%" }}
                          id={`labelFolio - ${i + 1} `}
                        >
                          Opción {i + 2}:
                        </InputLabel>
                        <Input
                          // sx={{ width: 260 }}
                          fullWidth
                          id={i.toString()}
                          type={item.type}
                          inputProps={{ maxlength: 150 }}
                          value={item.value ? item.value : ""}
                          onChange={handleChangeDinamicInput}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="delete input"
                                onClick={(e) => handleDeleteInput(e, item)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="mt-2">
              <InputLabel sx={{ marginTop: "10%" }} className="mx-2">
                Validaciones para el campo
              </InputLabel>
              <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                <Select
                  // closeMenuOnSelect={false}
                  isLoading={loadingSelect}
                  isMulti
                  options={state.validaciones}
                  placeholder="Seleccione una Validación"
                  getOptionValue={(options) => options.validacion}
                  getOptionLabel={(options) => `${options.descripcion} `}
                  onChange={(options) => validacionesInputSet(options)}
                  components={{ NoOptionsMessage }}
                />
              </FormControl>
            </div>

            {cantidadValidar.min ? (
              <div className="mt-2">
                <TextField
                  sx={{ marginBottom: "2%" }}
                  fullWidth
                  id="min"
                  label="Ingrese el minimo requerido"
                  defaultValue=""
                  variant="outlined"
                  // value={ItemState.name}
                  onChange={(e) =>
                    setCcantidad({
                      ...cantidadValidar,
                      valuemin: e.target.value,
                    })
                  }
                />
              </div>
            ) : null}
            {cantidadValidar.size ? (
              <div className="mt-2">
                <TextField
                  sx={{ marginBottom: "2%" }}
                  fullWidth
                  id="size"
                  label={
                    validacionesFile.max
                      ? "Ingrese el maximo de MB "
                      : "Ingrese el minimo requerido"
                  }
                  defaultValue=""
                  variant="outlined"
                  // value={ItemState.name}
                  onChange={(e) =>
                    setCcantidad({
                      ...cantidadValidar,
                      valuesize: e.target.value,
                    })
                  }
                />
              </div>
            ) : null}
          </Typography>
          <ColorButton
            sx={{ marginTop: "3%", marginLeft: "75%" }}
            onClick={() => handleAddItem(openModalItem.type)}
            variant="outlined"
          >
            {" "}
            Guardar{" "}
          </ColorButton>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfiguraTramiteGratuito;
