export function numberFormatMexico(number) {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    let num_clear = number.toString().replaceAll("$", "").replaceAll(" ", "").replaceAll(",", "");
    let arr = num_clear.split('.');
    arr[0] = arr[0].replace(exp, rep);
    if (arr[1]) {
        if (arr[1] == '0') {
            arr[1] = "00";
        }
        return arr.join('.');
    } else {
        return arr[0] + ".00";
    }
}