import React, { useContext, useState, useEffect } from "react";
// import logo from '../assets/img/logo_completo.png'

import logo from "../assets/img/aniversary-200.png";
import logoTeleferico200 from "../assets/img/LogoTeleferico200.png";
import logoVentanilla from "../assets/img/ventanilladigital.png";
// import logo from "../assets/img/Veda_Horizontal.png";
// import Sello from '../assets/img/Sello.png';
import Sello from "../assets/img/Sello.png";
import SelloWhite from "../assets/img/sello-white.png";
import logoVentanillaWhite from "../assets/img/ventanilla-tramites-white.png";
import logoTelefericoWhite from "../assets/img/logo-teleferico-white.png";
import { useHistory } from "react-router-dom";

// import LogoSecretaría from '../assets/img/LogoSecretaría.jpeg'
import capturaContext from "../context/captura_context/capturaContext";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuComponent from "./menu_component";
import IconUI from "./ui/icon_ui";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

import MenuIcon from "@mui/icons-material/Menu";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";

export const HeaderComponent = () => {
  let url = window.location.href;

  const history = useHistory();
  const location = useLocation();

  const { currentUser, setCurrentUser, setUserData, userData } =
    useContext(capturaContext);

  const user = JSON.parse(localStorage.getItem("usuario"));
  const rol = localStorage.getItem("rol");

  const [MenuLateral, setMenuLateral] = useState(false);
  const [menu, setmenu] = useState({
    open: false,
    modaluser: false,
  });
  const [showLogo, setShowLogo] = useState(false);

  const openmenu = () => {
    setmenu({ ...menu, open: !menu.open });
  };

  const token = localStorage.getItem("token");

  // const handleListItemClick = (event, opcion) => {
  //   setmenu({ ...menu, open: false });
  //   if (opcion === 0) {
  //     history.replace("/admin");
  //   }
  //   if (opcion === 1) {
  //     history.replace("/ventanilla");
  //   }
  // };

  // const cerrarSesion = () => {
  //   setCurrentUser(null);
  //   localStorage.clear();
  //   setmenu({ ...menu, open: false });
  //   history.replace("/");
  //   window.location.replace(process.env.REACT_APP_URLLLAVEGLOBAL);
  // };

  // const handleModalPerfil = () => {
  //     setmenu({ ...menu, modaluser: true })
  // }
  const handleShowMenuLateral = () => {
    setMenuLateral(true);
  };
  const handleHiddenMenuLateral = () => {
    setMenuLateral(false);
  };

  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login") {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (url.includes("educacion")) {
        setShowLogo(true);
      } else {
        setShowLogo(false);
      }
    }
    if (localStorage.getItem("token")) {
      setUserData(JSON.parse(localStorage.getItem("usuario")));
      setCurrentUser(true);
    } else {
      setCurrentUser(null);
    }

    return () => {
      isMounted = false;
    };
  }, [url, window.location]);

  const [vistaActual, setVistaActual] = useState("principal");

  const [logoLogin, setLogoLogin] = useState(false);

  const [sectionSelected, setSectionSelected] = useState(true);

  // useEffect(() => {
  //   const handlePopstate = (event) => {
  //     if (event.state && event.state.vista) {
  //       setVistaActual(event.state.vista);
  //     } else {
  //       setSectionSelected(true);
  //     }
  //   };

  //   window.addEventListener("popstate", handlePopstate);
  //   console.log(window.screenTop);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, []);

  const regresar = (value) => {
    if (value === "login") {
      history.replace("/");
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      {MenuLateral && currentUser ? (
        <MenuComponent onClose={handleHiddenMenuLateral}></MenuComponent>
      ) : null}
      <div className="py-2 bg-guinda-header header-z position-fixed d-block w-100 top-0 ">
        <div className="position-relative">
          <div className="container">
            <nav className="navbar pb-0 mb-0 navbar-light navbar-expand-lg bg-guinda-header">
              <div className="container-fluid p-0">
                <div className="d-flex w-100">
                  <Grid container spacing={12}>
                    <Grid item xs>
                      {token ? (
                        <div className="d-flex align-items-center">
                          {currentUser ? (
                            <div
                              className="mx-4 cursor-pointer"
                              onClick={handleShowMenuLateral}
                            >
                              <IconUI size={"20px"} color={"#fff"}>
                                <HiOutlineMenuAlt2 />
                              </IconUI>
                            </div>
                          ) : null}

                          <div
                            onClick={() => {
                              setShowLogo(false);
                              if (token) {
                                history.replace("/home");
                              } else {
                                history.replace("/");
                              }
                            }}
                            className="d-flex justify-content-center align-items-center gap-2 cursor-pointer"
                          >
                            <img
                              className="logo-header HeaderResponsivelogoMicht"
                              src={logoTelefericoWhite}
                              alt="Logo"
                            />
                            <img
                              className="logo-header-ventanilla HeaderResponsivelogoGD"
                              src={logoVentanillaWhite}
                              alt="Ventanilla Digital de Trámites"
                            />
                          </div>

                          {showLogo ? (
                            // <img className="logo-header mx-3" src={LogoSecretaría} alt="logo" />
                            <></>
                          ) : (
                            <div className="ms-3 text-guinda HeaderResponsiveText fw-bold">
                              VENTANILLA DIGITAL DE TRÁMITES
                            </div>
                          )}

                          <div className="col"></div>
                          {currentUser ? (
                            <>
                              <p
                                className="labelusuario"
                                style={{
                                  marginBottom: "0px",
                                  marginTop: " 0.5rem",
                                  color: "white",
                                }}
                                // >{`${userData.nombre} (${userData.rol})`}</p>
                              >{`${user.nombre} (${rol})`}</p>
                            </>
                          ) : (
                            <>
                              <div
                                className="cta btnLoginHeader"
                                style={{
                                  width: "max-content",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  history.push("/login");
                                }}
                              >
                                Acceso a Funcionarios
                              </div>

                              {/* <img className='logoSello ms-5' src={Sello} alt="logo" /> */}
                            </>
                          )}

                          <img
                            className="logoSello ms-md-4 HeaderResponsivelogoGD d-none d-md-block"
                            src={SelloWhite}
                            alt="logo"
                          />
                          <IconButton
                            className="usuariobutton"
                            onClick={() => openmenu()}
                          >
                            {menu.open ? (
                              <CloseIcon sx={{ color: "#fff" }} />
                            ) : (
                              <MenuIcon sx={{ color: "#fff" }} />
                            )}
                          </IconButton>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          {currentUser ? (
                            <div
                              className="mx-4 cursor-pointer"
                              onClick={handleShowMenuLateral}
                            >
                              <IconUI size={"20px"} color={"#6f1d46"}>
                                <HiOutlineMenuAlt2 />
                              </IconUI>
                            </div>
                          ) : null}

                          <div
                            onClick={() => {
                              if (login) {
                                regresar("login");
                              } else {
                                regresar("home");
                              }
                            }}
                            className="d-flex justify-content-center align-items-center gap-2 cursor-pointer"
                          >
                            <img
                              className="logo-header HeaderResponsivelogoMicht"
                              src={logoTelefericoWhite}
                              alt="logo"
                            />
                            <img
                              className="logo-header-ventanilla HeaderResponsivelogoGD"
                              src={logoVentanillaWhite}
                              alt="Ventanilla Digital de Trámites"
                            />
                          </div>

                          {showLogo ? (
                            // <img className="logo-header mx-3" src={LogoSecretaría} alt="logo" />
                            <></>
                          ) : (
                            <div className="ms-3 text-guinda HeaderResponsiveText fw-bold">
                              VENTANILLA DIGITAL DE TRÁMITES
                            </div>
                          )}

                          <div className="col"></div>
                          {currentUser ? (
                            <>
                              <p
                                className="labelusuario"
                                style={{
                                  marginBottom: "0px",
                                  marginTop: " 0.5rem",
                                }}
                                // >{`${userData.nombre} (${userData.rol})`}</p>
                              >{`${user.nombre} (${rol})`}</p>
                            </>
                          ) : (
                            <>
                              <div
                                className="cta cta--guinda btnLoginHeader p-4 p-lg-2"
                                style={{
                                  width: "180px",
                                  marginLeft: "5px",
                                  borderRadius: "10px",
                                  height: "40px",
                                  fontSize: "14px ",
                                }}
                                onClick={() => {
                                  history.push("/login");
                                }}
                              >
                                Acceso a Funcionarios
                              </div>

                              {/* <img className='logoSello ms-5' src={Sello} alt="logo" /> */}
                            </>
                          )}

                          <img
                            className="logoSello ms-md-4 HeaderResponsivelogoGD d-none d-md-block"
                            src={SelloWhite}
                            alt="logo"
                          />
                          <IconButton
                            className="usuariobutton"
                            onClick={() => openmenu()}
                          >
                            {menu.open ? (
                              <CloseIcon sx={{ color: "#fff" }} />
                            ) : (
                              <MenuIcon sx={{ color: "#fff" }} />
                            )}
                          </IconButton>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className="borderBottom--guindaClaro line-header d-none d-md-block mt-2"></div>

      {menu.open ? (
        <>
          <Box
            className="opcionesUsuario shadow mt-4"
            sx={{
              width: "100%",
              maxWidth: 230,
              zIndex: 999,
            }}
          >
            <List component="nav" aria-label="main mailbox folders">
              {currentUser?.rol ? (
                <>
                  <p
                    className="labelusuario"
                    style={{ marginBottom: "0px", marginTop: " 0.5rem" }}
                  >
                    {userData.nombre}
                  </p>
                </>
              ) : (
                <>
                  <ListItemButton
                    className="acceso-funcionarios-z"
                    onClick={(event) => {
                      setmenu({ ...menu, open: false });
                      history.replace("/login");
                    }}
                  >
                    <ListItemText primary="Acceso a Funcionarios" />
                    <ListItemIcon>
                      <LockOpenIcon className="color-text" />
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    className="acceso-funcionarios-z"
                    onClick={(event) => {
                      setmenu({ ...menu, open: false });
                      history.replace("/");
                    }}
                  >
                    <ListItemText primary="Inicio" />
                    <ListItemIcon>
                      <HomeIcon className="color-text" />
                    </ListItemIcon>
                  </ListItemButton>
                </>
              )}
              {/* <ListItemButton
                                onClick={(event) => cerrarSesion()}
                            >
                                <ListItemText primary="Cerrar Sesión" />
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                            </ListItemButton> */}
            </List>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
