import React, { createContext, useState, useContext } from "react";

const FooterVisibilityContext = createContext();

export const FooterVisibilityProvider = ({ children }) => {
  const [isFooterVisible, setFooterVisibility] = useState(true);

  return (
    <FooterVisibilityContext.Provider
      value={{ isFooterVisible, setFooterVisibility }}
    >
      {children}
    </FooterVisibilityContext.Provider>
  );
};

export const useFooterVisibility = () => useContext(FooterVisibilityContext);
